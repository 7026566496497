const insTokens = {
  "stocks": [
    {
      "instrument_token": "738561",
      "name": "RELIANCE INDUSTRIES",
      "tradingSymbol": "RELIANCE",
      "label": "RELIANCE",
      "value": "RELIANCE"
    },
    {
      "instrument_token": "2953217",
      "name": "TATA CONSULTANCY SERV LT",
      "tradingSymbol": "TCS",
      "label": "TCS",
      "value": "TCS"
    },
    {
      "instrument_token": "341249",
      "name": "HDFC BANK",
      "tradingSymbol": "HDFCBANK",
      "label": "HDFCBANK",
      "value": "HDFCBANK"
    },
    {
      "instrument_token": "1270529",
      "name": "ICICI BANK",
      "tradingSymbol": "ICICIBANK",
      "label": "ICICIBANK",
      "value": "ICICIBANK"
    },
    {
      "instrument_token": "2714625",
      "name": "BHARTI AIRTEL",
      "tradingSymbol": "BHARTIARTL",
      "label": "BHARTIARTL",
      "value": "BHARTIARTL"
    },
    {
      "instrument_token": "779521",
      "name": "STATE BANK OF INDIA",
      "tradingSymbol": "SBIN",
      "label": "SBIN",
      "value": "SBIN"
    },
    {
      "instrument_token": "408065",
      "name": "INFOSYS",
      "tradingSymbol": "INFY",
      "label": "INFY",
      "value": "INFY"
    },
    {
      "instrument_token": "2426881",
      "name": "LIFE INSURA CORP OF INDIA",
      "tradingSymbol": "LICI",
      "label": "LICI",
      "value": "LICI"
    },
    {
      "instrument_token": "424961",
      "name": "ITC",
      "tradingSymbol": "ITC",
      "label": "ITC",
      "value": "ITC"
    },
    {
      "instrument_token": "356865",
      "name": "HINDUSTAN UNILEVER",
      "tradingSymbol": "HINDUNILVR",
      "label": "HINDUNILVR",
      "value": "HINDUNILVR"
    },
    {
      "instrument_token": "2939649",
      "name": "LARSEN & TOUBRO",
      "tradingSymbol": "LT",
      "label": "LT",
      "value": "LT"
    },
    {
      "instrument_token": "81153",
      "name": "BAJAJ FINANCE",
      "tradingSymbol": "BAJFINANCE",
      "label": "BAJFINANCE",
      "value": "BAJFINANCE"
    },
    {
      "instrument_token": "1850625",
      "name": "HCL TECHNOLOGIES",
      "tradingSymbol": "HCLTECH",
      "label": "HCLTECH",
      "value": "HCLTECH"
    },
    {
      "instrument_token": "2815745",
      "name": "MARUTI SUZUKI INDIA",
      "tradingSymbol": "MARUTI",
      "label": "MARUTI",
      "value": "MARUTI"
    },
    {
      "instrument_token": "857857",
      "name": "SUN PHARMACEUTICAL IND L",
      "tradingSymbol": "SUNPHARMA",
      "label": "SUNPHARMA",
      "value": "SUNPHARMA"
    },
    {
      "instrument_token": "6401",
      "name": "ADANI ENTERPRISES",
      "tradingSymbol": "ADANIENT",
      "label": "ADANIENT",
      "value": "ADANIENT"
    },
    {
      "instrument_token": "492033",
      "name": "KOTAK MAHINDRA BANK",
      "tradingSymbol": "KOTAKBANK",
      "label": "KOTAKBANK",
      "value": "KOTAKBANK"
    },
    {
      "instrument_token": "897537",
      "name": "TITAN COMPANY",
      "tradingSymbol": "TITAN",
      "label": "TITAN",
      "value": "TITAN"
    },
    {
      "instrument_token": "633601",
      "name": "OIL AND NATURAL GAS CORP.",
      "tradingSymbol": "ONGC",
      "label": "ONGC",
      "value": "ONGC"
    },
    {
      "instrument_token": "884737",
      "name": "TATA MOTORS",
      "tradingSymbol": "TATAMOTORS",
      "label": "TATAMOTORS",
      "value": "TATAMOTORS"
    },
    {
      "instrument_token": "2977281",
      "name": "NTPC",
      "tradingSymbol": "NTPC",
      "label": "NTPC",
      "value": "NTPC"
    },
    {
      "instrument_token": "1510401",
      "name": "AXIS BANK",
      "tradingSymbol": "AXISBANK",
      "label": "AXISBANK",
      "value": "AXISBANK"
    },
    {
      "instrument_token": "5097729",
      "name": "AVENUE SUPERMARTS",
      "tradingSymbol": "DMART",
      "label": "DMART",
      "value": "DMART"
    },
    {
      "instrument_token": "912129",
      "name": "ADANI GREEN ENERGY",
      "tradingSymbol": "ADANIGREEN",
      "label": "ADANIGREEN",
      "value": "ADANIGREEN"
    },
    {
      "instrument_token": "3861249",
      "name": "ADANI PORT & SEZ",
      "tradingSymbol": "ADANIPORTS",
      "label": "ADANIPORTS",
      "value": "ADANIPORTS"
    },
    {
      "instrument_token": "2952193",
      "name": "ULTRATECH CEMENT",
      "tradingSymbol": "ULTRACEMCO",
      "label": "ULTRACEMCO",
      "value": "ULTRACEMCO"
    },
    {
      "instrument_token": "60417",
      "name": "ASIAN PAINTS",
      "tradingSymbol": "ASIANPAINT",
      "label": "ASIANPAINT",
      "value": "ASIANPAINT"
    },
    {
      "instrument_token": "5215745",
      "name": "COAL INDIA",
      "tradingSymbol": "COALINDIA",
      "label": "COALINDIA",
      "value": "COALINDIA"
    },
    {
      "instrument_token": "4268801",
      "name": "BAJAJ FINSERV",
      "tradingSymbol": "BAJAJFINSV",
      "label": "BAJAJFINSV",
      "value": "BAJAJFINSV"
    },
    {
      "instrument_token": "4267265",
      "name": "BAJAJ AUTO",
      "tradingSymbol": "BAJAJ-AUTO",
      "label": "BAJAJ-AUTO",
      "value": "BAJAJ-AUTO"
    },
    {
      "instrument_token": "3834113",
      "name": "POWER GRID CORP.",
      "tradingSymbol": "POWERGRID",
      "label": "POWERGRID",
      "value": "POWERGRID"
    },
    {
      "instrument_token": "4598529",
      "name": "NESTLE INDIA",
      "tradingSymbol": "NESTLEIND",
      "label": "NESTLEIND",
      "value": "NESTLEIND"
    },
    {
      "instrument_token": "969473",
      "name": "WIPRO",
      "tradingSymbol": "WIPRO",
      "label": "WIPRO",
      "value": "WIPRO"
    },
    {
      "instrument_token": "519937",
      "name": "MAHINDRA & MAHINDRA",
      "tradingSymbol": "M&M",
      "label": "M&M",
      "value": "M&M"
    },
    {
      "instrument_token": "415745",
      "name": "INDIAN OIL CORP",
      "tradingSymbol": "IOC",
      "label": "IOC",
      "value": "IOC"
    },
    {
      "instrument_token": "4644609",
      "name": "JIO FIN SERVICES",
      "tradingSymbol": "JIOFIN",
      "label": "JIOFIN",
      "value": "JIOFIN"
    },
    {
      "instrument_token": "589569",
      "name": "HINDUSTAN AERONAUTICS",
      "tradingSymbol": "HAL",
      "label": "HAL",
      "value": "HAL"
    },
    {
      "instrument_token": "3771393",
      "name": "DLF",
      "tradingSymbol": "DLF",
      "label": "DLF",
      "value": "DLF"
    },
    {
      "instrument_token": "4451329",
      "name": "ADANI POWER",
      "tradingSymbol": "ADANIPOWER",
      "label": "ADANIPOWER",
      "value": "ADANIPOWER"
    },
    {
      "instrument_token": "3001089",
      "name": "JSW STEEL",
      "tradingSymbol": "JSWSTEEL",
      "label": "JSWSTEEL",
      "value": "JSWSTEEL"
    },
    {
      "instrument_token": "895745",
      "name": "TATA STEEL",
      "tradingSymbol": "TATASTEEL",
      "label": "TATASTEEL",
      "value": "TATASTEEL"
    },
    {
      "instrument_token": "806401",
      "name": "SIEMENS",
      "tradingSymbol": "SIEMENS",
      "label": "SIEMENS",
      "value": "SIEMENS"
    },
    {
      "instrument_token": "519425",
      "name": "INDIAN RAILWAY FIN CORP L",
      "tradingSymbol": "IRFC",
      "label": "IRFC",
      "value": "IRFC"
    },
    {
      "instrument_token": "4843777",
      "name": "VARUN BEVERAGES",
      "tradingSymbol": "VBL",
      "label": "VBL",
      "value": "VBL"
    },
    {
      "instrument_token": "1304833",
      "name": "ZOMATO",
      "tradingSymbol": "ZOMATO",
      "label": "ZOMATO",
      "value": "ZOMATO"
    },
    {
      "instrument_token": "681985",
      "name": "PIDILITE INDUSTRIES",
      "tradingSymbol": "PIDILITIND",
      "label": "PIDILITIND",
      "value": "PIDILITIND"
    },
    {
      "instrument_token": "315393",
      "name": "GRASIM INDUSTRIES",
      "tradingSymbol": "GRASIM",
      "label": "GRASIM",
      "value": "GRASIM"
    },
    {
      "instrument_token": "5582849",
      "name": "SBI LIFE INSURANCE CO",
      "tradingSymbol": "SBILIFE",
      "label": "SBILIFE",
      "value": "SBILIFE"
    },
    {
      "instrument_token": "98049",
      "name": "BHARAT ELECTRONICS",
      "tradingSymbol": "BEL",
      "label": "BEL",
      "value": "BEL"
    },
    {
      "instrument_token": "4561409",
      "name": "LTIMINDTREE",
      "tradingSymbol": "LTIM",
      "label": "LTIM",
      "value": "LTIM"
    },
    {
      "instrument_token": "502785",
      "name": "TRENT",
      "tradingSymbol": "TRENT",
      "label": "TRENT",
      "value": "TRENT"
    },
    {
      "instrument_token": "2730497",
      "name": "PUNJAB NATIONAL BANK",
      "tradingSymbol": "PNB",
      "label": "PNB",
      "value": "PNB"
    },
    {
      "instrument_token": "2865921",
      "name": "INTERGLOBE AVIATION",
      "tradingSymbol": "INDIGO",
      "label": "INDIGO",
      "value": "INDIGO"
    },
    {
      "instrument_token": "1195009",
      "name": "BANK OF BARODA",
      "tradingSymbol": "BANKBARODA",
      "label": "BANKBARODA",
      "value": "BANKBARODA"
    },
    {
      "instrument_token": "119553",
      "name": "HDFC LIFE INS CO",
      "tradingSymbol": "HDFCLIFE",
      "label": "HDFCLIFE",
      "value": "HDFCLIFE"
    },
    {
      "instrument_token": "3329",
      "name": "ABB INDIA",
      "tradingSymbol": "ABB",
      "label": "ABB",
      "value": "ABB"
    },
    {
      "instrument_token": "134657",
      "name": "BHARAT PETROLEUM CORP  LT",
      "tradingSymbol": "BPCL",
      "label": "BPCL",
      "value": "BPCL"
    },
    {
      "instrument_token": "3660545",
      "name": "POWER FIN CORP",
      "tradingSymbol": "PFC",
      "label": "PFC",
      "value": "PFC"
    },
    {
      "instrument_token": "2585345",
      "name": "GODREJ CONSUMER PRODUCTS",
      "tradingSymbol": "GODREJCP",
      "label": "GODREJCP",
      "value": "GODREJCP"
    },
    {
      "instrument_token": "877057",
      "name": "TATA POWER CO",
      "tradingSymbol": "TATAPOWER",
      "label": "TATAPOWER",
      "value": "TATAPOWER"
    },
    {
      "instrument_token": "348929",
      "name": "HINDALCO  INDUSTRIES ",
      "tradingSymbol": "HINDALCO",
      "label": "HINDALCO",
      "value": "HINDALCO"
    },
    {
      "instrument_token": "364545",
      "name": "HINDUSTAN ZINC",
      "tradingSymbol": "HINDZINC",
      "label": "HINDZINC",
      "value": "HINDZINC"
    },
    {
      "instrument_token": "3465729",
      "name": "TECH MAHINDRA",
      "tradingSymbol": "TECHM",
      "label": "TECHM",
      "value": "TECHM"
    },
    {
      "instrument_token": "325121",
      "name": "AMBUJA CEMENTS",
      "tradingSymbol": "AMBUJACEM",
      "label": "AMBUJACEM",
      "value": "AMBUJACEM"
    },
    {
      "instrument_token": "1346049",
      "name": "INDUSIND BANK",
      "tradingSymbol": "INDUSINDBK",
      "label": "INDUSINDBK",
      "value": "INDUSINDBK"
    },
    {
      "instrument_token": "177665",
      "name": "CIPLA",
      "tradingSymbol": "CIPLA",
      "label": "CIPLA",
      "value": "CIPLA"
    },
    {
      "instrument_token": "1207553",
      "name": "GAIL (INDIA)",
      "tradingSymbol": "GAIL",
      "label": "GAIL",
      "value": "GAIL"
    },
    {
      "instrument_token": "3930881",
      "name": "REC",
      "tradingSymbol": "RECLTD",
      "label": "RECLTD",
      "value": "RECLTD"
    },
    {
      "instrument_token": "140033",
      "name": "BRITANNIA INDUSTRIES",
      "tradingSymbol": "BRITANNIA",
      "label": "BRITANNIA",
      "value": "BRITANNIA"
    },
    {
      "instrument_token": "2752769",
      "name": "UNION BANK OF INDIA",
      "tradingSymbol": "UNIONBANK",
      "label": "UNIONBANK",
      "value": "UNIONBANK"
    },
    {
      "instrument_token": "2615553",
      "name": "ADANI ENERGY SOLUTION",
      "tradingSymbol": "ADANIENSOL",
      "label": "ADANIENSOL",
      "value": "ADANIENSOL"
    },
    {
      "instrument_token": "2393089",
      "name": "INDIAN OVERSEAS BANK",
      "tradingSymbol": "IOB",
      "label": "IOB",
      "value": "IOB"
    },
    {
      "instrument_token": "824321",
      "name": "MACROTECH DEVELOPERS",
      "tradingSymbol": "LODHA",
      "label": "LODHA",
      "value": "LODHA"
    },
    {
      "instrument_token": "232961",
      "name": "EICHER MOTORS",
      "tradingSymbol": "EICHERMOT",
      "label": "EICHERMOT",
      "value": "EICHERMOT"
    },
    {
      "instrument_token": "2763265",
      "name": "CANARA BANK",
      "tradingSymbol": "CANBK",
      "label": "CANBK",
      "value": "CANBK"
    },
    {
      "instrument_token": "878593",
      "name": "TATA CONSUMER PRODUCT",
      "tradingSymbol": "TATACONSUM",
      "label": "TATACONSUM",
      "value": "TATACONSUM"
    },
    {
      "instrument_token": "225537",
      "name": "DR. REDDY S LABORATORIES",
      "tradingSymbol": "DRREDDY",
      "label": "DRREDDY",
      "value": "DRREDDY"
    },
    {
      "instrument_token": "2170625",
      "name": "TVS MOTOR COMPANY ",
      "tradingSymbol": "TVSMOTOR",
      "label": "TVSMOTOR",
      "value": "TVSMOTOR"
    },
    {
      "instrument_token": "2029825",
      "name": "ZYDUS LIFESCIENCES",
      "tradingSymbol": "ZYDUSLIFE",
      "label": "ZYDUSLIFE",
      "value": "ZYDUSLIFE"
    },
    {
      "instrument_token": "1552897",
      "name": "ADANI TOTAL GAS",
      "tradingSymbol": "ATGL",
      "label": "ATGL",
      "value": "ATGL"
    },
    {
      "instrument_token": "784129",
      "name": "VEDANTA",
      "tradingSymbol": "VEDL",
      "label": "VEDL",
      "value": "VEDL"
    },
    {
      "instrument_token": "175361",
      "name": "CHOLAMANDALAM IN & FIN CO",
      "tradingSymbol": "CHOLAFIN",
      "label": "CHOLAFIN",
      "value": "CHOLAFIN"
    },
    {
      "instrument_token": "2513665",
      "name": "HAVELLS INDIA",
      "tradingSymbol": "HAVELLS",
      "label": "HAVELLS",
      "value": "HAVELLS"
    },
    {
      "instrument_token": "345089",
      "name": "HERO MOTOCORP",
      "tradingSymbol": "HEROMOTOCO",
      "label": "HEROMOTOCO",
      "value": "HEROMOTOCO"
    },
    {
      "instrument_token": "197633",
      "name": "DABUR INDIA",
      "tradingSymbol": "DABUR",
      "label": "DABUR",
      "value": "DABUR"
    },
    {
      "instrument_token": "794369",
      "name": "SHREE CEMENT",
      "tradingSymbol": "SHREECEM",
      "label": "SHREECEM",
      "value": "SHREECEM"
    },
    {
      "instrument_token": "3937281",
      "name": "MANKIND PHARMA",
      "tradingSymbol": "MANKIND",
      "label": "MANKIND",
      "value": "MANKIND"
    },
    {
      "instrument_token": "78081",
      "name": "BAJAJ HOLDINGS & INVS",
      "tradingSymbol": "BAJAJHLDNG",
      "label": "BAJAJHLDNG",
      "value": "BAJAJHLDNG"
    },
    {
      "instrument_token": "2800641",
      "name": "DIVI S LABORATORIES",
      "tradingSymbol": "DIVISLAB",
      "label": "DIVISLAB",
      "value": "DIVISLAB"
    },
    {
      "instrument_token": "40193",
      "name": "APOLLO HOSPITALS ENTER. L",
      "tradingSymbol": "APOLLOHOSP",
      "label": "APOLLOHOSP",
      "value": "APOLLOHOSP"
    },
    {
      "instrument_token": "4454401",
      "name": "NHPC",
      "tradingSymbol": "NHPC",
      "label": "NHPC",
      "value": "NHPC"
    },
    {
      "instrument_token": "1102337",
      "name": "SHRIRAM FINANCE",
      "tradingSymbol": "SHRIRAMFIN",
      "label": "SHRIRAMFIN",
      "value": "SHRIRAMFIN"
    },
    {
      "instrument_token": "558337",
      "name": "BOSCH",
      "tradingSymbol": "BOSCHLTD",
      "label": "BOSCHLTD",
      "value": "BOSCHLTD"
    },
    {
      "instrument_token": "900609",
      "name": "TORRENT PHARMACEUTICALS L",
      "tradingSymbol": "TORNTPHARM",
      "label": "TORNTPHARM",
      "value": "TORNTPHARM"
    },
    {
      "instrument_token": "4774913",
      "name": "ICICI PRU LIFE INS CO",
      "tradingSymbol": "ICICIPRULI",
      "label": "ICICIPRULI",
      "value": "ICICIPRULI"
    },
    {
      "instrument_token": "377857",
      "name": "IDBI BANK",
      "tradingSymbol": "IDBI",
      "label": "IDBI",
      "value": "IDBI"
    },
    {
      "instrument_token": "4574465",
      "name": "JSW ENERGY",
      "tradingSymbol": "JSWENERGY",
      "label": "JSWENERGY",
      "value": "JSWENERGY"
    },
    {
      "instrument_token": "1723649",
      "name": "JINDAL STEEL & POWER",
      "tradingSymbol": "JINDALSTEL",
      "label": "JINDALSTEL",
      "value": "JINDALSTEL"
    },
    {
      "instrument_token": "112129",
      "name": "BHEL",
      "tradingSymbol": "BHEL",
      "label": "BHEL",
      "value": "BHEL"
    },
    {
      "instrument_token": "387073",
      "name": "THE INDIAN HOTELS CO.",
      "tradingSymbol": "INDHOTEL",
      "label": "INDHOTEL",
      "value": "INDHOTEL"
    },
    {
      "instrument_token": "486657",
      "name": "CUMMINS INDIA",
      "tradingSymbol": "CUMMINSIND",
      "label": "CUMMINSIND",
      "value": "CUMMINSIND"
    },
    {
      "instrument_token": "5573121",
      "name": "ICICI LOMBARD GIC",
      "tradingSymbol": "ICICIGI",
      "label": "ICICIGI",
      "value": "ICICIGI"
    },
    {
      "instrument_token": "194561",
      "name": "CG POWER AND IND SOL",
      "tradingSymbol": "CGPOWER",
      "label": "CGPOWER",
      "value": "CGPOWER"
    },
    {
      "instrument_token": "1086465",
      "name": "HDFC AMC",
      "tradingSymbol": "HDFCAMC",
      "label": "HDFCAMC",
      "value": "HDFCAMC"
    },
    {
      "instrument_token": "5728513",
      "name": "MAX HEALTHCARE INS",
      "tradingSymbol": "MAXHEALTH",
      "label": "MAXHEALTH",
      "value": "MAXHEALTH"
    },
    {
      "instrument_token": "3412993",
      "name": "SOLAR INDUSTRIES (I)",
      "tradingSymbol": "SOLARINDS",
      "label": "SOLARINDS",
      "value": "SOLARINDS"
    },
    {
      "instrument_token": "1076225",
      "name": "SAMVRDHNA MTHRSN INTL",
      "tradingSymbol": "MOTHERSON",
      "label": "MOTHERSON",
      "value": "MOTHERSON"
    },
    {
      "instrument_token": "7458561",
      "name": "INDUS TOWERS",
      "tradingSymbol": "INDUSTOWER",
      "label": "INDUSTOWER",
      "value": "INDUSTOWER"
    },
    {
      "instrument_token": "2455041",
      "name": "POLYCAB INDIA",
      "tradingSymbol": "POLYCAB",
      "label": "POLYCAB",
      "value": "POLYCAB"
    },
    {
      "instrument_token": "2748929",
      "name": "ORACLE FIN SERV SOFT",
      "tradingSymbol": "OFSS",
      "label": "OFSS",
      "value": "OFSS"
    },
    {
      "instrument_token": "837889",
      "name": "SRF",
      "tradingSymbol": "SRF",
      "label": "SRF",
      "value": "SRF"
    },
    {
      "instrument_token": "3484417",
      "name": "INDIAN RAIL TOUR CORP",
      "tradingSymbol": "IRCTC",
      "label": "IRCTC",
      "value": "IRCTC"
    },
    {
      "instrument_token": "3876097",
      "name": "COLGATE PALMOLIVE",
      "tradingSymbol": "COLPAL",
      "label": "COLPAL",
      "value": "COLPAL"
    },
    {
      "instrument_token": "2672641",
      "name": "LUPIN",
      "tradingSymbol": "LUPIN",
      "label": "LUPIN",
      "value": "LUPIN"
    },
    {
      "instrument_token": "3520257",
      "name": "INFO EDGE (I)",
      "tradingSymbol": "NAUKRI",
      "label": "NAUKRI",
      "value": "NAUKRI"
    },
    {
      "instrument_token": "79873",
      "name": "TUBE INVEST OF INDIA",
      "tradingSymbol": "TIINDIA",
      "label": "TIINDIA",
      "value": "TIINDIA"
    },
    {
      "instrument_token": "3663105",
      "name": "INDIAN BANK",
      "tradingSymbol": "INDIANB",
      "label": "INDIANB",
      "value": "INDIANB"
    },
    {
      "instrument_token": "359937",
      "name": "HINDUSTAN PETROLEUM CORP",
      "tradingSymbol": "HINDPETRO",
      "label": "HINDPETRO",
      "value": "HINDPETRO"
    },
    {
      "instrument_token": "103425",
      "name": "BERGER PAINTS (I)",
      "tradingSymbol": "BERGEPAINT",
      "label": "BERGEPAINT",
      "value": "BERGEPAINT"
    },
    {
      "instrument_token": "3050241",
      "name": "YES BANK",
      "tradingSymbol": "YESBANK",
      "label": "YESBANK",
      "value": "YESBANK"
    },
    {
      "instrument_token": "3529217",
      "name": "TORRENT POWER",
      "tradingSymbol": "TORNTPOWER",
      "label": "TORNTPOWER",
      "value": "TORNTPOWER"
    },
    {
      "instrument_token": "4464129",
      "name": "OIL INDIA",
      "tradingSymbol": "OIL",
      "label": "OIL",
      "value": "OIL"
    },
    {
      "instrument_token": "4600577",
      "name": "SBI CARDS & PAY SER",
      "tradingSymbol": "SBICARD",
      "label": "SBICARD",
      "value": "SBICARD"
    },
    {
      "instrument_token": "3677697",
      "name": "VODAFONE IDEA",
      "tradingSymbol": "IDEA",
      "label": "IDEA",
      "value": "IDEA"
    },
    {
      "instrument_token": "1041153",
      "name": "MARICO",
      "tradingSymbol": "MARICO",
      "label": "MARICO",
      "value": "MARICO"
    },
    {
      "instrument_token": "4576001",
      "name": "GODREJ PROPERTIES",
      "tradingSymbol": "GODREJPROP",
      "label": "GODREJPROP",
      "value": "GODREJPROP"
    },
    {
      "instrument_token": "70401",
      "name": "AUROBINDO PHARMA",
      "tradingSymbol": "AUROPHARMA",
      "label": "AUROPHARMA",
      "value": "AUROPHARMA"
    },
    {
      "instrument_token": "2873089",
      "name": "UCO BANK",
      "tradingSymbol": "UCOBANK",
      "label": "UCOBANK",
      "value": "UCOBANK"
    },
    {
      "instrument_token": "1214721",
      "name": "BANK OF INDIA",
      "tradingSymbol": "BANKINDIA",
      "label": "BANKINDIA",
      "value": "BANKINDIA"
    },
    {
      "instrument_token": "4701441",
      "name": "PERSISTENT SYSTEMS",
      "tradingSymbol": "PERSISTENT",
      "label": "PERSISTENT",
      "value": "PERSISTENT"
    },
    {
      "instrument_token": "6054401",
      "name": "MUTHOOT FINANCE",
      "tradingSymbol": "MUTHOOTFIN",
      "label": "MUTHOOTFIN",
      "value": "MUTHOOTFIN"
    },
    {
      "instrument_token": "3924993",
      "name": "NMDC",
      "tradingSymbol": "NMDC",
      "label": "NMDC",
      "value": "NMDC"
    },
    {
      "instrument_token": "2995969",
      "name": "ALKEM LABORATORIES",
      "tradingSymbol": "ALKEM",
      "label": "ALKEM",
      "value": "ALKEM"
    },
    {
      "instrument_token": "6191105",
      "name": "PI INDUSTRIES",
      "tradingSymbol": "PIIND",
      "label": "PIIND",
      "value": "PIIND"
    },
    {
      "instrument_token": "4752385",
      "name": "L&T TECHNOLOGY SER.",
      "tradingSymbol": "LTTS",
      "label": "LTTS",
      "value": "LTTS"
    },
    {
      "instrument_token": "70913",
      "name": "GENERAL INS CORP OF INDIA",
      "tradingSymbol": "GICRE",
      "label": "GICRE",
      "value": "GICRE"
    },
    {
      "instrument_token": "952577",
      "name": "TATA COMMUNICATIONS",
      "tradingSymbol": "TATACOMM",
      "label": "TATACOMM",
      "value": "TATACOMM"
    },
    {
      "instrument_token": "2876417",
      "name": "JINDAL STAINLESS",
      "tradingSymbol": "JSL",
      "label": "JSL",
      "value": "JSL"
    },
    {
      "instrument_token": "582913",
      "name": "MRF",
      "tradingSymbol": "MRF",
      "label": "MRF",
      "value": "MRF"
    },
    {
      "instrument_token": "758529",
      "name": "STEEL AUTHORITY OF INDIA",
      "tradingSymbol": "SAIL",
      "label": "SAIL",
      "value": "SAIL"
    },
    {
      "instrument_token": "648961",
      "name": "P&G HYGIENE & HEALTH CARE",
      "tradingSymbol": "PGHH",
      "label": "PGHH",
      "value": "PGHH"
    },
    {
      "instrument_token": "3076609",
      "name": "SUZLON ENERGY",
      "tradingSymbol": "SUZLON",
      "label": "SUZLON",
      "value": "SUZLON"
    },
    {
      "instrument_token": "416513",
      "name": "LINDE INDIA",
      "tradingSymbol": "LINDEINDIA",
      "label": "LINDEINDIA",
      "value": "LINDEINDIA"
    },
    {
      "instrument_token": "860929",
      "name": "SUPREME INDUSTRIES",
      "tradingSymbol": "SUPREMEIND",
      "label": "SUPREMEIND",
      "value": "SUPREMEIND"
    },
    {
      "instrument_token": "1215745",
      "name": "CONTAINER CORP OF IND",
      "tradingSymbol": "CONCOR",
      "label": "CONCOR",
      "value": "CONCOR"
    },
    {
      "instrument_token": "5181953",
      "name": "OBEROI REALTY",
      "tradingSymbol": "OBEROIRLTY",
      "label": "OBEROIRLTY",
      "value": "OBEROIRLTY"
    },
    {
      "instrument_token": "3691009",
      "name": "ASTRAL",
      "tradingSymbol": "ASTRAL",
      "label": "ASTRAL",
      "value": "ASTRAL"
    },
    {
      "instrument_token": "2863105",
      "name": "IDFC FIRST BANK",
      "tradingSymbol": "IDFCFIRSTB",
      "label": "IDFCFIRSTB",
      "value": "IDFCFIRSTB"
    },
    {
      "instrument_token": "2445313",
      "name": "RAIL VIKAS NIGAM",
      "tradingSymbol": "RVNL",
      "label": "RVNL",
      "value": "RVNL"
    },
    {
      "instrument_token": "108033",
      "name": "BHARAT FORGE",
      "tradingSymbol": "BHARATFORG",
      "label": "BHARATFORG",
      "value": "BHARATFORG"
    },
    {
      "instrument_token": "3812865",
      "name": "CENTRAL BANK OF INDIA",
      "tradingSymbol": "CENTRALBK",
      "label": "CENTRALBK",
      "value": "CENTRALBK"
    },
    {
      "instrument_token": "4869121",
      "name": "JSW INFRASTRUCTURE",
      "tradingSymbol": "JSWINFRA",
      "label": "JSWINFRA",
      "value": "JSWINFRA"
    },
    {
      "instrument_token": "1703937",
      "name": "PB FINTECH",
      "tradingSymbol": "POLICYBZR",
      "label": "POLICYBZR",
      "value": "POLICYBZR"
    },
    {
      "instrument_token": "54273",
      "name": "ASHOK LEYLAND",
      "tradingSymbol": "ASHOKLEY",
      "label": "ASHOKLEY",
      "value": "ASHOKLEY"
    },
    {
      "instrument_token": "889601",
      "name": "THERMAX",
      "tradingSymbol": "THERMAX",
      "label": "THERMAX",
      "value": "THERMAX"
    },
    {
      "instrument_token": "3725313",
      "name": "THE PHOENIX MILLS",
      "tradingSymbol": "PHOENIXLTD",
      "label": "PHOENIXLTD",
      "value": "PHOENIXLTD"
    },
    {
      "instrument_token": "3463169",
      "name": "GMR AIRPORTS INFRA",
      "tradingSymbol": "GMRINFRA",
      "label": "GMRINFRA",
      "value": "GMRINFRA"
    },
    {
      "instrument_token": "873217",
      "name": "TATA ELXSI",
      "tradingSymbol": "TATAELXSI",
      "label": "TATAELXSI",
      "value": "TATAELXSI"
    },
    {
      "instrument_token": "4359425",
      "name": "PATANJALI FOODS",
      "tradingSymbol": "PATANJALI",
      "label": "PATANJALI",
      "value": "PATANJALI"
    },
    {
      "instrument_token": "4834049",
      "name": "SJVN",
      "tradingSymbol": "SJVN",
      "label": "SJVN",
      "value": "SJVN"
    },
    {
      "instrument_token": "5197313",
      "name": "PRESTIGE ESTATE",
      "tradingSymbol": "PRESTIGE",
      "label": "PRESTIGE",
      "value": "PRESTIGE"
    },
    {
      "instrument_token": "5633",
      "name": "ACC",
      "tradingSymbol": "ACC",
      "label": "ACC",
      "value": "ACC"
    },
    {
      "instrument_token": "1675521",
      "name": "FSN E COMMERCE VENTURES",
      "tradingSymbol": "NYKAA",
      "label": "NYKAA",
      "value": "NYKAA"
    },
    {
      "instrument_token": "854785",
      "name": "SUNDARAM FINANCE",
      "tradingSymbol": "SUNDARMFIN",
      "label": "SUNDARMFIN",
      "value": "SUNDARMFIN"
    },
    {
      "instrument_token": "4278529",
      "name": "UNITED BREWERIES",
      "tradingSymbol": "UBL",
      "label": "UBL",
      "value": "UBL"
    },
    {
      "instrument_token": "5533185",
      "name": "ADITYA BIRLA CAPITAL",
      "tradingSymbol": "ABCAPITAL",
      "label": "ABCAPITAL",
      "value": "ABCAPITAL"
    },
    {
      "instrument_token": "1152769",
      "name": "MPHASIS",
      "tradingSymbol": "MPHASIS",
      "label": "MPHASIS",
      "value": "MPHASIS"
    },
    {
      "instrument_token": "85761",
      "name": "BALKRISHNA IND.",
      "tradingSymbol": "BALKRISIND",
      "label": "BALKRISIND",
      "value": "BALKRISIND"
    },
    {
      "instrument_token": "5552641",
      "name": "DIXON TECHNO (INDIA)",
      "tradingSymbol": "DIXON",
      "label": "DIXON",
      "value": "DIXON"
    },
    {
      "instrument_token": "2912513",
      "name": "BANK OF MAHARASHTRA",
      "tradingSymbol": "MAHABANK",
      "label": "MAHABANK",
      "value": "MAHABANK"
    },
    {
      "instrument_token": "756481",
      "name": "KALYAN JEWELLERS IND",
      "tradingSymbol": "KALYANKJIL",
      "label": "KALYANKJIL",
      "value": "KALYANKJIL"
    },
    {
      "instrument_token": "258817",
      "name": "SCHAEFFLER INDIA",
      "tradingSymbol": "SCHAEFFLER",
      "label": "SCHAEFFLER",
      "value": "SCHAEFFLER"
    },
    {
      "instrument_token": "2076161",
      "name": "ADANI WILMAR",
      "tradingSymbol": "AWL",
      "label": "AWL",
      "value": "AWL"
    },
    {
      "instrument_token": "6599681",
      "name": "APL APOLLO TUBES",
      "tradingSymbol": "APLAPOLLO",
      "label": "APLAPOLLO",
      "value": "APLAPOLLO"
    },
    {
      "instrument_token": "5195009",
      "name": "TATA TECHNOLOGIES",
      "tradingSymbol": "TATATECH",
      "label": "TATATECH",
      "value": "TATATECH"
    },
    {
      "instrument_token": "1199105",
      "name": "SONA BLW PRECISION FRGS L",
      "tradingSymbol": "SONACOMS",
      "label": "SONACOMS",
      "value": "SONACOMS"
    },
    {
      "instrument_token": "2478849",
      "name": "KPIT TECHNOLOGIES",
      "tradingSymbol": "KPITTECH",
      "label": "KPITTECH",
      "value": "KPITTECH"
    },
    {
      "instrument_token": "258049",
      "name": "FACT",
      "tradingSymbol": "FACT",
      "label": "FACT",
      "value": "FACT"
    },
    {
      "instrument_token": "5376257",
      "name": "PUNJAB & SIND BANK",
      "tradingSymbol": "PSB",
      "label": "PSB",
      "value": "PSB"
    },
    {
      "instrument_token": "2905857",
      "name": "PETRONET LNG",
      "tradingSymbol": "PETRONET",
      "label": "PETRONET",
      "value": "PETRONET"
    },
    {
      "instrument_token": "3623425",
      "name": "UNO MINDA",
      "tradingSymbol": "UNOMINDA",
      "label": "UNOMINDA",
      "value": "UNOMINDA"
    },
    {
      "instrument_token": "3689729",
      "name": "PAGE INDUSTRIES",
      "tradingSymbol": "PAGEIND",
      "label": "PAGEIND",
      "value": "PAGEIND"
    },
    {
      "instrument_token": "584449",
      "name": "MRPL",
      "tradingSymbol": "MRPL",
      "label": "MRPL",
      "value": "MRPL"
    },
    {
      "instrument_token": "5436929",
      "name": "AU SMALL FINANCE BANK",
      "tradingSymbol": "AUBANK",
      "label": "AUBANK",
      "value": "AUBANK"
    },
    {
      "instrument_token": "130305",
      "name": "MAZAGON DOCK SHIPBUIL",
      "tradingSymbol": "MAZDOCK",
      "label": "MAZDOCK",
      "value": "MAZDOCK"
    },
    {
      "instrument_token": "5331201",
      "name": "HSG & URBAN DEV CORPN",
      "tradingSymbol": "HUDCO",
      "label": "HUDCO",
      "value": "HUDCO"
    },
    {
      "instrument_token": "2713345",
      "name": "GUJARAT GAS",
      "tradingSymbol": "GUJGASLTD",
      "label": "GUJGASLTD",
      "value": "GUJGASLTD"
    },
    {
      "instrument_token": "102145",
      "name": "THE NEW INDIA ASSU CO",
      "tradingSymbol": "NIACL",
      "label": "NIACL",
      "value": "NIACL"
    },
    {
      "instrument_token": "193793",
      "name": "CRISIL",
      "tradingSymbol": "CRISIL",
      "label": "CRISIL",
      "value": "CRISIL"
    },
    {
      "instrument_token": "3350017",
      "name": "AIA ENGINEERING",
      "tradingSymbol": "AIAENG",
      "label": "AIAENG",
      "value": "AIAENG"
    },
    {
      "instrument_token": "261889",
      "name": "FEDERAL BANK",
      "tradingSymbol": "FEDERALBNK",
      "label": "FEDERALBNK",
      "value": "FEDERALBNK"
    },
    {
      "instrument_token": "5186817",
      "name": "INDIAN RENEWABLE ENERGY",
      "tradingSymbol": "IREDA",
      "label": "IREDA",
      "value": "IREDA"
    },
    {
      "instrument_token": "951809",
      "name": "VOLTAS",
      "tradingSymbol": "VOLTAS",
      "label": "VOLTAS",
      "value": "VOLTAS"
    },
    {
      "instrument_token": "2067201",
      "name": "DALMIA BHARAT",
      "tradingSymbol": "DALBHARAT",
      "label": "DALBHARAT",
      "value": "DALBHARAT"
    },
    {
      "instrument_token": "2919169",
      "name": "POONAWALLA FINCORP",
      "tradingSymbol": "POONAWALLA",
      "label": "POONAWALLA",
      "value": "POONAWALLA"
    },
    {
      "instrument_token": "3060737",
      "name": "GLOBAL HEALTH",
      "tradingSymbol": "MEDANTA",
      "label": "MEDANTA",
      "value": "MEDANTA"
    },
    {
      "instrument_token": "3920129",
      "name": "IRB INFRA DEV",
      "tradingSymbol": "IRB",
      "label": "IRB",
      "value": "IRB"
    },
    {
      "instrument_token": "121345",
      "name": "3M INDIA",
      "tradingSymbol": "3MINDIA",
      "label": "3MINDIA",
      "value": "3MINDIA"
    },
    {
      "instrument_token": "548353",
      "name": "MAX FINANCIAL SERV",
      "tradingSymbol": "MFSL",
      "label": "MFSL",
      "value": "MFSL"
    },
    {
      "instrument_token": "3400961",
      "name": "M&M FIN. SERVICES",
      "tradingSymbol": "M&MFIN",
      "label": "M&MFIN",
      "value": "M&MFIN"
    },
    {
      "instrument_token": "2889473",
      "name": "UPL",
      "tradingSymbol": "UPL",
      "label": "UPL",
      "value": "UPL"
    },
    {
      "instrument_token": "874753",
      "name": "HONEYWELL AUTOMATION IND",
      "tradingSymbol": "HONAUT",
      "label": "HONAUT",
      "value": "HONAUT"
    },
    {
      "instrument_token": "5013761",
      "name": "BSE",
      "tradingSymbol": "BSE",
      "label": "BSE",
      "value": "BSE"
    },
    {
      "instrument_token": "3520001",
      "name": "GUJARAT FLUOROCHEM",
      "tradingSymbol": "FLUOROCHEM",
      "label": "FLUOROCHEM",
      "value": "FLUOROCHEM"
    },
    {
      "instrument_token": "2955009",
      "name": "COFORGE",
      "tradingSymbol": "COFORGE",
      "label": "COFORGE",
      "value": "COFORGE"
    },
    {
      "instrument_token": "511233",
      "name": "LIC HOUSING FINANCE",
      "tradingSymbol": "LICHSGFIN",
      "label": "LICHSGFIN",
      "value": "LICHSGFIN"
    },
    {
      "instrument_token": "295169",
      "name": "GLAXOSMITHKLINE PHARMA LT",
      "tradingSymbol": "GLAXO",
      "label": "GLAXO",
      "value": "GLAXO"
    },
    {
      "instrument_token": "2457345",
      "name": "DELHIVERY",
      "tradingSymbol": "DELHIVERY",
      "label": "DELHIVERY",
      "value": "DELHIVERY"
    },
    {
      "instrument_token": "548865",
      "name": "BHARAT DYNAMICS",
      "tradingSymbol": "BDL",
      "label": "BDL",
      "value": "BDL"
    },
    {
      "instrument_token": "1813249",
      "name": "STAR HEALTH & AL INS CO L",
      "tradingSymbol": "STARHEALTH",
      "label": "STARHEALTH",
      "value": "STARHEALTH"
    },
    {
      "instrument_token": "3735553",
      "name": "FORTIS HEALTHCARE",
      "tradingSymbol": "FORTIS",
      "label": "FORTIS",
      "value": "FORTIS"
    },
    {
      "instrument_token": "2911489",
      "name": "BIOCON",
      "tradingSymbol": "BIOCON",
      "label": "BIOCON",
      "value": "BIOCON"
    },
    {
      "instrument_token": "189185",
      "name": "COROMANDEL INTERNTL.",
      "tradingSymbol": "COROMANDEL",
      "label": "COROMANDEL",
      "value": "COROMANDEL"
    },
    {
      "instrument_token": "2197761",
      "name": "NLC INDIA",
      "tradingSymbol": "NLCINDIA",
      "label": "NLCINDIA",
      "value": "NLCINDIA"
    },
    {
      "instrument_token": "414977",
      "name": "TATA INVESTMENT CORP",
      "tradingSymbol": "TATAINVEST",
      "label": "TATAINVEST",
      "value": "TATAINVEST"
    },
    {
      "instrument_token": "3397121",
      "name": "JK CEMENT",
      "tradingSymbol": "JKCEMENT",
      "label": "JKCEMENT",
      "value": "JKCEMENT"
    },
    {
      "instrument_token": "418049",
      "name": "IPCA LABORATORIES",
      "tradingSymbol": "IPCALAB",
      "label": "IPCALAB",
      "value": "IPCALAB"
    },
    {
      "instrument_token": "1853953",
      "name": "METRO BRANDS",
      "tradingSymbol": "METROBRAND",
      "label": "METROBRAND",
      "value": "METROBRAND"
    },
    {
      "instrument_token": "3407361",
      "name": "KEI INDUSTRIES",
      "tradingSymbol": "KEI",
      "label": "KEI",
      "value": "KEI"
    },
    {
      "instrument_token": "245249",
      "name": "ESCORTS KUBOTA",
      "tradingSymbol": "ESCORTS",
      "label": "ESCORTS",
      "value": "ESCORTS"
    },
    {
      "instrument_token": "4432129",
      "name": "LLOYDS METALS N ENERGY L",
      "tradingSymbol": "LLOYDSME",
      "label": "LLOYDSME",
      "value": "LLOYDSME"
    },
    {
      "instrument_token": "303617",
      "name": "GLAND PHARMA",
      "tradingSymbol": "GLAND",
      "label": "GLAND",
      "value": "GLAND"
    },
    {
      "instrument_token": "2883073",
      "name": "INDRAPRASTHA GAS",
      "tradingSymbol": "IGL",
      "label": "IGL",
      "value": "IGL"
    },
    {
      "instrument_token": "91393",
      "name": "NIPPON L I A M",
      "tradingSymbol": "NAM-INDIA",
      "label": "NAM-INDIA",
      "value": "NAM-INDIA"
    },
    {
      "instrument_token": "41729",
      "name": "APOLLO TYRES",
      "tradingSymbol": "APOLLOTYRE",
      "label": "APOLLOTYRE",
      "value": "APOLLOTYRE"
    },
    {
      "instrument_token": "4632577",
      "name": "JUBILANT FOODWORKS",
      "tradingSymbol": "JUBLFOOD",
      "label": "JUBLFOOD",
      "value": "JUBLFOOD"
    },
    {
      "instrument_token": "4724993",
      "name": "HITACHI ENERGY INDIA",
      "tradingSymbol": "POWERINDIA",
      "label": "POWERINDIA",
      "value": "POWERINDIA"
    },
    {
      "instrument_token": "2200577",
      "name": "MOTHERSON SUMI WRNG IND L",
      "tradingSymbol": "MSUMI",
      "label": "MSUMI",
      "value": "MSUMI"
    },
    {
      "instrument_token": "579329",
      "name": "BANDHAN BANK",
      "tradingSymbol": "BANDHANBNK",
      "label": "BANDHANBNK",
      "value": "BANDHANBNK"
    },
    {
      "instrument_token": "5105409",
      "name": "DEEPAK NITRITE",
      "tradingSymbol": "DEEPAKNTR",
      "label": "DEEPAKNTR",
      "value": "DEEPAKNTR"
    },
    {
      "instrument_token": "4330241",
      "name": "ZF COM VE CTR SYS IND",
      "tradingSymbol": "ZFCVINDIA",
      "label": "ZFCVINDIA",
      "value": "ZFCVINDIA"
    },
    {
      "instrument_token": "2079745",
      "name": "AJANTA PHARMA",
      "tradingSymbol": "AJANTPHARM",
      "label": "AJANTPHARM",
      "value": "AJANTPHARM"
    },
    {
      "instrument_token": "3817473",
      "name": "KPR MILL",
      "tradingSymbol": "KPRMILL",
      "label": "KPRMILL",
      "value": "KPRMILL"
    },
    {
      "instrument_token": "2622209",
      "name": "SYNGENE INTERNATIONAL",
      "tradingSymbol": "SYNGENE",
      "label": "SYNGENE",
      "value": "SYNGENE"
    },
    {
      "instrument_token": "235265",
      "name": "EIH",
      "tradingSymbol": "EIHOTEL",
      "label": "EIHOTEL",
      "value": "EIHOTEL"
    },
    {
      "instrument_token": "2941697",
      "name": "APAR INDUSTRIES",
      "tradingSymbol": "APARINDS",
      "label": "APARINDS",
      "value": "APARINDS"
    },
    {
      "instrument_token": "1629185",
      "name": "NATIONAL ALUMINIUM CO",
      "tradingSymbol": "NATIONALUM",
      "label": "NATIONALUM",
      "value": "NATIONALUM"
    },
    {
      "instrument_token": "871681",
      "name": "TATA CHEMICALS",
      "tradingSymbol": "TATACHEM",
      "label": "TATACHEM",
      "value": "TATACHEM"
    },
    {
      "instrument_token": "1895937",
      "name": "GLENMARK PHARMACEUTICALS",
      "tradingSymbol": "GLENMARK",
      "label": "GLENMARK",
      "value": "GLENMARK"
    },
    {
      "instrument_token": "4592385",
      "name": "HINDUSTAN COPPER",
      "tradingSymbol": "HINDCOPPER",
      "label": "HINDCOPPER",
      "value": "HINDCOPPER"
    },
    {
      "instrument_token": "2796801",
      "name": "GODREJ INDUSTRIES",
      "tradingSymbol": "GODREJIND",
      "label": "GODREJIND",
      "value": "GODREJIND"
    },
    {
      "instrument_token": "3031041",
      "name": "NARAYANA HRUDAYALAYA",
      "tradingSymbol": "NH",
      "label": "NH",
      "value": "NH"
    },
    {
      "instrument_token": "2127617",
      "name": "BLUE STAR",
      "tradingSymbol": "BLUESTARCO",
      "label": "BLUESTARCO",
      "value": "BLUESTARCO"
    },
    {
      "instrument_token": "173057",
      "name": "EXIDE INDUSTRIES",
      "tradingSymbol": "EXIDEIND",
      "label": "EXIDEIND",
      "value": "EXIDEIND"
    },
    {
      "instrument_token": "4818433",
      "name": "ENDURANCE TECHNO.",
      "tradingSymbol": "ENDURANCE",
      "label": "ENDURANCE",
      "value": "ENDURANCE"
    },
    {
      "instrument_token": "441857",
      "name": "J B CHEMICALS AND PHARMA",
      "tradingSymbol": "JBCHEPHARM",
      "label": "JBCHEPHARM",
      "value": "JBCHEPHARM"
    },
    {
      "instrument_token": "1716481",
      "name": "ONE 97 COMMUNICATIONS",
      "tradingSymbol": "PAYTM",
      "label": "PAYTM",
      "value": "PAYTM"
    },
    {
      "instrument_token": "82945",
      "name": "ANGEL ONE",
      "tradingSymbol": "ANGELONE",
      "label": "ANGELONE",
      "value": "ANGELONE"
    },
    {
      "instrument_token": "3826433",
      "name": "MOTILAL OSWAL FIN",
      "tradingSymbol": "MOTILALOFS",
      "label": "MOTILALOFS",
      "value": "MOTILALOFS"
    },
    {
      "instrument_token": "428801",
      "name": "ITI",
      "tradingSymbol": "ITI",
      "label": "ITI",
      "value": "ITI"
    },
    {
      "instrument_token": "3343617",
      "name": "360 ONE WAM",
      "tradingSymbol": "360ONE",
      "label": "360ONE",
      "value": "360ONE"
    },
    {
      "instrument_token": "152321",
      "name": "CARBORUNDUM UNIVERSAL",
      "tradingSymbol": "CARBORUNIV",
      "label": "CARBORUNIV",
      "value": "CARBORUNIV"
    },
    {
      "instrument_token": "1793",
      "name": "AARTI INDUSTRIES",
      "tradingSymbol": "AARTIIND",
      "label": "AARTIIND",
      "value": "AARTIIND"
    },
    {
      "instrument_token": "3431425",
      "name": "SUN TV NETWORK",
      "tradingSymbol": "SUNTV",
      "label": "SUNTV",
      "value": "SUNTV"
    },
    {
      "instrument_token": "4896257",
      "name": "KIOCL",
      "tradingSymbol": "KIOCL",
      "label": "KIOCL",
      "value": "KIOCL"
    },
    {
      "instrument_token": "637185",
      "name": "ICICI SECURITIES",
      "tradingSymbol": "ISEC",
      "label": "ISEC",
      "value": "ISEC"
    },
    {
      "instrument_token": "2813441",
      "name": "RADICO KHAITAN",
      "tradingSymbol": "RADICO",
      "label": "RADICO",
      "value": "RADICO"
    },
    {
      "instrument_token": "856321",
      "name": "SUNDRAM FASTENERS",
      "tradingSymbol": "SUNDRMFAST",
      "label": "SUNDRMFAST",
      "value": "SUNDRMFAST"
    },
    {
      "instrument_token": "1131777",
      "name": "CREDITACCESS GRAMEEN",
      "tradingSymbol": "CREDITACC",
      "label": "CREDITACC",
      "value": "CREDITACC"
    },
    {
      "instrument_token": "996353",
      "name": "HATSUN AGRO PRODUCT",
      "tradingSymbol": "HATSUN",
      "label": "HATSUN",
      "value": "HATSUN"
    },
    {
      "instrument_token": "2090753",
      "name": "VEDANT FASHIONS",
      "tradingSymbol": "MANYAVAR",
      "label": "MANYAVAR",
      "value": "MANYAVAR"
    },
    {
      "instrument_token": "1471489",
      "name": "CYIENT",
      "tradingSymbol": "CYIENT",
      "label": "CYIENT",
      "value": "CYIENT"
    },
    {
      "instrument_token": "3887105",
      "name": "BRIGADE ENTER.",
      "tradingSymbol": "BRIGADE",
      "label": "BRIGADE",
      "value": "BRIGADE"
    },
    {
      "instrument_token": "3634689",
      "name": "TIMKEN INDIA",
      "tradingSymbol": "TIMKEN",
      "label": "TIMKEN",
      "value": "TIMKEN"
    },
    {
      "instrument_token": "8042241",
      "name": "NBCC (INDIA)",
      "tradingSymbol": "NBCC",
      "label": "NBCC",
      "value": "NBCC"
    },
    {
      "instrument_token": "2983681",
      "name": "JBM AUTO",
      "tradingSymbol": "JBMA",
      "label": "JBMA",
      "value": "JBMA"
    },
    {
      "instrument_token": "403457",
      "name": "GILLETTE INDIA",
      "tradingSymbol": "GILLETTE",
      "label": "GILLETTE",
      "value": "GILLETTE"
    },
    {
      "instrument_token": "306177",
      "name": "KANSAI NEROLAC PAINTS",
      "tradingSymbol": "KANSAINER",
      "label": "KANSAINER",
      "value": "KANSAINER"
    },
    {
      "instrument_token": "4923905",
      "name": "LAURUS LABS",
      "tradingSymbol": "LAURUSLABS",
      "label": "LAURUSLABS",
      "value": "LAURUSLABS"
    },
    {
      "instrument_token": "3471361",
      "name": "GRINDWELL NORTON",
      "tradingSymbol": "GRINDWELL",
      "label": "GRINDWELL",
      "value": "GRINDWELL"
    },
    {
      "instrument_token": "3080193",
      "name": "FIVE-STAR BUS FIN",
      "tradingSymbol": "FIVESTAR",
      "label": "FIVESTAR",
      "value": "FIVESTAR"
    },
    {
      "instrument_token": "6936321",
      "name": "SWAN ENERGY",
      "tradingSymbol": "SWANENERGY",
      "label": "SWANENERGY",
      "value": "SWANENERGY"
    },
    {
      "instrument_token": "5565441",
      "name": "CHOLAMANDALAM FIN HOL",
      "tradingSymbol": "CHOLAHLDNG",
      "label": "CHOLAHLDNG",
      "value": "CHOLAHLDNG"
    },
    {
      "instrument_token": "1276417",
      "name": "IRCON INTERNATIONAL",
      "tradingSymbol": "IRCON",
      "label": "IRCON",
      "value": "IRCON"
    },
    {
      "instrument_token": "815617",
      "name": "SKF INDIA",
      "tradingSymbol": "SKFINDIA",
      "label": "SKFINDIA",
      "value": "SKFINDIA"
    },
    {
      "instrument_token": "1790465",
      "name": "BIRLASOFT",
      "tradingSymbol": "BSOFT",
      "label": "BSOFT",
      "value": "BSOFT"
    },
    {
      "instrument_token": "386049",
      "name": "ASTER DM HEALTHCARE",
      "tradingSymbol": "ASTERDM",
      "label": "ASTERDM",
      "value": "ASTERDM"
    },
    {
      "instrument_token": "6201601",
      "name": "RELAXO FOOT",
      "tradingSymbol": "RELAXO",
      "label": "RELAXO",
      "value": "RELAXO"
    },
    {
      "instrument_token": "1688577",
      "name": "SONATA SOFTWARE",
      "tradingSymbol": "SONATSOFTW",
      "label": "SONATSOFTW",
      "value": "SONATSOFTW"
    },
    {
      "instrument_token": "3378433",
      "name": "GUJARAT STATE PETRO",
      "tradingSymbol": "GSPL",
      "label": "GSPL",
      "value": "GSPL"
    },
    {
      "instrument_token": "3443457",
      "name": "RATNAMANI MET & TUB",
      "tradingSymbol": "RATNAMANI",
      "label": "RATNAMANI",
      "value": "RATNAMANI"
    },
    {
      "instrument_token": "7707649",
      "name": "ADITYA BIRLA FASHION & RT",
      "tradingSymbol": "ABFRL",
      "label": "ABFRL",
      "value": "ABFRL"
    },
    {
      "instrument_token": "6483969",
      "name": "ALEMBIC PHARMA",
      "tradingSymbol": "APLLTD",
      "label": "APLLTD",
      "value": "APLLTD"
    },
    {
      "instrument_token": "676609",
      "name": "PFIZER",
      "tradingSymbol": "PFIZER",
      "label": "PFIZER",
      "value": "PFIZER"
    },
    {
      "instrument_token": "523009",
      "name": "THE RAMCO CEMENTS",
      "tradingSymbol": "RAMCOCEM",
      "label": "RAMCOCEM",
      "value": "RAMCOCEM"
    },
    {
      "instrument_token": "4798209",
      "name": "SIGNATUREGLOBAL INDIA",
      "tradingSymbol": "SIGNATURE",
      "label": "SIGNATURE",
      "value": "SIGNATURE"
    },
    {
      "instrument_token": "617473",
      "name": "PIRAMAL ENTERPRISES",
      "tradingSymbol": "PEL",
      "label": "PEL",
      "value": "PEL"
    },
    {
      "instrument_token": "239873",
      "name": "ELGI EQUIPMENTS",
      "tradingSymbol": "ELGIEQUIP",
      "label": "ELGIEQUIP",
      "value": "ELGIEQUIP"
    },
    {
      "instrument_token": "2983425",
      "name": "DR. LAL PATH LABS",
      "tradingSymbol": "LALPATHLAB",
      "label": "LALPATHLAB",
      "value": "LALPATHLAB"
    },
    {
      "instrument_token": "3460353",
      "name": "EMAMI",
      "tradingSymbol": "EMAMILTD",
      "label": "EMAMILTD",
      "value": "EMAMILTD"
    },
    {
      "instrument_token": "5461505",
      "name": "JYOTI CNC AUTOMATION",
      "tradingSymbol": "JYOTICNC",
      "label": "JYOTICNC",
      "value": "JYOTICNC"
    },
    {
      "instrument_token": "2479361",
      "name": "TRIDENT",
      "tradingSymbol": "TRIDENT",
      "label": "TRIDENT",
      "value": "TRIDENT"
    },
    {
      "instrument_token": "320001",
      "name": "CASTROL INDIA",
      "tradingSymbol": "CASTROLIND",
      "label": "CASTROLIND",
      "value": "CASTROLIND"
    },
    {
      "instrument_token": "462849",
      "name": "KAJARIA CERAMICS",
      "tradingSymbol": "KAJARIACER",
      "label": "KAJARIACER",
      "value": "KAJARIACER"
    },
    {
      "instrument_token": "3095553",
      "name": "KAYNES TECHNOLOGY IND",
      "tradingSymbol": "KAYNES",
      "label": "KAYNES",
      "value": "KAYNES"
    },
    {
      "instrument_token": "160001",
      "name": "CENTURY TEXTILES",
      "tradingSymbol": "CENTURYTEX",
      "label": "CENTURYTEX",
      "value": "CENTURYTEX"
    },
    {
      "instrument_token": "2187777",
      "name": "CHALET HOTELS",
      "tradingSymbol": "CHALET",
      "label": "CHALET",
      "value": "CHALET"
    },
    {
      "instrument_token": "1375489",
      "name": "DEVYANI INTERNATIONAL",
      "tradingSymbol": "DEVYANI",
      "label": "DEVYANI",
      "value": "DEVYANI"
    },
    {
      "instrument_token": "5420545",
      "name": "CENTRAL DEPO SER (I)",
      "tradingSymbol": "CDSL",
      "label": "CDSL",
      "value": "CDSL"
    },
    {
      "instrument_token": "3394561",
      "name": "KEC INTL.",
      "tradingSymbol": "KEC",
      "label": "KEC",
      "value": "KEC"
    },
    {
      "instrument_token": "7995905",
      "name": "SCHNEIDER ELECTRIC INFRA",
      "tradingSymbol": "SCHNEIDER",
      "label": "SCHNEIDER",
      "value": "SCHNEIDER"
    },
    {
      "instrument_token": "3060993",
      "name": "IDFC",
      "tradingSymbol": "IDFC",
      "label": "IDFC",
      "value": "IDFC"
    },
    {
      "instrument_token": "94977",
      "name": "BATA INDIA",
      "tradingSymbol": "BATAINDIA",
      "label": "BATAINDIA",
      "value": "BATAINDIA"
    },
    {
      "instrument_token": "3823873",
      "name": "CIE AUTOMOTIVE INDIA",
      "tradingSymbol": "CIEINDIA",
      "label": "CIEINDIA",
      "value": "CIEINDIA"
    },
    {
      "instrument_token": "464385",
      "name": "KALPATARU PROJECT INT",
      "tradingSymbol": "KPIL",
      "label": "KPIL",
      "value": "KPIL"
    },
    {
      "instrument_token": "4752897",
      "name": "R R KABEL",
      "tradingSymbol": "RRKABEL",
      "label": "RRKABEL",
      "value": "RRKABEL"
    },
    {
      "instrument_token": "4378881",
      "name": "SUMITOMO CHEM INDIA",
      "tradingSymbol": "SUMICHEM",
      "label": "SUMICHEM",
      "value": "SUMICHEM"
    },
    {
      "instrument_token": "1003009",
      "name": "NATCO PHARMA",
      "tradingSymbol": "NATCOPHARM",
      "label": "NATCOPHARM",
      "value": "NATCOPHARM"
    },
    {
      "instrument_token": "4593921",
      "name": "SUVEN PHARMACEUTICALS",
      "tradingSymbol": "SUVENPHAR",
      "label": "SUVENPHAR",
      "value": "SUVENPHAR"
    },
    {
      "instrument_token": "4376065",
      "name": "CROMPT GREA CON ELEC",
      "tradingSymbol": "CROMPTON",
      "label": "CROMPTON",
      "value": "CROMPTON"
    },
    {
      "instrument_token": "6549505",
      "name": "TRIVENI TURBINE",
      "tradingSymbol": "TRITURBINE",
      "label": "TRITURBINE",
      "value": "TRITURBINE"
    },
    {
      "instrument_token": "2962177",
      "name": "PIRAMAL PHARMA",
      "tradingSymbol": "PPLPHARMA",
      "label": "PPLPHARMA",
      "value": "PPLPHARMA"
    },
    {
      "instrument_token": "2010113",
      "name": "INOX WIND",
      "tradingSymbol": "INOXWIND",
      "label": "INOXWIND",
      "value": "INOXWIND"
    },
    {
      "instrument_token": "3478273",
      "name": "ACTION CONST EQUIP",
      "tradingSymbol": "ACE",
      "label": "ACE",
      "value": "ACE"
    },
    {
      "instrument_token": "67329",
      "name": "ATUL",
      "tradingSymbol": "ATUL",
      "label": "ATUL",
      "value": "ATUL"
    },
    {
      "instrument_token": "5204225",
      "name": "CAPRI GLOBAL CAPITAL",
      "tradingSymbol": "CGCL",
      "label": "CGCL",
      "value": "CGCL"
    },
    {
      "instrument_token": "7426049",
      "name": "TVS HOLDINGS",
      "tradingSymbol": "TVSHLTD",
      "label": "TVSHLTD",
      "value": "TVSHLTD"
    },
    {
      "instrument_token": "1201409",
      "name": "SHYAM METALICS AND ENGY L",
      "tradingSymbol": "SHYAMMETL",
      "label": "SHYAMMETL",
      "value": "SHYAMMETL"
    },
    {
      "instrument_token": "4792577",
      "name": "NUVAMA WEALTH MANAGE",
      "tradingSymbol": "NUVAMA",
      "label": "NUVAMA",
      "value": "NUVAMA"
    },
    {
      "instrument_token": "1240833",
      "name": "KRISHNA INST OF MED SCI L",
      "tradingSymbol": "KIMS",
      "label": "KIMS",
      "value": "KIMS"
    },
    {
      "instrument_token": "5067521",
      "name": "CELLO WORLD",
      "tradingSymbol": "CELLO",
      "label": "CELLO",
      "value": "CELLO"
    },
    {
      "instrument_token": "4840449",
      "name": "PNB HOUSING FIN",
      "tradingSymbol": "PNBHOUSING",
      "label": "PNBHOUSING",
      "value": "PNBHOUSING"
    },
    {
      "instrument_token": "3649281",
      "name": "REDINGTON",
      "tradingSymbol": "REDINGTON",
      "label": "REDINGTON",
      "value": "REDINGTON"
    },
    {
      "instrument_token": "506625",
      "name": "LAKSHMI MACHINES",
      "tradingSymbol": "LAXMIMACH",
      "label": "LAXMIMACH",
      "value": "LAXMIMACH"
    },
    {
      "instrument_token": "3877377",
      "name": "JYOTHY LABS",
      "tradingSymbol": "JYOTHYLAB",
      "label": "JYOTHYLAB",
      "value": "JYOTHYLAB"
    },
    {
      "instrument_token": "160769",
      "name": "CESC",
      "tradingSymbol": "CESC",
      "label": "CESC",
      "value": "CESC"
    },
    {
      "instrument_token": "302337",
      "name": "GODFREY PHILLIPS INDIA LT",
      "tradingSymbol": "GODFRYPHLP",
      "label": "GODFRYPHLP",
      "value": "GODFRYPHLP"
    },
    {
      "instrument_token": "3630081",
      "name": "NMDC STEEL",
      "tradingSymbol": "NSLNISP",
      "label": "NSLNISP",
      "value": "NSLNISP"
    },
    {
      "instrument_token": "962817",
      "name": "RITES",
      "tradingSymbol": "RITES",
      "label": "RITES",
      "value": "RITES"
    },
    {
      "instrument_token": "4623361",
      "name": "CONCORD BIOTECH",
      "tradingSymbol": "CONCORDBIO",
      "label": "CONCORDBIO",
      "value": "CONCORDBIO"
    },
    {
      "instrument_token": "2745857",
      "name": "INDIAMART INTERMESH",
      "tradingSymbol": "INDIAMART",
      "label": "INDIAMART",
      "value": "INDIAMART"
    },
    {
      "instrument_token": "2723073",
      "name": "OLECTRA GREENTECH",
      "tradingSymbol": "OLECTRA",
      "label": "OLECTRA",
      "value": "OLECTRA"
    },
    {
      "instrument_token": "4610817",
      "name": "WHIRLPOOL OF INDIA",
      "tradingSymbol": "WHIRLPOOL",
      "label": "WHIRLPOOL",
      "value": "WHIRLPOOL"
    },
    {
      "instrument_token": "1829121",
      "name": "ANAND RATHI WEALTH",
      "tradingSymbol": "ANANDRATHI",
      "label": "ANANDRATHI",
      "value": "ANANDRATHI"
    },
    {
      "instrument_token": "3756033",
      "name": "NAVIN FLUORINE INT.",
      "tradingSymbol": "NAVINFLUOR",
      "label": "NAVINFLUOR",
      "value": "NAVINFLUOR"
    },
    {
      "instrument_token": "5177345",
      "name": "JUPITER WAGONS",
      "tradingSymbol": "JWL",
      "label": "JWL",
      "value": "JWL"
    },
    {
      "instrument_token": "1391361",
      "name": "APTUS VALUE HSG FIN I",
      "tradingSymbol": "APTUS",
      "label": "APTUS",
      "value": "APTUS"
    },
    {
      "instrument_token": "265729",
      "name": "FINOLEX CABLES",
      "tradingSymbol": "FINCABLES",
      "label": "FINCABLES",
      "value": "FINCABLES"
    },
    {
      "instrument_token": "266497",
      "name": "FINOLEX INDUSTRIES",
      "tradingSymbol": "FINPIPE",
      "label": "FINPIPE",
      "value": "FINPIPE"
    },
    {
      "instrument_token": "6583809",
      "name": "POLY MEDICURE",
      "tradingSymbol": "POLYMED",
      "label": "POLYMED",
      "value": "POLYMED"
    },
    {
      "instrument_token": "4445185",
      "name": "VINATI ORGANICS",
      "tradingSymbol": "VINATIORGA",
      "label": "VINATIORGA",
      "value": "VINATIORGA"
    },
    {
      "instrument_token": "1517057",
      "name": "INTELLECT DESIGN ARENA",
      "tradingSymbol": "INTELLECT",
      "label": "INTELLECT",
      "value": "INTELLECT"
    },
    {
      "instrument_token": "1442049",
      "name": "J & K BANK",
      "tradingSymbol": "J&KBANK",
      "label": "J&KBANK",
      "value": "J&KBANK"
    },
    {
      "instrument_token": "470529",
      "name": "KARUR VYSYA BANK",
      "tradingSymbol": "KARURVYSYA",
      "label": "KARURVYSYA",
      "value": "KARURVYSYA"
    },
    {
      "instrument_token": "126721",
      "name": "BLUE DART EXPRESS",
      "tradingSymbol": "BLUEDART",
      "label": "BLUEDART",
      "value": "BLUEDART"
    },
    {
      "instrument_token": "4879617",
      "name": "MANAPPURAM FINANCE",
      "tradingSymbol": "MANAPPURAM",
      "label": "MANAPPURAM",
      "value": "MANAPPURAM"
    },
    {
      "instrument_token": "2903809",
      "name": "AFFLE (INDIA)",
      "tradingSymbol": "AFFLE",
      "label": "AFFLE",
      "value": "AFFLE"
    },
    {
      "instrument_token": "593665",
      "name": "NCC",
      "tradingSymbol": "NCC",
      "label": "NCC",
      "value": "NCC"
    },
    {
      "instrument_token": "4708097",
      "name": "RBL BANK",
      "tradingSymbol": "RBLBANK",
      "label": "RBLBANK",
      "value": "RBLBANK"
    },
    {
      "instrument_token": "2292225",
      "name": "TATA TELESERV(MAHARASTRA)",
      "tradingSymbol": "TTML",
      "label": "TTML",
      "value": "TTML"
    },
    {
      "instrument_token": "94209",
      "name": "BASF INDIA",
      "tradingSymbol": "BASF",
      "label": "BASF",
      "value": "BASF"
    },
    {
      "instrument_token": "3932673",
      "name": "V-GUARD IND",
      "tradingSymbol": "VGUARD",
      "label": "VGUARD",
      "value": "VGUARD"
    },
    {
      "instrument_token": "87553",
      "name": "COMPUTER AGE MNGT SER",
      "tradingSymbol": "CAMS",
      "label": "CAMS",
      "value": "CAMS"
    },
    {
      "instrument_token": "3526657",
      "name": "THE GE SHPG.LTD",
      "tradingSymbol": "GESHIP",
      "label": "GESHIP",
      "value": "GESHIP"
    },
    {
      "instrument_token": "3406081",
      "name": "CENTURY PLYBOARDS (I)",
      "tradingSymbol": "CENTURYPLY",
      "label": "CENTURYPLY",
      "value": "CENTURYPLY"
    },
    {
      "instrument_token": "1292545",
      "name": "CLEAN SCIENCE & TECH",
      "tradingSymbol": "CLEAN",
      "label": "CLEAN",
      "value": "CLEAN"
    },
    {
      "instrument_token": "774145",
      "name": "JINDAL SAW",
      "tradingSymbol": "JINDALSAW",
      "label": "JINDALSAW",
      "value": "JINDALSAW"
    },
    {
      "instrument_token": "3661825",
      "name": "FIRSTSOURCE SOLU.",
      "tradingSymbol": "FSL",
      "label": "FSL",
      "value": "FSL"
    },
    {
      "instrument_token": "275457",
      "name": "ZENSAR TECHNOLOGIES ",
      "tradingSymbol": "ZENSARTECH",
      "label": "ZENSARTECH",
      "value": "ZENSARTECH"
    },
    {
      "instrument_token": "3539457",
      "name": "SOBHA",
      "tradingSymbol": "SOBHA",
      "label": "SOBHA",
      "value": "SOBHA"
    },
    {
      "instrument_token": "163073",
      "name": "CHAMBAL FERTILIZERS",
      "tradingSymbol": "CHAMBLFERT",
      "label": "CHAMBLFERT",
      "value": "CHAMBLFERT"
    },
    {
      "instrument_token": "1883649",
      "name": "DATA PATTERNS INDIA",
      "tradingSymbol": "DATAPATTNS",
      "label": "DATAPATTNS",
      "value": "DATAPATTNS"
    },
    {
      "instrument_token": "524545",
      "name": "CHENNAI PETROLEUM CORP LT",
      "tradingSymbol": "CHENNPETRO",
      "label": "CHENNPETRO",
      "value": "CHENNPETRO"
    },
    {
      "instrument_token": "3026177",
      "name": "WELSPUN CORP",
      "tradingSymbol": "WELCORP",
      "label": "WELCORP",
      "value": "WELCORP"
    },
    {
      "instrument_token": "4488705",
      "name": "MAHANAGAR GAS",
      "tradingSymbol": "MGL",
      "label": "MGL",
      "value": "MGL"
    },
    {
      "instrument_token": "498945",
      "name": "KSB",
      "tradingSymbol": "KSB",
      "label": "KSB",
      "value": "KSB"
    },
    {
      "instrument_token": "2880769",
      "name": "WELSPUN LIVING",
      "tradingSymbol": "WELSPUNLIV",
      "label": "WELSPUNLIV",
      "value": "WELSPUNLIV"
    },
    {
      "instrument_token": "3669505",
      "name": "HIMADRI SPECIALITY CHEM L",
      "tradingSymbol": "HSCL",
      "label": "HSCL",
      "value": "HSCL"
    },
    {
      "instrument_token": "207617",
      "name": "DCM SHRIRAM",
      "tradingSymbol": "DCMSHRIRAM",
      "label": "DCMSHRIRAM",
      "value": "DCMSHRIRAM"
    },
    {
      "instrument_token": "1436161",
      "name": "ASTRAZENECA PHARMA IND LT",
      "tradingSymbol": "ASTRAZEN",
      "label": "ASTRAZEN",
      "value": "ASTRAZEN"
    },
    {
      "instrument_token": "975873",
      "name": "ZEE ENTERTAINMENT ENT",
      "tradingSymbol": "ZEEL",
      "label": "ZEEL",
      "value": "ZEEL"
    },
    {
      "instrument_token": "101121",
      "name": "BEML",
      "tradingSymbol": "BEML",
      "label": "BEML",
      "value": "BEML"
    },
    {
      "instrument_token": "5619457",
      "name": "HFCL",
      "tradingSymbol": "HFCL",
      "label": "HFCL",
      "value": "HFCL"
    },
    {
      "instrument_token": "2408449",
      "name": "RAINBOW CHILDRENS MED",
      "tradingSymbol": "RAINBOW",
      "label": "RAINBOW",
      "value": "RAINBOW"
    },
    {
      "instrument_token": "1540609",
      "name": "ADIT BIRL SUN LIF AMC",
      "tradingSymbol": "ABSLAMC",
      "label": "ABSLAMC",
      "value": "ABSLAMC"
    },
    {
      "instrument_token": "5072129",
      "name": "HONASA CONSUMER",
      "tradingSymbol": "HONASA",
      "label": "HONASA",
      "value": "HONASA"
    },
    {
      "instrument_token": "1376769",
      "name": "ASAHI INDIA GLASS",
      "tradingSymbol": "ASAHIINDIA",
      "label": "ASAHIINDIA",
      "value": "ASAHIINDIA"
    },
    {
      "instrument_token": "3365633",
      "name": "PVR INOX",
      "tradingSymbol": "PVRINOX",
      "label": "PVRINOX",
      "value": "PVRINOX"
    },
    {
      "instrument_token": "25601",
      "name": "AMARA RAJA ENERGY MOB",
      "tradingSymbol": "ARE&M",
      "label": "ARE&M",
      "value": "ARE&M"
    },
    {
      "instrument_token": "3023105",
      "name": "IIFL FINANCE",
      "tradingSymbol": "IIFL",
      "label": "IIFL",
      "value": "IIFL"
    },
    {
      "instrument_token": "4423425",
      "name": "BLS INTL SERVS",
      "tradingSymbol": "BLS",
      "label": "BLS",
      "value": "BLS"
    },
    {
      "instrument_token": "4524801",
      "name": "ALOK INDUSTRIES",
      "tradingSymbol": "ALOKINDS",
      "label": "ALOKINDS",
      "value": "ALOKINDS"
    },
    {
      "instrument_token": "530689",
      "name": "VARDHMAN TEXTILES",
      "tradingSymbol": "VTL",
      "label": "VTL",
      "value": "VTL"
    },
    {
      "instrument_token": "1293825",
      "name": "G R INFRAPROJECTS",
      "tradingSymbol": "GRINFRA",
      "label": "GRINFRA",
      "value": "GRINFRA"
    },
    {
      "instrument_token": "3575297",
      "name": "HBL POWER SYSTEMS",
      "tradingSymbol": "HBLPOWER",
      "label": "HBLPOWER",
      "value": "HBLPOWER"
    },
    {
      "instrument_token": "2964481",
      "name": "WESTLIFE FOODWORLD",
      "tradingSymbol": "WESTLIFE",
      "label": "WESTLIFE",
      "value": "WESTLIFE"
    },
    {
      "instrument_token": "2921217",
      "name": "RAMKRISHNA FORGINGS",
      "tradingSymbol": "RKFORGE",
      "label": "RKFORGE",
      "value": "RKFORGE"
    },
    {
      "instrument_token": "5359617",
      "name": "KIRLOSKAR OIL ENG",
      "tradingSymbol": "KIRLOSENG",
      "label": "KIRLOSENG",
      "value": "KIRLOSENG"
    },
    {
      "instrument_token": "3945985",
      "name": "TITAGARH RAIL SYSTEMS",
      "tradingSymbol": "TITAGARH",
      "label": "TITAGARH",
      "value": "TITAGARH"
    },
    {
      "instrument_token": "958465",
      "name": "FINE ORGANIC IND.",
      "tradingSymbol": "FINEORG",
      "label": "FINEORG",
      "value": "FINEORG"
    },
    {
      "instrument_token": "303361",
      "name": "AMBER ENTERPRISES (I)",
      "tradingSymbol": "AMBER",
      "label": "AMBER",
      "value": "AMBER"
    },
    {
      "instrument_token": "3063297",
      "name": "BIKAJI FOODS INTERN",
      "tradingSymbol": "BIKAJI",
      "label": "BIKAJI",
      "value": "BIKAJI"
    },
    {
      "instrument_token": "3197185",
      "name": "STRLNG & WIL REN ENE",
      "tradingSymbol": "SWSOLAR",
      "label": "SWSOLAR",
      "value": "SWSOLAR"
    },
    {
      "instrument_token": "731905",
      "name": "RAYMOND",
      "tradingSymbol": "RAYMOND",
      "label": "RAYMOND",
      "value": "RAYMOND"
    },
    {
      "instrument_token": "56321",
      "name": "INDIAN ENERGY EXC",
      "tradingSymbol": "IEX",
      "label": "IEX",
      "value": "IEX"
    },
    {
      "instrument_token": "3785729",
      "name": "SUN PHARMA ADV.RES.CO.LTD",
      "tradingSymbol": "SPARC",
      "label": "SPARC",
      "value": "SPARC"
    },
    {
      "instrument_token": "151553",
      "name": "GRAPHITE INDIA",
      "tradingSymbol": "GRAPHITE",
      "label": "GRAPHITE",
      "value": "GRAPHITE"
    },
    {
      "instrument_token": "2461953",
      "name": "SUPREME PETROCHEM",
      "tradingSymbol": "SPLPETRO",
      "label": "SPLPETRO",
      "value": "SPLPETRO"
    },
    {
      "instrument_token": "622337",
      "name": "RAILTEL CORP OF IND",
      "tradingSymbol": "RAILTEL",
      "label": "RAILTEL",
      "value": "RAILTEL"
    },
    {
      "instrument_token": "408833",
      "name": "INGERSOLL-RAND INDIA",
      "tradingSymbol": "INGERRAND",
      "label": "INGERRAND",
      "value": "INGERRAND"
    },
    {
      "instrument_token": "3885825",
      "name": "ECLERX SERVICES",
      "tradingSymbol": "ECLERX",
      "label": "ECLERX",
      "value": "ECLERX"
    },
    {
      "instrument_token": "5842945",
      "name": "JUNIPER HOTELS",
      "tradingSymbol": "JUNIPER",
      "label": "JUNIPER",
      "value": "JUNIPER"
    },
    {
      "instrument_token": "5415425",
      "name": "ERIS LIFESCIENCES",
      "tradingSymbol": "ERIS",
      "label": "ERIS",
      "value": "ERIS"
    },
    {
      "instrument_token": "7977729",
      "name": "RHI MAGNESITA INDIA",
      "tradingSymbol": "RHIM",
      "label": "RHIM",
      "value": "RHIM"
    },
    {
      "instrument_token": "1256193",
      "name": "ENGINEERS INDIA",
      "tradingSymbol": "ENGINERSIN",
      "label": "ENGINERSIN",
      "value": "ENGINERSIN"
    },
    {
      "instrument_token": "534529",
      "name": "MAHARASHTRA SEAMLESS",
      "tradingSymbol": "MAHSEAMLES",
      "label": "MAHSEAMLES",
      "value": "MAHSEAMLES"
    },
    {
      "instrument_token": "12289",
      "name": "HAPPIEST MINDS TECHNO",
      "tradingSymbol": "HAPPSTMNDS",
      "label": "HAPPSTMNDS",
      "value": "HAPPSTMNDS"
    },
    {
      "instrument_token": "3695361",
      "name": "JK TYRE & INDUSTRIES",
      "tradingSymbol": "JKTYRE",
      "label": "JKTYRE",
      "value": "JKTYRE"
    },
    {
      "instrument_token": "5409537",
      "name": "TEJAS NETWORKS",
      "tradingSymbol": "TEJASNET",
      "label": "TEJASNET",
      "value": "TEJASNET"
    },
    {
      "instrument_token": "2402561",
      "name": "PNC INFRATECH",
      "tradingSymbol": "PNCINFRA",
      "label": "PNCINFRA",
      "value": "PNCINFRA"
    },
    {
      "instrument_token": "297985",
      "name": "NEWGEN SOFTWARE TECH",
      "tradingSymbol": "NEWGEN",
      "label": "NEWGEN",
      "value": "NEWGEN"
    },
    {
      "instrument_token": "5275393",
      "name": "INOX INDIA",
      "tradingSymbol": "INOXINDIA",
      "label": "INOXINDIA",
      "value": "INOXINDIA"
    },
    {
      "instrument_token": "3577857",
      "name": "TANLA PLATFORMS",
      "tradingSymbol": "TANLA",
      "label": "TANLA",
      "value": "TANLA"
    },
    {
      "instrument_token": "122881",
      "name": "BIRLA CORPORATION",
      "tradingSymbol": "BIRLACORPN",
      "label": "BIRLACORPN",
      "value": "BIRLACORPN"
    },
    {
      "instrument_token": "97281",
      "name": "BOMBAY BURMAH TRADING COR",
      "tradingSymbol": "BBTC",
      "label": "BBTC",
      "value": "BBTC"
    },
    {
      "instrument_token": "1332225",
      "name": "GUJARAT MINERAL DEV CORP",
      "tradingSymbol": "GMDCLTD",
      "label": "GMDCLTD",
      "value": "GMDCLTD"
    },
    {
      "instrument_token": "1389057",
      "name": "NUVOCO VISTAS CORP",
      "tradingSymbol": "NUVOCO",
      "label": "NUVOCO",
      "value": "NUVOCO"
    },
    {
      "instrument_token": "375553",
      "name": "AKZO NOBEL INDIA",
      "tradingSymbol": "AKZOINDIA",
      "label": "AKZOINDIA",
      "value": "AKZOINDIA"
    },
    {
      "instrument_token": "3905025",
      "name": "CEAT",
      "tradingSymbol": "CEATLTD",
      "label": "CEATLTD",
      "value": "CEATLTD"
    },
    {
      "instrument_token": "3906305",
      "name": "RELIANCE POWER",
      "tradingSymbol": "RPOWER",
      "label": "RPOWER",
      "value": "RPOWER"
    },
    {
      "instrument_token": "141569",
      "name": "RELIANCE INFRASTRUCTU",
      "tradingSymbol": "RELINFRA",
      "label": "RELINFRA",
      "value": "RELINFRA"
    },
    {
      "instrument_token": "3432705",
      "name": "GODAWARI POW & ISP",
      "tradingSymbol": "GPIL",
      "label": "GPIL",
      "value": "GPIL"
    },
    {
      "instrument_token": "3492609",
      "name": "ELECON ENG. CO.",
      "tradingSymbol": "ELECON",
      "label": "ELECON",
      "value": "ELECON"
    },
    {
      "instrument_token": "3486721",
      "name": "ANANT RAJ",
      "tradingSymbol": "ANANTRAJ",
      "label": "ANANTRAJ",
      "value": "ANANTRAJ"
    },
    {
      "instrument_token": "237569",
      "name": "ELECTROSTEEL CASTINGS",
      "tradingSymbol": "ELECTCAST",
      "label": "ELECTCAST",
      "value": "ELECTCAST"
    },
    {
      "instrument_token": "4639745",
      "name": "VALOR ESTATE",
      "tradingSymbol": "DBREALTY",
      "label": "DBREALTY",
      "value": "DBREALTY"
    },
    {
      "instrument_token": "233729",
      "name": "EQUITAS SMALL FIN BNK",
      "tradingSymbol": "EQUITASBNK",
      "label": "EQUITASBNK",
      "value": "EQUITASBNK"
    },
    {
      "instrument_token": "3419905",
      "name": "KFIN TECHNOLOGIES",
      "tradingSymbol": "KFINTECH",
      "label": "KFINTECH",
      "value": "KFINTECH"
    },
    {
      "instrument_token": "3848705",
      "name": "BAJAJ ELECT.LTD",
      "tradingSymbol": "BAJAJELEC",
      "label": "BAJAJELEC",
      "value": "BAJAJELEC"
    },
    {
      "instrument_token": "3011329",
      "name": "JAIPRAKASH POWER VEN.",
      "tradingSymbol": "JPPOWER",
      "label": "JPPOWER",
      "value": "JPPOWER"
    },
    {
      "instrument_token": "3039233",
      "name": "GRANULES INDIA",
      "tradingSymbol": "GRANULES",
      "label": "GRANULES",
      "value": "GRANULES"
    },
    {
      "instrument_token": "1378561",
      "name": "AAVAS FINANCIERS",
      "tradingSymbol": "AAVAS",
      "label": "AAVAS",
      "value": "AAVAS"
    },
    {
      "instrument_token": "2511361",
      "name": "AETHER INDUSTRIES",
      "tradingSymbol": "AETHER",
      "label": "AETHER",
      "value": "AETHER"
    },
    {
      "instrument_token": "134913",
      "name": "UTI ASSET MNGMT CO",
      "tradingSymbol": "UTIAMC",
      "label": "UTIAMC",
      "value": "UTIAMC"
    },
    {
      "instrument_token": "667137",
      "name": "LEMON TREE HOTELS",
      "tradingSymbol": "LEMONTREE",
      "label": "LEMONTREE",
      "value": "LEMONTREE"
    },
    {
      "instrument_token": "3453697",
      "name": "JK LAKSHMI CEMENT",
      "tradingSymbol": "JKLAKSHMI",
      "label": "JKLAKSHMI",
      "value": "JKLAKSHMI"
    },
    {
      "instrument_token": "5051137",
      "name": "GUJARAT PIPAVAV PORT",
      "tradingSymbol": "GPPL",
      "label": "GPPL",
      "value": "GPPL"
    },
    {
      "instrument_token": "4911105",
      "name": "SHEELA FOAM",
      "tradingSymbol": "SFL",
      "label": "SFL",
      "value": "SFL"
    },
    {
      "instrument_token": "678145",
      "name": "PCBL",
      "tradingSymbol": "PCBL",
      "label": "PCBL",
      "value": "PCBL"
    },
    {
      "instrument_token": "1850113",
      "name": "C.E. INFO SYSTEMS",
      "tradingSymbol": "MAPMYINDIA",
      "label": "MAPMYINDIA",
      "value": "MAPMYINDIA"
    },
    {
      "instrument_token": "32769",
      "name": "ROUTE MOBILE",
      "tradingSymbol": "ROUTE",
      "label": "ROUTE",
      "value": "ROUTE"
    },
    {
      "instrument_token": "149249",
      "name": "CAN FIN HOMES",
      "tradingSymbol": "CANFINHOME",
      "label": "CANFINHOME",
      "value": "CANFINHOME"
    },
    {
      "instrument_token": "1459457",
      "name": "CITY UNION BANK",
      "tradingSymbol": "CUB",
      "label": "CUB",
      "value": "CUB"
    },
    {
      "instrument_token": "1719809",
      "name": "SAPPHIRE FOODS INDIA",
      "tradingSymbol": "SAPPHIRE",
      "label": "SAPPHIRE",
      "value": "SAPPHIRE"
    },
    {
      "instrument_token": "999937",
      "name": "CAPLIN POINT LAB",
      "tradingSymbol": "CAPLIPOINT",
      "label": "CAPLIPOINT",
      "value": "CAPLIPOINT"
    },
    {
      "instrument_token": "6629633",
      "name": "MINDA CORPORATION",
      "tradingSymbol": "MINDACORP",
      "label": "MINDACORP",
      "value": "MINDACORP"
    },
    {
      "instrument_token": "4596993",
      "name": "MMTC",
      "tradingSymbol": "MMTC",
      "label": "MMTC",
      "value": "MMTC"
    },
    {
      "instrument_token": "381697",
      "name": "IFCI",
      "tradingSymbol": "IFCI",
      "label": "IFCI",
      "value": "IFCI"
    },
    {
      "instrument_token": "692481",
      "name": "PRAJ INDUSTRIES",
      "tradingSymbol": "PRAJIND",
      "label": "PRAJIND",
      "value": "PRAJIND"
    },
    {
      "instrument_token": "3475713",
      "name": "VOLTAMP TRANSFORMERS",
      "tradingSymbol": "VOLTAMP",
      "label": "VOLTAMP",
      "value": "VOLTAMP"
    },
    {
      "instrument_token": "780289",
      "name": "SHIPPING CORP OF INDIA LT",
      "tradingSymbol": "SCI",
      "label": "SCI",
      "value": "SCI"
    },
    {
      "instrument_token": "2263041",
      "name": "USHA MARTIN",
      "tradingSymbol": "USHAMART",
      "label": "USHAMART",
      "value": "USHAMART"
    },
    {
      "instrument_token": "234497",
      "name": "EID PARRY INDIA",
      "tradingSymbol": "EIDPARRY",
      "label": "EIDPARRY",
      "value": "EIDPARRY"
    },
    {
      "instrument_token": "6988033",
      "name": "RATTANINDIA ENT",
      "tradingSymbol": "RTNINDIA",
      "label": "RTNINDIA",
      "value": "RTNINDIA"
    },
    {
      "instrument_token": "724225",
      "name": "ANUPAM RASAYAN INDIA",
      "tradingSymbol": "ANURAS",
      "label": "ANURAS",
      "value": "ANURAS"
    },
    {
      "instrument_token": "1347841",
      "name": "GLENMARK LIFE SCIENCE",
      "tradingSymbol": "GLS",
      "label": "GLS",
      "value": "GLS"
    },
    {
      "instrument_token": "5261057",
      "name": "DOMS INDUSTRIES",
      "tradingSymbol": "DOMS",
      "label": "DOMS",
      "value": "DOMS"
    },
    {
      "instrument_token": "4159745",
      "name": "INFIBEAM AVENUES",
      "tradingSymbol": "INFIBEAM",
      "label": "INFIBEAM",
      "value": "INFIBEAM"
    },
    {
      "instrument_token": "2962689",
      "name": "FORCE MOTORS",
      "tradingSymbol": "FORCEMOT",
      "label": "FORCEMOT",
      "value": "FORCEMOT"
    },
    {
      "instrument_token": "4514561",
      "name": "ZYDUS WELLNESS",
      "tradingSymbol": "ZYDUSWELL",
      "label": "ZYDUSWELL",
      "value": "ZYDUSWELL"
    },
    {
      "instrument_token": "5399297",
      "name": "STAR CEMENT",
      "tradingSymbol": "STARCEMENT",
      "label": "STARCEMENT",
      "value": "STARCEMENT"
    },
    {
      "instrument_token": "36865",
      "name": "GODREJ AGROVET",
      "tradingSymbol": "GODREJAGRO",
      "label": "GODREJAGRO",
      "value": "GODREJAGRO"
    },
    {
      "instrument_token": "907777",
      "name": "TTK PRESTIGE",
      "tradingSymbol": "TTKPRESTIG",
      "label": "TTKPRESTIG",
      "value": "TTKPRESTIG"
    },
    {
      "instrument_token": "1148673",
      "name": "ALKYL AMINES CHEM.",
      "tradingSymbol": "ALKYLAMINE",
      "label": "ALKYLAMINE",
      "value": "ALKYLAMINE"
    },
    {
      "instrument_token": "300545",
      "name": "GUJ NAR VAL FER & CHEM L",
      "tradingSymbol": "GNFC",
      "label": "GNFC",
      "value": "GNFC"
    },
    {
      "instrument_token": "730625",
      "name": "CRAFTSMAN AUTOMATION",
      "tradingSymbol": "CRAFTSMAN",
      "label": "CRAFTSMAN",
      "value": "CRAFTSMAN"
    },
    {
      "instrument_token": "2060801",
      "name": "MAHINDRA LIFESPACE DEVLTD",
      "tradingSymbol": "MAHLIFE",
      "label": "MAHLIFE",
      "value": "MAHLIFE"
    },
    {
      "instrument_token": "4571905",
      "name": "REDTAPE",
      "tradingSymbol": "REDTAPE",
      "label": "REDTAPE",
      "value": "REDTAPE"
    },
    {
      "instrument_token": "931073",
      "name": "JUBILANT PHARMOVA",
      "tradingSymbol": "JUBLPHARMA",
      "label": "JUBLPHARMA",
      "value": "JUBLPHARMA"
    },
    {
      "instrument_token": "4462849",
      "name": "NETWEB TECH INDIA",
      "tradingSymbol": "NETWEB",
      "label": "NETWEB",
      "value": "NETWEB"
    },
    {
      "instrument_token": "3612417",
      "name": "NETWORK18 MEDIA & INV",
      "tradingSymbol": "NETWORK18",
      "label": "NETWORK18",
      "value": "NETWORK18"
    },
    {
      "instrument_token": "701185",
      "name": "PRISM JOHNSON",
      "tradingSymbol": "PRSMJOHNSN",
      "label": "PRSMJOHNSN",
      "value": "PRSMJOHNSN"
    },
    {
      "instrument_token": "2452737",
      "name": "METROPOLIS HEALTHCARE",
      "tradingSymbol": "METROPOLIS",
      "label": "METROPOLIS",
      "value": "METROPOLIS"
    },
    {
      "instrument_token": "3849985",
      "name": "CERA SANITARYWARE",
      "tradingSymbol": "CERA",
      "label": "CERA",
      "value": "CERA"
    },
    {
      "instrument_token": "4614657",
      "name": "SBFC FINANCE",
      "tradingSymbol": "SBFC",
      "label": "SBFC",
      "value": "SBFC"
    },
    {
      "instrument_token": "1401601",
      "name": "GARDEN REACH SHIP&ENG",
      "tradingSymbol": "GRSE",
      "label": "GRSE",
      "value": "GRSE"
    },
    {
      "instrument_token": "4756737",
      "name": "KIRLOSKAR BROTHERS",
      "tradingSymbol": "KIRLOSBROS",
      "label": "KIRLOSBROS",
      "value": "KIRLOSBROS"
    },
    {
      "instrument_token": "3898369",
      "name": "UJJIVAN SMALL FINANC BANK",
      "tradingSymbol": "UJJIVANSFB",
      "label": "UJJIVANSFB",
      "value": "UJJIVANSFB"
    },
    {
      "instrument_token": "4399617",
      "name": "SHRIRAM PIST. & RING",
      "tradingSymbol": "SHRIPISTON",
      "label": "SHRIPISTON",
      "value": "SHRIPISTON"
    },
    {
      "instrument_token": "3078657",
      "name": "SHREE RENUKA SUGARS",
      "tradingSymbol": "RENUKA",
      "label": "RENUKA",
      "value": "RENUKA"
    },
    {
      "instrument_token": "1837313",
      "name": "RATEGAIN TRAVEL TECHN",
      "tradingSymbol": "RATEGAIN",
      "label": "RATEGAIN",
      "value": "RATEGAIN"
    },
    {
      "instrument_token": "3336961",
      "name": "SAFARI IND (INDIA)",
      "tradingSymbol": "SAFARI",
      "label": "SAFARI",
      "value": "SAFARI"
    },
    {
      "instrument_token": "5338625",
      "name": "HAPPY FORGINGS",
      "tradingSymbol": "HAPPYFORGE",
      "label": "HAPPYFORGE",
      "value": "HAPPYFORGE"
    },
    {
      "instrument_token": "3024129",
      "name": "SHOPPERS STOP",
      "tradingSymbol": "SHOPERSTOP",
      "label": "SHOPERSTOP",
      "value": "SHOPERSTOP"
    },
    {
      "instrument_token": "2763009",
      "name": "SYRMA SGS TECHNOLOGY",
      "tradingSymbol": "SYRMA",
      "label": "SYRMA",
      "value": "SYRMA"
    },
    {
      "instrument_token": "1818881",
      "name": "TEGA INDUSTRIES",
      "tradingSymbol": "TEGA",
      "label": "TEGA",
      "value": "TEGA"
    },
    {
      "instrument_token": "3078145",
      "name": "ARCHEAN CHEMICAL IND",
      "tradingSymbol": "ACI",
      "label": "ACI",
      "value": "ACI"
    },
    {
      "instrument_token": "1857025",
      "name": "MEDPLUS HEALTH SERV",
      "tradingSymbol": "MEDPLUS",
      "label": "MEDPLUS",
      "value": "MEDPLUS"
    },
    {
      "instrument_token": "533761",
      "name": "MAHARASHTRA SCOOTERS",
      "tradingSymbol": "MAHSCOOTER",
      "label": "MAHSCOOTER",
      "value": "MAHSCOOTER"
    },
    {
      "instrument_token": "615937",
      "name": "NEULAND LAB",
      "tradingSymbol": "NEULANDLAB",
      "label": "NEULANDLAB",
      "value": "NEULANDLAB"
    },
    {
      "instrument_token": "5351681",
      "name": "AZAD ENGINEERING",
      "tradingSymbol": "AZAD",
      "label": "AZAD",
      "value": "AZAD"
    },
    {
      "instrument_token": "244481",
      "name": "ESAB INDIA",
      "tradingSymbol": "ESABINDIA",
      "label": "ESABINDIA",
      "value": "ESABINDIA"
    },
    {
      "instrument_token": "336641",
      "name": "GALAXY SURFACTANTS",
      "tradingSymbol": "GALAXYSURF",
      "label": "GALAXYSURF",
      "value": "GALAXYSURF"
    },
    {
      "instrument_token": "1922049",
      "name": "ZEN TECHNOLOGIES",
      "tradingSymbol": "ZENTEC",
      "label": "ZENTEC",
      "value": "ZENTEC"
    },
    {
      "instrument_token": "3041281",
      "name": "JSW HOLDINGS",
      "tradingSymbol": "JSWHL",
      "label": "JSWHL",
      "value": "JSWHL"
    },
    {
      "instrument_token": "3637249",
      "name": "TV18 BROADCAST",
      "tradingSymbol": "TV18BRDCST",
      "label": "TV18BRDCST",
      "value": "TV18BRDCST"
    },
    {
      "instrument_token": "526337",
      "name": "HOME FIRST FIN CO IND",
      "tradingSymbol": "HOMEFIRST",
      "label": "HOMEFIRST",
      "value": "HOMEFIRST"
    },
    {
      "instrument_token": "4437249",
      "name": "MAHINDRA HOLIDAYS",
      "tradingSymbol": "MHRIL",
      "label": "MHRIL",
      "value": "MHRIL"
    },
    {
      "instrument_token": "2681089",
      "name": "POWER MECH PROJECTS",
      "tradingSymbol": "POWERMECH",
      "label": "POWERMECH",
      "value": "POWERMECH"
    },
    {
      "instrument_token": "2061825",
      "name": "KARNATAKA BANK",
      "tradingSymbol": "KTKBANK",
      "label": "KTKBANK",
      "value": "KTKBANK"
    },
    {
      "instrument_token": "4749569",
      "name": "JUPITER LIFE LINE HOSP L",
      "tradingSymbol": "JLHL",
      "label": "JLHL",
      "value": "JLHL"
    },
    {
      "instrument_token": "543745",
      "name": "MASTEK",
      "tradingSymbol": "MASTEK",
      "label": "MASTEK",
      "value": "MASTEK"
    },
    {
      "instrument_token": "240641",
      "name": "PROCTER & GAMBLE HEALTH L",
      "tradingSymbol": "PGHL",
      "label": "PGHL",
      "value": "PGHL"
    },
    {
      "instrument_token": "891137",
      "name": "THOMAS COOK (INDIA)",
      "tradingSymbol": "THOMASCOOK",
      "label": "THOMASCOOK",
      "value": "THOMASCOOK"
    },
    {
      "instrument_token": "2931713",
      "name": "CCL PRODUCTS (I)",
      "tradingSymbol": "CCL",
      "label": "CCL",
      "value": "CCL"
    },
    {
      "instrument_token": "319233",
      "name": "GUJ STATE FERT & CHEM",
      "tradingSymbol": "GSFC",
      "label": "GSFC",
      "value": "GSFC"
    },
    {
      "instrument_token": "1894657",
      "name": "RAJESH EXPORTS",
      "tradingSymbol": "RAJESHEXPO",
      "label": "RAJESHEXPO",
      "value": "RAJESHEXPO"
    },
    {
      "instrument_token": "4532225",
      "name": "QUESS CORP",
      "tradingSymbol": "QUESS",
      "label": "QUESS",
      "value": "QUESS"
    },
    {
      "instrument_token": "987393",
      "name": "VARROC ENGINEERING",
      "tradingSymbol": "VARROC",
      "label": "VARROC",
      "value": "VARROC"
    },
    {
      "instrument_token": "2801921",
      "name": "TAMILNAD MERCA BANK",
      "tradingSymbol": "TMB",
      "label": "TMB",
      "value": "TMB"
    },
    {
      "instrument_token": "4665857",
      "name": "MAN INFRA",
      "tradingSymbol": "MANINFRA",
      "label": "MANINFRA",
      "value": "MANINFRA"
    },
    {
      "instrument_token": "714753",
      "name": "EASY TRIP PLANNERS",
      "tradingSymbol": "EASEMYTRIP",
      "label": "EASEMYTRIP",
      "value": "EASEMYTRIP"
    },
    {
      "instrument_token": "947969",
      "name": "VIP INDUSTRIES",
      "tradingSymbol": "VIPIND",
      "label": "VIPIND",
      "value": "VIPIND"
    },
    {
      "instrument_token": "417281",
      "name": "ION EXCHANGE (INDIA)",
      "tradingSymbol": "IONEXCHANG",
      "label": "IONEXCHANG",
      "value": "IONEXCHANG"
    },
    {
      "instrument_token": "5202689",
      "name": "RESPONSIVE INDUSTRIES",
      "tradingSymbol": "RESPONIND",
      "label": "RESPONIND",
      "value": "RESPONIND"
    },
    {
      "instrument_token": "630529",
      "name": "MISHRA DHATU NIGAM",
      "tradingSymbol": "MIDHANI",
      "label": "MIDHANI",
      "value": "MIDHANI"
    },
    {
      "instrument_token": "2951681",
      "name": "ELECTRONICS MART IND",
      "tradingSymbol": "EMIL",
      "label": "EMIL",
      "value": "EMIL"
    },
    {
      "instrument_token": "2259969",
      "name": "GUJARAT AMBUJA EXPORTS LT",
      "tradingSymbol": "GAEL",
      "label": "GAEL",
      "value": "GAEL"
    },
    {
      "instrument_token": "87297",
      "name": "BALRAMPUR CHINI MILLS",
      "tradingSymbol": "BALRAMCHIN",
      "label": "BALRAMCHIN",
      "value": "BALRAMCHIN"
    },
    {
      "instrument_token": "1887745",
      "name": "STRIDES PHARMA SCI",
      "tradingSymbol": "STAR",
      "label": "STAR",
      "value": "STAR"
    },
    {
      "instrument_token": "712449",
      "name": "JUBILANT INGREVIA",
      "tradingSymbol": "JUBLINGREA",
      "label": "JUBLINGREA",
      "value": "JUBLINGREA"
    },
    {
      "instrument_token": "4546049",
      "name": "SARDA ENERGY & MIN",
      "tradingSymbol": "SARDAEN",
      "label": "SARDAEN",
      "value": "SARDAEN"
    },
    {
      "instrument_token": "3491073",
      "name": "JM FINANCIAL",
      "tradingSymbol": "JMFINANCIL",
      "label": "JMFINANCIL",
      "value": "JMFINANCIL"
    },
    {
      "instrument_token": "1522689",
      "name": "THE SOUTH INDIAN BANK",
      "tradingSymbol": "SOUTHBANK",
      "label": "SOUTHBANK",
      "value": "SOUTHBANK"
    },
    {
      "instrument_token": "342017",
      "name": "HEG",
      "tradingSymbol": "HEG",
      "label": "HEG",
      "value": "HEG"
    },
    {
      "instrument_token": "1394945",
      "name": "CHEMPLAST SANMAR",
      "tradingSymbol": "CHEMPLASTS",
      "label": "CHEMPLASTS",
      "value": "CHEMPLASTS"
    },
    {
      "instrument_token": "49409",
      "name": "ARVIND",
      "tradingSymbol": "ARVIND",
      "label": "ARVIND",
      "value": "ARVIND"
    },
    {
      "instrument_token": "733697",
      "name": "RASHTRIYA CHEMICALS & FER",
      "tradingSymbol": "RCF",
      "label": "RCF",
      "value": "RCF"
    },
    {
      "instrument_token": "1027585",
      "name": "NAVA",
      "tradingSymbol": "NAVA",
      "label": "NAVA",
      "value": "NAVA"
    },
    {
      "instrument_token": "3456257",
      "name": "ALLCARGO LOGISTICS",
      "tradingSymbol": "ALLCARGO",
      "label": "ALLCARGO",
      "value": "ALLCARGO"
    },
    {
      "instrument_token": "3068673",
      "name": "INDO COUNT INDUSTRIES",
      "tradingSymbol": "ICIL",
      "label": "ICIL",
      "value": "ICIL"
    },
    {
      "instrument_token": "3912449",
      "name": "KNR CONSTRU",
      "tradingSymbol": "KNRCON",
      "label": "KNRCON",
      "value": "KNRCON"
    },
    {
      "instrument_token": "1253889",
      "name": "FDC",
      "tradingSymbol": "FDC",
      "label": "FDC",
      "value": "FDC"
    },
    {
      "instrument_token": "3857409",
      "name": "RELIGARE ENTER.",
      "tradingSymbol": "RELIGARE",
      "label": "RELIGARE",
      "value": "RELIGARE"
    },
    {
      "instrument_token": "5256705",
      "name": "GRAVITA INDIA",
      "tradingSymbol": "GRAVITA",
      "label": "GRAVITA",
      "value": "GRAVITA"
    },
    {
      "instrument_token": "3128065",
      "name": "KEYSTONE REALTORS",
      "tradingSymbol": "RUSTOMJEE",
      "label": "RUSTOMJEE",
      "value": "RUSTOMJEE"
    },
    {
      "instrument_token": "2708225",
      "name": "MARKSANS PHARMA",
      "tradingSymbol": "MARKSANS",
      "label": "MARKSANS",
      "value": "MARKSANS"
    },
    {
      "instrument_token": "4543233",
      "name": "NIIT LEARNING SYSTEMS",
      "tradingSymbol": "NIITMTS",
      "label": "NIITMTS",
      "value": "NIITMTS"
    },
    {
      "instrument_token": "4565249",
      "name": "AHLUWALIA CONT IND",
      "tradingSymbol": "AHLUCONT",
      "label": "AHLUCONT",
      "value": "AHLUCONT"
    },
    {
      "instrument_token": "7670273",
      "name": "JUSTDIAL",
      "tradingSymbol": "JUSTDIAL",
      "label": "JUSTDIAL",
      "value": "JUSTDIAL"
    },
    {
      "instrument_token": "3348737",
      "name": "TRIVENI ENGG. & INDS.",
      "tradingSymbol": "TRIVENI",
      "label": "TRIVENI",
      "value": "TRIVENI"
    },
    {
      "instrument_token": "4646657",
      "name": "TVS SUPPLY CHAIN SOL L",
      "tradingSymbol": "TVSSCS",
      "label": "TVSSCS",
      "value": "TVSSCS"
    },
    {
      "instrument_token": "281601",
      "name": "GARWARE TECH FIBRES",
      "tradingSymbol": "GARFIBRES",
      "label": "GARFIBRES",
      "value": "GARFIBRES"
    },
    {
      "instrument_token": "941057",
      "name": "VESUVIUS INDIA",
      "tradingSymbol": "VESUVIUS",
      "label": "VESUVIUS",
      "value": "VESUVIUS"
    },
    {
      "instrument_token": "1252353",
      "name": "SAREGAMA INDIA",
      "tradingSymbol": "SAREGAMA",
      "label": "SAREGAMA",
      "value": "SAREGAMA"
    },
    {
      "instrument_token": "4630017",
      "name": "DILIP BUILDCON",
      "tradingSymbol": "DBL",
      "label": "DBL",
      "value": "DBL"
    },
    {
      "instrument_token": "5262337",
      "name": "INDIA SHELTER FIN CORP L",
      "tradingSymbol": "INDIASHLTR",
      "label": "INDIASHLTR",
      "value": "INDIASHLTR"
    },
    {
      "instrument_token": "5039617",
      "name": "BLUE JET HEALTHCARE",
      "tradingSymbol": "BLUEJET",
      "label": "BLUEJET",
      "value": "BLUEJET"
    },
    {
      "instrument_token": "3712257",
      "name": "BALAJI AMINES",
      "tradingSymbol": "BALAMINES",
      "label": "BALAMINES",
      "value": "BALAMINES"
    },
    {
      "instrument_token": "852225",
      "name": "ISGEC HEAVY ENG",
      "tradingSymbol": "ISGEC",
      "label": "ISGEC",
      "value": "ISGEC"
    },
    {
      "instrument_token": "2031617",
      "name": "AVANTI FEEDS",
      "tradingSymbol": "AVANTIFEED",
      "label": "AVANTIFEED",
      "value": "AVANTIFEED"
    },
    {
      "instrument_token": "387841",
      "name": "THE INDIA CEMENTS",
      "tradingSymbol": "INDIACEM",
      "label": "INDIACEM",
      "value": "INDIACEM"
    },
    {
      "instrument_token": "416769",
      "name": "MRS BECTORS FOOD SPE",
      "tradingSymbol": "BECTORFOOD",
      "label": "BECTORFOOD",
      "value": "BECTORFOOD"
    },
    {
      "instrument_token": "2396673",
      "name": "CAMPUS ACTIVEWEAR",
      "tradingSymbol": "CAMPUS",
      "label": "CAMPUS",
      "value": "CAMPUS"
    },
    {
      "instrument_token": "3536897",
      "name": "LT FOODS",
      "tradingSymbol": "LTFOODS",
      "label": "LTFOODS",
      "value": "LTFOODS"
    },
    {
      "instrument_token": "1429761",
      "name": "VIJAYA DIAGNOSTIC CEN",
      "tradingSymbol": "VIJAYA",
      "label": "VIJAYA",
      "value": "VIJAYA"
    },
    {
      "instrument_token": "1782785",
      "name": "GO FASHION INDIA",
      "tradingSymbol": "GOCOLORS",
      "label": "GOCOLORS",
      "value": "GOCOLORS"
    },
    {
      "instrument_token": "806145",
      "name": "BOROSIL RENEWABLES",
      "tradingSymbol": "BORORENEW",
      "label": "BORORENEW",
      "value": "BORORENEW"
    },
    {
      "instrument_token": "727297",
      "name": "LAXMI ORGANIC INDUS",
      "tradingSymbol": "LXCHEM",
      "label": "LXCHEM",
      "value": "LXCHEM"
    },
    {
      "instrument_token": "1753089",
      "name": "GREENLAM INDUSTRIES",
      "tradingSymbol": "GREENLAM",
      "label": "GREENLAM",
      "value": "GREENLAM"
    },
    {
      "instrument_token": "211713",
      "name": "DEEPAK FERTILIZERS & PETR",
      "tradingSymbol": "DEEPAKFERT",
      "label": "DEEPAKFERT",
      "value": "DEEPAKFERT"
    },
    {
      "instrument_token": "1946369",
      "name": "CMS INFO SYSTEMS",
      "tradingSymbol": "CMSINFO",
      "label": "CMSINFO",
      "value": "CMSINFO"
    },
    {
      "instrument_token": "2707713",
      "name": "KRBL",
      "tradingSymbol": "KRBL",
      "label": "KRBL",
      "value": "KRBL"
    },
    {
      "instrument_token": "2496001",
      "name": "ETHOS",
      "tradingSymbol": "ETHOSLTD",
      "label": "ETHOSLTD",
      "value": "ETHOSLTD"
    },
    {
      "instrument_token": "5587969",
      "name": "TEXMACO RAIL & ENG.",
      "tradingSymbol": "TEXRAIL",
      "label": "TEXRAIL",
      "value": "TEXRAIL"
    },
    {
      "instrument_token": "2708481",
      "name": "TRANSPORT CORPN OF INDIA",
      "tradingSymbol": "TCI",
      "label": "TCI",
      "value": "TCI"
    },
    {
      "instrument_token": "5284353",
      "name": "JINDAL WORLDWIDE",
      "tradingSymbol": "JINDWORLD",
      "label": "JINDWORLD",
      "value": "JINDWORLD"
    },
    {
      "instrument_token": "2497537",
      "name": "EMUDHRA",
      "tradingSymbol": "EMUDHRA",
      "label": "EMUDHRA",
      "value": "EMUDHRA"
    },
    {
      "instrument_token": "1347585",
      "name": "PDS",
      "tradingSymbol": "PDSL",
      "label": "PDSL",
      "value": "PDSL"
    },
    {
      "instrument_token": "3670785",
      "name": "GANESH HOUSING CORP",
      "tradingSymbol": "GANESHHOUC",
      "label": "GANESHHOUC",
      "value": "GANESHHOUC"
    },
    {
      "instrument_token": "3831297",
      "name": "CSB BANK",
      "tradingSymbol": "CSBBANK",
      "label": "CSBBANK",
      "value": "CSBBANK"
    },
    {
      "instrument_token": "26625",
      "name": "SHARE IND. SECURITIES",
      "tradingSymbol": "SHAREINDIA",
      "label": "SHAREINDIA",
      "value": "SHAREINDIA"
    },
    {
      "instrument_token": "380161",
      "name": "IFB INDUSTRIES",
      "tradingSymbol": "IFBIND",
      "label": "IFBIND",
      "value": "IFBIND"
    },
    {
      "instrument_token": "4107521",
      "name": "PRINCE PIPES FITTINGS",
      "tradingSymbol": "PRINCEPIPE",
      "label": "PRINCEPIPE",
      "value": "PRINCEPIPE"
    },
    {
      "instrument_token": "2909185",
      "name": "VAIBHAV GLOBAL",
      "tradingSymbol": "VAIBHAVGBL",
      "label": "VAIBHAVGBL",
      "value": "VAIBHAVGBL"
    },
    {
      "instrument_token": "2332417",
      "name": "ARVIND FASHIONS",
      "tradingSymbol": "ARVINDFASN",
      "label": "ARVINDFASN",
      "value": "ARVINDFASN"
    },
    {
      "instrument_token": "3870465",
      "name": "EDELWEISS FIN SERV",
      "tradingSymbol": "EDELWEISS",
      "label": "EDELWEISS",
      "value": "EDELWEISS"
    },
    {
      "instrument_token": "4421377",
      "name": "SENCO GOLD",
      "tradingSymbol": "SENCO",
      "label": "SENCO",
      "value": "SENCO"
    },
    {
      "instrument_token": "2927361",
      "name": "SPANDANA SPHOORTY FIN",
      "tradingSymbol": "SPANDANA",
      "label": "SPANDANA",
      "value": "SPANDANA"
    },
    {
      "instrument_token": "524289",
      "name": "INDIGO PAINTS",
      "tradingSymbol": "INDIGOPNTS",
      "label": "INDIGOPNTS",
      "value": "INDIGOPNTS"
    },
    {
      "instrument_token": "3047681",
      "name": "GENUS POWER INFRASTRU",
      "tradingSymbol": "GENUSPOWER",
      "label": "GENUSPOWER",
      "value": "GENUSPOWER"
    },
    {
      "instrument_token": "6192641",
      "name": "SYMPHONY",
      "tradingSymbol": "SYMPHONY",
      "label": "SYMPHONY",
      "value": "SYMPHONY"
    },
    {
      "instrument_token": "428033",
      "name": "H.G.INFRA ENGINEERING",
      "tradingSymbol": "HGINFRA",
      "label": "HGINFRA",
      "value": "HGINFRA"
    },
    {
      "instrument_token": "2333953",
      "name": "TIPS INDUSTRIES",
      "tradingSymbol": "TIPSINDLTD",
      "label": "TIPSINDLTD",
      "value": "TIPSINDLTD"
    },
    {
      "instrument_token": "5504257",
      "name": "SIS",
      "tradingSymbol": "SIS",
      "label": "SIS",
      "value": "SIS"
    },
    {
      "instrument_token": "2395137",
      "name": "MSTC",
      "tradingSymbol": "MSTCLTD",
      "label": "MSTCLTD",
      "value": "MSTCLTD"
    },
    {
      "instrument_token": "3944705",
      "name": "NESCO",
      "tradingSymbol": "NESCO",
      "label": "NESCO",
      "value": "NESCO"
    },
    {
      "instrument_token": "3598849",
      "name": "SANGHVI MOVERS",
      "tradingSymbol": "SANGHVIMOV",
      "label": "SANGHVIMOV",
      "value": "SANGHVIMOV"
    },
    {
      "instrument_token": "4699905",
      "name": "SANDUR MANG & IRON ORES L",
      "tradingSymbol": "SANDUMA",
      "label": "SANDUMA",
      "value": "SANDUMA"
    },
    {
      "instrument_token": "1439233",
      "name": "ITD CEMENTATION INDIA",
      "tradingSymbol": "ITDCEM",
      "label": "ITDCEM",
      "value": "ITDCEM"
    },
    {
      "instrument_token": "4399873",
      "name": "CYIENT DLM",
      "tradingSymbol": "CYIENTDLM",
      "label": "CYIENTDLM",
      "value": "CYIENTDLM"
    },
    {
      "instrument_token": "251137",
      "name": "EPL",
      "tradingSymbol": "EPL",
      "label": "EPL",
      "value": "EPL"
    },
    {
      "instrument_token": "2992385",
      "name": "SUPRAJIT ENGINEERING",
      "tradingSymbol": "SUPRAJIT",
      "label": "SUPRAJIT",
      "value": "SUPRAJIT"
    },
    {
      "instrument_token": "4516097",
      "name": "SUNTECK REALTY",
      "tradingSymbol": "SUNTECK",
      "label": "SUNTECK",
      "value": "SUNTECK"
    },
    {
      "instrument_token": "179457",
      "name": "HEMISPHERE PROP IND",
      "tradingSymbol": "HEMIPROP",
      "label": "HEMIPROP",
      "value": "HEMIPROP"
    },
    {
      "instrument_token": "5332481",
      "name": "MOIL",
      "tradingSymbol": "MOIL",
      "label": "MOIL",
      "value": "MOIL"
    },
    {
      "instrument_token": "3764993",
      "name": "TIME TECHNOPLAST",
      "tradingSymbol": "TIMETECHNO",
      "label": "TIMETECHNO",
      "value": "TIMETECHNO"
    },
    {
      "instrument_token": "2974209",
      "name": "ASTRA MICROWAVE",
      "tradingSymbol": "ASTRAMICRO",
      "label": "ASTRAMICRO",
      "value": "ASTRAMICRO"
    },
    {
      "instrument_token": "768513",
      "name": "WONDERLA HOLIDAYS",
      "tradingSymbol": "WONDERLA",
      "label": "WONDERLA",
      "value": "WONDERLA"
    },
    {
      "instrument_token": "5158913",
      "name": "ASK AUTOMOTIVE",
      "tradingSymbol": "ASKAUTOLTD",
      "label": "ASKAUTOLTD",
      "value": "ASKAUTOLTD"
    },
    {
      "instrument_token": "4557057",
      "name": "LLOYDS ENGG WORK",
      "tradingSymbol": "LLOYDSENGG",
      "label": "LLOYDSENGG",
      "value": "LLOYDSENGG"
    },
    {
      "instrument_token": "401921",
      "name": "GMM PFAUDLER",
      "tradingSymbol": "GMMPFAUDLR",
      "label": "GMMPFAUDLR",
      "value": "GMMPFAUDLR"
    },
    {
      "instrument_token": "864001",
      "name": "SURYA ROSHNI",
      "tradingSymbol": "SURYAROSNI",
      "label": "SURYAROSNI",
      "value": "SURYAROSNI"
    },
    {
      "instrument_token": "953345",
      "name": "VST INDUSTRIES",
      "tradingSymbol": "VSTIND",
      "label": "VSTIND",
      "value": "VSTIND"
    },
    {
      "instrument_token": "2906881",
      "name": "PTC INDIA",
      "tradingSymbol": "PTC",
      "label": "PTC",
      "value": "PTC"
    },
    {
      "instrument_token": "3036161",
      "name": "JK PAPER",
      "tradingSymbol": "JKPAPER",
      "label": "JKPAPER",
      "value": "JKPAPER"
    },
    {
      "instrument_token": "1472257",
      "name": "SANSERA ENGINEERING",
      "tradingSymbol": "SANSERA",
      "label": "SANSERA",
      "value": "SANSERA"
    },
    {
      "instrument_token": "2269697",
      "name": "CHOICE INTERNATIONAL",
      "tradingSymbol": "CHOICEIN",
      "label": "CHOICEIN",
      "value": "CHOICEIN"
    },
    {
      "instrument_token": "3077633",
      "name": "AURIONPRO SOLN",
      "tradingSymbol": "AURIONPRO",
      "label": "AURIONPRO",
      "value": "AURIONPRO"
    },
    {
      "instrument_token": "6519809",
      "name": "PAISALO DIGITAL",
      "tradingSymbol": "PAISALO",
      "label": "PAISALO",
      "value": "PAISALO"
    },
    {
      "instrument_token": "4940545",
      "name": "INDIA TOUR. DEV. CO.",
      "tradingSymbol": "ITDC",
      "label": "ITDC",
      "value": "ITDC"
    },
    {
      "instrument_token": "3116289",
      "name": "HINDUSTAN FOODS",
      "tradingSymbol": "HNDFDS",
      "label": "HNDFDS",
      "value": "HNDFDS"
    },
    {
      "instrument_token": "2493697",
      "name": "PARADEEP PHOSPHATES",
      "tradingSymbol": "PARADEEP",
      "label": "PARADEEP",
      "value": "PARADEEP"
    },
    {
      "instrument_token": "475905",
      "name": "KESORAM INDUSTRIES",
      "tradingSymbol": "KESORAMIND",
      "label": "KESORAMIND",
      "value": "KESORAMIND"
    },
    {
      "instrument_token": "352001",
      "name": "HINDUSTAN CONSTRUCTION CO",
      "tradingSymbol": "HCC",
      "label": "HCC",
      "value": "HCC"
    },
    {
      "instrument_token": "237057",
      "name": "ORCHID PHARMA",
      "tradingSymbol": "ORCHPHARMA",
      "label": "ORCHPHARMA",
      "value": "ORCHPHARMA"
    },
    {
      "instrument_token": "5319169",
      "name": "JAMNA AUTO IND",
      "tradingSymbol": "JAMNAAUTO",
      "label": "JAMNAAUTO",
      "value": "JAMNAAUTO"
    },
    {
      "instrument_token": "3717889",
      "name": "ICRA",
      "tradingSymbol": "ICRA",
      "label": "ICRA",
      "value": "ICRA"
    },
    {
      "instrument_token": "3433985",
      "name": "R SYS INTERNATIONAL",
      "tradingSymbol": "RSYSTEMS",
      "label": "RSYSTEMS",
      "value": "RSYSTEMS"
    },
    {
      "instrument_token": "2445569",
      "name": "PRUDENT CORP ADV SER",
      "tradingSymbol": "PRUDENT",
      "label": "PRUDENT",
      "value": "PRUDENT"
    },
    {
      "instrument_token": "693505",
      "name": "MTAR TECHNOLOGIES",
      "tradingSymbol": "MTARTECH",
      "label": "MTARTECH",
      "value": "MTARTECH"
    },
    {
      "instrument_token": "4443649",
      "name": "UTKARSH SMALL FIN BANK L",
      "tradingSymbol": "UTKARSHBNK",
      "label": "UTKARSHBNK",
      "value": "UTKARSHBNK"
    },
    {
      "instrument_token": "3926273",
      "name": "RAIN INDUSTRIES",
      "tradingSymbol": "RAIN",
      "label": "RAIN",
      "value": "RAIN"
    },
    {
      "instrument_token": "1158401",
      "name": "DYNAMATIC TECHNOLOGIES",
      "tradingSymbol": "DYNAMATECH",
      "label": "DYNAMATECH",
      "value": "DYNAMATECH"
    },
    {
      "instrument_token": "1316609",
      "name": "JAI CORP",
      "tradingSymbol": "JAICORPLTD",
      "label": "JAICORPLTD",
      "value": "JAICORPLTD"
    },
    {
      "instrument_token": "382465",
      "name": "RESTAURANT BRAND ASIA",
      "tradingSymbol": "RBA",
      "label": "RBA",
      "value": "RBA"
    },
    {
      "instrument_token": "2178561",
      "name": "GATEWAY DISTRIPARKS",
      "tradingSymbol": "GATEWAY",
      "label": "GATEWAY",
      "value": "GATEWAY"
    },
    {
      "instrument_token": "324353",
      "name": "GUJARAT ALKALIES & CHEM",
      "tradingSymbol": "GUJALKALI",
      "label": "GUJALKALI",
      "value": "GUJALKALI"
    },
    {
      "instrument_token": "764673",
      "name": "NAZARA TECHNOLOGIES",
      "tradingSymbol": "NAZARA",
      "label": "NAZARA",
      "value": "NAZARA"
    },
    {
      "instrument_token": "720897",
      "name": "RALLIS INDIA",
      "tradingSymbol": "RALLIS",
      "label": "RALLIS",
      "value": "RALLIS"
    },
    {
      "instrument_token": "2226177",
      "name": "VRL LOGISTICS",
      "tradingSymbol": "VRLLOG",
      "label": "VRLLOG",
      "value": "VRLLOG"
    },
    {
      "instrument_token": "277761",
      "name": "GABRIEL INDIA",
      "tradingSymbol": "GABRIEL",
      "label": "GABRIEL",
      "value": "GABRIEL"
    },
    {
      "instrument_token": "1234433",
      "name": "DODLA DAIRY",
      "tradingSymbol": "DODLA",
      "label": "DODLA",
      "value": "DODLA"
    },
    {
      "instrument_token": "3908097",
      "name": "JKUMAR INFR.LTD.",
      "tradingSymbol": "JKIL",
      "label": "JKIL",
      "value": "JKIL"
    },
    {
      "instrument_token": "1351425",
      "name": "ROLEX RINGS",
      "tradingSymbol": "ROLEXRINGS",
      "label": "ROLEXRINGS",
      "value": "ROLEXRINGS"
    },
    {
      "instrument_token": "5168129",
      "name": "VA TECH WABAG",
      "tradingSymbol": "WABAG",
      "label": "WABAG",
      "value": "WABAG"
    },
    {
      "instrument_token": "5025537",
      "name": "PRICOL",
      "tradingSymbol": "PRICOLLTD",
      "label": "PRICOLLTD",
      "value": "PRICOLLTD"
    },
    {
      "instrument_token": "3982081",
      "name": "HEALTHCARE GLOB. ENT.",
      "tradingSymbol": "HCG",
      "label": "HCG",
      "value": "HCG"
    },
    {
      "instrument_token": "361473",
      "name": "AGI GREENPAC",
      "tradingSymbol": "AGI",
      "label": "AGI",
      "value": "AGI"
    },
    {
      "instrument_token": "4577537",
      "name": "D.B.CORP",
      "tradingSymbol": "DBCORP",
      "label": "DBCORP",
      "value": "DBCORP"
    },
    {
      "instrument_token": "3054593",
      "name": "FUSION MICRO FINANCE",
      "tradingSymbol": "FUSION",
      "label": "FUSION",
      "value": "FUSION"
    },
    {
      "instrument_token": "6248705",
      "name": "DHANUKA AGRITECH",
      "tradingSymbol": "DHANUKA",
      "label": "DHANUKA",
      "value": "DHANUKA"
    },
    {
      "instrument_token": "50945",
      "name": "MAS FINANCIAL SERV",
      "tradingSymbol": "MASFIN",
      "label": "MASFIN",
      "value": "MASFIN"
    },
    {
      "instrument_token": "3383809",
      "name": "SULA VINEYARDS",
      "tradingSymbol": "SULA",
      "label": "SULA",
      "value": "SULA"
    },
    {
      "instrument_token": "6445569",
      "name": "TD POWER SYSTEMS",
      "tradingSymbol": "TDPOWERSYS",
      "label": "TDPOWERSYS",
      "value": "TDPOWERSYS"
    },
    {
      "instrument_token": "596737",
      "name": "JAYASWAL NECO INDUSTR",
      "tradingSymbol": "JAYNECOIND",
      "label": "JAYNECOIND",
      "value": "JAYNECOIND"
    },
    {
      "instrument_token": "1124097",
      "name": "GULF OIL LUB. IND.",
      "tradingSymbol": "GULFOILLUB",
      "label": "GULFOILLUB",
      "value": "GULFOILLUB"
    },
    {
      "instrument_token": "4765185",
      "name": "SAMHI HOTELS",
      "tradingSymbol": "SAMHI",
      "label": "SAMHI",
      "value": "SAMHI"
    },
    {
      "instrument_token": "3255297",
      "name": "TEAMLEASE SERVICES",
      "tradingSymbol": "TEAMLEASE",
      "label": "TEAMLEASE",
      "value": "TEAMLEASE"
    },
    {
      "instrument_token": "3886081",
      "name": "KIRLOSKAR PNEUMATIC COM L",
      "tradingSymbol": "KIRLPNU",
      "label": "KIRLPNU",
      "value": "KIRLPNU"
    },
    {
      "instrument_token": "1377793",
      "name": "EPIGRAL",
      "tradingSymbol": "EPIGRAL",
      "label": "EPIGRAL",
      "value": "EPIGRAL"
    },
    {
      "instrument_token": "3641089",
      "name": "TECHNOCRAFT IND",
      "tradingSymbol": "TIIL",
      "label": "TIIL",
      "value": "TIIL"
    },
    {
      "instrument_token": "5904897",
      "name": "GOPAL SNACKS",
      "tradingSymbol": "GOPAL",
      "label": "GOPAL",
      "value": "GOPAL"
    },
    {
      "instrument_token": "828673",
      "name": "JTEKT INDIA",
      "tradingSymbol": "JTEKTINDIA",
      "label": "JTEKTINDIA",
      "value": "JTEKTINDIA"
    },
    {
      "instrument_token": "592897",
      "name": "HEIDELBERGCEMENT (I)",
      "tradingSymbol": "HEIDELBERG",
      "label": "HEIDELBERG",
      "value": "HEIDELBERG"
    },
    {
      "instrument_token": "558849",
      "name": "SUNDARAM FINANCE HOLD",
      "tradingSymbol": "SUNDARMHLD",
      "label": "SUNDARMHLD",
      "value": "SUNDARMHLD"
    },
    {
      "instrument_token": "4485121",
      "name": "RATTANINDIA POWER",
      "tradingSymbol": "RTNPOWER",
      "label": "RTNPOWER",
      "value": "RTNPOWER"
    },
    {
      "instrument_token": "2383105",
      "name": "STERLITE TECHNOLOGIES",
      "tradingSymbol": "STLTECH",
      "label": "STLTECH",
      "value": "STLTECH"
    },
    {
      "instrument_token": "2994945",
      "name": "PATEL ENGINEERING",
      "tradingSymbol": "PATELENG",
      "label": "PATELENG",
      "value": "PATELENG"
    },
    {
      "instrument_token": "5166593",
      "name": "ASHOKA BUILDCON",
      "tradingSymbol": "ASHOKA",
      "label": "ASHOKA",
      "value": "ASHOKA"
    },
    {
      "instrument_token": "4315905",
      "name": "SINDHU TRADE LINKS",
      "tradingSymbol": "SINDHUTRAD",
      "label": "SINDHUTRAD",
      "value": "SINDHUTRAD"
    },
    {
      "instrument_token": "6491649",
      "name": "PG ELECTROPLAST",
      "tradingSymbol": "PGEL",
      "label": "PGEL",
      "value": "PGEL"
    },
    {
      "instrument_token": "3564801",
      "name": "NATIONAL FERT.",
      "tradingSymbol": "NFL",
      "label": "NFL",
      "value": "NFL"
    },
    {
      "instrument_token": "5815553",
      "name": "ENTERO HEALTHCARE SOLU L",
      "tradingSymbol": "ENTERO",
      "label": "ENTERO",
      "value": "ENTERO"
    },
    {
      "instrument_token": "5801729",
      "name": "JANA SMALL FIN BANK",
      "tradingSymbol": "JSFB",
      "label": "JSFB",
      "value": "JSFB"
    },
    {
      "instrument_token": "3015169",
      "name": "GOKALDAS EXPORTS",
      "tradingSymbol": "GOKEX",
      "label": "GOKEX",
      "value": "GOKEX"
    },
    {
      "instrument_token": "3553281",
      "name": "BANCO PRODUCTS (I)",
      "tradingSymbol": "BANCOINDIA",
      "label": "BANCOINDIA",
      "value": "BANCOINDIA"
    },
    {
      "instrument_token": "7496705",
      "name": "VMART RETAIL",
      "tradingSymbol": "VMART",
      "label": "VMART",
      "value": "VMART"
    },
    {
      "instrument_token": "787969",
      "name": "SHANTHI GEARS",
      "tradingSymbol": "SHANTIGEAR",
      "label": "SHANTIGEAR",
      "value": "SHANTIGEAR"
    },
    {
      "instrument_token": "288513",
      "name": "GHCL",
      "tradingSymbol": "GHCL",
      "label": "GHCL",
      "value": "GHCL"
    },
    {
      "instrument_token": "851713",
      "name": "SUDARSHAN CHEMICAL INDS L",
      "tradingSymbol": "SUDARSCHEM",
      "label": "SUDARSCHEM",
      "value": "SUDARSCHEM"
    },
    {
      "instrument_token": "2976257",
      "name": "WELSPUN ENTERPRISES",
      "tradingSymbol": "WELENT",
      "label": "WELENT",
      "value": "WELENT"
    },
    {
      "instrument_token": "5202433",
      "name": "FEDBANK FINANCIAL SER L",
      "tradingSymbol": "FEDFINA",
      "label": "FEDFINA",
      "value": "FEDFINA"
    },
    {
      "instrument_token": "625153",
      "name": "NOCIL",
      "tradingSymbol": "NOCIL",
      "label": "NOCIL",
      "value": "NOCIL"
    },
    {
      "instrument_token": "3425537",
      "name": "KEWAL KIRAN CLOTHING",
      "tradingSymbol": "KKCL",
      "label": "KKCL",
      "value": "KKCL"
    },
    {
      "instrument_token": "760833",
      "name": "ORIENT ELECTRIC",
      "tradingSymbol": "ORIENTELEC",
      "label": "ORIENTELEC",
      "value": "ORIENTELEC"
    },
    {
      "instrument_token": "4966657",
      "name": "BOROSIL",
      "tradingSymbol": "BOROLTD",
      "label": "BOROLTD",
      "value": "BOROLTD"
    },
    {
      "instrument_token": "4870401",
      "name": "KIRLOSKAR INDUSTRIES",
      "tradingSymbol": "KIRLOSIND",
      "label": "KIRLOSIND",
      "value": "KIRLOSIND"
    },
    {
      "instrument_token": "86529",
      "name": "BALMER LAWRIE & CO",
      "tradingSymbol": "BALMLAWRIE",
      "label": "BALMLAWRIE",
      "value": "BALMLAWRIE"
    },
    {
      "instrument_token": "1586689",
      "name": "FINEOTEX CHEMICAL",
      "tradingSymbol": "FCL",
      "label": "FCL",
      "value": "FCL"
    },
    {
      "instrument_token": "2043393",
      "name": "GARWARE HI-TECH FILMS",
      "tradingSymbol": "GRWRHITECH",
      "label": "GRWRHITECH",
      "value": "GRWRHITECH"
    },
    {
      "instrument_token": "2695681",
      "name": "SHARDA MOTOR INDS",
      "tradingSymbol": "SHARDAMOTR",
      "label": "SHARDAMOTR",
      "value": "SHARDAMOTR"
    },
    {
      "instrument_token": "5798145",
      "name": "PARKHOTELS",
      "tradingSymbol": "PARKHOTELS",
      "label": "PARKHOTELS",
      "value": "PARKHOTELS"
    },
    {
      "instrument_token": "5029377",
      "name": "MAX ESTATES",
      "tradingSymbol": "MAXESTATES",
      "label": "MAXESTATES",
      "value": "MAXESTATES"
    },
    {
      "instrument_token": "4914177",
      "name": "TILAKNAGAR INDUSTRIES",
      "tradingSymbol": "TI",
      "label": "TI",
      "value": "TI"
    },
    {
      "instrument_token": "1427969",
      "name": "AMI ORGANICS",
      "tradingSymbol": "AMIORG",
      "label": "AMIORG",
      "value": "AMIORG"
    },
    {
      "instrument_token": "7702785",
      "name": "ORIENT CEMENT",
      "tradingSymbol": "ORIENTCEM",
      "label": "ORIENTCEM",
      "value": "ORIENTCEM"
    },
    {
      "instrument_token": "4544513",
      "name": "SHILPA MEDICARE",
      "tradingSymbol": "SHILPAMED",
      "label": "SHILPAMED",
      "value": "SHILPAMED"
    },
    {
      "instrument_token": "1147137",
      "name": "AARTI DRUGS",
      "tradingSymbol": "AARTIDRUGS",
      "label": "AARTIDRUGS",
      "value": "AARTIDRUGS"
    },
    {
      "instrument_token": "4690177",
      "name": "LG BALAKRISHNAN & BROS",
      "tradingSymbol": "LGBBROSLTD",
      "label": "LGBBROSLTD",
      "value": "LGBBROSLTD"
    },
    {
      "instrument_token": "3550209",
      "name": "AARTI PHARMALABS",
      "tradingSymbol": "AARTIPHARM",
      "label": "AARTIPHARM",
      "value": "AARTIPHARM"
    },
    {
      "instrument_token": "4921089",
      "name": "TCI EXPRESS",
      "tradingSymbol": "TCIEXP",
      "label": "TCIEXP",
      "value": "TCIEXP"
    },
    {
      "instrument_token": "972545",
      "name": "WEST COAST PAPER MILLS LT",
      "tradingSymbol": "WSTCSTPAPR",
      "label": "WSTCSTPAPR",
      "value": "WSTCSTPAPR"
    },
    {
      "instrument_token": "4617985",
      "name": "ADVANCED ENZYME TECH",
      "tradingSymbol": "ADVENZYMES",
      "label": "ADVENZYMES",
      "value": "ADVENZYMES"
    },
    {
      "instrument_token": "2259201",
      "name": "PRIVI SPECIALITY CHE",
      "tradingSymbol": "PRIVISCL",
      "label": "PRIVISCL",
      "value": "PRIVISCL"
    },
    {
      "instrument_token": "3535361",
      "name": "GREENPANEL INDUSTRIES",
      "tradingSymbol": "GREENPANEL",
      "label": "GREENPANEL",
      "value": "GREENPANEL"
    },
    {
      "instrument_token": "2455553",
      "name": "VENUS PIPES & TUBES",
      "tradingSymbol": "VENUSPIPES",
      "label": "VENUSPIPES",
      "value": "VENUSPIPES"
    },
    {
      "instrument_token": "3346433",
      "name": "IIFL SECURITIES",
      "tradingSymbol": "IIFLSEC",
      "label": "IIFLSEC",
      "value": "IIFLSEC"
    },
    {
      "instrument_token": "2929921",
      "name": "PILANI INV & IND COR",
      "tradingSymbol": "PILANIINVS",
      "label": "PILANIINVS",
      "value": "PILANIINVS"
    },
    {
      "instrument_token": "4968961",
      "name": "ROSSARI BIOTECH",
      "tradingSymbol": "ROSSARI",
      "label": "ROSSARI",
      "value": "ROSSARI"
    },
    {
      "instrument_token": "4835585",
      "name": "KALYANI STEELS",
      "tradingSymbol": "KSL",
      "label": "KSL",
      "value": "KSL"
    },
    {
      "instrument_token": "3513601",
      "name": "DCB BANK",
      "tradingSymbol": "DCBBANK",
      "label": "DCBBANK",
      "value": "DCBBANK"
    },
    {
      "instrument_token": "1964033",
      "name": "IMAGICAAWORLD ENT",
      "tradingSymbol": "IMAGICAA",
      "label": "IMAGICAA",
      "value": "IMAGICAA"
    },
    {
      "instrument_token": "78849",
      "name": "BAJAJ HINDUSTHAN SUGAR LT",
      "tradingSymbol": "BAJAJHIND",
      "label": "BAJAJHIND",
      "value": "BAJAJHIND"
    },
    {
      "instrument_token": "5556225",
      "name": "DISHMAN CARBO AMCIS",
      "tradingSymbol": "DCAL",
      "label": "DCAL",
      "value": "DCAL"
    },
    {
      "instrument_token": "2857473",
      "name": "HARSHA ENGINEERS INT",
      "tradingSymbol": "HARSHA",
      "label": "HARSHA",
      "value": "HARSHA"
    },
    {
      "instrument_token": "3522817",
      "name": "BHARAT BIJLEE",
      "tradingSymbol": "BBL",
      "label": "BBL",
      "value": "BBL"
    },
    {
      "instrument_token": "4540929",
      "name": "YATHARTH HOSP & TRA C S L",
      "tradingSymbol": "YATHARTH",
      "label": "YATHARTH",
      "value": "YATHARTH"
    },
    {
      "instrument_token": "5102337",
      "name": "ORISSA MIN DEV CO",
      "tradingSymbol": "ORISSAMINE",
      "label": "ORISSAMINE",
      "value": "ORISSAMINE"
    },
    {
      "instrument_token": "4638209",
      "name": "THANGAMAYIL JEWELLERY",
      "tradingSymbol": "THANGAMAYL",
      "label": "THANGAMAYL",
      "value": "THANGAMAYL"
    },
    {
      "instrument_token": "4763649",
      "name": "ZAGGLE PREPA OCEAN SER L",
      "tradingSymbol": "ZAGGLE",
      "label": "ZAGGLE",
      "value": "ZAGGLE"
    },
    {
      "instrument_token": "981505",
      "name": "BHARAT RASAYAN",
      "tradingSymbol": "BHARATRAS",
      "label": "BHARATRAS",
      "value": "BHARATRAS"
    },
    {
      "instrument_token": "3871745",
      "name": "KOLTE PATIL DEV.",
      "tradingSymbol": "KOLTEPATIL",
      "label": "KOLTEPATIL",
      "value": "KOLTEPATIL"
    },
    {
      "instrument_token": "3832833",
      "name": "KAVERI SEED CO.",
      "tradingSymbol": "KSCL",
      "label": "KSCL",
      "value": "KSCL"
    },
    {
      "instrument_token": "5556481",
      "name": "MEDI ASSIST HEALTH SER L",
      "tradingSymbol": "MEDIASSIST",
      "label": "MEDIASSIST",
      "value": "MEDIASSIST"
    },
    {
      "instrument_token": "3120129",
      "name": "INOX GREEN ENERGY SER",
      "tradingSymbol": "INOXGREEN",
      "label": "INOXGREEN",
      "value": "INOXGREEN"
    },
    {
      "instrument_token": "4647425",
      "name": "HATHWAY CABLE & DATACOM",
      "tradingSymbol": "HATHWAY",
      "label": "HATHWAY",
      "value": "HATHWAY"
    },
    {
      "instrument_token": "3028225",
      "name": "STEEL STRIPS WHEELS",
      "tradingSymbol": "SSWL",
      "label": "SSWL",
      "value": "SSWL"
    },
    {
      "instrument_token": "916225",
      "name": "UNICHEM LABORATORIES",
      "tradingSymbol": "UNICHEMLAB",
      "label": "UNICHEMLAB",
      "value": "UNICHEMLAB"
    },
    {
      "instrument_token": "1316353",
      "name": "CIGNITI TECHNOLOGIES",
      "tradingSymbol": "CIGNITITEC",
      "label": "CIGNITITEC",
      "value": "CIGNITITEC"
    },
    {
      "instrument_token": "4924161",
      "name": "INDIAN METALS & FERRO",
      "tradingSymbol": "IMFA",
      "label": "IMFA",
      "value": "IMFA"
    },
    {
      "instrument_token": "51969",
      "name": "ASHAPURA MINECHEM",
      "tradingSymbol": "ASHAPURMIN",
      "label": "ASHAPURMIN",
      "value": "ASHAPURMIN"
    },
    {
      "instrument_token": "3766273",
      "name": "HINDUJA GLOBAL SOLS.",
      "tradingSymbol": "HGS",
      "label": "HGS",
      "value": "HGS"
    },
    {
      "instrument_token": "5332737",
      "name": "MUTHOOT MICROFIN",
      "tradingSymbol": "MUTHOOTMF",
      "label": "MUTHOOTMF",
      "value": "MUTHOOTMF"
    },
    {
      "instrument_token": "850945",
      "name": "SUBROS",
      "tradingSymbol": "SUBROS",
      "label": "SUBROS",
      "value": "SUBROS"
    },
    {
      "instrument_token": "5154305",
      "name": "RAMKY INFRA",
      "tradingSymbol": "RAMKY",
      "label": "RAMKY",
      "value": "RAMKY"
    },
    {
      "instrument_token": "857089",
      "name": "SUNFLAG IRON AND STEEL CO",
      "tradingSymbol": "SUNFLAG",
      "label": "SUNFLAG",
      "value": "SUNFLAG"
    },
    {
      "instrument_token": "7452929",
      "name": "CARE RATINGS",
      "tradingSymbol": "CARERATING",
      "label": "CARERATING",
      "value": "CARERATING"
    },
    {
      "instrument_token": "4354561",
      "name": "GENSOL ENGINEERING",
      "tradingSymbol": "GENSOL",
      "label": "GENSOL",
      "value": "GENSOL"
    },
    {
      "instrument_token": "2413569",
      "name": "SKIPPER",
      "tradingSymbol": "SKIPPER",
      "label": "SKIPPER",
      "value": "SKIPPER"
    },
    {
      "instrument_token": "3692289",
      "name": "LA OPALA RG",
      "tradingSymbol": "LAOPALA",
      "label": "LAOPALA",
      "value": "LAOPALA"
    },
    {
      "instrument_token": "3587585",
      "name": "LUMAX AUTO TECH",
      "tradingSymbol": "LUMAXTECH",
      "label": "LUMAXTECH",
      "value": "LUMAXTECH"
    },
    {
      "instrument_token": "131329",
      "name": "BOMBAY DYEING & MFG. CO L",
      "tradingSymbol": "BOMDYEING",
      "label": "BOMDYEING",
      "value": "BOMDYEING"
    },
    {
      "instrument_token": "2475009",
      "name": "HIKAL",
      "tradingSymbol": "HIKAL",
      "label": "HIKAL",
      "value": "HIKAL"
    },
    {
      "instrument_token": "2661633",
      "name": "JAIN IRRIGATION SYSTEMS",
      "tradingSymbol": "JISLJALEQS",
      "label": "JISLJALEQS",
      "value": "JISLJALEQS"
    },
    {
      "instrument_token": "3854849",
      "name": "AVALON TECHNOLOGIES",
      "tradingSymbol": "AVALON",
      "label": "AVALON",
      "value": "AVALON"
    },
    {
      "instrument_token": "2893057",
      "name": "LUX INDUSTRIES",
      "tradingSymbol": "LUXIND",
      "label": "LUXIND",
      "value": "LUXIND"
    },
    {
      "instrument_token": "2762497",
      "name": "NUCLEUS SOFTWARE EXPORTS",
      "tradingSymbol": "NUCLEUS",
      "label": "NUCLEUS",
      "value": "NUCLEUS"
    },
    {
      "instrument_token": "5143553",
      "name": "TASTY BITE EATABLES",
      "tradingSymbol": "TASTYBITE",
      "label": "TASTYBITE",
      "value": "TASTYBITE"
    },
    {
      "instrument_token": "773377",
      "name": "SAVITA OIL TECHNOLO.",
      "tradingSymbol": "SOTL",
      "label": "SOTL",
      "value": "SOTL"
    },
    {
      "instrument_token": "2676993",
      "name": "ARVIND SMARTSPACES",
      "tradingSymbol": "ARVSMART",
      "label": "ARVSMART",
      "value": "ARVSMART"
    },
    {
      "instrument_token": "613633",
      "name": "SANDHAR TECHNOLOGIES",
      "tradingSymbol": "SANDHAR",
      "label": "SANDHAR",
      "value": "SANDHAR"
    },
    {
      "instrument_token": "5468673",
      "name": "SALASAR TECHNO ENGG.",
      "tradingSymbol": "SALASAR",
      "label": "SALASAR",
      "value": "SALASAR"
    },
    {
      "instrument_token": "2538753",
      "name": "NEOGEN CHEMICALS",
      "tradingSymbol": "NEOGEN",
      "label": "NEOGEN",
      "value": "NEOGEN"
    },
    {
      "instrument_token": "2924289",
      "name": "DATAMATICS GLOBAL SER",
      "tradingSymbol": "DATAMATICS",
      "label": "DATAMATICS",
      "value": "DATAMATICS"
    },
    {
      "instrument_token": "1865473",
      "name": "JTL INDUSTRIES",
      "tradingSymbol": "JTLIND",
      "label": "JTLIND",
      "value": "JTLIND"
    },
    {
      "instrument_token": "2307585",
      "name": "THE ANUP ENGINEERING",
      "tradingSymbol": "ANUP",
      "label": "ANUP",
      "value": "ANUP"
    },
    {
      "instrument_token": "1177089",
      "name": "HERITAGE FOODS",
      "tradingSymbol": "HERITGFOOD",
      "label": "HERITGFOOD",
      "value": "HERITGFOOD"
    },
    {
      "instrument_token": "4360193",
      "name": "THYROCARE TECH",
      "tradingSymbol": "THYROCARE",
      "label": "THYROCARE",
      "value": "THYROCARE"
    },
    {
      "instrument_token": "6194177",
      "name": "VADILAL INDUSTRIES",
      "tradingSymbol": "VADILALIND",
      "label": "VADILALIND",
      "value": "VADILALIND"
    },
    {
      "instrument_token": "610561",
      "name": "NAVNEET EDUCATION",
      "tradingSymbol": "NAVNETEDUL",
      "label": "NAVNETEDUL",
      "value": "NAVNETEDUL"
    },
    {
      "instrument_token": "3721473",
      "name": "DISH TV INDIA",
      "tradingSymbol": "DISHTV",
      "label": "DISHTV",
      "value": "DISHTV"
    },
    {
      "instrument_token": "3816449",
      "name": "KDDL",
      "tradingSymbol": "KDDL",
      "label": "KDDL",
      "value": "KDDL"
    },
    {
      "instrument_token": "4801281",
      "name": "SAI SILKS (KALAMANDIR) L",
      "tradingSymbol": "KALAMANDIR",
      "label": "KALAMANDIR",
      "value": "KALAMANDIR"
    },
    {
      "instrument_token": "3398657",
      "name": "LANDMARK CARS",
      "tradingSymbol": "LANDMARK",
      "label": "LANDMARK",
      "value": "LANDMARK"
    },
    {
      "instrument_token": "2989313",
      "name": "INDOCO REMEDIES",
      "tradingSymbol": "INDOCO",
      "label": "INDOCO",
      "value": "INDOCO"
    },
    {
      "instrument_token": "4999937",
      "name": "BAJAJ CONSUMER CARE",
      "tradingSymbol": "BAJAJCON",
      "label": "BAJAJCON",
      "value": "BAJAJCON"
    },
    {
      "instrument_token": "3646721",
      "name": "TVS SRICHAKRA",
      "tradingSymbol": "TVSSRICHAK",
      "label": "TVSSRICHAK",
      "value": "TVSSRICHAK"
    },
    {
      "instrument_token": "1384193",
      "name": "CARTRADE TECH",
      "tradingSymbol": "CARTRADE",
      "label": "CARTRADE",
      "value": "CARTRADE"
    },
    {
      "instrument_token": "1191937",
      "name": "SHIVALIK BIMETAL CON.",
      "tradingSymbol": "SBCL",
      "label": "SBCL",
      "value": "SBCL"
    },
    {
      "instrument_token": "3509761",
      "name": "FIEM INDUSTRIES",
      "tradingSymbol": "FIEMIND",
      "label": "FIEMIND",
      "value": "FIEMIND"
    },
    {
      "instrument_token": "693249",
      "name": "PRAKASH INDUSTRIES",
      "tradingSymbol": "PRAKASH",
      "label": "PRAKASH",
      "value": "PRAKASH"
    },
    {
      "instrument_token": "3851265",
      "name": "DELTA CORP",
      "tradingSymbol": "DELTACORP",
      "label": "DELTACORP",
      "value": "DELTACORP"
    },
    {
      "instrument_token": "4854273",
      "name": "RAJRATAN GLOBAL WIRE",
      "tradingSymbol": "RAJRATAN",
      "label": "RAJRATAN",
      "value": "RAJRATAN"
    },
    {
      "instrument_token": "4387841",
      "name": "IDEAFORGE TECHNO",
      "tradingSymbol": "IDEAFORGE",
      "label": "IDEAFORGE",
      "value": "IDEAFORGE"
    },
    {
      "instrument_token": "98561",
      "name": "MAHINDRA LOGISTIC",
      "tradingSymbol": "MAHLOG",
      "label": "MAHLOG",
      "value": "MAHLOG"
    },
    {
      "instrument_token": "316161",
      "name": "GREAVES COTTON",
      "tradingSymbol": "GREAVESCOT",
      "label": "GREAVESCOT",
      "value": "GREAVESCOT"
    },
    {
      "instrument_token": "5263361",
      "name": "DOLLAR INDUSTRIES",
      "tradingSymbol": "DOLLAR",
      "label": "DOLLAR",
      "value": "DOLLAR"
    },
    {
      "instrument_token": "269569",
      "name": "UFLEX",
      "tradingSymbol": "UFLEX",
      "label": "UFLEX",
      "value": "UFLEX"
    },
    {
      "instrument_token": "3729153",
      "name": "BF UTILITIES",
      "tradingSymbol": "BFUTILITIE",
      "label": "BFUTILITIE",
      "value": "BFUTILITIE"
    },
    {
      "instrument_token": "1277953",
      "name": "SHARDA CROPCHEM",
      "tradingSymbol": "SHARDACROP",
      "label": "SHARDACROP",
      "value": "SHARDACROP"
    },
    {
      "instrument_token": "89601",
      "name": "BANNARI AMMAN SUGARS",
      "tradingSymbol": "BANARISUG",
      "label": "BANARISUG",
      "value": "BANARISUG"
    },
    {
      "instrument_token": "3659777",
      "name": "SEQUENT SCIENTIFIC",
      "tradingSymbol": "SEQUENT",
      "label": "SEQUENT",
      "value": "SEQUENT"
    },
    {
      "instrument_token": "1020673",
      "name": "GREENPLY INDUSTRIES",
      "tradingSymbol": "GREENPLY",
      "label": "GREENPLY",
      "value": "GREENPLY"
    },
    {
      "instrument_token": "6281729",
      "name": "MAITHAN ALLOYS",
      "tradingSymbol": "MAITHANALL",
      "label": "MAITHANALL",
      "value": "MAITHANALL"
    },
    {
      "instrument_token": "2870273",
      "name": "S H KELKAR AND CO.",
      "tradingSymbol": "SHK",
      "label": "SHK",
      "value": "SHK"
    },
    {
      "instrument_token": "5364737",
      "name": "SUNDARAM CLAYTON",
      "tradingSymbol": "SUNCLAY",
      "label": "SUNCLAY",
      "value": "SUNCLAY"
    },
    {
      "instrument_token": "2971137",
      "name": "GUFIC BIOSCIENCES",
      "tradingSymbol": "GUFICBIO",
      "label": "GUFICBIO",
      "value": "GUFICBIO"
    },
    {
      "instrument_token": "5776897",
      "name": "BLS E-SERVICES",
      "tradingSymbol": "BLSE",
      "label": "BLSE",
      "value": "BLSE"
    },
    {
      "instrument_token": "5088769",
      "name": "ESAF SMALL FINANCE BANK L",
      "tradingSymbol": "ESAFSFB",
      "label": "ESAFSFB",
      "value": "ESAFSFB"
    },
    {
      "instrument_token": "6218753",
      "name": "VST TILLERS TRACTORS",
      "tradingSymbol": "VSTTILLERS",
      "label": "VSTTILLERS",
      "value": "VSTTILLERS"
    },
    {
      "instrument_token": "585985",
      "name": "HLE GLASCOAT",
      "tradingSymbol": "HLEGLAS",
      "label": "HLEGLAS",
      "value": "HLEGLAS"
    },
    {
      "instrument_token": "1568001",
      "name": "GOODLUCK INDIA",
      "tradingSymbol": "GOODLUCK",
      "label": "GOODLUCK",
      "value": "GOODLUCK"
    },
    {
      "instrument_token": "866305",
      "name": "SWARAJ ENGINES",
      "tradingSymbol": "SWARAJENG",
      "label": "SWARAJENG",
      "value": "SWARAJENG"
    },
    {
      "instrument_token": "669697",
      "name": "SEAMEC",
      "tradingSymbol": "SEAMECLTD",
      "label": "SEAMECLTD",
      "value": "SEAMECLTD"
    },
    {
      "instrument_token": "867073",
      "name": "SML ISUZU",
      "tradingSymbol": "SMLISUZU",
      "label": "SMLISUZU",
      "value": "SMLISUZU"
    },
    {
      "instrument_token": "6247169",
      "name": "ASHIANA HOUSING",
      "tradingSymbol": "ASHIANA",
      "label": "ASHIANA",
      "value": "ASHIANA"
    },
    {
      "instrument_token": "199937",
      "name": "DALMIA BHARAT SUG IN",
      "tradingSymbol": "DALMIASUG",
      "label": "DALMIASUG",
      "value": "DALMIASUG"
    },
    {
      "instrument_token": "4066049",
      "name": "HINDWARE HME INOVATON",
      "tradingSymbol": "HINDWAREAP",
      "label": "HINDWAREAP",
      "value": "HINDWAREAP"
    },
    {
      "instrument_token": "3601409",
      "name": "SAGAR CEMENTS",
      "tradingSymbol": "SAGCEM",
      "label": "SAGCEM",
      "value": "SAGCEM"
    },
    {
      "instrument_token": "3019265",
      "name": "SAKSOFT",
      "tradingSymbol": "SAKSOFT",
      "label": "SAKSOFT",
      "value": "SAKSOFT"
    },
    {
      "instrument_token": "290305",
      "name": "APOLLO MICRO SYSTEMS",
      "tradingSymbol": "APOLLO",
      "label": "APOLLO",
      "value": "APOLLO"
    },
    {
      "instrument_token": "1891841",
      "name": "SUPRIYA LIFESCIENCE",
      "tradingSymbol": "SUPRIYA",
      "label": "SUPRIYA",
      "value": "SUPRIYA"
    },
    {
      "instrument_token": "71169",
      "name": "AUTOMOTIVE AXLES",
      "tradingSymbol": "AUTOAXLES",
      "label": "AUTOAXLES",
      "value": "AUTOAXLES"
    },
    {
      "instrument_token": "1327617",
      "name": "STYLAM INDUSTRIES",
      "tradingSymbol": "STYLAMIND",
      "label": "STYLAMIND",
      "value": "STYLAMIND"
    },
    {
      "instrument_token": "5215233",
      "name": "FLAIR WRITING INDUST",
      "tradingSymbol": "FLAIR",
      "label": "FLAIR",
      "value": "FLAIR"
    },
    {
      "instrument_token": "945665",
      "name": "VINDHYA TELELINKS",
      "tradingSymbol": "VINDHYATEL",
      "label": "VINDHYATEL",
      "value": "VINDHYATEL"
    },
    {
      "instrument_token": "481025",
      "name": "CARYSIL",
      "tradingSymbol": "CARYSIL",
      "label": "CARYSIL",
      "value": "CARYSIL"
    },
    {
      "instrument_token": "4935425",
      "name": "THEJO ENGINEERING",
      "tradingSymbol": "THEJO",
      "label": "THEJO",
      "value": "THEJO"
    },
    {
      "instrument_token": "2707969",
      "name": "MPS",
      "tradingSymbol": "MPSLTD",
      "label": "MPSLTD",
      "value": "MPSLTD"
    },
    {
      "instrument_token": "4776705",
      "name": "MARATHON NXTGEN REALT",
      "tradingSymbol": "MARATHON",
      "label": "MARATHON",
      "value": "MARATHON"
    },
    {
      "instrument_token": "6280193",
      "name": "FILATEX INDIA",
      "tradingSymbol": "FILATEX",
      "label": "FILATEX",
      "value": "FILATEX"
    },
    {
      "instrument_token": "1933569",
      "name": "NRB BEARING",
      "tradingSymbol": "NRBBEARING",
      "label": "NRBBEARING",
      "value": "NRBBEARING"
    },
    {
      "instrument_token": "1149697",
      "name": "JOHNSON CONTROLS HITACHI",
      "tradingSymbol": "JCHAC",
      "label": "JCHAC",
      "value": "JCHAC"
    },
    {
      "instrument_token": "1718529",
      "name": "MOLD-TEK PACKAGING",
      "tradingSymbol": "MOLDTKPAC",
      "label": "MOLDTKPAC",
      "value": "MOLDTKPAC"
    },
    {
      "instrument_token": "2779905",
      "name": "DREAMFOLKS SERVICES",
      "tradingSymbol": "DREAMFOLKS",
      "label": "DREAMFOLKS",
      "value": "DREAMFOLKS"
    },
    {
      "instrument_token": "2183425",
      "name": "GMR POW AND URBAN INFRA L",
      "tradingSymbol": "GMRP&UI",
      "label": "GMRP&UI",
      "value": "GMRP&UI"
    },
    {
      "instrument_token": "182785",
      "name": "SHALBY",
      "tradingSymbol": "SHALBY",
      "label": "SHALBY",
      "value": "SHALBY"
    },
    {
      "instrument_token": "5391873",
      "name": "INNOVA CAPTAB",
      "tradingSymbol": "INNOVACAP",
      "label": "INNOVACAP",
      "value": "INNOVACAP"
    },
    {
      "instrument_token": "5786113",
      "name": "PTC INDIA FIN SERV",
      "tradingSymbol": "PFS",
      "label": "PFS",
      "value": "PFS"
    },
    {
      "instrument_token": "4430593",
      "name": "AJMERA REALTY & INF I",
      "tradingSymbol": "AJMERA",
      "label": "AJMERA",
      "value": "AJMERA"
    },
    {
      "instrument_token": "4365569",
      "name": "HMA AGRO INDUSTRIES",
      "tradingSymbol": "HMAAGRO",
      "label": "HMAAGRO",
      "value": "HMAAGRO"
    },
    {
      "instrument_token": "619777",
      "name": "NILKAMAL",
      "tradingSymbol": "NILKAMAL",
      "label": "NILKAMAL",
      "value": "NILKAMAL"
    },
    {
      "instrument_token": "4281601",
      "name": "RPG LIFE SCIENCES",
      "tradingSymbol": "RPGLIFE",
      "label": "RPGLIFE",
      "value": "RPGLIFE"
    },
    {
      "instrument_token": "1321473",
      "name": "TATVA CHIN PHARM CHEM",
      "tradingSymbol": "TATVA",
      "label": "TATVA",
      "value": "TATVA"
    },
    {
      "instrument_token": "4865",
      "name": "STYRENIX PERFORMANCE",
      "tradingSymbol": "STYRENIX",
      "label": "STYRENIX",
      "value": "STYRENIX"
    },
    {
      "instrument_token": "3357697",
      "name": "QUICK HEAL TECH",
      "tradingSymbol": "QUICKHEAL",
      "label": "QUICKHEAL",
      "value": "QUICKHEAL"
    },
    {
      "instrument_token": "1805569",
      "name": "ACCELYA SOLN INDIA",
      "tradingSymbol": "ACCELYA",
      "label": "ACCELYA",
      "value": "ACCELYA"
    },
    {
      "instrument_token": "7577089",
      "name": "REPCO HOME FINANCE",
      "tradingSymbol": "REPCOHOME",
      "label": "REPCOHOME",
      "value": "REPCOHOME"
    },
    {
      "instrument_token": "7455745",
      "name": "PC JEWELLER",
      "tradingSymbol": "PCJEWELLER",
      "label": "PCJEWELLER",
      "value": "PCJEWELLER"
    },
    {
      "instrument_token": "3676417",
      "name": "APOLLO PIPES",
      "tradingSymbol": "APOLLOPIPE",
      "label": "APOLLOPIPE",
      "value": "APOLLOPIPE"
    },
    {
      "instrument_token": "1777665",
      "name": "GANESHA ECOSPHERE",
      "tradingSymbol": "GANECOS",
      "label": "GANECOS",
      "value": "GANECOS"
    },
    {
      "instrument_token": "4385281",
      "name": "PARAG MILK FOODS",
      "tradingSymbol": "PARAGMILK",
      "label": "PARAGMILK",
      "value": "PARAGMILK"
    },
    {
      "instrument_token": "5255937",
      "name": "BAJEL PROJECTS",
      "tradingSymbol": "BAJEL",
      "label": "BAJEL",
      "value": "BAJEL"
    },
    {
      "instrument_token": "5344513",
      "name": "PSP PROJECTS",
      "tradingSymbol": "PSPPROJECT",
      "label": "PSPPROJECT",
      "value": "PSPPROJECT"
    },
    {
      "instrument_token": "293121",
      "name": "GUJ IND POW CO.",
      "tradingSymbol": "GIPCL",
      "label": "GIPCL",
      "value": "GIPCL"
    },
    {
      "instrument_token": "2920193",
      "name": "XPRO INDIA",
      "tradingSymbol": "XPROINDIA",
      "label": "XPROINDIA",
      "value": "XPROINDIA"
    },
    {
      "instrument_token": "3618305",
      "name": "PITTI ENGINEERING",
      "tradingSymbol": "PITTIENG",
      "label": "PITTIENG",
      "value": "PITTIENG"
    },
    {
      "instrument_token": "3588865",
      "name": "TIDE WATER OIL",
      "tradingSymbol": "TIDEWATER",
      "label": "TIDEWATER",
      "value": "TIDEWATER"
    },
    {
      "instrument_token": "2234113",
      "name": "SHAILY ENG PLASTICS",
      "tradingSymbol": "SHAILY",
      "label": "SHAILY",
      "value": "SHAILY"
    },
    {
      "instrument_token": "3016193",
      "name": "EVEREADY INDS. IND. ",
      "tradingSymbol": "EVEREADY",
      "label": "EVEREADY",
      "value": "EVEREADY"
    },
    {
      "instrument_token": "2620929",
      "name": "CONFIDENCE PETRO IND",
      "tradingSymbol": "CONFIPET",
      "label": "CONFIPET",
      "value": "CONFIPET"
    },
    {
      "instrument_token": "687873",
      "name": "POLYPLEX CORPORATION",
      "tradingSymbol": "POLYPLEX",
      "label": "POLYPLEX",
      "value": "POLYPLEX"
    },
    {
      "instrument_token": "2394625",
      "name": "TAJ GVK HOTELS & RESORTS",
      "tradingSymbol": "TAJGVK",
      "label": "TAJGVK",
      "value": "TAJGVK"
    },
    {
      "instrument_token": "894977",
      "name": "THIRUMALAI CHEMICALS",
      "tradingSymbol": "TIRUMALCHM",
      "label": "TIRUMALCHM",
      "value": "TIRUMALCHM"
    },
    {
      "instrument_token": "3650561",
      "name": "PEARL GLOBAL IND",
      "tradingSymbol": "PGIL",
      "label": "PGIL",
      "value": "PGIL"
    },
    {
      "instrument_token": "993281",
      "name": "SOMANY CERAMICS",
      "tradingSymbol": "SOMANYCERA",
      "label": "SOMANYCERA",
      "value": "SOMANYCERA"
    },
    {
      "instrument_token": "4849665",
      "name": "KINGFA SCI & TEC IND",
      "tradingSymbol": "KINGFA",
      "label": "KINGFA",
      "value": "KINGFA"
    },
    {
      "instrument_token": "1684225",
      "name": "FINO PAYMENTS BANK",
      "tradingSymbol": "FINOPB",
      "label": "FINOPB",
      "value": "FINOPB"
    },
    {
      "instrument_token": "3342593",
      "name": "UNIPARTS INDIA",
      "tradingSymbol": "UNIPARTS",
      "label": "UNIPARTS",
      "value": "UNIPARTS"
    },
    {
      "instrument_token": "790529",
      "name": "INDOSTAR CAPITAL FIN",
      "tradingSymbol": "INDOSTAR",
      "label": "INDOSTAR",
      "value": "INDOSTAR"
    },
    {
      "instrument_token": "3706625",
      "name": "DIVGI TORQTRANSFER SYST L",
      "tradingSymbol": "DIVGIITTS",
      "label": "DIVGIITTS",
      "value": "DIVGIITTS"
    },
    {
      "instrument_token": "359169",
      "name": "HINDUSTAN OIL EXPLORATION",
      "tradingSymbol": "HINDOILEXP",
      "label": "HINDOILEXP",
      "value": "HINDOILEXP"
    },
    {
      "instrument_token": "3918849",
      "name": "SEPC",
      "tradingSymbol": "SEPC",
      "label": "SEPC",
      "value": "SEPC"
    },
    {
      "instrument_token": "389377",
      "name": "INDIA GLYCOLS",
      "tradingSymbol": "INDIAGLYCO",
      "label": "INDIAGLYCO",
      "value": "INDIAGLYCO"
    },
    {
      "instrument_token": "1263105",
      "name": "INDIA PESTICIDES",
      "tradingSymbol": "IPL",
      "label": "IPL",
      "value": "IPL"
    },
    {
      "instrument_token": "2791681",
      "name": "GENESYS INTL CORPN",
      "tradingSymbol": "GENESYS",
      "label": "GENESYS",
      "value": "GENESYS"
    },
    {
      "instrument_token": "767233",
      "name": "SANGHI INDUSTRIES",
      "tradingSymbol": "SANGHIIND",
      "label": "SANGHIIND",
      "value": "SANGHIIND"
    },
    {
      "instrument_token": "207105",
      "name": "BOMBAY SUPER HYBRID SEEDS",
      "tradingSymbol": "BSHSL",
      "label": "BSHSL",
      "value": "BSHSL"
    },
    {
      "instrument_token": "2675969",
      "name": "SATIN CREDIT NET",
      "tradingSymbol": "SATIN",
      "label": "SATIN",
      "value": "SATIN"
    },
    {
      "instrument_token": "2415617",
      "name": "AXISCADES TECH",
      "tradingSymbol": "AXISCADES",
      "label": "AXISCADES",
      "value": "AXISCADES"
    },
    {
      "instrument_token": "4329729",
      "name": "ARTEMIS MED SERVICE",
      "tradingSymbol": "ARTEMISMED",
      "label": "ARTEMISMED",
      "value": "ARTEMISMED"
    },
    {
      "instrument_token": "3067649",
      "name": "SASKEN TECHNOLOGIES",
      "tradingSymbol": "SASKEN",
      "label": "SASKEN",
      "value": "SASKEN"
    },
    {
      "instrument_token": "3942145",
      "name": "EIH ASSOCIATED HOTELS",
      "tradingSymbol": "EIHAHOTELS",
      "label": "EIHAHOTELS",
      "value": "EIHAHOTELS"
    },
    {
      "instrument_token": "3938305",
      "name": "DHANI SERVICES",
      "tradingSymbol": "DHANI",
      "label": "DHANI",
      "value": "DHANI"
    },
    {
      "instrument_token": "695553",
      "name": "PRECISION WIRES INDIA",
      "tradingSymbol": "PRECWIRE",
      "label": "PRECWIRE",
      "value": "PRECWIRE"
    },
    {
      "instrument_token": "3465217",
      "name": "VIDHI SPCLTY F INGRDNTS L",
      "tradingSymbol": "VIDHIING",
      "label": "VIDHIING",
      "value": "VIDHIING"
    },
    {
      "instrument_token": "39425",
      "name": "APCOTEX INDUSTRIES LIMITE",
      "tradingSymbol": "APCOTEXIND",
      "label": "APCOTEXIND",
      "value": "APCOTEXIND"
    },
    {
      "instrument_token": "655873",
      "name": "HUHTAMAKI INDIA",
      "tradingSymbol": "HUHTAMAKI",
      "label": "HUHTAMAKI",
      "value": "HUHTAMAKI"
    },
    {
      "instrument_token": "516609",
      "name": "LUMAX INDUSTRIES",
      "tradingSymbol": "LUMAXIND",
      "label": "LUMAXIND",
      "value": "LUMAXIND"
    },
    {
      "instrument_token": "1014529",
      "name": "GOCL CORPORATION",
      "tradingSymbol": "GOCLCORP",
      "label": "GOCLCORP",
      "value": "GOCLCORP"
    },
    {
      "instrument_token": "1084161",
      "name": "WENDT (INDIA)",
      "tradingSymbol": "WENDT",
      "label": "WENDT",
      "value": "WENDT"
    },
    {
      "instrument_token": "4536833",
      "name": "DEN NETWORKS",
      "tradingSymbol": "DEN",
      "label": "DEN",
      "value": "DEN"
    },
    {
      "instrument_token": "4802561",
      "name": "YATRA ONLINE",
      "tradingSymbol": "YATRA",
      "label": "YATRA",
      "value": "YATRA"
    },
    {
      "instrument_token": "803329",
      "name": "HONDA I POWER PRODUCT",
      "tradingSymbol": "HONDAPOWER",
      "label": "HONDAPOWER",
      "value": "HONDAPOWER"
    },
    {
      "instrument_token": "471297",
      "name": "KCP",
      "tradingSymbol": "KCP",
      "label": "KCP",
      "value": "KCP"
    },
    {
      "instrument_token": "4759809",
      "name": "EMS",
      "tradingSymbol": "EMSLIMITED",
      "label": "EMSLIMITED",
      "value": "EMSLIMITED"
    },
    {
      "instrument_token": "3382017",
      "name": "JAGRAN PRAKASHAN",
      "tradingSymbol": "JAGRAN",
      "label": "JAGRAN",
      "value": "JAGRAN"
    },
    {
      "instrument_token": "1071105",
      "name": "SANGAM (INDIA)",
      "tradingSymbol": "SANGAMIND",
      "label": "SANGAMIND",
      "value": "SANGAMIND"
    },
    {
      "instrument_token": "107265",
      "name": "BHANSALI ENG. POLYMERS LT",
      "tradingSymbol": "BEPL",
      "label": "BEPL",
      "value": "BEPL"
    },
    {
      "instrument_token": "5567745",
      "name": "CAPACITE INFRAPROJECT",
      "tradingSymbol": "CAPACITE",
      "label": "CAPACITE",
      "value": "CAPACITE"
    },
    {
      "instrument_token": "2875649",
      "name": "SUVEN LIFE SCIENCES",
      "tradingSymbol": "SUVEN",
      "label": "SUVEN",
      "value": "SUVEN"
    },
    {
      "instrument_token": "3042305",
      "name": "MAN INDUSTRIES (I)",
      "tradingSymbol": "MANINDS",
      "label": "MANINDS",
      "value": "MANINDS"
    },
    {
      "instrument_token": "5591041",
      "name": "PRATAAP SNACKS",
      "tradingSymbol": "DIAMONDYD",
      "label": "DIAMONDYD",
      "value": "DIAMONDYD"
    },
    {
      "instrument_token": "3835393",
      "name": "CENTUM ELECTRONICS",
      "tradingSymbol": "CENTUM",
      "label": "CENTUM",
      "value": "CENTUM"
    },
    {
      "instrument_token": "961793",
      "name": "VENKY S (INDIA)",
      "tradingSymbol": "VENKEYS",
      "label": "VENKEYS",
      "value": "VENKEYS"
    },
    {
      "instrument_token": "4306433",
      "name": "IKIO LIGHTING",
      "tradingSymbol": "IKIO",
      "label": "IKIO",
      "value": "IKIO"
    },
    {
      "instrument_token": "5496065",
      "name": "OPTIEMUS INFRACOM",
      "tradingSymbol": "OPTIEMUS",
      "label": "OPTIEMUS",
      "value": "OPTIEMUS"
    },
    {
      "instrument_token": "578305",
      "name": "MOREPEN LAB.",
      "tradingSymbol": "MOREPENLAB",
      "label": "MOREPENLAB",
      "value": "MOREPENLAB"
    },
    {
      "instrument_token": "2899201",
      "name": "MUKAND",
      "tradingSymbol": "MUKANDLTD",
      "label": "MUKANDLTD",
      "value": "MUKANDLTD"
    },
    {
      "instrument_token": "4892929",
      "name": "UPDATER SERVICES",
      "tradingSymbol": "UDS",
      "label": "UDS",
      "value": "UDS"
    },
    {
      "instrument_token": "20225",
      "name": "ALEMBIC",
      "tradingSymbol": "ALEMBICLTD",
      "label": "ALEMBICLTD",
      "value": "ALEMBICLTD"
    },
    {
      "instrument_token": "2636801",
      "name": "RAMA STEEL TUBES",
      "tradingSymbol": "RAMASTEEL",
      "label": "RAMASTEEL",
      "value": "RAMASTEEL"
    },
    {
      "instrument_token": "5225729",
      "name": "IOL CHEM AND PHARMA",
      "tradingSymbol": "IOLCP",
      "label": "IOLCP",
      "value": "IOLCP"
    },
    {
      "instrument_token": "3247617",
      "name": "MADHYA BHARAT AGRO P.",
      "tradingSymbol": "MBAPL",
      "label": "MBAPL",
      "value": "MBAPL"
    },
    {
      "instrument_token": "3544065",
      "name": "MM FORGINGS",
      "tradingSymbol": "MMFL",
      "label": "MMFL",
      "value": "MMFL"
    },
    {
      "instrument_token": "3415553",
      "name": "VAKRANGEE",
      "tradingSymbol": "VAKRANGEE",
      "label": "VAKRANGEE",
      "value": "VAKRANGEE"
    },
    {
      "instrument_token": "1777409",
      "name": "TARSONS PRODUCTS",
      "tradingSymbol": "TARSONS",
      "label": "TARSONS",
      "value": "TARSONS"
    },
    {
      "instrument_token": "4538369",
      "name": "ASTEC LIFESCIENCES",
      "tradingSymbol": "ASTEC",
      "label": "ASTEC",
      "value": "ASTEC"
    },
    {
      "instrument_token": "1768449",
      "name": "VISHNU CHEMICALS",
      "tradingSymbol": "VISHNU",
      "label": "VISHNU",
      "value": "VISHNU"
    },
    {
      "instrument_token": "2910465",
      "name": "TTK HEALTHCARE",
      "tradingSymbol": "TTKHLTCARE",
      "label": "TTKHLTCARE",
      "value": "TTKHLTCARE"
    },
    {
      "instrument_token": "587265",
      "name": "MAHANAGAR TELEPHONE NIGAM",
      "tradingSymbol": "MTNL",
      "label": "MTNL",
      "value": "MTNL"
    },
    {
      "instrument_token": "5803521",
      "name": "RASHI PERIPHERALS",
      "tradingSymbol": "RPTECH",
      "label": "RPTECH",
      "value": "RPTECH"
    },
    {
      "instrument_token": "2078465",
      "name": "RPSG VENTURES",
      "tradingSymbol": "RPSGVENT",
      "label": "RPSGVENT",
      "value": "RPSGVENT"
    },
    {
      "instrument_token": "638209",
      "name": "ORIENT HOTELS",
      "tradingSymbol": "ORIENTHOT",
      "label": "ORIENTHOT",
      "value": "ORIENTHOT"
    },
    {
      "instrument_token": "3518721",
      "name": "GTL INFRA.LTD",
      "tradingSymbol": "GTLINFRA",
      "label": "GTLINFRA",
      "value": "GTLINFRA"
    },
    {
      "instrument_token": "3579393",
      "name": "JASH ENGINEERING",
      "tradingSymbol": "JASH",
      "label": "JASH",
      "value": "JASH"
    },
    {
      "instrument_token": "1360129",
      "name": "UGRO CAPITAL",
      "tradingSymbol": "UGROCAP",
      "label": "UGROCAP",
      "value": "UGROCAP"
    },
    {
      "instrument_token": "342529",
      "name": "SOM DIST & BREW",
      "tradingSymbol": "SDBL",
      "label": "SDBL",
      "value": "SDBL"
    },
    {
      "instrument_token": "4781825",
      "name": "HPL ELECTRIC & POWER",
      "tradingSymbol": "HPL",
      "label": "HPL",
      "value": "HPL"
    },
    {
      "instrument_token": "47105",
      "name": "TCPL PACKAGING",
      "tradingSymbol": "TCPLPACK",
      "label": "TCPLPACK",
      "value": "TCPLPACK"
    },
    {
      "instrument_token": "5058817",
      "name": "ADF FOODS",
      "tradingSymbol": "ADFFOODS",
      "label": "ADFFOODS",
      "value": "ADFFOODS"
    },
    {
      "instrument_token": "1380865",
      "name": "MEGHMANI ORGANICS",
      "tradingSymbol": "MOL",
      "label": "MOL",
      "value": "MOL"
    },
    {
      "instrument_token": "5064449",
      "name": "MUFIN GREEN FINANCE",
      "tradingSymbol": "MUFIN",
      "label": "MUFIN",
      "value": "MUFIN"
    },
    {
      "instrument_token": "3708161",
      "name": "THEMIS MEDICARE",
      "tradingSymbol": "THEMISMED",
      "label": "THEMISMED",
      "value": "THEMISMED"
    },
    {
      "instrument_token": "6500353",
      "name": "PANAMA PETROCHEM",
      "tradingSymbol": "PANAMAPET",
      "label": "PANAMAPET",
      "value": "PANAMAPET"
    },
    {
      "instrument_token": "539137",
      "name": "MANGALAM CEMENT",
      "tradingSymbol": "MANGLMCEM",
      "label": "MANGLMCEM",
      "value": "MANGLMCEM"
    },
    {
      "instrument_token": "5799937",
      "name": "SIGNPOST INDIA",
      "tradingSymbol": "SIGNPOST",
      "label": "SIGNPOST",
      "value": "SIGNPOST"
    },
    {
      "instrument_token": "734209",
      "name": "HI-TECH PIPES",
      "tradingSymbol": "HITECH",
      "label": "HITECH",
      "value": "HITECH"
    },
    {
      "instrument_token": "7399937",
      "name": "MAYUR UNIQUOTERS",
      "tradingSymbol": "MAYURUNIQ",
      "label": "MAYURUNIQ",
      "value": "MAYURUNIQ"
    },
    {
      "instrument_token": "3608577",
      "name": "SIYARAM SILK MILLS",
      "tradingSymbol": "SIYSIL",
      "label": "SIYSIL",
      "value": "SIYSIL"
    },
    {
      "instrument_token": "449537",
      "name": "JINDAL POLY FILMS",
      "tradingSymbol": "JINDALPOLY",
      "label": "JINDALPOLY",
      "value": "JINDALPOLY"
    },
    {
      "instrument_token": "1371905",
      "name": "KRSNAA DIAGNOSTICS",
      "tradingSymbol": "KRSNAA",
      "label": "KRSNAA",
      "value": "KRSNAA"
    },
    {
      "instrument_token": "842753",
      "name": "DEEP INDUSTRIES",
      "tradingSymbol": "DEEPINDS",
      "label": "DEEPINDS",
      "value": "DEEPINDS"
    },
    {
      "instrument_token": "2236417",
      "name": "PNB GILTS",
      "tradingSymbol": "PNBGILTS",
      "label": "PNBGILTS",
      "value": "PNBGILTS"
    },
    {
      "instrument_token": "372481",
      "name": "HIL",
      "tradingSymbol": "HIL",
      "label": "HIL",
      "value": "HIL"
    },
    {
      "instrument_token": "744705",
      "name": "RICO AUTO INDUSTRIES",
      "tradingSymbol": "RICOAUTO",
      "label": "RICOAUTO",
      "value": "RICOAUTO"
    },
    {
      "instrument_token": "5404929",
      "name": "BF INVESTMENT",
      "tradingSymbol": "BFINVEST",
      "label": "BFINVEST",
      "value": "BFINVEST"
    },
    {
      "instrument_token": "5197569",
      "name": "GANDHAR OIL REFINE IND L",
      "tradingSymbol": "GANDHAR",
      "label": "GANDHAR",
      "value": "GANDHAR"
    },
    {
      "instrument_token": "111617",
      "name": "IFGL REFRACTORIES",
      "tradingSymbol": "IFGLEXPOR",
      "label": "IFGLEXPOR",
      "value": "IFGLEXPOR"
    },
    {
      "instrument_token": "800513",
      "name": "BARBEQUE NATION HOSP.",
      "tradingSymbol": "BARBEQUE",
      "label": "BARBEQUE",
      "value": "BARBEQUE"
    },
    {
      "instrument_token": "42497",
      "name": "ANDHRA PAPER",
      "tradingSymbol": "ANDHRAPAP",
      "label": "ANDHRAPAP",
      "value": "ANDHRAPAP"
    },
    {
      "instrument_token": "5016833",
      "name": "IRM ENERGY",
      "tradingSymbol": "IRMENERGY",
      "label": "IRMENERGY",
      "value": "IRMENERGY"
    },
    {
      "instrument_token": "745473",
      "name": "RELIANCE INDUSTRIAL INFRA",
      "tradingSymbol": "RIIL",
      "label": "RIIL",
      "value": "RIIL"
    },
    {
      "instrument_token": "1843201",
      "name": "SHRIRAM PROPERTIES",
      "tradingSymbol": "SHRIRAMPPS",
      "label": "SHRIRAMPPS",
      "value": "SHRIRAMPPS"
    },
    {
      "instrument_token": "4460545",
      "name": "GLOBUS SPIRITS",
      "tradingSymbol": "GLOBUSSPR",
      "label": "GLOBUSSPR",
      "value": "GLOBUSSPR"
    },
    {
      "instrument_token": "6585345",
      "name": "RUPA & COMPANY",
      "tradingSymbol": "RUPA",
      "label": "RUPA",
      "value": "RUPA"
    },
    {
      "instrument_token": "1700609",
      "name": "SJS ENTERPRISES",
      "tradingSymbol": "SJS",
      "label": "SJS",
      "value": "SJS"
    },
    {
      "instrument_token": "4476417",
      "name": "EXPLEO SOLUTIONS",
      "tradingSymbol": "EXPLEOSOL",
      "label": "EXPLEOSOL",
      "value": "EXPLEOSOL"
    },
    {
      "instrument_token": "3226369",
      "name": "PRECISION CAMSHAFTS",
      "tradingSymbol": "PRECAM",
      "label": "PRECAM",
      "value": "PRECAM"
    },
    {
      "instrument_token": "4127489",
      "name": "BHARAT WIRE ROPES",
      "tradingSymbol": "BHARATWIRE",
      "label": "BHARATWIRE",
      "value": "BHARATWIRE"
    },
    {
      "instrument_token": "5425921",
      "name": "GTPL HATHWAY",
      "tradingSymbol": "GTPL",
      "label": "GTPL",
      "value": "GTPL"
    },
    {
      "instrument_token": "4695297",
      "name": "VISHNU PRAKASH R PUNGLI L",
      "tradingSymbol": "VPRPL",
      "label": "VPRPL",
      "value": "VPRPL"
    },
    {
      "instrument_token": "8705",
      "name": "ADOR WELDING",
      "tradingSymbol": "ADORWELD",
      "label": "ADORWELD",
      "value": "ADORWELD"
    },
    {
      "instrument_token": "239617",
      "name": "D. P. ABHUSHAN",
      "tradingSymbol": "DPABHUSHAN",
      "label": "DPABHUSHAN",
      "value": "DPABHUSHAN"
    },
    {
      "instrument_token": "274689",
      "name": "FOSECO INDIA",
      "tradingSymbol": "FOSECOIND",
      "label": "FOSECOIND",
      "value": "FOSECOIND"
    },
    {
      "instrument_token": "5920513",
      "name": "SHIPPING CORP OF ILA",
      "tradingSymbol": "SCILAL",
      "label": "SCILAL",
      "value": "SCILAL"
    },
    {
      "instrument_token": "784897",
      "name": "SESHASAYEE PAPER & BOARDS",
      "tradingSymbol": "SESHAPAPER",
      "label": "SESHAPAPER",
      "value": "SESHAPAPER"
    },
    {
      "instrument_token": "3552001",
      "name": "JINDAL DRILLING IND.",
      "tradingSymbol": "JINDRILL",
      "label": "JINDRILL",
      "value": "JINDRILL"
    },
    {
      "instrument_token": "4641537",
      "name": "YASHO INDUSTRIES",
      "tradingSymbol": "YASHO",
      "label": "YASHO",
      "value": "YASHO"
    },
    {
      "instrument_token": "5148161",
      "name": "ORIENT GREEN POWER CO",
      "tradingSymbol": "GREENPOWER",
      "label": "GREENPOWER",
      "value": "GREENPOWER"
    },
    {
      "instrument_token": "3372801",
      "name": "NITIN SPINNERS",
      "tradingSymbol": "NITINSPIN",
      "label": "NITINSPIN",
      "value": "NITINSPIN"
    },
    {
      "instrument_token": "3064577",
      "name": "GOLDIAM INTERNATIONAL",
      "tradingSymbol": "GOLDIAM",
      "label": "GOLDIAM",
      "value": "GOLDIAM"
    },
    {
      "instrument_token": "2326273",
      "name": "PIX TRANSMISSIONS",
      "tradingSymbol": "PIXTRANS",
      "label": "PIXTRANS",
      "value": "PIXTRANS"
    },
    {
      "instrument_token": "1705729",
      "name": "SIGACHI INDUSTRIES",
      "tradingSymbol": "SIGACHI",
      "label": "SIGACHI",
      "value": "SIGACHI"
    },
    {
      "instrument_token": "4419329",
      "name": "ARMAN FIN SERV",
      "tradingSymbol": "ARMANFIN",
      "label": "ARMANFIN",
      "value": "ARMANFIN"
    },
    {
      "instrument_token": "1965825",
      "name": "MONARCH NETWORTH CAP",
      "tradingSymbol": "MONARCH",
      "label": "MONARCH",
      "value": "MONARCH"
    },
    {
      "instrument_token": "4492033",
      "name": "AMRUTAJAN HEALTH",
      "tradingSymbol": "AMRUTANJAN",
      "label": "AMRUTANJAN",
      "value": "AMRUTANJAN"
    },
    {
      "instrument_token": "304641",
      "name": "FEDERAL-MOGUL GOETZE (IND",
      "tradingSymbol": "FMGOETZE",
      "label": "FMGOETZE",
      "value": "FMGOETZE"
    },
    {
      "instrument_token": "5278977",
      "name": "PENNAR INDUSTRIES",
      "tradingSymbol": "PENIND",
      "label": "PENIND",
      "value": "PENIND"
    },
    {
      "instrument_token": "5924353",
      "name": "POPULAR VEHICLES N SER L",
      "tradingSymbol": "PVSL",
      "label": "PVSL",
      "value": "PVSL"
    },
    {
      "instrument_token": "5514497",
      "name": "JUBILANT INDUSTRIES",
      "tradingSymbol": "JUBLINDS",
      "label": "JUBLINDS",
      "value": "JUBLINDS"
    },
    {
      "instrument_token": "3038209",
      "name": "63 MOONS TECHNOLOGIES",
      "tradingSymbol": "63MOONS",
      "label": "63MOONS",
      "value": "63MOONS"
    },
    {
      "instrument_token": "5160961",
      "name": "CANTABIL RETAIL",
      "tradingSymbol": "CANTABIL",
      "label": "CANTABIL",
      "value": "CANTABIL"
    },
    {
      "instrument_token": "1174273",
      "name": "RAMCO INDUSTRIES",
      "tradingSymbol": "RAMCOIND",
      "label": "RAMCOIND",
      "value": "RAMCOIND"
    },
    {
      "instrument_token": "461313",
      "name": "JYOTI STRUCTURES",
      "tradingSymbol": "JYOTISTRUC",
      "label": "JYOTISTRUC",
      "value": "JYOTISTRUC"
    },
    {
      "instrument_token": "6929153",
      "name": "VARDHMAN SPC STEEL",
      "tradingSymbol": "VSSL",
      "label": "VSSL",
      "value": "VSSL"
    },
    {
      "instrument_token": "3913729",
      "name": "HERCULES HOI.",
      "tradingSymbol": "HERCULES",
      "label": "HERCULES",
      "value": "HERCULES"
    },
    {
      "instrument_token": "2877185",
      "name": "NALWA SONS INVESTMENT",
      "tradingSymbol": "NSIL",
      "label": "NSIL",
      "value": "NSIL"
    },
    {
      "instrument_token": "760321",
      "name": "SURYODAY SMALL FIN BK",
      "tradingSymbol": "SURYODAY",
      "label": "SURYODAY",
      "value": "SURYODAY"
    },
    {
      "instrument_token": "1018881",
      "name": "TAMILNADU NEWSPRT & PAPER",
      "tradingSymbol": "TNPL",
      "label": "TNPL",
      "value": "TNPL"
    },
    {
      "instrument_token": "247553",
      "name": "SUBEX",
      "tradingSymbol": "SUBEXLTD",
      "label": "SUBEXLTD",
      "value": "SUBEXLTD"
    },
    {
      "instrument_token": "4714753",
      "name": "RISHABH INSTRUMENTS",
      "tradingSymbol": "RISHABH",
      "label": "RISHABH",
      "value": "RISHABH"
    },
    {
      "instrument_token": "1409793",
      "name": "SERVOTECH POWER SYS",
      "tradingSymbol": "SERVOTECH",
      "label": "SERVOTECH",
      "value": "SERVOTECH"
    },
    {
      "instrument_token": "1577985",
      "name": "BHAGIRADHA CHEM & INDS L",
      "tradingSymbol": "BHAGCHEM",
      "label": "BHAGCHEM",
      "value": "BHAGCHEM"
    },
    {
      "instrument_token": "425729",
      "name": "AGRO TECH FOODS",
      "tradingSymbol": "ATFL",
      "label": "ATFL",
      "value": "ATFL"
    },
    {
      "instrument_token": "254209",
      "name": "EVEREST INDUSTRIES",
      "tradingSymbol": "EVERESTIND",
      "label": "EVERESTIND",
      "value": "EVERESTIND"
    },
    {
      "instrument_token": "4754177",
      "name": "GNA AXLES",
      "tradingSymbol": "GNA",
      "label": "GNA",
      "value": "GNA"
    },
    {
      "instrument_token": "3706881",
      "name": "GOKUL AGRO RESOURCES",
      "tradingSymbol": "GOKULAGRO",
      "label": "GOKULAGRO",
      "value": "GOKULAGRO"
    },
    {
      "instrument_token": "3493889",
      "name": "TALBROS AUTO. COMP.",
      "tradingSymbol": "TALBROAUTO",
      "label": "TALBROAUTO",
      "value": "TALBROAUTO"
    },
    {
      "instrument_token": "1875201",
      "name": "DCM SHRIRAM IND",
      "tradingSymbol": "DCMSRIND",
      "label": "DCMSRIND",
      "value": "DCMSRIND"
    },
    {
      "instrument_token": "611329",
      "name": "NELCO",
      "tradingSymbol": "NELCO",
      "label": "NELCO",
      "value": "NELCO"
    },
    {
      "instrument_token": "5321729",
      "name": "MOTISONS JEWELLERS",
      "tradingSymbol": "MOTISONS",
      "label": "MOTISONS",
      "value": "MOTISONS"
    },
    {
      "instrument_token": "113921",
      "name": "5PAISA CAPITAL",
      "tradingSymbol": "5PAISA",
      "label": "5PAISA",
      "value": "5PAISA"
    },
    {
      "instrument_token": "4259585",
      "name": "KIRI INDUSTRIES",
      "tradingSymbol": "KIRIINDUS",
      "label": "KIRIINDUS",
      "value": "KIRIINDUS"
    },
    {
      "instrument_token": "5804801",
      "name": "CAPITAL SMALL FIN BANK L",
      "tradingSymbol": "CAPITALSFB",
      "label": "CAPITALSFB",
      "value": "CAPITALSFB"
    },
    {
      "instrument_token": "1216257",
      "name": "INDRAPRASTHA MEDICAL CORP",
      "tradingSymbol": "INDRAMEDCO",
      "label": "INDRAMEDCO",
      "value": "INDRAMEDCO"
    },
    {
      "instrument_token": "4676609",
      "name": "AEROFLEX INDUSTRIES",
      "tradingSymbol": "AEROFLEX",
      "label": "AEROFLEX",
      "value": "AEROFLEX"
    },
    {
      "instrument_token": "2828801",
      "name": "SIRCA PAINT INDIA",
      "tradingSymbol": "SIRCA",
      "label": "SIRCA",
      "value": "SIRCA"
    },
    {
      "instrument_token": "5202177",
      "name": "SHANKARA BLDG PRODUCT",
      "tradingSymbol": "SHANKARA",
      "label": "SHANKARA",
      "value": "SHANKARA"
    },
    {
      "instrument_token": "413185",
      "name": "FAIRCHEM ORGANICS",
      "tradingSymbol": "FAIRCHEMOR",
      "label": "FAIRCHEMOR",
      "value": "FAIRCHEMOR"
    },
    {
      "instrument_token": "3402241",
      "name": "B.L.KASHYAP & SON",
      "tradingSymbol": "BLKASHYAP",
      "label": "BLKASHYAP",
      "value": "BLKASHYAP"
    },
    {
      "instrument_token": "887297",
      "name": "TOURISM FINANCE CORP. OF",
      "tradingSymbol": "TFCILTD",
      "label": "TFCILTD",
      "value": "TFCILTD"
    },
    {
      "instrument_token": "3927809",
      "name": "SADHANA NITROCHEM",
      "tradingSymbol": "SADHNANIQ",
      "label": "SADHNANIQ",
      "value": "SADHNANIQ"
    },
    {
      "instrument_token": "1215233",
      "name": "INDIA NIPPON ELECT ",
      "tradingSymbol": "INDNIPPON",
      "label": "INDNIPPON",
      "value": "INDNIPPON"
    },
    {
      "instrument_token": "728065",
      "name": "RANE HOLDINGS",
      "tradingSymbol": "RANEHOLDIN",
      "label": "RANEHOLDIN",
      "value": "RANEHOLDIN"
    },
    {
      "instrument_token": "3045377",
      "name": "GEOJIT FINANCIAL SER L",
      "tradingSymbol": "GEOJITFSL",
      "label": "GEOJITFSL",
      "value": "GEOJITFSL"
    },
    {
      "instrument_token": "209153",
      "name": "DCW",
      "tradingSymbol": "DCW",
      "label": "DCW",
      "value": "DCW"
    },
    {
      "instrument_token": "3619585",
      "name": "SURATWWALA BUS GROUP",
      "tradingSymbol": "SBGLP",
      "label": "SBGLP",
      "value": "SBGLP"
    },
    {
      "instrument_token": "643329",
      "name": "BCL INDUSTRIES",
      "tradingSymbol": "BCLIND",
      "label": "BCLIND",
      "value": "BCLIND"
    },
    {
      "instrument_token": "3668225",
      "name": "SMS PHARMACEUTICALS",
      "tradingSymbol": "SMSPHARMA",
      "label": "SMSPHARMA",
      "value": "SMSPHARMA"
    },
    {
      "instrument_token": "3947265",
      "name": "DPSC",
      "tradingSymbol": "DPSCLTD",
      "label": "DPSCLTD",
      "value": "DPSCLTD"
    },
    {
      "instrument_token": "1591297",
      "name": "CAMLIN FINE SCIENCES",
      "tradingSymbol": "CAMLINFINE",
      "label": "CAMLINFINE",
      "value": "CAMLINFINE"
    },
    {
      "instrument_token": "4474113",
      "name": "CONTROL PRINT",
      "tradingSymbol": "CONTROLPR",
      "label": "CONTROLPR",
      "value": "CONTROLPR"
    },
    {
      "instrument_token": "4547585",
      "name": "REFEX INDUSTRIES",
      "tradingSymbol": "REFEX",
      "label": "REFEX",
      "value": "REFEX"
    },
    {
      "instrument_token": "3288833",
      "name": "KRISHANA PHOSCHEM",
      "tradingSymbol": "KRISHANA",
      "label": "KRISHANA",
      "value": "KRISHANA"
    },
    {
      "instrument_token": "3351297",
      "name": "EVEREST KANTO CYLINDERLTD",
      "tradingSymbol": "EKC",
      "label": "EKC",
      "value": "EKC"
    },
    {
      "instrument_token": "964097",
      "name": "WHEELS INDIA",
      "tradingSymbol": "WHEELS",
      "label": "WHEELS",
      "value": "WHEELS"
    },
    {
      "instrument_token": "5040897",
      "name": "JITF INFRALOGISTICS",
      "tradingSymbol": "JITFINFRA",
      "label": "JITFINFRA",
      "value": "JITFINFRA"
    },
    {
      "instrument_token": "2918145",
      "name": "SALZER ELECTRONICS",
      "tradingSymbol": "SALZERELEC",
      "label": "SALZERELEC",
      "value": "SALZERELEC"
    },
    {
      "instrument_token": "923393",
      "name": "UNIVERSAL CABLES",
      "tradingSymbol": "UNIVCABLES",
      "label": "UNIVCABLES",
      "value": "UNIVCABLES"
    },
    {
      "instrument_token": "4672513",
      "name": "S. P. APPARELS",
      "tradingSymbol": "SPAL",
      "label": "SPAL",
      "value": "SPAL"
    },
    {
      "instrument_token": "5750529",
      "name": "EPACK DURABLE",
      "tradingSymbol": "EPACK",
      "label": "EPACK",
      "value": "EPACK"
    },
    {
      "instrument_token": "3048705",
      "name": "SWELECT ENERGY SYS",
      "tradingSymbol": "SWELECTES",
      "label": "SWELECTES",
      "value": "SWELECTES"
    },
    {
      "instrument_token": "5848577",
      "name": "GPT HEALTHCARE",
      "tradingSymbol": "GPTHEALTH",
      "label": "GPTHEALTH",
      "value": "GPTHEALTH"
    },
    {
      "instrument_token": "3752193",
      "name": "INSECTICIDES (I)",
      "tradingSymbol": "INSECTICID",
      "label": "INSECTICID",
      "value": "INSECTICID"
    },
    {
      "instrument_token": "577537",
      "name": "PENINSULA LAND",
      "tradingSymbol": "PENINLAND",
      "label": "PENINLAND",
      "value": "PENINLAND"
    },
    {
      "instrument_token": "793345",
      "name": "SHREE DIGVIJAY CEM CO",
      "tradingSymbol": "SHREDIGCEM",
      "label": "SHREDIGCEM",
      "value": "SHREDIGCEM"
    },
    {
      "instrument_token": "832513",
      "name": "SPIC",
      "tradingSymbol": "SPIC",
      "label": "SPIC",
      "value": "SPIC"
    },
    {
      "instrument_token": "2949633",
      "name": "NIIT",
      "tradingSymbol": "NIITLTD",
      "label": "NIITLTD",
      "value": "NIITLTD"
    },
    {
      "instrument_token": "4718337",
      "name": "BIGBLOC CONSTRUCTION",
      "tradingSymbol": "BIGBLOC",
      "label": "BIGBLOC",
      "value": "BIGBLOC"
    },
    {
      "instrument_token": "4959233",
      "name": "SPACENET ENTERS IND",
      "tradingSymbol": "SPCENET",
      "label": "SPCENET",
      "value": "SPCENET"
    },
    {
      "instrument_token": "3927553",
      "name": "SHALIM PAINTS",
      "tradingSymbol": "SHALPAINTS",
      "label": "SHALPAINTS",
      "value": "SHALPAINTS"
    },
    {
      "instrument_token": "3444993",
      "name": "KAMDHENU",
      "tradingSymbol": "KAMDHENU",
      "label": "KAMDHENU",
      "value": "KAMDHENU"
    },
    {
      "instrument_token": "539393",
      "name": "STOVE KRAFT",
      "tradingSymbol": "STOVEKRAFT",
      "label": "STOVEKRAFT",
      "value": "STOVEKRAFT"
    },
    {
      "instrument_token": "5899009",
      "name": "R K SWAMY",
      "tradingSymbol": "RKSWAMY",
      "label": "RKSWAMY",
      "value": "RKSWAMY"
    },
    {
      "instrument_token": "2702593",
      "name": "NAVKAR CORPORATION",
      "tradingSymbol": "NAVKARCORP",
      "label": "NAVKARCORP",
      "value": "NAVKARCORP"
    },
    {
      "instrument_token": "695297",
      "name": "BTRFLY GANDHI APPL",
      "tradingSymbol": "BUTTERFLY",
      "label": "BUTTERFLY",
      "value": "BUTTERFLY"
    },
    {
      "instrument_token": "219393",
      "name": "DHAMPUR SUGAR MILLS",
      "tradingSymbol": "DHAMPURSUG",
      "label": "DHAMPURSUG",
      "value": "DHAMPURSUG"
    },
    {
      "instrument_token": "2925313",
      "name": "NDTV",
      "tradingSymbol": "NDTV",
      "label": "NDTV",
      "value": "NDTV"
    },
    {
      "instrument_token": "5160705",
      "name": "ARIHANT SUPERSTRUCT",
      "tradingSymbol": "ARIHANTSUP",
      "label": "ARIHANTSUP",
      "value": "ARIHANTSUP"
    },
    {
      "instrument_token": "4636161",
      "name": "VASCON ENGINEERS",
      "tradingSymbol": "VASCONEQ",
      "label": "VASCONEQ",
      "value": "VASCONEQ"
    },
    {
      "instrument_token": "3550721",
      "name": "KUANTUM PAPERS",
      "tradingSymbol": "KUANTUM",
      "label": "KUANTUM",
      "value": "KUANTUM"
    },
    {
      "instrument_token": "2753281",
      "name": "APTECH",
      "tradingSymbol": "APTECHT",
      "label": "APTECHT",
      "value": "APTECHT"
    },
    {
      "instrument_token": "391681",
      "name": "INDIAN HUME PIPE CO.",
      "tradingSymbol": "INDIANHUME",
      "label": "INDIANHUME",
      "value": "INDIANHUME"
    },
    {
      "instrument_token": "3401729",
      "name": "ABANS HOLDINGS",
      "tradingSymbol": "AHL",
      "label": "AHL",
      "value": "AHL"
    },
    {
      "instrument_token": "5446401",
      "name": "SUMMIT SECURITIES",
      "tradingSymbol": "SUMMITSEC",
      "label": "SUMMITSEC",
      "value": "SUMMITSEC"
    },
    {
      "instrument_token": "3495937",
      "name": "ALICON CASTALLOY",
      "tradingSymbol": "ALICON",
      "label": "ALICON",
      "value": "ALICON"
    },
    {
      "instrument_token": "2831361",
      "name": "KSOLVES INDIA",
      "tradingSymbol": "KSOLVES",
      "label": "KSOLVES",
      "value": "KSOLVES"
    },
    {
      "instrument_token": "3606017",
      "name": "I G PETROCHEMICALS",
      "tradingSymbol": "IGPL",
      "label": "IGPL",
      "value": "IGPL"
    },
    {
      "instrument_token": "1741569",
      "name": "STEELCAST",
      "tradingSymbol": "STEELCAS",
      "label": "STEELCAS",
      "value": "STEELCAS"
    },
    {
      "instrument_token": "4518657",
      "name": "POKARNA",
      "tradingSymbol": "POKARNA",
      "label": "POKARNA",
      "value": "POKARNA"
    },
    {
      "instrument_token": "4551169",
      "name": "ALLCARGO TERMINALS",
      "tradingSymbol": "ATL",
      "label": "ATL",
      "value": "ATL"
    },
    {
      "instrument_token": "1437185",
      "name": "GANESH BENZOPLAST",
      "tradingSymbol": "GANESHBE",
      "label": "GANESHBE",
      "value": "GANESHBE"
    },
    {
      "instrument_token": "189953",
      "name": "COSMO FIRST",
      "tradingSymbol": "COSMOFIRST",
      "label": "COSMOFIRST",
      "value": "COSMOFIRST"
    },
    {
      "instrument_token": "460033",
      "name": "ANTONY WASTE HDG CELL",
      "tradingSymbol": "AWHCL",
      "label": "AWHCL",
      "value": "AWHCL"
    },
    {
      "instrument_token": "2986753",
      "name": "DWARIKESH SUGAR IND",
      "tradingSymbol": "DWARKESH",
      "label": "DWARKESH",
      "value": "DWARKESH"
    },
    {
      "instrument_token": "2295809",
      "name": "HARIOM PIPE INDUSTRIES L",
      "tradingSymbol": "HARIOMPIPE",
      "label": "HARIOMPIPE",
      "value": "HARIOMPIPE"
    },
    {
      "instrument_token": "593921",
      "name": "SMC GLOBAL SECURITIES",
      "tradingSymbol": "SMCGLOBAL",
      "label": "SMCGLOBAL",
      "value": "SMCGLOBAL"
    },
    {
      "instrument_token": "1257217",
      "name": "MADRAS FERTILISERS",
      "tradingSymbol": "MADRASFERT",
      "label": "MADRASFERT",
      "value": "MADRASFERT"
    },
    {
      "instrument_token": "6576641",
      "name": "DYNACONS SYS & SOLN",
      "tradingSymbol": "DSSL",
      "label": "DSSL",
      "value": "DSSL"
    },
    {
      "instrument_token": "5462785",
      "name": "STEEL EXCHANGE INDIA",
      "tradingSymbol": "STEELXIND",
      "label": "STEELXIND",
      "value": "STEELXIND"
    },
    {
      "instrument_token": "1520129",
      "name": "MONTE CARLO FASHIONS",
      "tradingSymbol": "MONTECARLO",
      "label": "MONTECARLO",
      "value": "MONTECARLO"
    },
    {
      "instrument_token": "4828929",
      "name": "VERTOZ ADVERTISING",
      "tradingSymbol": "VERTOZ",
      "label": "VERTOZ",
      "value": "VERTOZ"
    },
    {
      "instrument_token": "2630913",
      "name": "NGL FINE CHEM",
      "tradingSymbol": "NGLFINE",
      "label": "NGLFINE",
      "value": "NGLFINE"
    },
    {
      "instrument_token": "162305",
      "name": "IGARASHI MOTORS INDIA",
      "tradingSymbol": "IGARASHI",
      "label": "IGARASHI",
      "value": "IGARASHI"
    },
    {
      "instrument_token": "437249",
      "name": "JAY BHARAT MARUTI",
      "tradingSymbol": "JAYBARMARU",
      "label": "JAYBARMARU",
      "value": "JAYBARMARU"
    },
    {
      "instrument_token": "3586049",
      "name": "AVT NATURAL PRODUCTS",
      "tradingSymbol": "AVTNPL",
      "label": "AVTNPL",
      "value": "AVTNPL"
    },
    {
      "instrument_token": "2886401",
      "name": "TV TODAY NETWORK",
      "tradingSymbol": "TVTODAY",
      "label": "TVTODAY",
      "value": "TVTODAY"
    },
    {
      "instrument_token": "2996481",
      "name": "XCHANGING SOLUTIONS",
      "tradingSymbol": "XCHANGING",
      "label": "XCHANGING",
      "value": "XCHANGING"
    },
    {
      "instrument_token": "34817",
      "name": "ANDHRA SUGARS",
      "tradingSymbol": "ANDHRSUGAR",
      "label": "ANDHRSUGAR",
      "value": "ANDHRSUGAR"
    },
    {
      "instrument_token": "3504129",
      "name": "ALLCARGO GATI",
      "tradingSymbol": "ACLGATI",
      "label": "ACLGATI",
      "value": "ACLGATI"
    },
    {
      "instrument_token": "491265",
      "name": "KOPRAN",
      "tradingSymbol": "KOPRAN",
      "label": "KOPRAN",
      "value": "KOPRAN"
    },
    {
      "instrument_token": "3377153",
      "name": "ENTERTAIN NET. IND.",
      "tradingSymbol": "ENIL",
      "label": "ENIL",
      "value": "ENIL"
    },
    {
      "instrument_token": "4867841",
      "name": "MANOJ VAIBHAV GEM N JEW L",
      "tradingSymbol": "MVGJL",
      "label": "MVGJL",
      "value": "MVGJL"
    },
    {
      "instrument_token": "2916865",
      "name": "ZOTA HEALTH CARE",
      "tradingSymbol": "ZOTA",
      "label": "ZOTA",
      "value": "ZOTA"
    },
    {
      "instrument_token": "1239809",
      "name": "SNOWMAN LOGISTICS",
      "tradingSymbol": "SNOWMAN",
      "label": "SNOWMAN",
      "value": "SNOWMAN"
    },
    {
      "instrument_token": "1112065",
      "name": "PUNJAB CHEM & CROP PROT L",
      "tradingSymbol": "PUNJABCHEM",
      "label": "PUNJABCHEM",
      "value": "PUNJABCHEM"
    },
    {
      "instrument_token": "7398145",
      "name": "KITEX GARMENTS",
      "tradingSymbol": "KITEX",
      "label": "KITEX",
      "value": "KITEX"
    },
    {
      "instrument_token": "2956545",
      "name": "TEXMACO INFRA & HOLDG",
      "tradingSymbol": "TEXINFRA",
      "label": "TEXINFRA",
      "value": "TEXINFRA"
    },
    {
      "instrument_token": "3074305",
      "name": "IND MOTOR PART & ACC",
      "tradingSymbol": "IMPAL",
      "label": "IMPAL",
      "value": "IMPAL"
    },
    {
      "instrument_token": "348161",
      "name": "HIMATSINGKA SEIDE",
      "tradingSymbol": "HIMATSEIDE",
      "label": "HIMATSEIDE",
      "value": "HIMATSEIDE"
    },
    {
      "instrument_token": "3103745",
      "name": "DOLAT ALGOTECH",
      "tradingSymbol": "DOLATALGO",
      "label": "DOLATALGO",
      "value": "DOLATALGO"
    },
    {
      "instrument_token": "3841793",
      "name": "MANG.CHEM.FERT.LTD",
      "tradingSymbol": "MANGCHEFER",
      "label": "MANGCHEFER",
      "value": "MANGCHEFER"
    },
    {
      "instrument_token": "867585",
      "name": "AGARWAL INDS CORP",
      "tradingSymbol": "AGARIND",
      "label": "AGARIND",
      "value": "AGARIND"
    },
    {
      "instrument_token": "3360257",
      "name": "REPRO INDIA",
      "tradingSymbol": "REPRO",
      "label": "REPRO",
      "value": "REPRO"
    },
    {
      "instrument_token": "3424257",
      "name": "UTTAM SUGAR MILLS",
      "tradingSymbol": "UTTAMSUGAR",
      "label": "UTTAMSUGAR",
      "value": "UTTAMSUGAR"
    },
    {
      "instrument_token": "628225",
      "name": "CENTRUM CAPITAL",
      "tradingSymbol": "CENTRUM",
      "label": "CENTRUM",
      "value": "CENTRUM"
    },
    {
      "instrument_token": "1804289",
      "name": "HESTER BIOSCIENCES",
      "tradingSymbol": "HESTERBIO",
      "label": "HESTERBIO",
      "value": "HESTERBIO"
    },
    {
      "instrument_token": "4931841",
      "name": "BLISS GVS PHARMA",
      "tradingSymbol": "BLISSGVS",
      "label": "BLISSGVS",
      "value": "BLISSGVS"
    },
    {
      "instrument_token": "2968577",
      "name": "LINCOLN PHARMA",
      "tradingSymbol": "LINCOLN",
      "label": "LINCOLN",
      "value": "LINCOLN"
    },
    {
      "instrument_token": "772609",
      "name": "SAURASHTRA CEMENT",
      "tradingSymbol": "SAURASHCEM",
      "label": "SAURASHCEM",
      "value": "SAURASHCEM"
    },
    {
      "instrument_token": "5561857",
      "name": "MATRIMONY.COM",
      "tradingSymbol": "MATRIMONY",
      "label": "MATRIMONY",
      "value": "MATRIMONY"
    },
    {
      "instrument_token": "2294273",
      "name": "HARDWYN INDIA",
      "tradingSymbol": "HARDWYN",
      "label": "HARDWYN",
      "value": "HARDWYN"
    },
    {
      "instrument_token": "299009",
      "name": "G M BREWERIES",
      "tradingSymbol": "GMBREW",
      "label": "GMBREW",
      "value": "GMBREW"
    },
    {
      "instrument_token": "4555521",
      "name": "TRANSINDIA REAL ESTATE L",
      "tradingSymbol": "TREL",
      "label": "TREL",
      "value": "TREL"
    },
    {
      "instrument_token": "4402433",
      "name": "PAKKA",
      "tradingSymbol": "PAKKA",
      "label": "PAKKA",
      "value": "PAKKA"
    },
    {
      "instrument_token": "669185",
      "name": "HERANBA INDUSTRIES",
      "tradingSymbol": "HERANBA",
      "label": "HERANBA",
      "value": "HERANBA"
    },
    {
      "instrument_token": "2684161",
      "name": "RAM RATNA WIRES",
      "tradingSymbol": "RAMRAT",
      "label": "RAMRAT",
      "value": "RAMRAT"
    },
    {
      "instrument_token": "3894785",
      "name": "DHUNSERI VENTURES",
      "tradingSymbol": "DVL",
      "label": "DVL",
      "value": "DVL"
    },
    {
      "instrument_token": "5228801",
      "name": "NACL INDUSTRIES",
      "tradingSymbol": "NACLIND",
      "label": "NACLIND",
      "value": "NACLIND"
    },
    {
      "instrument_token": "3065601",
      "name": "RANE (MADRAS)",
      "tradingSymbol": "RML",
      "label": "RML",
      "value": "RML"
    },
    {
      "instrument_token": "3778817",
      "name": "NELCAST",
      "tradingSymbol": "NELCAST",
      "label": "NELCAST",
      "value": "NELCAST"
    },
    {
      "instrument_token": "4307713",
      "name": "KOKUYO CAMLIN",
      "tradingSymbol": "KOKUYOCMLN",
      "label": "KOKUYOCMLN",
      "value": "KOKUYOCMLN"
    },
    {
      "instrument_token": "3020289",
      "name": "ALLSEC TECHNOLOGIES",
      "tradingSymbol": "ALLSEC",
      "label": "ALLSEC",
      "value": "ALLSEC"
    },
    {
      "instrument_token": "2316545",
      "name": "ROTO PUMPS",
      "tradingSymbol": "ROTO",
      "label": "ROTO",
      "value": "ROTO"
    },
    {
      "instrument_token": "1100545",
      "name": "STERLING TOOLS",
      "tradingSymbol": "STERTOOLS",
      "label": "STERTOOLS",
      "value": "STERTOOLS"
    },
    {
      "instrument_token": "5882881",
      "name": "MUKKA PROTEINS",
      "tradingSymbol": "MUKKA",
      "label": "MUKKA",
      "value": "MUKKA"
    },
    {
      "instrument_token": "2649601",
      "name": "CSL FINANCE",
      "tradingSymbol": "CSLFINANCE",
      "label": "CSLFINANCE",
      "value": "CSLFINANCE"
    },
    {
      "instrument_token": "291585",
      "name": "GIC HOUSING FINANCE",
      "tradingSymbol": "GICHSGFIN",
      "label": "GICHSGFIN",
      "value": "GICHSGFIN"
    },
    {
      "instrument_token": "2827521",
      "name": "SATIA INDUSTRIES",
      "tradingSymbol": "SATIA",
      "label": "SATIA",
      "value": "SATIA"
    },
    {
      "instrument_token": "5344769",
      "name": "CREDO BRANDS MARKETING L",
      "tradingSymbol": "MUFTI",
      "label": "MUFTI",
      "value": "MUFTI"
    },
    {
      "instrument_token": "3558401",
      "name": "CREST VENTURES",
      "tradingSymbol": "CREST",
      "label": "CREST",
      "value": "CREST"
    },
    {
      "instrument_token": "5479937",
      "name": "AVADH SUG & ENERGY",
      "tradingSymbol": "AVADHSUGAR",
      "label": "AVADHSUGAR",
      "value": "AVADHSUGAR"
    },
    {
      "instrument_token": "1373697",
      "name": "WINDLAS BIOTECH",
      "tradingSymbol": "WINDLAS",
      "label": "WINDLAS",
      "value": "WINDLAS"
    },
    {
      "instrument_token": "5932801",
      "name": "KRYSTAL INTEGRATED SER L",
      "tradingSymbol": "KRYSTAL",
      "label": "KRYSTAL",
      "value": "KRYSTAL"
    },
    {
      "instrument_token": "1408257",
      "name": "YUKEN INDIA",
      "tradingSymbol": "YUKEN",
      "label": "YUKEN",
      "value": "YUKEN"
    },
    {
      "instrument_token": "1799681",
      "name": "ASIAN ENERGY SERVICES",
      "tradingSymbol": "ASIANENE",
      "label": "ASIANENE",
      "value": "ASIANENE"
    },
    {
      "instrument_token": "2747649",
      "name": "SPORTKING INDIA",
      "tradingSymbol": "SPORTKING",
      "label": "SPORTKING",
      "value": "SPORTKING"
    },
    {
      "instrument_token": "891393",
      "name": "CHAMAN LAL SETIA EXP",
      "tradingSymbol": "CLSEL",
      "label": "CLSEL",
      "value": "CLSEL"
    },
    {
      "instrument_token": "3536385",
      "name": "KAMDHENU VENTURES",
      "tradingSymbol": "KAMOPAINTS",
      "label": "KAMOPAINTS",
      "value": "KAMOPAINTS"
    },
    {
      "instrument_token": "84481",
      "name": "VALIANT ORGANICS",
      "tradingSymbol": "VALIANTORG",
      "label": "VALIANTORG",
      "value": "VALIANTORG"
    },
    {
      "instrument_token": "3069953",
      "name": "SYNCOM FORMU (I)",
      "tradingSymbol": "SYNCOMF",
      "label": "SYNCOMF",
      "value": "SYNCOMF"
    },
    {
      "instrument_token": "5697281",
      "name": "NDR AUTO COMPONENTS",
      "tradingSymbol": "NDRAUTO",
      "label": "NDRAUTO",
      "value": "NDRAUTO"
    },
    {
      "instrument_token": "2907905",
      "name": "DHANLAXMI BANK",
      "tradingSymbol": "DHANBANK",
      "label": "DHANBANK",
      "value": "DHANBANK"
    },
    {
      "instrument_token": "2544385",
      "name": "ONE POINT ONE SOL",
      "tradingSymbol": "ONEPOINT",
      "label": "ONEPOINT",
      "value": "ONEPOINT"
    },
    {
      "instrument_token": "3555841",
      "name": "HIND RECTIFIER",
      "tradingSymbol": "HIRECT",
      "label": "HIRECT",
      "value": "HIRECT"
    },
    {
      "instrument_token": "462081",
      "name": "KABRA EXTRUSION TECHNIK L",
      "tradingSymbol": "KABRAEXTRU",
      "label": "KABRAEXTRU",
      "value": "KABRAEXTRU"
    },
    {
      "instrument_token": "2814209",
      "name": "INDO RAMA SYNTHETICS",
      "tradingSymbol": "INDORAMA",
      "label": "INDORAMA",
      "value": "INDORAMA"
    },
    {
      "instrument_token": "1609217",
      "name": "GULSHAN POLYOLS",
      "tradingSymbol": "GULPOLY",
      "label": "GULPOLY",
      "value": "GULPOLY"
    },
    {
      "instrument_token": "183041",
      "name": "HEUBACH COLORANTS IND",
      "tradingSymbol": "HEUBACHIND",
      "label": "HEUBACHIND",
      "value": "HEUBACHIND"
    },
    {
      "instrument_token": "2756609",
      "name": "ORIENTAL AROMATICS",
      "tradingSymbol": "OAL",
      "label": "OAL",
      "value": "OAL"
    },
    {
      "instrument_token": "2855681",
      "name": "CREATIVE NEWTECH",
      "tradingSymbol": "CREATIVE",
      "label": "CREATIVE",
      "value": "CREATIVE"
    },
    {
      "instrument_token": "635137",
      "name": "ONWARD TECHNOLOGIES",
      "tradingSymbol": "ONWARDTEC",
      "label": "ONWARDTEC",
      "value": "ONWARDTEC"
    },
    {
      "instrument_token": "5171969",
      "name": "URJA GLOBAL",
      "tradingSymbol": "URJA",
      "label": "URJA",
      "value": "URJA"
    },
    {
      "instrument_token": "3374593",
      "name": "ROYAL ORCHID HOTELS",
      "tradingSymbol": "ROHLTD",
      "label": "ROHLTD",
      "value": "ROHLTD"
    },
    {
      "instrument_token": "3857153",
      "name": "BEML LAND ASSETS",
      "tradingSymbol": "BLAL",
      "label": "BLAL",
      "value": "BLAL"
    },
    {
      "instrument_token": "3075841",
      "name": "SAT INDUSTRIES",
      "tradingSymbol": "SATINDLTD",
      "label": "SATINDLTD",
      "value": "SATINDLTD"
    },
    {
      "instrument_token": "3353857",
      "name": "VIMTA LABS",
      "tradingSymbol": "VIMTALABS",
      "label": "VIMTALABS",
      "value": "VIMTALABS"
    },
    {
      "instrument_token": "979713",
      "name": "ZUARI INDUSTRIES",
      "tradingSymbol": "ZUARIIND",
      "label": "ZUARIIND",
      "value": "ZUARIIND"
    },
    {
      "instrument_token": "3737345",
      "name": "GLOBAL SURFACES",
      "tradingSymbol": "GSLSU",
      "label": "GSLSU",
      "value": "GSLSU"
    },
    {
      "instrument_token": "3696641",
      "name": "NAHAR SPINNING MILLS",
      "tradingSymbol": "NAHARSPING",
      "label": "NAHARSPING",
      "value": "NAHARSPING"
    },
    {
      "instrument_token": "3531777",
      "name": "MANALI PETROCHEMICALS LT",
      "tradingSymbol": "MANALIPETC",
      "label": "MANALIPETC",
      "value": "MANALIPETC"
    },
    {
      "instrument_token": "5136129",
      "name": "SASTASUNDAR VENTURES",
      "tradingSymbol": "SASTASUNDR",
      "label": "SASTASUNDR",
      "value": "SASTASUNDR"
    },
    {
      "instrument_token": "4569857",
      "name": "D-LINK INDIA",
      "tradingSymbol": "DLINKINDIA",
      "label": "DLINKINDIA",
      "value": "DLINKINDIA"
    },
    {
      "instrument_token": "3873025",
      "name": "RENAISSANCE GLOBAL",
      "tradingSymbol": "RGL",
      "label": "RGL",
      "value": "RGL"
    },
    {
      "instrument_token": "3613697",
      "name": "GANDHI SPL. TUBES",
      "tradingSymbol": "GANDHITUBE",
      "label": "GANDHITUBE",
      "value": "GANDHITUBE"
    },
    {
      "instrument_token": "3438081",
      "name": "KELLTON TECH SOL",
      "tradingSymbol": "KELLTONTEC",
      "label": "KELLTONTEC",
      "value": "KELLTONTEC"
    },
    {
      "instrument_token": "5870081",
      "name": "PLATINUM INDUSTRIES",
      "tradingSymbol": "PLATIND",
      "label": "PLATIND",
      "value": "PLATIND"
    },
    {
      "instrument_token": "3358977",
      "name": "KERNEX MICROSYS(I)",
      "tradingSymbol": "KERNEX",
      "label": "KERNEX",
      "value": "KERNEX"
    },
    {
      "instrument_token": "956417",
      "name": "WALCHANDNAGAR INDUSTRIES",
      "tradingSymbol": "WALCHANNAG",
      "label": "WALCHANNAG",
      "value": "WALCHANNAG"
    },
    {
      "instrument_token": "716545",
      "name": "CHEMFAB ALKALIS",
      "tradingSymbol": "CHEMFAB",
      "label": "CHEMFAB",
      "value": "CHEMFAB"
    },
    {
      "instrument_token": "2891009",
      "name": "UNIPHOS ENTERPRISES",
      "tradingSymbol": "UNIENTER",
      "label": "UNIENTER",
      "value": "UNIENTER"
    },
    {
      "instrument_token": "3305729",
      "name": "SARVESHWAR FOODS",
      "tradingSymbol": "SARVESHWAR",
      "label": "SARVESHWAR",
      "value": "SARVESHWAR"
    },
    {
      "instrument_token": "6404353",
      "name": "BODAL CHEMICALS",
      "tradingSymbol": "BODALCHEM",
      "label": "BODALCHEM",
      "value": "BODALCHEM"
    },
    {
      "instrument_token": "1080577",
      "name": "VISAKA INDUSTRIES",
      "tradingSymbol": "VISAKAIND",
      "label": "VISAKAIND",
      "value": "VISAKAIND"
    },
    {
      "instrument_token": "147969",
      "name": "LIKHITHA INFRASTRUC",
      "tradingSymbol": "LIKHITHA",
      "label": "LIKHITHA",
      "value": "LIKHITHA"
    },
    {
      "instrument_token": "2722305",
      "name": "AUTOMOTIVE STAMPINGS & AS",
      "tradingSymbol": "ASAL",
      "label": "ASAL",
      "value": "ASAL"
    },
    {
      "instrument_token": "5085953",
      "name": "AMINES & PLASTICIZERS",
      "tradingSymbol": "AMNPLST",
      "label": "AMNPLST",
      "value": "AMNPLST"
    },
    {
      "instrument_token": "3633665",
      "name": "NINTEC SYSTEMS",
      "tradingSymbol": "NINSYS",
      "label": "NINSYS",
      "value": "NINSYS"
    },
    {
      "instrument_token": "1796097",
      "name": "SHIVALIK RASAYAN",
      "tradingSymbol": "SHIVALIK",
      "label": "SHIVALIK",
      "value": "SHIVALIK"
    },
    {
      "instrument_token": "3044353",
      "name": "VARDHMAN HOLDINGS",
      "tradingSymbol": "VHL",
      "label": "VHL",
      "value": "VHL"
    },
    {
      "instrument_token": "2964993",
      "name": "TRACXN TECHNOLOGIES",
      "tradingSymbol": "TRACXN",
      "label": "TRACXN",
      "value": "TRACXN"
    },
    {
      "instrument_token": "2936577",
      "name": "EXCEL INDUSTRIES",
      "tradingSymbol": "EXCELINDUS",
      "label": "EXCELINDUS",
      "value": "EXCELINDUS"
    },
    {
      "instrument_token": "2822913",
      "name": "INFOBEANS TECHNO.",
      "tradingSymbol": "INFOBEAN",
      "label": "INFOBEAN",
      "value": "INFOBEAN"
    },
    {
      "instrument_token": "764929",
      "name": "SANDESH",
      "tradingSymbol": "SANDESH",
      "label": "SANDESH",
      "value": "SANDESH"
    },
    {
      "instrument_token": "158465",
      "name": "CENTURY ENKA",
      "tradingSymbol": "CENTENKA",
      "label": "CENTENKA",
      "value": "CENTENKA"
    },
    {
      "instrument_token": "1888257",
      "name": "HP ADHESIVES",
      "tradingSymbol": "HPAL",
      "label": "HPAL",
      "value": "HPAL"
    },
    {
      "instrument_token": "3072001",
      "name": "FAZE THREE",
      "tradingSymbol": "FAZE3Q",
      "label": "FAZE3Q",
      "value": "FAZE3Q"
    },
    {
      "instrument_token": "638977",
      "name": "ORIENT PAPER AND INDS",
      "tradingSymbol": "ORIENTPPR",
      "label": "ORIENTPPR",
      "value": "ORIENTPPR"
    },
    {
      "instrument_token": "5741569",
      "name": "MAX INDIA",
      "tradingSymbol": "MAXIND",
      "label": "MAXIND",
      "value": "MAXIND"
    },
    {
      "instrument_token": "1095425",
      "name": "DE NORA INDIA",
      "tradingSymbol": "DENORA",
      "label": "DENORA",
      "value": "DENORA"
    },
    {
      "instrument_token": "4505089",
      "name": "ASSO ALCOHOLS & BREW",
      "tradingSymbol": "ASALCBR",
      "label": "ASALCBR",
      "value": "ASALCBR"
    },
    {
      "instrument_token": "951041",
      "name": "VLS FINANCE",
      "tradingSymbol": "VLSFINANCE",
      "label": "VLSFINANCE",
      "value": "VLSFINANCE"
    },
    {
      "instrument_token": "6939393",
      "name": "SPECIALITY REST",
      "tradingSymbol": "SPECIALITY",
      "label": "SPECIALITY",
      "value": "SPECIALITY"
    },
    {
      "instrument_token": "69121",
      "name": "CHEMCON SPECIAL CHEM",
      "tradingSymbol": "CHEMCON",
      "label": "CHEMCON",
      "value": "CHEMCON"
    },
    {
      "instrument_token": "3732993",
      "name": "SREE RAYALSEEMA HHP",
      "tradingSymbol": "SRHHYPOLTD",
      "label": "SRHHYPOLTD",
      "value": "SRHHYPOLTD"
    },
    {
      "instrument_token": "2636033",
      "name": "PRAKASH PIPES",
      "tradingSymbol": "PPL",
      "label": "PPL",
      "value": "PPL"
    },
    {
      "instrument_token": "3709441",
      "name": "NCL INDUSTRIES",
      "tradingSymbol": "NCLIND",
      "label": "NCLIND",
      "value": "NCLIND"
    },
    {
      "instrument_token": "3798529",
      "name": "DECCAN CEMENTS",
      "tradingSymbol": "DECCANCE",
      "label": "DECCANCE",
      "value": "DECCANCE"
    },
    {
      "instrument_token": "3533057",
      "name": "SUTLEJ TEXT & INDUS",
      "tradingSymbol": "SUTLEJTEX",
      "label": "SUTLEJTEX",
      "value": "SUTLEJTEX"
    },
    {
      "instrument_token": "2089729",
      "name": "SPENCER S RETAIL",
      "tradingSymbol": "SPENCERS",
      "label": "SPENCERS",
      "value": "SPENCERS"
    },
    {
      "instrument_token": "2412033",
      "name": "SILVER TOUCH TECHNO",
      "tradingSymbol": "SILVERTUC",
      "label": "SILVERTUC",
      "value": "SILVERTUC"
    },
    {
      "instrument_token": "5298689",
      "name": "S CHAND AND COMPANY",
      "tradingSymbol": "SCHAND",
      "label": "SCHAND",
      "value": "SCHAND"
    },
    {
      "instrument_token": "2029313",
      "name": "AGS TRANSACT TECH",
      "tradingSymbol": "AGSTRA",
      "label": "AGSTRA",
      "value": "AGSTRA"
    },
    {
      "instrument_token": "2666753",
      "name": "DYNAMIC CABLES",
      "tradingSymbol": "DYCL",
      "label": "DYCL",
      "value": "DYCL"
    },
    {
      "instrument_token": "3476481",
      "name": "RADIANT CASH MGMT SER",
      "tradingSymbol": "RADIANTCMS",
      "label": "RADIANTCMS",
      "value": "RADIANTCMS"
    },
    {
      "instrument_token": "3899905",
      "name": "AMBIKA COTTON MILL",
      "tradingSymbol": "AMBIKCO",
      "label": "AMBIKCO",
      "value": "AMBIKCO"
    },
    {
      "instrument_token": "1756929",
      "name": "BAJAJ HEALTHCARE",
      "tradingSymbol": "BAJAJHCARE",
      "label": "BAJAJHCARE",
      "value": "BAJAJHCARE"
    },
    {
      "instrument_token": "715265",
      "name": "RSWM",
      "tradingSymbol": "RSWM",
      "label": "RSWM",
      "value": "RSWM"
    },
    {
      "instrument_token": "2441985",
      "name": "SAKAR HEALTHCARE",
      "tradingSymbol": "SAKAR",
      "label": "SAKAR",
      "value": "SAKAR"
    },
    {
      "instrument_token": "3458817",
      "name": "MUNJAL AUTO IND.",
      "tradingSymbol": "MUNJALAU",
      "label": "MUNJALAU",
      "value": "MUNJALAU"
    },
    {
      "instrument_token": "4918017",
      "name": "HINDUSTAN MEDIA VENT",
      "tradingSymbol": "HMVL",
      "label": "HMVL",
      "value": "HMVL"
    },
    {
      "instrument_token": "2291969",
      "name": "ELDECO HSG & IND",
      "tradingSymbol": "ELDEHSG",
      "label": "ELDEHSG",
      "value": "ELDEHSG"
    },
    {
      "instrument_token": "2333697",
      "name": "INDO AMINES",
      "tradingSymbol": "INDOAMIN",
      "label": "INDOAMIN",
      "value": "INDOAMIN"
    },
    {
      "instrument_token": "2542337",
      "name": "VIKAS LIFECARE",
      "tradingSymbol": "VIKASLIFE",
      "label": "VIKASLIFE",
      "value": "VIKASLIFE"
    },
    {
      "instrument_token": "6296321",
      "name": "RUSHIL DECOR",
      "tradingSymbol": "RUSHIL",
      "label": "RUSHIL",
      "value": "RUSHIL"
    },
    {
      "instrument_token": "3792129",
      "name": "ALLIED DIGITAL SERV.",
      "tradingSymbol": "ADSL",
      "label": "ADSL",
      "value": "ADSL"
    },
    {
      "instrument_token": "1991681",
      "name": "BRAND CONCEPTS",
      "tradingSymbol": "BCONCEPTS",
      "label": "BCONCEPTS",
      "value": "BCONCEPTS"
    },
    {
      "instrument_token": "2788609",
      "name": "DHAMPUR BIO ORGANICS",
      "tradingSymbol": "DBOL",
      "label": "DBOL",
      "value": "DBOL"
    },
    {
      "instrument_token": "1779457",
      "name": "LINC",
      "tradingSymbol": "LINC",
      "label": "LINC",
      "value": "LINC"
    },
    {
      "instrument_token": "2647809",
      "name": "RADHIKA JEWELTECH",
      "tradingSymbol": "RADHIKAJWE",
      "label": "RADHIKAJWE",
      "value": "RADHIKAJWE"
    },
    {
      "instrument_token": "3328257",
      "name": "DHARMAJ CROP GUARD",
      "tradingSymbol": "DHARMAJ",
      "label": "DHARMAJ",
      "value": "DHARMAJ"
    },
    {
      "instrument_token": "5476353",
      "name": "MAGADH SUGAR & ENERGY",
      "tradingSymbol": "MAGADSUGAR",
      "label": "MAGADSUGAR",
      "value": "MAGADSUGAR"
    },
    {
      "instrument_token": "2529025",
      "name": "CHEVIOT COMPANY",
      "tradingSymbol": "CHEVIOT",
      "label": "CHEVIOT",
      "value": "CHEVIOT"
    },
    {
      "instrument_token": "120577",
      "name": "OSWAL GREENTECH",
      "tradingSymbol": "OSWALGREEN",
      "label": "OSWALGREEN",
      "value": "OSWALGREEN"
    },
    {
      "instrument_token": "125185",
      "name": "ICE MAKE REFRIGERAT",
      "tradingSymbol": "ICEMAKE",
      "label": "ICEMAKE",
      "value": "ICEMAKE"
    },
    {
      "instrument_token": "329985",
      "name": "GFL",
      "tradingSymbol": "GFLLIMITED",
      "label": "GFLLIMITED",
      "value": "GFLLIMITED"
    },
    {
      "instrument_token": "1038081",
      "name": "PANACEA BIOTEC",
      "tradingSymbol": "PANACEABIO",
      "label": "PANACEABIO",
      "value": "PANACEABIO"
    },
    {
      "instrument_token": "2802689",
      "name": "THE STATE TRADING CORPN",
      "tradingSymbol": "STCINDIA",
      "label": "STCINDIA",
      "value": "STCINDIA"
    },
    {
      "instrument_token": "5011969",
      "name": "THE UGAR SUGAR WORKS",
      "tradingSymbol": "UGARSUGAR",
      "label": "UGARSUGAR",
      "value": "UGARSUGAR"
    },
    {
      "instrument_token": "3844353",
      "name": "MAAN ALUMINIUM",
      "tradingSymbol": "MAANALU",
      "label": "MAANALU",
      "value": "MAANALU"
    },
    {
      "instrument_token": "5200385",
      "name": "N R AGARWAL INDS",
      "tradingSymbol": "NRAIL",
      "label": "NRAIL",
      "value": "NRAIL"
    },
    {
      "instrument_token": "1128961",
      "name": "JAGSONPAL PHARMACEUTICALS",
      "tradingSymbol": "JAGSNPHARM",
      "label": "JAGSNPHARM",
      "value": "JAGSNPHARM"
    },
    {
      "instrument_token": "4274177",
      "name": "GHCL TEXTILES",
      "tradingSymbol": "GHCLTEXTIL",
      "label": "GHCLTEXTIL",
      "value": "GHCLTEXTIL"
    },
    {
      "instrument_token": "3811585",
      "name": "ASIAN GRANITO IND.",
      "tradingSymbol": "ASIANTILES",
      "label": "ASIANTILES",
      "value": "ASIANTILES"
    },
    {
      "instrument_token": "5579265",
      "name": "DAVANGERE SUGAR COMPANY L",
      "tradingSymbol": "DAVANGERE",
      "label": "DAVANGERE",
      "value": "DAVANGERE"
    },
    {
      "instrument_token": "3682561",
      "name": "PONDY OXIDES & CHEM",
      "tradingSymbol": "POCL",
      "label": "POCL",
      "value": "POCL"
    },
    {
      "instrument_token": "1176065",
      "name": "KOTHARI PETROCHEM",
      "tradingSymbol": "KOTHARIPET",
      "label": "KOTHARIPET",
      "value": "KOTHARIPET"
    },
    {
      "instrument_token": "3003137",
      "name": "CONSO. FIN. & HOLD.",
      "tradingSymbol": "CONSOFINVT",
      "label": "CONSOFINVT",
      "value": "CONSOFINVT"
    },
    {
      "instrument_token": "3947521",
      "name": "ANDHRA CEMENTS",
      "tradingSymbol": "ACL",
      "label": "ACL",
      "value": "ACL"
    },
    {
      "instrument_token": "7436801",
      "name": "ZUARI AGRO CHEMICALS",
      "tradingSymbol": "ZUARI",
      "label": "ZUARI",
      "value": "ZUARI"
    },
    {
      "instrument_token": "2782977",
      "name": "GRM OVERSEAS",
      "tradingSymbol": "GRMOVER",
      "label": "GRMOVER",
      "value": "GRMOVER"
    },
    {
      "instrument_token": "5170177",
      "name": "BEDMUTHA INDUST",
      "tradingSymbol": "BEDMUTHA",
      "label": "BEDMUTHA",
      "value": "BEDMUTHA"
    },
    {
      "instrument_token": "3021825",
      "name": "SUKHJIT STARCH & CHEM",
      "tradingSymbol": "SUKHJITS",
      "label": "SUKHJITS",
      "value": "SUKHJITS"
    },
    {
      "instrument_token": "6211841",
      "name": "ESTER INDUSTRIES",
      "tradingSymbol": "ESTER",
      "label": "ESTER",
      "value": "ESTER"
    },
    {
      "instrument_token": "3795969",
      "name": "W.S.INDUSTRIES (I)",
      "tradingSymbol": "WSI",
      "label": "WSI",
      "value": "WSI"
    },
    {
      "instrument_token": "898305",
      "name": "TAMILNADU PETROPRODUCTS L",
      "tradingSymbol": "TNPETRO",
      "label": "TNPETRO",
      "value": "TNPETRO"
    },
    {
      "instrument_token": "1708289",
      "name": "FOODS & INNS",
      "tradingSymbol": "FOODSIN",
      "label": "FOODSIN",
      "value": "FOODSIN"
    },
    {
      "instrument_token": "5691649",
      "name": "DHUNSERI INVESTMENTS",
      "tradingSymbol": "DHUNINV",
      "label": "DHUNINV",
      "value": "DHUNINV"
    },
    {
      "instrument_token": "6921473",
      "name": "TRIB BHIMJI ZAVERI",
      "tradingSymbol": "TBZ",
      "label": "TBZ",
      "value": "TBZ"
    },
    {
      "instrument_token": "4664321",
      "name": "KIRLOSKAR ELECTRIC CO",
      "tradingSymbol": "KECL",
      "label": "KECL",
      "value": "KECL"
    },
    {
      "instrument_token": "2578945",
      "name": "EMAMI PAPER MILLS",
      "tradingSymbol": "EMAMIPAP",
      "label": "EMAMIPAP",
      "value": "EMAMIPAP"
    },
    {
      "instrument_token": "3481089",
      "name": "SELAN EXPLO. TECH",
      "tradingSymbol": "SELAN",
      "label": "SELAN",
      "value": "SELAN"
    },
    {
      "instrument_token": "3990785",
      "name": "AVG LOGISTICS",
      "tradingSymbol": "AVG",
      "label": "AVG",
      "value": "AVG"
    },
    {
      "instrument_token": "1785089",
      "name": "DMCC SPECIALITY CHEMICALS",
      "tradingSymbol": "DMCC",
      "label": "DMCC",
      "value": "DMCC"
    },
    {
      "instrument_token": "122113",
      "name": "BIRLA CABLE",
      "tradingSymbol": "BIRLACABLE",
      "label": "BIRLACABLE",
      "value": "BIRLACABLE"
    },
    {
      "instrument_token": "3759873",
      "name": "GOA CARBON",
      "tradingSymbol": "GOACARBON",
      "label": "GOACARBON",
      "value": "GOACARBON"
    },
    {
      "instrument_token": "5902337",
      "name": "J.G.CHEMICALS",
      "tradingSymbol": "JGCHEM",
      "label": "JGCHEM",
      "value": "JGCHEM"
    },
    {
      "instrument_token": "1595393",
      "name": "3I INFOTECH",
      "tradingSymbol": "3IINFOLTD",
      "label": "3IINFOLTD",
      "value": "3IINFOLTD"
    },
    {
      "instrument_token": "3774721",
      "name": "ADVANI HOT.& RES.(I)",
      "tradingSymbol": "ADVANIHOTR",
      "label": "ADVANIHOTR",
      "value": "ADVANIHOTR"
    },
    {
      "instrument_token": "1642753",
      "name": "KRITI INDUSTRIES IND",
      "tradingSymbol": "KRITI",
      "label": "KRITI",
      "value": "KRITI"
    },
    {
      "instrument_token": "3436289",
      "name": "ELIN ELECTRONICS",
      "tradingSymbol": "ELIN",
      "label": "ELIN",
      "value": "ELIN"
    },
    {
      "instrument_token": "4326401",
      "name": "D P WIRES",
      "tradingSymbol": "DPWIRES",
      "label": "DPWIRES",
      "value": "DPWIRES"
    },
    {
      "instrument_token": "3787009",
      "name": "MEGASOFT",
      "tradingSymbol": "MEGASOFT",
      "label": "MEGASOFT",
      "value": "MEGASOFT"
    },
    {
      "instrument_token": "590593",
      "name": "MUNJAL SHOWA",
      "tradingSymbol": "MUNJALSHOW",
      "label": "MUNJALSHOW",
      "value": "MUNJALSHOW"
    },
    {
      "instrument_token": "3584769",
      "name": "ZEE MEDIA CORPORATION",
      "tradingSymbol": "ZEEMEDIA",
      "label": "ZEEMEDIA",
      "value": "ZEEMEDIA"
    },
    {
      "instrument_token": "4197121",
      "name": "MMP INDUSTRIES",
      "tradingSymbol": "MMP",
      "label": "MMP",
      "value": "MMP"
    },
    {
      "instrument_token": "3742209",
      "name": "CHEMBOND CHEMICALS",
      "tradingSymbol": "CHEMBOND",
      "label": "CHEMBOND",
      "value": "CHEMBOND"
    },
    {
      "instrument_token": "1034497",
      "name": "JAYANT AGRO ORGANICS",
      "tradingSymbol": "JAYAGROGN",
      "label": "JAYAGROGN",
      "value": "JAYAGROGN"
    },
    {
      "instrument_token": "8065793",
      "name": "JIND POL INV & FIN CO",
      "tradingSymbol": "JPOLYINVST",
      "label": "JPOLYINVST",
      "value": "JPOLYINVST"
    },
    {
      "instrument_token": "3890945",
      "name": "MANAKSIA",
      "tradingSymbol": "MANAKSIA",
      "label": "MANAKSIA",
      "value": "MANAKSIA"
    },
    {
      "instrument_token": "8150273",
      "name": "SREELEATHERS",
      "tradingSymbol": "SREEL",
      "label": "SREEL",
      "value": "SREEL"
    },
    {
      "instrument_token": "3911169",
      "name": "ONMOBILE GLOBAL",
      "tradingSymbol": "ONMOBILE",
      "label": "ONMOBILE",
      "value": "ONMOBILE"
    },
    {
      "instrument_token": "1738753",
      "name": "SBC EXPORTS",
      "tradingSymbol": "SBC",
      "label": "SBC",
      "value": "SBC"
    },
    {
      "instrument_token": "3430401",
      "name": "BHAGERIA INDUSTRIES",
      "tradingSymbol": "BHAGERIA",
      "label": "BHAGERIA",
      "value": "BHAGERIA"
    },
    {
      "instrument_token": "4919809",
      "name": "VALIANT LABORATORIES",
      "tradingSymbol": "VALIANTLAB",
      "label": "VALIANTLAB",
      "value": "VALIANTLAB"
    },
    {
      "instrument_token": "1782017",
      "name": "MENON BEARINGS",
      "tradingSymbol": "MENONBE",
      "label": "MENONBE",
      "value": "MENONBE"
    },
    {
      "instrument_token": "5103873",
      "name": "KILITCH DRUGS INDIA",
      "tradingSymbol": "KILITCH",
      "label": "KILITCH",
      "value": "KILITCH"
    },
    {
      "instrument_token": "4145153",
      "name": "PAVNA INDUSTRIES",
      "tradingSymbol": "PAVNAIND",
      "label": "PAVNAIND",
      "value": "PAVNAIND"
    },
    {
      "instrument_token": "229377",
      "name": "KHAITAN CHEM & FERT",
      "tradingSymbol": "KHAICHEM",
      "label": "KHAICHEM",
      "value": "KHAICHEM"
    },
    {
      "instrument_token": "3071745",
      "name": "FCS SOFTWARE SOLN.",
      "tradingSymbol": "FCSSOFT",
      "label": "FCSSOFT",
      "value": "FCSSOFT"
    },
    {
      "instrument_token": "2039041",
      "name": "MALLCOM (INDIA)",
      "tradingSymbol": "MALLCOM",
      "label": "MALLCOM",
      "value": "MALLCOM"
    },
    {
      "instrument_token": "3500801",
      "name": "NUPUR RECYCLERS",
      "tradingSymbol": "NRL",
      "label": "NRL",
      "value": "NRL"
    },
    {
      "instrument_token": "5535489",
      "name": "APEX FROZEN FOODS",
      "tradingSymbol": "APEX",
      "label": "APEX",
      "value": "APEX"
    },
    {
      "instrument_token": "467457",
      "name": "KAMAT HOTELS (I)",
      "tradingSymbol": "KAMATHOTEL",
      "label": "KAMATHOTEL",
      "value": "KAMATHOTEL"
    },
    {
      "instrument_token": "3066625",
      "name": "HT MEDIA",
      "tradingSymbol": "HTMEDIA",
      "label": "HTMEDIA",
      "value": "HTMEDIA"
    },
    {
      "instrument_token": "752385",
      "name": "THE RUBY MILLS",
      "tradingSymbol": "RUBYMILLS",
      "label": "RUBYMILLS",
      "value": "RUBYMILLS"
    },
    {
      "instrument_token": "4417537",
      "name": "ALBERT DAVID",
      "tradingSymbol": "ALBERTDAVD",
      "label": "ALBERTDAVD",
      "value": "ALBERTDAVD"
    },
    {
      "instrument_token": "3037185",
      "name": "PRIME SECURITIES",
      "tradingSymbol": "PRIMESECU",
      "label": "PRIMESECU",
      "value": "PRIMESECU"
    },
    {
      "instrument_token": "3563521",
      "name": "PLASTIBLENDS INDIA",
      "tradingSymbol": "PLASTIBLEN",
      "label": "PLASTIBLEN",
      "value": "PLASTIBLEN"
    },
    {
      "instrument_token": "582401",
      "name": "IRIS CLOTHINGS",
      "tradingSymbol": "IRISDOREME",
      "label": "IRISDOREME",
      "value": "IRISDOREME"
    },
    {
      "instrument_token": "3936513",
      "name": "PUDUMJEE PAPER PRO.",
      "tradingSymbol": "PDMJEPAPER",
      "label": "PDMJEPAPER",
      "value": "PDMJEPAPER"
    },
    {
      "instrument_token": "1124865",
      "name": "MIRZA INTERNATIONAL LIMIT",
      "tradingSymbol": "MIRZAINT",
      "label": "MIRZAINT",
      "value": "MIRZAINT"
    },
    {
      "instrument_token": "3392257",
      "name": "SAKUMA EXPORTS",
      "tradingSymbol": "SAKUMA",
      "label": "SAKUMA",
      "value": "SAKUMA"
    },
    {
      "instrument_token": "2370561",
      "name": "ARROW GREENTECH",
      "tradingSymbol": "ARROWGREEN",
      "label": "ARROWGREEN",
      "value": "ARROWGREEN"
    },
    {
      "instrument_token": "4306177",
      "name": "VINYL CHEMICALS (I)",
      "tradingSymbol": "VINYLINDIA",
      "label": "VINYLINDIA",
      "value": "VINYLINDIA"
    },
    {
      "instrument_token": "643585",
      "name": "OSWAL AGRO MILLS",
      "tradingSymbol": "OSWALAGRO",
      "label": "OSWALAGRO",
      "value": "OSWALAGRO"
    },
    {
      "instrument_token": "351233",
      "name": "HINDUSTAN COMPOSITES",
      "tradingSymbol": "HINDCOMPOS",
      "label": "HINDCOMPOS",
      "value": "HINDCOMPOS"
    },
    {
      "instrument_token": "976129",
      "name": "ARIHANT CAPITAL MKTS",
      "tradingSymbol": "ARIHANTCAP",
      "label": "ARIHANTCAP",
      "value": "ARIHANTCAP"
    },
    {
      "instrument_token": "4670465",
      "name": "DEEP ENE RESOURCES",
      "tradingSymbol": "DEEPENR",
      "label": "DEEPENR",
      "value": "DEEPENR"
    },
    {
      "instrument_token": "2600705",
      "name": "ORICON ENTERPRISES",
      "tradingSymbol": "ORICONENT",
      "label": "ORICONENT",
      "value": "ORICONENT"
    },
    {
      "instrument_token": "801025",
      "name": "SHREYAS SHIPPING & LOGIST",
      "tradingSymbol": "SHREYAS",
      "label": "SHREYAS",
      "value": "SHREYAS"
    },
    {
      "instrument_token": "1211393",
      "name": "SKM EGG PROD EXPORT(I) LT",
      "tradingSymbol": "SKMEGGPROD",
      "label": "SKMEGGPROD",
      "value": "SKMEGGPROD"
    },
    {
      "instrument_token": "3356417",
      "name": "ORIENT CERATECH",
      "tradingSymbol": "ORIENTCER",
      "label": "ORIENTCER",
      "value": "ORIENTCER"
    },
    {
      "instrument_token": "3006209",
      "name": "JINDAL PHOTO",
      "tradingSymbol": "JINDALPHOT",
      "label": "JINDALPHOT",
      "value": "JINDALPHOT"
    },
    {
      "instrument_token": "5088513",
      "name": "MUSIC BROADCAST",
      "tradingSymbol": "RADIOCITY",
      "label": "RADIOCITY",
      "value": "RADIOCITY"
    },
    {
      "instrument_token": "4286721",
      "name": "RANE BRAKE LINING",
      "tradingSymbol": "RBL",
      "label": "RBL",
      "value": "RBL"
    },
    {
      "instrument_token": "108801",
      "name": "KHADIM INDIA",
      "tradingSymbol": "KHADIM",
      "label": "KHADIM",
      "value": "KHADIM"
    },
    {
      "instrument_token": "2534913",
      "name": "AXITA COTTON",
      "tradingSymbol": "AXITA",
      "label": "AXITA",
      "value": "AXITA"
    },
    {
      "instrument_token": "3600385",
      "name": "BARTRONICS INDIA",
      "tradingSymbol": "ASMS",
      "label": "ASMS",
      "value": "ASMS"
    },
    {
      "instrument_token": "3111169",
      "name": "B&B TRIPLEWALL CONT",
      "tradingSymbol": "BBTCL",
      "label": "BBTCL",
      "value": "BBTCL"
    },
    {
      "instrument_token": "4427521",
      "name": "NAGARJUN FERT AND CHE",
      "tradingSymbol": "NAGAFERT",
      "label": "NAGAFERT",
      "value": "NAGAFERT"
    },
    {
      "instrument_token": "3496449",
      "name": "GEECEE VENTURES",
      "tradingSymbol": "GEECEE",
      "label": "GEECEE",
      "value": "GEECEE"
    },
    {
      "instrument_token": "3940097",
      "name": "RACE ECO CHAIN",
      "tradingSymbol": "RACE",
      "label": "RACE",
      "value": "RACE"
    },
    {
      "instrument_token": "2289665",
      "name": "RITCO LOGISTICS",
      "tradingSymbol": "RITCO",
      "label": "RITCO",
      "value": "RITCO"
    },
    {
      "instrument_token": "2895105",
      "name": "BALAXI PHARMA",
      "tradingSymbol": "BALAXI",
      "label": "BALAXI",
      "value": "BALAXI"
    },
    {
      "instrument_token": "4672001",
      "name": "PYRAMID TECHNOPLAST",
      "tradingSymbol": "PYRAMID",
      "label": "PYRAMID",
      "value": "PYRAMID"
    },
    {
      "instrument_token": "1015041",
      "name": "REMSONS INDUSTRIES",
      "tradingSymbol": "REMSONSIND",
      "label": "REMSONSIND",
      "value": "REMSONSIND"
    },
    {
      "instrument_token": "2313985",
      "name": "UFO MOVIEZ INDIA",
      "tradingSymbol": "UFO",
      "label": "UFO",
      "value": "UFO"
    },
    {
      "instrument_token": "3609857",
      "name": "PTL ENTERPRISES",
      "tradingSymbol": "PTL",
      "label": "PTL",
      "value": "PTL"
    },
    {
      "instrument_token": "2205185",
      "name": "INDO BORAX & CHEMICAL",
      "tradingSymbol": "INDOBORAX",
      "label": "INDOBORAX",
      "value": "INDOBORAX"
    },
    {
      "instrument_token": "4512001",
      "name": "MOLD-TEK TECHNOLOGIES",
      "tradingSymbol": "MOLDTECH",
      "label": "MOLDTECH",
      "value": "MOLDTECH"
    },
    {
      "instrument_token": "4829697",
      "name": "MAZDA",
      "tradingSymbol": "MAZDA",
      "label": "MAZDA",
      "value": "MAZDA"
    },
    {
      "instrument_token": "4331777",
      "name": "20 MICRONS",
      "tradingSymbol": "20MICRONS",
      "label": "20MICRONS",
      "value": "20MICRONS"
    },
    {
      "instrument_token": "6593537",
      "name": "VIKAS ECOTECH",
      "tradingSymbol": "VIKASECO",
      "label": "VIKASECO",
      "value": "VIKASECO"
    },
    {
      "instrument_token": "3655169",
      "name": "ORIENT BELL",
      "tradingSymbol": "ORIENTBELL",
      "label": "ORIENTBELL",
      "value": "ORIENTBELL"
    },
    {
      "instrument_token": "1435137",
      "name": "INTL CONVEYORS",
      "tradingSymbol": "INTLCONV",
      "label": "INTLCONV",
      "value": "INTLCONV"
    },
    {
      "instrument_token": "2710529",
      "name": "SHRE PUSH CHEM & FERT",
      "tradingSymbol": "SHREEPUSHK",
      "label": "SHREEPUSHK",
      "value": "SHREEPUSHK"
    },
    {
      "instrument_token": "1354753",
      "name": "PRITIKA AUTO INDUS",
      "tradingSymbol": "PRITIKAUTO",
      "label": "PRITIKAUTO",
      "value": "PRITIKAUTO"
    },
    {
      "instrument_token": "4772865",
      "name": "DIAMINES & CHEMICALS",
      "tradingSymbol": "DIAMINESQ",
      "label": "DIAMINESQ",
      "value": "DIAMINESQ"
    },
    {
      "instrument_token": "1050881",
      "name": "SIL INVESTMENTS",
      "tradingSymbol": "SILINV",
      "label": "SILINV",
      "value": "SILINV"
    },
    {
      "instrument_token": "3714817",
      "name": "BANSWARA SYNTEX",
      "tradingSymbol": "BANSWRAS",
      "label": "BANSWRAS",
      "value": "BANSWRAS"
    },
    {
      "instrument_token": "5235969",
      "name": "ANJANI PORTLAND CEM",
      "tradingSymbol": "APCL",
      "label": "APCL",
      "value": "APCL"
    },
    {
      "instrument_token": "3355137",
      "name": "NAHAR INDS ENT",
      "tradingSymbol": "NAHARINDUS",
      "label": "NAHARINDUS",
      "value": "NAHARINDUS"
    },
    {
      "instrument_token": "4588033",
      "name": "GEEKAY WIRES",
      "tradingSymbol": "GEEKAYWIRE",
      "label": "GEEKAYWIRE",
      "value": "GEEKAYWIRE"
    },
    {
      "instrument_token": "1309185",
      "name": "SYNERGY GREEN IND.",
      "tradingSymbol": "SGIL",
      "label": "SGIL",
      "value": "SGIL"
    },
    {
      "instrument_token": "1607169",
      "name": "MEDICAMEN BIOTECH",
      "tradingSymbol": "MEDICAMEQ",
      "label": "MEDICAMEQ",
      "value": "MEDICAMEQ"
    },
    {
      "instrument_token": "3600129",
      "name": "WANBURY",
      "tradingSymbol": "WANBURY",
      "label": "WANBURY",
      "value": "WANBURY"
    },
    {
      "instrument_token": "1689601",
      "name": "GENUS P&B",
      "tradingSymbol": "GENUSPAPER",
      "label": "GENUSPAPER",
      "value": "GENUSPAPER"
    },
    {
      "instrument_token": "469761",
      "name": "KANORIA CHEMICALS & INDUS",
      "tradingSymbol": "KANORICHEM",
      "label": "KANORICHEM",
      "value": "KANORICHEM"
    },
    {
      "instrument_token": "2883841",
      "name": "TVS ELECTRONICS",
      "tradingSymbol": "TVSELECT",
      "label": "TVSELECT",
      "value": "TVSELECT"
    },
    {
      "instrument_token": "8002049",
      "name": "NANDAN DENIM",
      "tradingSymbol": "NDL",
      "label": "NDL",
      "value": "NDL"
    },
    {
      "instrument_token": "3932161",
      "name": "NAHAR CAP & FIN.",
      "tradingSymbol": "NAHARCAP",
      "label": "NAHARCAP",
      "value": "NAHARCAP"
    },
    {
      "instrument_token": "5177089",
      "name": "CL EDUCATE",
      "tradingSymbol": "CLEDUCATE",
      "label": "CLEDUCATE",
      "value": "CLEDUCATE"
    },
    {
      "instrument_token": "3170305",
      "name": "ZIM LABORATORIES",
      "tradingSymbol": "ZIMLAB",
      "label": "ZIMLAB",
      "value": "ZIMLAB"
    },
    {
      "instrument_token": "51457",
      "name": "UNITED DRILLING TOOLS",
      "tradingSymbol": "UNIDT",
      "label": "UNIDT",
      "value": "UNIDT"
    },
    {
      "instrument_token": "2377729",
      "name": "KRITIKA WIRES",
      "tradingSymbol": "KRITIKA",
      "label": "KRITIKA",
      "value": "KRITIKA"
    },
    {
      "instrument_token": "3611137",
      "name": "AUTOLINE INDUSTRIES",
      "tradingSymbol": "AUTOIND",
      "label": "AUTOIND",
      "value": "AUTOIND"
    },
    {
      "instrument_token": "2666241",
      "name": "MUTHOOT CAP SERV",
      "tradingSymbol": "MUTHOOTCAP",
      "label": "MUTHOOTCAP",
      "value": "MUTHOOTCAP"
    },
    {
      "instrument_token": "563713",
      "name": "MIRC ELECTRONICS",
      "tradingSymbol": "MIRCELECTR",
      "label": "MIRCELECTR",
      "value": "MIRCELECTR"
    },
    {
      "instrument_token": "2527745",
      "name": "AION-TECH SOLUTIONS",
      "tradingSymbol": "GOLDTECH",
      "label": "GOLDTECH",
      "value": "GOLDTECH"
    },
    {
      "instrument_token": "3547905",
      "name": "VENUS REMEDIES",
      "tradingSymbol": "VENUSREM",
      "label": "VENUSREM",
      "value": "VENUSREM"
    },
    {
      "instrument_token": "6392065",
      "name": "WINDSOR MACHINES",
      "tradingSymbol": "WINDMACHIN",
      "label": "WINDMACHIN",
      "value": "WINDMACHIN"
    },
    {
      "instrument_token": "1613313",
      "name": "APOLLO SINDOORI HOTEL",
      "tradingSymbol": "APOLSINHOT",
      "label": "APOLSINHOT",
      "value": "APOLSINHOT"
    },
    {
      "instrument_token": "1549057",
      "name": "CYBERTECH SYSTEMS & SOFTW",
      "tradingSymbol": "CYBERTECH",
      "label": "CYBERTECH",
      "value": "CYBERTECH"
    },
    {
      "instrument_token": "2981633",
      "name": "KOTHARI SUG & CHEM",
      "tradingSymbol": "KOTARISUG",
      "label": "KOTARISUG",
      "value": "KOTARISUG"
    },
    {
      "instrument_token": "7387905",
      "name": "PROZONE REALTY",
      "tradingSymbol": "PROZONER",
      "label": "PROZONER",
      "value": "PROZONER"
    },
    {
      "instrument_token": "4923649",
      "name": "AARTI SURFACTANTS",
      "tradingSymbol": "AARTISURF",
      "label": "AARTISURF",
      "value": "AARTISURF"
    },
    {
      "instrument_token": "3820545",
      "name": "LORENZINI APPARELS",
      "tradingSymbol": "LAL",
      "label": "LAL",
      "value": "LAL"
    },
    {
      "instrument_token": "6953217",
      "name": "CINELINE INDIA",
      "tradingSymbol": "CINELINE",
      "label": "CINELINE",
      "value": "CINELINE"
    },
    {
      "instrument_token": "1736193",
      "name": "INDO-NATIONAL",
      "tradingSymbol": "NIPPOBATRY",
      "label": "NIPPOBATRY",
      "value": "NIPPOBATRY"
    },
    {
      "instrument_token": "1000449",
      "name": "PREMIER POLYFILM",
      "tradingSymbol": "PREMIERPOL",
      "label": "PREMIERPOL",
      "value": "PREMIERPOL"
    },
    {
      "instrument_token": "2872321",
      "name": "VARDHAMAN ACRYLICS",
      "tradingSymbol": "VARDHACRLC",
      "label": "VARDHACRLC",
      "value": "VARDHACRLC"
    },
    {
      "instrument_token": "2368769",
      "name": "NILA INFRASTRUCTURES",
      "tradingSymbol": "NILAINFRA",
      "label": "NILAINFRA",
      "value": "NILAINFRA"
    },
    {
      "instrument_token": "3404801",
      "name": "NITCO",
      "tradingSymbol": "NITCO",
      "label": "NITCO",
      "value": "NITCO"
    },
    {
      "instrument_token": "2539777",
      "name": "ALANKIT",
      "tradingSymbol": "ALANKIT",
      "label": "ALANKIT",
      "value": "ALANKIT"
    },
    {
      "instrument_token": "4934913",
      "name": "EMAMI REALTY",
      "tradingSymbol": "EMAMIREAL",
      "label": "EMAMIREAL",
      "value": "EMAMIREAL"
    },
    {
      "instrument_token": "1297409",
      "name": "SHEMAROO ENTER.",
      "tradingSymbol": "SHEMAROO",
      "label": "SHEMAROO",
      "value": "SHEMAROO"
    },
    {
      "instrument_token": "6422529",
      "name": "ASAHI SONGWON COLOR",
      "tradingSymbol": "ASAHISONG",
      "label": "ASAHISONG",
      "value": "ASAHISONG"
    },
    {
      "instrument_token": "8057601",
      "name": "ORBIT EXPORTS",
      "tradingSymbol": "ORBTEXP",
      "label": "ORBTEXP",
      "value": "ORBTEXP"
    },
    {
      "instrument_token": "1370113",
      "name": "EXXARO TILES",
      "tradingSymbol": "EXXARO",
      "label": "EXXARO",
      "value": "EXXARO"
    },
    {
      "instrument_token": "3814401",
      "name": "SAHYADRI INDUSTRIES",
      "tradingSymbol": "SAHYADRI",
      "label": "SAHYADRI",
      "value": "SAHYADRI"
    },
    {
      "instrument_token": "1231105",
      "name": "KCP SUGAR IND CORP",
      "tradingSymbol": "KCPSUGIND",
      "label": "KCPSUGIND",
      "value": "KCPSUGIND"
    },
    {
      "instrument_token": "4520705",
      "name": "UNIVERSUS IMAGINGS",
      "tradingSymbol": "UNIVPHOTO",
      "label": "UNIVPHOTO",
      "value": "UNIVPHOTO"
    },
    {
      "instrument_token": "3090433",
      "name": "SHEETAL COOL PRODUCTS",
      "tradingSymbol": "SCPL",
      "label": "SCPL",
      "value": "SCPL"
    },
    {
      "instrument_token": "4264449",
      "name": "SIGMA SOLVE",
      "tradingSymbol": "SIGMA",
      "label": "SIGMA",
      "value": "SIGMA"
    },
    {
      "instrument_token": "97537",
      "name": "SINTERCOM INDIA",
      "tradingSymbol": "SINTERCOM",
      "label": "SINTERCOM",
      "value": "SINTERCOM"
    },
    {
      "instrument_token": "4873217",
      "name": "PODDAR PIGMENTS",
      "tradingSymbol": "PODDARMENT",
      "label": "PODDARMENT",
      "value": "PODDARMENT"
    },
    {
      "instrument_token": "4981249",
      "name": "PLAZA WIRES",
      "tradingSymbol": "PLAZACABLE",
      "label": "PLAZACABLE",
      "value": "PLAZACABLE"
    },
    {
      "instrument_token": "760065",
      "name": "SAKTHI SUGARS",
      "tradingSymbol": "SAKHTISUG",
      "label": "SAKHTISUG",
      "value": "SAKHTISUG"
    },
    {
      "instrument_token": "519169",
      "name": "LYKA LABS",
      "tradingSymbol": "LYKALABS",
      "label": "LYKALABS",
      "value": "LYKALABS"
    },
    {
      "instrument_token": "842497",
      "name": "STAR PAPER MILLS",
      "tradingSymbol": "STARPAPER",
      "label": "STARPAPER",
      "value": "STARPAPER"
    },
    {
      "instrument_token": "4638465",
      "name": "SRG HOUSING FINANCE L",
      "tradingSymbol": "SRGHFL",
      "label": "SRGHFL",
      "value": "SRGHFL"
    },
    {
      "instrument_token": "2729217",
      "name": "PONNIE SUGARS (ERODE)",
      "tradingSymbol": "PONNIERODE",
      "label": "PONNIERODE",
      "value": "PONNIERODE"
    },
    {
      "instrument_token": "3881217",
      "name": "HITECH CORPORATION",
      "tradingSymbol": "HITECHCORP",
      "label": "HITECHCORP",
      "value": "HITECHCORP"
    },
    {
      "instrument_token": "3538177",
      "name": "RUCHIRA PAPERS",
      "tradingSymbol": "RUCHIRA",
      "label": "RUCHIRA",
      "value": "RUCHIRA"
    },
    {
      "instrument_token": "4357633",
      "name": "MAWANA SUGARS",
      "tradingSymbol": "MAWANASUG",
      "label": "MAWANASUG",
      "value": "MAWANASUG"
    },
    {
      "instrument_token": "1546241",
      "name": "EURO (I) FRESH FOODS",
      "tradingSymbol": "EIFFL",
      "label": "EIFFL",
      "value": "EIFFL"
    },
    {
      "instrument_token": "913921",
      "name": "UCAL",
      "tradingSymbol": "UCAL",
      "label": "UCAL",
      "value": "UCAL"
    },
    {
      "instrument_token": "1547521",
      "name": "PASUPATI ACRYLON",
      "tradingSymbol": "PASUPTAC",
      "label": "PASUPTAC",
      "value": "PASUPTAC"
    },
    {
      "instrument_token": "1537537",
      "name": "COASTAL CORPORATION",
      "tradingSymbol": "COASTCORP",
      "label": "COASTCORP",
      "value": "COASTCORP"
    },
    {
      "instrument_token": "5620993",
      "name": "IND TERRAIN FASHIONS",
      "tradingSymbol": "INDTERRAIN",
      "label": "INDTERRAIN",
      "value": "INDTERRAIN"
    },
    {
      "instrument_token": "3892225",
      "name": "ARIES AGRO",
      "tradingSymbol": "ARIES",
      "label": "ARIES",
      "value": "ARIES"
    },
    {
      "instrument_token": "2018817",
      "name": "MAHALAXMI RUBTECH",
      "tradingSymbol": "MHLXMIRU",
      "label": "MHLXMIRU",
      "value": "MHLXMIRU"
    },
    {
      "instrument_token": "3724033",
      "name": "NDL VENTURES",
      "tradingSymbol": "NDLVENTURE",
      "label": "NDLVENTURE",
      "value": "NDLVENTURE"
    },
    {
      "instrument_token": "2964737",
      "name": "GP PETROLEUMS",
      "tradingSymbol": "GULFPETRO",
      "label": "GULFPETRO",
      "value": "GULFPETRO"
    },
    {
      "instrument_token": "3818753",
      "name": "TAKE SOLUTIONS",
      "tradingSymbol": "TAKE",
      "label": "TAKE",
      "value": "TAKE"
    },
    {
      "instrument_token": "1751809",
      "name": "SARTHAK METALS",
      "tradingSymbol": "SMLT",
      "label": "SMLT",
      "value": "SMLT"
    },
    {
      "instrument_token": "336129",
      "name": "HARRISON MALAYALAM",
      "tradingSymbol": "HARRMALAYA",
      "label": "HARRMALAYA",
      "value": "HARRMALAYA"
    },
    {
      "instrument_token": "5894913",
      "name": "LANCOR HOLDINGS",
      "tradingSymbol": "LANCORHOL",
      "label": "LANCORHOL",
      "value": "LANCORHOL"
    },
    {
      "instrument_token": "2116097",
      "name": "SOFTTECH ENGINEERS",
      "tradingSymbol": "SOFTTECH",
      "label": "SOFTTECH",
      "value": "SOFTTECH"
    },
    {
      "instrument_token": "378881",
      "name": "RUDRABHISHEK ENTERP",
      "tradingSymbol": "REPL",
      "label": "REPL",
      "value": "REPL"
    },
    {
      "instrument_token": "726273",
      "name": "RANA SUGARS",
      "tradingSymbol": "RANASUG",
      "label": "RANASUG",
      "value": "RANASUG"
    },
    {
      "instrument_token": "800257",
      "name": "SHREYANS INDUSTRIES",
      "tradingSymbol": "SHREYANIND",
      "label": "SHREYANIND",
      "value": "SHREYANIND"
    },
    {
      "instrument_token": "2911745",
      "name": "TIPS FILMS",
      "tradingSymbol": "TIPSFILMS",
      "label": "TIPSFILMS",
      "value": "TIPSFILMS"
    },
    {
      "instrument_token": "2477569",
      "name": "SOUTH WEST PINNACLE",
      "tradingSymbol": "SOUTHWEST",
      "label": "SOUTHWEST",
      "value": "SOUTHWEST"
    },
    {
      "instrument_token": "3915521",
      "name": "VIRINCHI",
      "tradingSymbol": "VIRINCHI",
      "label": "VIRINCHI",
      "value": "VIRINCHI"
    },
    {
      "instrument_token": "6555649",
      "name": "INDO THAI SEC",
      "tradingSymbol": "INDOTHAI",
      "label": "INDOTHAI",
      "value": "INDOTHAI"
    },
    {
      "instrument_token": "2705409",
      "name": "RAMA PHOSPHATES",
      "tradingSymbol": "RAMAPHO",
      "label": "RAMAPHO",
      "value": "RAMAPHO"
    },
    {
      "instrument_token": "1769729",
      "name": "RAJSHREE POLYPACK",
      "tradingSymbol": "RPPL",
      "label": "RPPL",
      "value": "RPPL"
    },
    {
      "instrument_token": "838401",
      "name": "TEMBO GLOBAL IND",
      "tradingSymbol": "TEMBO",
      "label": "TEMBO",
      "value": "TEMBO"
    },
    {
      "instrument_token": "7893761",
      "name": "PIL ITALICA LIFESTYLE",
      "tradingSymbol": "PILITA",
      "label": "PILITA",
      "value": "PILITA"
    },
    {
      "instrument_token": "263681",
      "name": "AARON INDUSTRIES",
      "tradingSymbol": "AARON",
      "label": "AARON",
      "value": "AARON"
    },
    {
      "instrument_token": "440321",
      "name": "JAYSHREE TEA & INDUSTRIES",
      "tradingSymbol": "JAYSREETEA",
      "label": "JAYSREETEA",
      "value": "JAYSREETEA"
    },
    {
      "instrument_token": "1168897",
      "name": "RUCHI INFRASTRUCTURE",
      "tradingSymbol": "RUCHINFRA",
      "label": "RUCHINFRA",
      "value": "RUCHINFRA"
    },
    {
      "instrument_token": "592129",
      "name": "MURUDESHWAR CERAMICS",
      "tradingSymbol": "MURUDCERA",
      "label": "MURUDCERA",
      "value": "MURUDCERA"
    },
    {
      "instrument_token": "1069825",
      "name": "SUNDARAM BRAK LININGS",
      "tradingSymbol": "SUNDRMBRAK",
      "label": "SUNDRMBRAK",
      "value": "SUNDRMBRAK"
    },
    {
      "instrument_token": "3507713",
      "name": "VISHWARAJ SUGAR IND",
      "tradingSymbol": "VISHWARAJ",
      "label": "VISHWARAJ",
      "value": "VISHWARAJ"
    },
    {
      "instrument_token": "1441537",
      "name": "PAR DRUGS AND CHEM",
      "tradingSymbol": "PAR",
      "label": "PAR",
      "value": "PAR"
    },
    {
      "instrument_token": "2087169",
      "name": "MEGASTAR FOODS",
      "tradingSymbol": "MEGASTAR",
      "label": "MEGASTAR",
      "value": "MEGASTAR"
    },
    {
      "instrument_token": "5318657",
      "name": "BHAGYANAGAR INDIA",
      "tradingSymbol": "BHAGYANGR",
      "label": "BHAGYANGR",
      "value": "BHAGYANGR"
    },
    {
      "instrument_token": "5068801",
      "name": "WELSPUN INV & COMM",
      "tradingSymbol": "WELINV",
      "label": "WELINV",
      "value": "WELINV"
    },
    {
      "instrument_token": "540673",
      "name": "MARAL OVERSEAS",
      "tradingSymbol": "MARALOVER",
      "label": "MARALOVER",
      "value": "MARALOVER"
    },
    {
      "instrument_token": "3828993",
      "name": "MAGNUM VENTURES",
      "tradingSymbol": "MAGNUM",
      "label": "MAGNUM",
      "value": "MAGNUM"
    },
    {
      "instrument_token": "292353",
      "name": "GINNI FILAMENTS",
      "tradingSymbol": "GINNIFILA",
      "label": "GINNIFILA",
      "value": "GINNIFILA"
    },
    {
      "instrument_token": "3870977",
      "name": "SHREE VASU LOGISTICS",
      "tradingSymbol": "SVLL",
      "label": "SVLL",
      "value": "SVLL"
    },
    {
      "instrument_token": "3757313",
      "name": "GUJ. APOLLO IND.",
      "tradingSymbol": "GUJAPOLLO",
      "label": "GUJAPOLLO",
      "value": "GUJAPOLLO"
    },
    {
      "instrument_token": "601601",
      "name": "NURECA",
      "tradingSymbol": "NURECA",
      "label": "NURECA",
      "value": "NURECA"
    },
    {
      "instrument_token": "5523969",
      "name": "COMMERCIAL SYN BAGS",
      "tradingSymbol": "COMSYN",
      "label": "COMSYN",
      "value": "COMSYN"
    },
    {
      "instrument_token": "3057409",
      "name": "MCLEOD RUSSEL INDIA",
      "tradingSymbol": "MCLEODRUSS",
      "label": "MCLEODRUSS",
      "value": "MCLEODRUSS"
    },
    {
      "instrument_token": "2711041",
      "name": "LOYAL TEXTILE MILLS",
      "tradingSymbol": "LOYALTEX",
      "label": "LOYALTEX",
      "value": "LOYALTEX"
    },
    {
      "instrument_token": "3080705",
      "name": "BANNARI AM SPIN MILL",
      "tradingSymbol": "BASML",
      "label": "BASML",
      "value": "BASML"
    },
    {
      "instrument_token": "400641",
      "name": "RAVINDER HEIGHTS",
      "tradingSymbol": "RVHL",
      "label": "RVHL",
      "value": "RVHL"
    },
    {
      "instrument_token": "3893505",
      "name": "PPAP AUTOMOTIVE",
      "tradingSymbol": "PPAP",
      "label": "PPAP",
      "value": "PPAP"
    },
    {
      "instrument_token": "4976129",
      "name": "ASIAN HOTELS (EAST)",
      "tradingSymbol": "AHLEAST",
      "label": "AHLEAST",
      "value": "AHLEAST"
    },
    {
      "instrument_token": "2214913",
      "name": "VETO SWITCHGEAR CABLE",
      "tradingSymbol": "VETO",
      "label": "VETO",
      "value": "VETO"
    },
    {
      "instrument_token": "3504385",
      "name": "SAH POLYMERS",
      "tradingSymbol": "SAH",
      "label": "SAH",
      "value": "SAH"
    },
    {
      "instrument_token": "1553409",
      "name": "AIRO LAM",
      "tradingSymbol": "AIROLAM",
      "label": "AIROLAM",
      "value": "AIROLAM"
    },
    {
      "instrument_token": "4662785",
      "name": "TEXMO PIPE & PRODUCTS",
      "tradingSymbol": "TEXMOPIPES",
      "label": "TEXMOPIPES",
      "value": "TEXMOPIPES"
    },
    {
      "instrument_token": "3664385",
      "name": "T T",
      "tradingSymbol": "TTL",
      "label": "TTL",
      "value": "TTL"
    },
    {
      "instrument_token": "1819905",
      "name": "JULLUNDUR MOT AGENCY",
      "tradingSymbol": "JMA",
      "label": "JMA",
      "value": "JMA"
    },
    {
      "instrument_token": "2085377",
      "name": "DEV INFO TECHNOLOGY",
      "tradingSymbol": "DEVIT",
      "label": "DEVIT",
      "value": "DEVIT"
    },
    {
      "instrument_token": "4288257",
      "name": "RANE ENG VALVE",
      "tradingSymbol": "RANEENGINE",
      "label": "RANEENGINE",
      "value": "RANEENGINE"
    },
    {
      "instrument_token": "2125825",
      "name": "PRITI INTERNATIONAL",
      "tradingSymbol": "PRITI",
      "label": "PRITI",
      "value": "PRITI"
    },
    {
      "instrument_token": "5277697",
      "name": "SUPERHOUSE",
      "tradingSymbol": "SUPERHOUSE",
      "label": "SUPERHOUSE",
      "value": "SUPERHOUSE"
    },
    {
      "instrument_token": "3472641",
      "name": "SHREE RAMA NEWSPRINT",
      "tradingSymbol": "RAMANEWS",
      "label": "RAMANEWS",
      "value": "RAMANEWS"
    },
    {
      "instrument_token": "3744513",
      "name": "HILTON METAL FORGING",
      "tradingSymbol": "HILTON",
      "label": "HILTON",
      "value": "HILTON"
    },
    {
      "instrument_token": "1935361",
      "name": "UNITED POLYFAB GUJ.",
      "tradingSymbol": "UNITEDPOLY",
      "label": "UNITEDPOLY",
      "value": "UNITEDPOLY"
    },
    {
      "instrument_token": "933377",
      "name": "VARDHMAN POLYTEX",
      "tradingSymbol": "VARDMNPOLY",
      "label": "VARDMNPOLY",
      "value": "VARDMNPOLY"
    },
    {
      "instrument_token": "5165569",
      "name": "AKSHARCHEM INDIA",
      "tradingSymbol": "AKSHARCHEM",
      "label": "AKSHARCHEM",
      "value": "AKSHARCHEM"
    },
    {
      "instrument_token": "3405569",
      "name": "INDIABULLS ENT",
      "tradingSymbol": "IEL",
      "label": "IEL",
      "value": "IEL"
    },
    {
      "instrument_token": "4421121",
      "name": "ASPINWALL & CO",
      "tradingSymbol": "ASPINWALL",
      "label": "ASPINWALL",
      "value": "ASPINWALL"
    },
    {
      "instrument_token": "1594113",
      "name": "DHUNSERI TEA & IND.",
      "tradingSymbol": "DTIL",
      "label": "DTIL",
      "value": "DTIL"
    },
    {
      "instrument_token": "456193",
      "name": "KANPUR PLASTIPACK",
      "tradingSymbol": "KANPRPLA",
      "label": "KANPRPLA",
      "value": "KANPRPLA"
    },
    {
      "instrument_token": "719105",
      "name": "RAJSHREE SUGAR & CHEMICAL",
      "tradingSymbol": "RAJSREESUG",
      "label": "RAJSREESUG",
      "value": "RAJSREESUG"
    },
    {
      "instrument_token": "4334849",
      "name": "INDBANK MERCH BANK",
      "tradingSymbol": "INDBANK",
      "label": "INDBANK",
      "value": "INDBANK"
    },
    {
      "instrument_token": "7971329",
      "name": "COMPUCOM SOFTWARE",
      "tradingSymbol": "COMPUSOFT",
      "label": "COMPUSOFT",
      "value": "COMPUSOFT"
    },
    {
      "instrument_token": "2451457",
      "name": "MAHESHWARI LOGISTICS",
      "tradingSymbol": "MAHESHWARI",
      "label": "MAHESHWARI",
      "value": "MAHESHWARI"
    },
    {
      "instrument_token": "205825",
      "name": "SHIVA TEXYARN",
      "tradingSymbol": "SHIVATEX",
      "label": "SHIVATEX",
      "value": "SHIVATEX"
    },
    {
      "instrument_token": "1157633",
      "name": "SIGNET INDUSTRIES",
      "tradingSymbol": "SIGIND",
      "label": "SIGIND",
      "value": "SIGIND"
    },
    {
      "instrument_token": "6366721",
      "name": "INVENTURE GRO & SEC",
      "tradingSymbol": "INVENTURE",
      "label": "INVENTURE",
      "value": "INVENTURE"
    },
    {
      "instrument_token": "4882689",
      "name": "AARVI ENCON",
      "tradingSymbol": "AARVI",
      "label": "AARVI",
      "value": "AARVI"
    },
    {
      "instrument_token": "3801089",
      "name": "ALPA LABORATORIES",
      "tradingSymbol": "ALPA",
      "label": "ALPA",
      "value": "ALPA"
    },
    {
      "instrument_token": "5140481",
      "name": "EROS INTL MEDIA",
      "tradingSymbol": "EROSMEDIA",
      "label": "EROSMEDIA",
      "value": "EROSMEDIA"
    },
    {
      "instrument_token": "3568897",
      "name": "PRAJAY ENG. SYN.",
      "tradingSymbol": "PRAENG",
      "label": "PRAENG",
      "value": "PRAENG"
    },
    {
      "instrument_token": "2223873",
      "name": "MUKTA ARTS",
      "tradingSymbol": "MUKTAARTS",
      "label": "MUKTAARTS",
      "value": "MUKTAARTS"
    },
    {
      "instrument_token": "143105",
      "name": "BSL",
      "tradingSymbol": "BSL",
      "label": "BSL",
      "value": "BSL"
    },
    {
      "instrument_token": "4486913",
      "name": "MAHAMAYA STEEL INDS",
      "tradingSymbol": "MAHASTEEL",
      "label": "MAHASTEEL",
      "value": "MAHASTEEL"
    },
    {
      "instrument_token": "4441857",
      "name": "SALONA COTSPIN",
      "tradingSymbol": "SALONA",
      "label": "SALONA",
      "value": "SALONA"
    },
    {
      "instrument_token": "5738241",
      "name": "LOVABLE LINGERIE",
      "tradingSymbol": "LOVABLE",
      "label": "LOVABLE",
      "value": "LOVABLE"
    },
    {
      "instrument_token": "4730625",
      "name": "INTRASOFT TECH.",
      "tradingSymbol": "ISFT",
      "label": "ISFT",
      "value": "ISFT"
    },
    {
      "instrument_token": "5702913",
      "name": "WORTH PERIPHERALS",
      "tradingSymbol": "WORTH",
      "label": "WORTH",
      "value": "WORTH"
    },
    {
      "instrument_token": "2254337",
      "name": "SONAM",
      "tradingSymbol": "SONAMLTD",
      "label": "SONAMLTD",
      "value": "SONAMLTD"
    },
    {
      "instrument_token": "4644353",
      "name": "EMMBI INDUSTRIES",
      "tradingSymbol": "EMMBI",
      "label": "EMMBI",
      "value": "EMMBI"
    },
    {
      "instrument_token": "4607745",
      "name": "UNITED NILGIRI TEA",
      "tradingSymbol": "UNITEDTEA",
      "label": "UNITEDTEA",
      "value": "UNITEDTEA"
    },
    {
      "instrument_token": "463617",
      "name": "KAKATIYA CEM SUGAR &IND L",
      "tradingSymbol": "KAKATCEM",
      "label": "KAKATCEM",
      "value": "KAKATCEM"
    },
    {
      "instrument_token": "109057",
      "name": "BHARAT GEARS",
      "tradingSymbol": "BHARATGEAR",
      "label": "BHARATGEAR",
      "value": "BHARATGEAR"
    },
    {
      "instrument_token": "3055361",
      "name": "SPL INDUSTRIES",
      "tradingSymbol": "SPLIL",
      "label": "SPLIL",
      "value": "SPLIL"
    },
    {
      "instrument_token": "4978433",
      "name": "CAPITAL TRUST",
      "tradingSymbol": "CAPTRUST",
      "label": "CAPTRUST",
      "value": "CAPTRUST"
    },
    {
      "instrument_token": "4566785",
      "name": "GILLANDERS ARBUTHNOT",
      "tradingSymbol": "GILLANDERS",
      "label": "GILLANDERS",
      "value": "GILLANDERS"
    },
    {
      "instrument_token": "2946817",
      "name": "CENTURY EXTRUSIONS",
      "tradingSymbol": "CENTEXT",
      "label": "CENTEXT",
      "value": "CENTEXT"
    },
    {
      "instrument_token": "2168065",
      "name": "MITCON CON & ENG SER",
      "tradingSymbol": "MITCON",
      "label": "MITCON",
      "value": "MITCON"
    },
    {
      "instrument_token": "4333313",
      "name": "JOCIL",
      "tradingSymbol": "JOCIL",
      "label": "JOCIL",
      "value": "JOCIL"
    },
    {
      "instrument_token": "4246785",
      "name": "VAISHALI PHARMA",
      "tradingSymbol": "VAISHALI",
      "label": "VAISHALI",
      "value": "VAISHALI"
    },
    {
      "instrument_token": "1025793",
      "name": "CORAL INDIA FIN & HOUS LT",
      "tradingSymbol": "CORALFINAC",
      "label": "CORALFINAC",
      "value": "CORALFINAC"
    },
    {
      "instrument_token": "65281",
      "name": "NIRAJ CEMENT STRUC",
      "tradingSymbol": "NIRAJ",
      "label": "NIRAJ",
      "value": "NIRAJ"
    },
    {
      "instrument_token": "2470913",
      "name": "RELIANCE CHEMOTEX IND",
      "tradingSymbol": "RELCHEMQ",
      "label": "RELCHEMQ",
      "value": "RELCHEMQ"
    },
    {
      "instrument_token": "1132033",
      "name": "INDIAN CARD CLOTHING CO.",
      "tradingSymbol": "INDIANCARD",
      "label": "INDIANCARD",
      "value": "INDIANCARD"
    },
    {
      "instrument_token": "3025153",
      "name": "MANGALAM DRUG & CHEM",
      "tradingSymbol": "MANGALAM",
      "label": "MANGALAM",
      "value": "MANGALAM"
    },
    {
      "instrument_token": "3440897",
      "name": "BAL PHARMA",
      "tradingSymbol": "BALPHARMA",
      "label": "BALPHARMA",
      "value": "BALPHARMA"
    },
    {
      "instrument_token": "770305",
      "name": "KOHINOOR FOODS",
      "tradingSymbol": "KOHINOOR",
      "label": "KOHINOOR",
      "value": "KOHINOOR"
    },
    {
      "instrument_token": "1640193",
      "name": "LAMBODHARA TEXTILES",
      "tradingSymbol": "LAMBODHARA",
      "label": "LAMBODHARA",
      "value": "LAMBODHARA"
    },
    {
      "instrument_token": "4590849",
      "name": "BEARDSELL",
      "tradingSymbol": "BEARDSELL",
      "label": "BEARDSELL",
      "value": "BEARDSELL"
    },
    {
      "instrument_token": "3019777",
      "name": "HINDPRAKASH INDUSTRY",
      "tradingSymbol": "HPIL",
      "label": "HPIL",
      "value": "HPIL"
    },
    {
      "instrument_token": "7963393",
      "name": "DB (INT) STOCK BROKERS",
      "tradingSymbol": "DBSTOCKBRO",
      "label": "DBSTOCKBRO",
      "value": "DBSTOCKBRO"
    },
    {
      "instrument_token": "3811841",
      "name": "AKI INDIA",
      "tradingSymbol": "AKI",
      "label": "AKI",
      "value": "AKI"
    },
    {
      "instrument_token": "2834433",
      "name": "AGRI-TECH (INDIA)",
      "tradingSymbol": "AGRITECH",
      "label": "AGRITECH",
      "value": "AGRITECH"
    },
    {
      "instrument_token": "3896065",
      "name": "PATEL INT. LOG.",
      "tradingSymbol": "PATINTLOG",
      "label": "PATINTLOG",
      "value": "PATINTLOG"
    },
    {
      "instrument_token": "284161",
      "name": "DANGEE DUMS",
      "tradingSymbol": "DANGEE",
      "label": "DANGEE",
      "value": "DANGEE"
    },
    {
      "instrument_token": "3034113",
      "name": "SURYALAKSHMI COT MIL",
      "tradingSymbol": "SURYALAXMI",
      "label": "SURYALAXMI",
      "value": "SURYALAXMI"
    },
    {
      "instrument_token": "3858689",
      "name": "BARAK VALLEY CEM.",
      "tradingSymbol": "BVCL",
      "label": "BVCL",
      "value": "BVCL"
    },
    {
      "instrument_token": "5006337",
      "name": "BHANDARI HOSIERY EXP",
      "tradingSymbol": "BHANDARI",
      "label": "BHANDARI",
      "value": "BHANDARI"
    },
    {
      "instrument_token": "580609",
      "name": "MOTOR & GENERAL FINANCE L",
      "tradingSymbol": "MOTOGENFIN",
      "label": "MOTOGENFIN",
      "value": "MOTOGENFIN"
    },
    {
      "instrument_token": "5474049",
      "name": "PALASH SECURITIES",
      "tradingSymbol": "PALASHSECU",
      "label": "PALASHSECU",
      "value": "PALASHSECU"
    },
    {
      "instrument_token": "3035137",
      "name": "SHAH ALLOYS",
      "tradingSymbol": "SHAHALLOYS",
      "label": "SHAHALLOYS",
      "value": "SHAHALLOYS"
    },
    {
      "instrument_token": "767489",
      "name": "SUVIDHAA INFOSERVE",
      "tradingSymbol": "SUVIDHAA",
      "label": "SUVIDHAA",
      "value": "SUVIDHAA"
    },
    {
      "instrument_token": "4302593",
      "name": "LOTUS EYE HOSP & INST L",
      "tradingSymbol": "LOTUSEYE",
      "label": "LOTUSEYE",
      "value": "LOTUSEYE"
    },
    {
      "instrument_token": "5478145",
      "name": "GANGES SECURITIES",
      "tradingSymbol": "GANGESSECU",
      "label": "GANGESSECU",
      "value": "GANGESSECU"
    },
    {
      "instrument_token": "3685889",
      "name": "AMD INDUSTRIES",
      "tradingSymbol": "AMDIND",
      "label": "AMDIND",
      "value": "AMDIND"
    },
    {
      "instrument_token": "2663937",
      "name": "STEEL CITY SECURITIES",
      "tradingSymbol": "STEELCITY",
      "label": "STEELCITY",
      "value": "STEELCITY"
    },
    {
      "instrument_token": "5558529",
      "name": "LASA SUPERGENERICS",
      "tradingSymbol": "LASA",
      "label": "LASA",
      "value": "LASA"
    },
    {
      "instrument_token": "3763713",
      "name": "NAGREEKA EXPORTS",
      "tradingSymbol": "NAGREEKEXP",
      "label": "NAGREEKEXP",
      "value": "NAGREEKEXP"
    },
    {
      "instrument_token": "2259713",
      "name": "AVRO INDIA",
      "tradingSymbol": "AVROIND",
      "label": "AVROIND",
      "value": "AVROIND"
    },
    {
      "instrument_token": "4341505",
      "name": "ALKALI METALS",
      "tradingSymbol": "ALKALI",
      "label": "ALKALI",
      "value": "ALKALI"
    },
    {
      "instrument_token": "4549121",
      "name": "THE GROB TEA COMPANY",
      "tradingSymbol": "GROBTEA",
      "label": "GROBTEA",
      "value": "GROBTEA"
    },
    {
      "instrument_token": "3446529",
      "name": "PIONEER EMBROIDERIES",
      "tradingSymbol": "PIONEEREMB",
      "label": "PIONEEREMB",
      "value": "PIONEEREMB"
    },
    {
      "instrument_token": "310785",
      "name": "DELTA MANUFACTURING",
      "tradingSymbol": "DELTAMAGNT",
      "label": "DELTAMAGNT",
      "value": "DELTAMAGNT"
    },
    {
      "instrument_token": "1456129",
      "name": "ARCHIES",
      "tradingSymbol": "ARCHIES",
      "label": "ARCHIES",
      "value": "ARCHIES"
    },
    {
      "instrument_token": "2252033",
      "name": "ORISSA BENGAL CARRIER",
      "tradingSymbol": "OBCL",
      "label": "OBCL",
      "value": "OBCL"
    },
    {
      "instrument_token": "1058817",
      "name": "BIOFIL CHEM & PHARMA",
      "tradingSymbol": "BIOFILCHEM",
      "label": "BIOFILCHEM",
      "value": "BIOFILCHEM"
    },
    {
      "instrument_token": "1056513",
      "name": "SAMBHAAV MEDIA",
      "tradingSymbol": "SAMBHAAV",
      "label": "SAMBHAAV",
      "value": "SAMBHAAV"
    },
    {
      "instrument_token": "4490497",
      "name": "DAMODAR INDUSTRIES",
      "tradingSymbol": "DAMODARIND",
      "label": "DAMODARIND",
      "value": "DAMODARIND"
    },
    {
      "instrument_token": "4946433",
      "name": "HISAR METAL IND.",
      "tradingSymbol": "HISARMETAL",
      "label": "HISARMETAL",
      "value": "HISARMETAL"
    },
    {
      "instrument_token": "3369473",
      "name": "CELEBRITY FASHIONS",
      "tradingSymbol": "CELEBRITY",
      "label": "CELEBRITY",
      "value": "CELEBRITY"
    },
    {
      "instrument_token": "982273",
      "name": "TOKYO PLAST INTL",
      "tradingSymbol": "TOKYOPLAST",
      "label": "TOKYOPLAST",
      "value": "TOKYOPLAST"
    },
    {
      "instrument_token": "6420993",
      "name": "TREE HOUSE EDU",
      "tradingSymbol": "TREEHOUSE",
      "label": "TREEHOUSE",
      "value": "TREEHOUSE"
    },
    {
      "instrument_token": "5724161",
      "name": "MADHAV COPPER",
      "tradingSymbol": "MCL",
      "label": "MCL",
      "value": "MCL"
    },
    {
      "instrument_token": "2743553",
      "name": "TIMES GUARANTY",
      "tradingSymbol": "TIMESGTY",
      "label": "TIMESGTY",
      "value": "TIMESGTY"
    },
    {
      "instrument_token": "7611649",
      "name": "NRB INDUS. BEARINGS",
      "tradingSymbol": "NIBL",
      "label": "NIBL",
      "value": "NIBL"
    },
    {
      "instrument_token": "2391553",
      "name": "PRITISH NANDY COMMUNICATI",
      "tradingSymbol": "PNC",
      "label": "PNC",
      "value": "PNC"
    },
    {
      "instrument_token": "1285121",
      "name": "SVP GLOBAL TEXTILES",
      "tradingSymbol": "SVPGLOB",
      "label": "SVPGLOB",
      "value": "SVPGLOB"
    },
    {
      "instrument_token": "2216193",
      "name": "DEBOCK INDUSTRIES",
      "tradingSymbol": "DIL",
      "label": "DIL",
      "value": "DIL"
    },
    {
      "instrument_token": "2606337",
      "name": "BALKRISHNA PAPER MILLS L",
      "tradingSymbol": "BALKRISHNA",
      "label": "BALKRISHNA",
      "value": "BALKRISHNA"
    },
    {
      "instrument_token": "4661249",
      "name": "ORIENT PRESS",
      "tradingSymbol": "ORIENTLTD",
      "label": "ORIENTLTD",
      "value": "ORIENTLTD"
    },
    {
      "instrument_token": "2315777",
      "name": "AGRO PHOS INDIA",
      "tradingSymbol": "AGROPHOS",
      "label": "AGROPHOS",
      "value": "AGROPHOS"
    },
    {
      "instrument_token": "6601217",
      "name": "KANANI INDUSTRIES",
      "tradingSymbol": "KANANIIND",
      "label": "KANANIIND",
      "value": "KANANIIND"
    },
    {
      "instrument_token": "383233",
      "name": "SHIVA MILLS",
      "tradingSymbol": "SHIVAMILLS",
      "label": "SHIVAMILLS",
      "value": "SHIVAMILLS"
    },
    {
      "instrument_token": "5254913",
      "name": "PRUDENTIAL SUGAR CORPORAT",
      "tradingSymbol": "PRUDMOULI",
      "label": "PRUDMOULI",
      "value": "PRUDMOULI"
    },
    {
      "instrument_token": "3726593",
      "name": "ARO GRANITE IND.",
      "tradingSymbol": "AROGRANITE",
      "label": "AROGRANITE",
      "value": "AROGRANITE"
    },
    {
      "instrument_token": "3701505",
      "name": "LEXUS GRANITO (INDIA)",
      "tradingSymbol": "LEXUS",
      "label": "LEXUS",
      "value": "LEXUS"
    },
    {
      "instrument_token": "1382657",
      "name": "NAKODA GROUP OF IND.",
      "tradingSymbol": "NGIL",
      "label": "NGIL",
      "value": "NGIL"
    },
    {
      "instrument_token": "3917569",
      "name": "BANG OVERSEAS",
      "tradingSymbol": "BANG",
      "label": "BANG",
      "value": "BANG"
    },
    {
      "instrument_token": "3474433",
      "name": "MANUGRAPH INDIA",
      "tradingSymbol": "MANUGRAPH",
      "label": "MANUGRAPH",
      "value": "MANUGRAPH"
    },
    {
      "instrument_token": "88833",
      "name": "BANARAS BEADS",
      "tradingSymbol": "BANARBEADS",
      "label": "BANARBEADS",
      "value": "BANARBEADS"
    },
    {
      "instrument_token": "1846017",
      "name": "JET FREIGHT LOGISTICS",
      "tradingSymbol": "JETFREIGHT",
      "label": "JETFREIGHT",
      "value": "JETFREIGHT"
    },
    {
      "instrument_token": "557057",
      "name": "AKG EXIM",
      "tradingSymbol": "AKG",
      "label": "AKG",
      "value": "AKG"
    },
    {
      "instrument_token": "637953",
      "name": "SILGO RETAIL",
      "tradingSymbol": "SILGO",
      "label": "SILGO",
      "value": "SILGO"
    },
    {
      "instrument_token": "3458561",
      "name": "AKASH INFRA-PROJECTS",
      "tradingSymbol": "AKASH",
      "label": "AKASH",
      "value": "AKASH"
    },
    {
      "instrument_token": "871169",
      "name": "AJOONI BIOTECH",
      "tradingSymbol": "AJOONI",
      "label": "AJOONI",
      "value": "AJOONI"
    },
    {
      "instrument_token": "2022913",
      "name": "LAKSHMI FIN IND CORP",
      "tradingSymbol": "LFIC",
      "label": "LFIC",
      "value": "LFIC"
    },
    {
      "instrument_token": "2836993",
      "name": "LIBAS CONSU PRODUCTS",
      "tradingSymbol": "LIBAS",
      "label": "LIBAS",
      "value": "LIBAS"
    },
    {
      "instrument_token": "1681409",
      "name": "LAXMI COTSPIN",
      "tradingSymbol": "LAXMICOT",
      "label": "LAXMICOT",
      "value": "LAXMICOT"
    },
    {
      "instrument_token": "2942977",
      "name": "AMBICA AGAR & AROMAINDLTD",
      "tradingSymbol": "AMBICAAGAR",
      "label": "AMBICAAGAR",
      "value": "AMBICAAGAR"
    },
    {
      "instrument_token": "4337409",
      "name": "ASHOKA METCAST",
      "tradingSymbol": "ASHOKAMET",
      "label": "ASHOKAMET",
      "value": "ASHOKAMET"
    },
    {
      "instrument_token": "62721",
      "name": "ARCHIDPLY DECOR",
      "tradingSymbol": "ADL",
      "label": "ADL",
      "value": "ADL"
    },
    {
      "instrument_token": "2468097",
      "name": "KHANDWALA SECURITIES",
      "tradingSymbol": "KHANDSE",
      "label": "KHANDSE",
      "value": "KHANDSE"
    },
    {
      "instrument_token": "974593",
      "name": "VINEET LABORATORIES",
      "tradingSymbol": "VINEETLAB",
      "label": "VINEETLAB",
      "value": "VINEETLAB"
    },
    {
      "instrument_token": "3739393",
      "name": "TGB BANQUETS&HOTELS",
      "tradingSymbol": "TGBHOTELS",
      "label": "TGBHOTELS",
      "value": "TGBHOTELS"
    },
    {
      "instrument_token": "3421953",
      "name": "STL GLOBAL",
      "tradingSymbol": "SGL",
      "label": "SGL",
      "value": "SGL"
    },
    {
      "instrument_token": "1994497",
      "name": "UNIINFO TELECOM SERVI",
      "tradingSymbol": "UNIINFO",
      "label": "UNIINFO",
      "value": "UNIINFO"
    },
    {
      "instrument_token": "859393",
      "name": "SUPER SPINNING MILLS",
      "tradingSymbol": "SUPERSPIN",
      "label": "SUPERSPIN",
      "value": "SUPERSPIN"
    },
    {
      "instrument_token": "965633",
      "name": "WILLIAMSON MAGOR",
      "tradingSymbol": "WILLAMAGOR",
      "label": "WILLAMAGOR",
      "value": "WILLAMAGOR"
    },
    {
      "instrument_token": "1908993",
      "name": "SRI HAVISHA HOSP & INFR L",
      "tradingSymbol": "HAVISHA",
      "label": "HAVISHA",
      "value": "HAVISHA"
    },
    {
      "instrument_token": "624385",
      "name": "NK INDUSTRIES",
      "tradingSymbol": "NKIND",
      "label": "NKIND",
      "value": "NKIND"
    },
    {
      "instrument_token": "2824193",
      "name": "SANGINITA CHEMICALS",
      "tradingSymbol": "SANGINITA",
      "label": "SANGINITA",
      "value": "SANGINITA"
    },
    {
      "instrument_token": "1267969",
      "name": "ADROIT INFOTECH",
      "tradingSymbol": "ADROITINFO",
      "label": "ADROITINFO",
      "value": "ADROITINFO"
    },
    {
      "instrument_token": "5380353",
      "name": "FUTURE MKT NETWORKS",
      "tradingSymbol": "FMNL",
      "label": "FMNL",
      "value": "FMNL"
    },
    {
      "instrument_token": "3015425",
      "name": "ACE INTEGRATED SOLU.",
      "tradingSymbol": "ACEINTEG",
      "label": "ACEINTEG",
      "value": "ACEINTEG"
    },
    {
      "instrument_token": "1329665",
      "name": "ANTARCTICA",
      "tradingSymbol": "ANTGRAPHIC",
      "label": "ANTGRAPHIC",
      "value": "ANTGRAPHIC"
    },
    {
      "instrument_token": "3672577",
      "name": "ORIENTAL TRIMEX",
      "tradingSymbol": "ORIENTALTL",
      "label": "ORIENTALTL",
      "value": "ORIENTALTL"
    },
    {
      "instrument_token": "4751873",
      "name": "MITTAL LIFE STYLE",
      "tradingSymbol": "MITTAL",
      "label": "MITTAL",
      "value": "MITTAL"
    },
    {
      "instrument_token": "1164289",
      "name": "GUJARAT RAFFIA INDUST",
      "tradingSymbol": "GUJRAFFIA",
      "label": "GUJRAFFIA",
      "value": "GUJRAFFIA"
    },
    {
      "instrument_token": "8055809",
      "name": "LYPSA GEMS & JEWEL",
      "tradingSymbol": "LYPSAGEMS",
      "label": "LYPSAGEMS",
      "value": "LYPSAGEMS"
    },
    {
      "instrument_token": "1064961",
      "name": "NORBEN TEA & EXPORTS",
      "tradingSymbol": "NORBTEAEXP",
      "label": "NORBTEAEXP",
      "value": "NORBTEAEXP"
    },
    {
      "instrument_token": "762625",
      "name": "ORTIN LABORATORIES",
      "tradingSymbol": "ORTINLAB",
      "label": "ORTINLAB",
      "value": "ORTINLAB"
    },
    {
      "instrument_token": "255745",
      "name": "EUROTEX INDUSTRIES",
      "tradingSymbol": "EUROTEXIND",
      "label": "EUROTEXIND",
      "value": "EUROTEXIND"
    },
    {
      "instrument_token": "4272641",
      "name": "PTC INDUSTRIES",
      "tradingSymbol": "PTCIL-BE",
      "label": "PTCIL-BE",
      "value": "PTCIL-BE"
    },
    {
      "instrument_token": "1308161",
      "name": "KPI GREEN ENERGY",
      "tradingSymbol": "KPIGREEN-BE",
      "label": "KPIGREEN-BE",
      "value": "KPIGREEN-BE"
    },
    {
      "instrument_token": "1919233",
      "name": "WOCKHARDT-DEPO",
      "tradingSymbol": "WOCKPHARMA-BE",
      "label": "WOCKPHARMA-BE",
      "value": "WOCKPHARMA-BE"
    },
    {
      "instrument_token": "969729",
      "name": "INOX WIND ENERGY",
      "tradingSymbol": "IWEL-BE",
      "label": "IWEL-BE",
      "value": "IWEL-BE"
    },
    {
      "instrument_token": "406017",
      "name": "TARC",
      "tradingSymbol": "TARC-BE",
      "label": "TARC-BE",
      "value": "TARC-BE"
    },
    {
      "instrument_token": "2089985",
      "name": "BLACK BOX",
      "tradingSymbol": "BBOX-BE",
      "label": "BBOX-BE",
      "value": "BBOX-BE"
    },
    {
      "instrument_token": "3045889",
      "name": "DCX SYSTEMS",
      "tradingSymbol": "DCXINDIA-BE",
      "label": "DCXINDIA-BE",
      "value": "DCXINDIA-BE"
    },
    {
      "instrument_token": "3456001",
      "name": "PRIME FOCUS",
      "tradingSymbol": "PFOCUS-BE",
      "label": "PFOCUS-BE",
      "value": "PFOCUS-BE"
    },
    {
      "instrument_token": "1513985",
      "name": "PARAS DEF AND SPCE TECH L",
      "tradingSymbol": "PARAS-BE",
      "label": "PARAS-BE",
      "value": "PARAS-BE"
    },
    {
      "instrument_token": "5875201",
      "name": "EXICOM TELE SYSTEMS",
      "tradingSymbol": "EXICOM-BE",
      "label": "EXICOM-BE",
      "value": "EXICOM-BE"
    },
    {
      "instrument_token": "2886145",
      "name": "DREDGING CORP OF INDIA",
      "tradingSymbol": "DREDGECORP-BE",
      "label": "DREDGECORP-BE",
      "value": "DREDGECORP-BE"
    },
    {
      "instrument_token": "2013697",
      "name": "GE POWER INDIA",
      "tradingSymbol": "GEPIL-BE",
      "label": "GEPIL-BE",
      "value": "GEPIL-BE"
    },
    {
      "instrument_token": "3803393",
      "name": "OMAXE",
      "tradingSymbol": "OMAXE-BE",
      "label": "OMAXE-BE",
      "value": "OMAXE-BE"
    },
    {
      "instrument_token": "4458241",
      "name": "PREMIER EXPLOSIVES",
      "tradingSymbol": "PREMEXPLN-BE",
      "label": "PREMEXPLN-BE",
      "value": "PREMEXPLN-BE"
    },
    {
      "instrument_token": "5407489",
      "name": "KALYANI INVEST CO",
      "tradingSymbol": "KICL-BE",
      "label": "KICL-BE",
      "value": "KICL-BE"
    },
    {
      "instrument_token": "3386625",
      "name": "GVK POW. & INFRA",
      "tradingSymbol": "GVKPIL-BE",
      "label": "GVKPIL-BE",
      "value": "GVKPIL-BE"
    },
    {
      "instrument_token": "943105",
      "name": "SOLARA ACTIVE PHA SCI",
      "tradingSymbol": "SOLARA-BE",
      "label": "SOLARA-BE",
      "value": "SOLARA-BE"
    },
    {
      "instrument_token": "7686913",
      "name": "ATUL AUTO",
      "tradingSymbol": "ATULAUTO-BE",
      "label": "ATULAUTO-BE",
      "value": "ATULAUTO-BE"
    },
    {
      "instrument_token": "2279425",
      "name": "VERANDA LEARNING SOL",
      "tradingSymbol": "VERANDA-BE",
      "label": "VERANDA-BE",
      "value": "VERANDA-BE"
    },
    {
      "instrument_token": "6204161",
      "name": "OM INFRA",
      "tradingSymbol": "OMINFRAL-BE",
      "label": "OMINFRAL-BE",
      "value": "OMINFRAL-BE"
    },
    {
      "instrument_token": "341505",
      "name": "MARINE ELECTRICAL (I)",
      "tradingSymbol": "MARINE-BE",
      "label": "MARINE-BE",
      "value": "MARINE-BE"
    },
    {
      "instrument_token": "5330945",
      "name": "SURAJ ESTATE DEVELOPERS L",
      "tradingSymbol": "SURAJEST-BE",
      "label": "SURAJEST-BE",
      "value": "SURAJEST-BE"
    },
    {
      "instrument_token": "2859265",
      "name": "COFFEE DAY ENTERPRISE",
      "tradingSymbol": "COFFEEDAY-BE",
      "label": "COFFEEDAY-BE",
      "value": "COFFEEDAY-BE"
    },
    {
      "instrument_token": "1605889",
      "name": "PVP VENTURES",
      "tradingSymbol": "PVP-BE",
      "label": "PVP-BE",
      "value": "PVP-BE"
    },
    {
      "instrument_token": "1750785",
      "name": "FOCUS LIGHTG & FIXTRS",
      "tradingSymbol": "FOCUS-BE",
      "label": "FOCUS-BE",
      "value": "FOCUS-BE"
    },
    {
      "instrument_token": "1834241",
      "name": "MIC ELECTRONICS",
      "tradingSymbol": "MICEL-BE",
      "label": "MICEL-BE",
      "value": "MICEL-BE"
    },
    {
      "instrument_token": "1676545",
      "name": "GRP",
      "tradingSymbol": "GRPLTD-BE",
      "label": "GRPLTD-BE",
      "value": "GRPLTD-BE"
    },
    {
      "instrument_token": "2345473",
      "name": "BALAJI TELEFILMS",
      "tradingSymbol": "BALAJITELE-BE",
      "label": "BALAJITELE-BE",
      "value": "BALAJITELE-BE"
    },
    {
      "instrument_token": "5486337",
      "name": "THE INVEST TRUST OF IND L",
      "tradingSymbol": "THEINVEST-BE",
      "label": "THEINVEST-BE",
      "value": "THEINVEST-BE"
    },
    {
      "instrument_token": "494849",
      "name": "WEALTH FRST PORT. MG.",
      "tradingSymbol": "WEALTH-BE",
      "label": "WEALTH-BE",
      "value": "WEALTH-BE"
    },
    {
      "instrument_token": "3531521",
      "name": "PARSVNATH DEVELOPER",
      "tradingSymbol": "PARSVNATH-BE",
      "label": "PARSVNATH-BE",
      "value": "PARSVNATH-BE"
    },
    {
      "instrument_token": "3137537",
      "name": "ORIENTAL CARBN & CHEM",
      "tradingSymbol": "OCCL-BE",
      "label": "OCCL-BE",
      "value": "OCCL-BE"
    },
    {
      "instrument_token": "1834497",
      "name": "SPML INFRA",
      "tradingSymbol": "SPMLINFRA-BE",
      "label": "SPMLINFRA-BE",
      "value": "SPMLINFRA-BE"
    },
    {
      "instrument_token": "2228737",
      "name": "IND SWIFT LABORATORIES LT",
      "tradingSymbol": "INDSWFTLAB-BE",
      "label": "INDSWFTLAB-BE",
      "value": "INDSWFTLAB-BE"
    },
    {
      "instrument_token": "5500929",
      "name": "STEL HOLDINGS",
      "tradingSymbol": "STEL-BE",
      "label": "STEL-BE",
      "value": "STEL-BE"
    },
    {
      "instrument_token": "4616449",
      "name": "MBL INFRASTRUCTURE",
      "tradingSymbol": "MBLINFRA-BE",
      "label": "MBLINFRA-BE",
      "value": "MBLINFRA-BE"
    },
    {
      "instrument_token": "4271873",
      "name": "DIGISPICE TECHNOLOGIES LT",
      "tradingSymbol": "DIGISPICE-BE",
      "label": "DIGISPICE-BE",
      "value": "DIGISPICE-BE"
    },
    {
      "instrument_token": "4716545",
      "name": "RATNAVEER PRECISION ENG L",
      "tradingSymbol": "RATNAVEER-BE",
      "label": "RATNAVEER-BE",
      "value": "RATNAVEER-BE"
    },
    {
      "instrument_token": "3902209",
      "name": "ADITYA BIRLA MONEY",
      "tradingSymbol": "BIRLAMONEY-BE",
      "label": "BIRLAMONEY-BE",
      "value": "BIRLAMONEY-BE"
    },
    {
      "instrument_token": "4992769",
      "name": "TANTIA CONSTRUCTIONS",
      "tradingSymbol": "TCLCONS-BE",
      "label": "TCLCONS-BE",
      "value": "TCLCONS-BE"
    },
    {
      "instrument_token": "4621569",
      "name": "MINDTECK (INDIA)",
      "tradingSymbol": "MINDTECK-BE",
      "label": "MINDTECK-BE",
      "value": "MINDTECK-BE"
    },
    {
      "instrument_token": "3542017",
      "name": "DONEAR IND.",
      "tradingSymbol": "DONEAR-BE",
      "label": "DONEAR-BE",
      "value": "DONEAR-BE"
    },
    {
      "instrument_token": "5143041",
      "name": "CAREER POINT",
      "tradingSymbol": "CAREERP-BE",
      "label": "CAREERP-BE",
      "value": "CAREERP-BE"
    },
    {
      "instrument_token": "2361089",
      "name": "TPL PLASTECH",
      "tradingSymbol": "TPLPLASTEH-BE",
      "label": "TPLPLASTEH-BE",
      "value": "TPLPLASTEH-BE"
    },
    {
      "instrument_token": "2399489",
      "name": "LIBERTYSHOESLTD",
      "tradingSymbol": "LIBERTSHOE-BE",
      "label": "LIBERTSHOE-BE",
      "value": "LIBERTSHOE-BE"
    },
    {
      "instrument_token": "5754369",
      "name": "NOVA AGRITECH",
      "tradingSymbol": "NOVAAGRI-BE",
      "label": "NOVAAGRI-BE",
      "value": "NOVAAGRI-BE"
    },
    {
      "instrument_token": "2928641",
      "name": "KRITI NUTRIENTS",
      "tradingSymbol": "KRITINUT-BE",
      "label": "KRITINUT-BE",
      "value": "KRITINUT-BE"
    },
    {
      "instrument_token": "3521793",
      "name": "SHIVAM AUTO.LTD",
      "tradingSymbol": "SHIVAMAUTO-BE",
      "label": "SHIVAMAUTO-BE",
      "value": "SHIVAMAUTO-BE"
    },
    {
      "instrument_token": "3596033",
      "name": "SARLA PERF. FIBERS",
      "tradingSymbol": "SARLAPOLY-BE",
      "label": "SARLAPOLY-BE",
      "value": "SARLAPOLY-BE"
    },
    {
      "instrument_token": "4893441",
      "name": "N.B.I. IND. FIN. CO.",
      "tradingSymbol": "NBIFIN-BE",
      "label": "NBIFIN-BE",
      "value": "NBIFIN-BE"
    },
    {
      "instrument_token": "2633985",
      "name": "AYM SYNTEX",
      "tradingSymbol": "AYMSYNTEX-BE",
      "label": "AYMSYNTEX-BE",
      "value": "AYMSYNTEX-BE"
    },
    {
      "instrument_token": "3698689",
      "name": "NAHAR POLY FILMS",
      "tradingSymbol": "NAHARPOLY-BE",
      "label": "NAHARPOLY-BE",
      "value": "NAHARPOLY-BE"
    },
    {
      "instrument_token": "4999425",
      "name": "INDUSTRIAL INV TRUST",
      "tradingSymbol": "IITL-BE",
      "label": "IITL-BE",
      "value": "IITL-BE"
    },
    {
      "instrument_token": "1321729",
      "name": "BPL",
      "tradingSymbol": "BPL-BE",
      "label": "BPL-BE",
      "value": "BPL-BE"
    },
    {
      "instrument_token": "6563329",
      "name": "ESSAR SHIPPING",
      "tradingSymbol": "ESSARSHPNG-BE",
      "label": "ESSARSHPNG-BE",
      "value": "ESSARSHPNG-BE"
    },
    {
      "instrument_token": "5315585",
      "name": "R.P.P INFRA PROJECTS",
      "tradingSymbol": "RPPINFRA-BE",
      "label": "RPPINFRA-BE",
      "value": "RPPINFRA-BE"
    },
    {
      "instrument_token": "2632193",
      "name": "KAYA",
      "tradingSymbol": "KAYA-BE",
      "label": "KAYA-BE",
      "value": "KAYA-BE"
    },
    {
      "instrument_token": "2380801",
      "name": "IFB AGRO INDUSTRIES",
      "tradingSymbol": "IFBAGRO-BE",
      "label": "IFBAGRO-BE",
      "value": "IFBAGRO-BE"
    },
    {
      "instrument_token": "1567233",
      "name": "DIC INDIA",
      "tradingSymbol": "DICIND-BE",
      "label": "DICIND-BE",
      "value": "DICIND-BE"
    },
    {
      "instrument_token": "1506305",
      "name": "PRECOT",
      "tradingSymbol": "PRECOT-BE",
      "label": "PRECOT-BE",
      "value": "PRECOT-BE"
    },
    {
      "instrument_token": "2640385",
      "name": "AURUM PROPTECH",
      "tradingSymbol": "AURUM-BE",
      "label": "AURUM-BE",
      "value": "AURUM-BE"
    },
    {
      "instrument_token": "3629569",
      "name": "IZMO",
      "tradingSymbol": "IZMO-BE",
      "label": "IZMO-BE",
      "value": "IZMO-BE"
    },
    {
      "instrument_token": "2826497",
      "name": "DCM NOUVELLE",
      "tradingSymbol": "DCMNVL-BE",
      "label": "DCMNVL-BE",
      "value": "DCMNVL-BE"
    },
    {
      "instrument_token": "5549825",
      "name": "HINDUSTAN MOTORS",
      "tradingSymbol": "HINDMOTORS-BE",
      "label": "HINDMOTORS-BE",
      "value": "HINDMOTORS-BE"
    },
    {
      "instrument_token": "2021121",
      "name": "ASHIMA",
      "tradingSymbol": "ASHIMASYN-BE",
      "label": "ASHIMASYN-BE",
      "value": "ASHIMASYN-BE"
    },
    {
      "instrument_token": "1742849",
      "name": "KOTHARI PRODUCTS",
      "tradingSymbol": "KOTHARIPRO-BE",
      "label": "KOTHARIPRO-BE",
      "value": "KOTHARIPRO-BE"
    },
    {
      "instrument_token": "3881473",
      "name": "ROBUST HOTELS",
      "tradingSymbol": "RHL-BE",
      "label": "RHL-BE",
      "value": "RHL-BE"
    },
    {
      "instrument_token": "2833665",
      "name": "NATH BIO-GENES (I)",
      "tradingSymbol": "NATHBIOGEN-BE",
      "label": "NATHBIOGEN-BE",
      "value": "NATHBIOGEN-BE"
    },
    {
      "instrument_token": "5457409",
      "name": "DYNEMIC PRODUCTS",
      "tradingSymbol": "DYNPRO-BE",
      "label": "DYNPRO-BE",
      "value": "DYNPRO-BE"
    },
    {
      "instrument_token": "3522561",
      "name": "MANOMAY TEX INDIA",
      "tradingSymbol": "MANOMAY-BE",
      "label": "MANOMAY-BE",
      "value": "MANOMAY-BE"
    },
    {
      "instrument_token": "2289153",
      "name": "IL&FS INVESTMENT MANAGERS",
      "tradingSymbol": "IVC-BE",
      "label": "IVC-BE",
      "value": "IVC-BE"
    },
    {
      "instrument_token": "3800833",
      "name": "VIP CLOTHING",
      "tradingSymbol": "VIPCLOTHNG-BE",
      "label": "VIPCLOTHNG-BE",
      "value": "VIPCLOTHNG-BE"
    },
    {
      "instrument_token": "2533889",
      "name": "AIRAN",
      "tradingSymbol": "AIRAN-BE",
      "label": "AIRAN-BE",
      "value": "AIRAN-BE"
    },
    {
      "instrument_token": "1885697",
      "name": "TRIGYN TECHNOLOGIES",
      "tradingSymbol": "TRIGYN-BE",
      "label": "TRIGYN-BE",
      "value": "TRIGYN-BE"
    },
    {
      "instrument_token": "409089",
      "name": "MANGALAM GLOBAL ENT",
      "tradingSymbol": "MGEL-BE",
      "label": "MGEL-BE",
      "value": "MGEL-BE"
    },
    {
      "instrument_token": "4281089",
      "name": "ALMONDZ GLOBAL SEC",
      "tradingSymbol": "ALMONDZ-BE",
      "label": "ALMONDZ-BE",
      "value": "ALMONDZ-BE"
    },
    {
      "instrument_token": "2015489",
      "name": "ZODIAC CLOTHING CO",
      "tradingSymbol": "ZODIACLOTH-BE",
      "label": "ZODIACLOTH-BE",
      "value": "ZODIACLOTH-BE"
    },
    {
      "instrument_token": "5139713",
      "name": "INTENSE TECHNOLOGIES",
      "tradingSymbol": "INTENTECH-BE",
      "label": "INTENTECH-BE",
      "value": "INTENTECH-BE"
    },
    {
      "instrument_token": "3807233",
      "name": "UDAYSHIVAKUMAR INFRA",
      "tradingSymbol": "USK-BE",
      "label": "USK-BE",
      "value": "USK-BE"
    },
    {
      "instrument_token": "2384385",
      "name": "STARTECK FINANCE",
      "tradingSymbol": "STARTECK-BE",
      "label": "STARTECK-BE",
      "value": "STARTECK-BE"
    },
    {
      "instrument_token": "397569",
      "name": "RKEC PROJECTS",
      "tradingSymbol": "RKEC-BE",
      "label": "RKEC-BE",
      "value": "RKEC-BE"
    },
    {
      "instrument_token": "2719233",
      "name": "SADBHAV INFRA PROJ",
      "tradingSymbol": "SADBHIN-BE",
      "label": "SADBHIN-BE",
      "value": "SADBHIN-BE"
    },
    {
      "instrument_token": "3533313",
      "name": "BAID FINSERV",
      "tradingSymbol": "BAIDFIN-BE",
      "label": "BAIDFIN-BE",
      "value": "BAIDFIN-BE"
    },
    {
      "instrument_token": "1606145",
      "name": "SEL MANUFACTURING CO",
      "tradingSymbol": "SELMC-BE",
      "label": "SELMC-BE",
      "value": "SELMC-BE"
    },
    {
      "instrument_token": "3910401",
      "name": "CORDS CABLE INDUS",
      "tradingSymbol": "CORDSCABLE-BE",
      "label": "CORDSCABLE-BE",
      "value": "CORDSCABLE-BE"
    },
    {
      "instrument_token": "2030081",
      "name": "THE BYKE HOSPITALITY",
      "tradingSymbol": "BYKE-BE",
      "label": "BYKE-BE",
      "value": "BYKE-BE"
    },
    {
      "instrument_token": "3464449",
      "name": "NORTH EAST CARRY CORP",
      "tradingSymbol": "NECCLTD-BE",
      "label": "NECCLTD-BE",
      "value": "NECCLTD-BE"
    },
    {
      "instrument_token": "1639169",
      "name": "COUNTRY CLUB HOSP HOL",
      "tradingSymbol": "CCHHL-BE",
      "label": "CCHHL-BE",
      "value": "CCHHL-BE"
    },
    {
      "instrument_token": "4704001",
      "name": "NAGA DHUNSERI GROUP",
      "tradingSymbol": "NDGL-BE",
      "label": "NDGL-BE",
      "value": "NDGL-BE"
    },
    {
      "instrument_token": "3828737",
      "name": "INDOWIND ENERGY",
      "tradingSymbol": "INDOWIND-BE",
      "label": "INDOWIND-BE",
      "value": "INDOWIND-BE"
    },
    {
      "instrument_token": "3737857",
      "name": "ALPHAGEO (INDIA)",
      "tradingSymbol": "ALPHAGEO-BE",
      "label": "ALPHAGEO-BE",
      "value": "ALPHAGEO-BE"
    },
    {
      "instrument_token": "5518081",
      "name": "SMS LIFESCIENCES (I)",
      "tradingSymbol": "SMSLIFE-BE",
      "label": "SMSLIFE-BE",
      "value": "SMSLIFE-BE"
    },
    {
      "instrument_token": "560897",
      "name": "GENERIC ENG CONS PROJ",
      "tradingSymbol": "GENCON-BE",
      "label": "GENCON-BE",
      "value": "GENCON-BE"
    },
    {
      "instrument_token": "2968321",
      "name": "SURANA TELECOM AND POW LT",
      "tradingSymbol": "SURANAT&P-BE",
      "label": "SURANAT&P-BE",
      "value": "SURANAT&P-BE"
    },
    {
      "instrument_token": "604161",
      "name": "KBC GLOBAL",
      "tradingSymbol": "KBCGLOBAL-BE",
      "label": "KBCGLOBAL-BE",
      "value": "KBCGLOBAL-BE"
    },
    {
      "instrument_token": "2221825",
      "name": "MAHA RASHTRA APEX COPR. L",
      "tradingSymbol": "MAHAPEXLTD-BE",
      "label": "MAHAPEXLTD-BE",
      "value": "MAHAPEXLTD-BE"
    },
    {
      "instrument_token": "1265921",
      "name": "WEIZMANN",
      "tradingSymbol": "WEIZMANIND-BE",
      "label": "WEIZMANIND-BE",
      "value": "WEIZMANIND-BE"
    },
    {
      "instrument_token": "1901313",
      "name": "MANAK ALUMINIUM CO.",
      "tradingSymbol": "MANAKALUCO-BE",
      "label": "MANAKALUCO-BE",
      "value": "MANAKALUCO-BE"
    },
    {
      "instrument_token": "4482817",
      "name": "INCREDIBLE INDUSTRIES",
      "tradingSymbol": "INCREDIBLE-BE",
      "label": "INCREDIBLE-BE",
      "value": "INCREDIBLE-BE"
    },
    {
      "instrument_token": "2916353",
      "name": "UMANG DAIRIES",
      "tradingSymbol": "UMANGDAIRY-BE",
      "label": "UMANGDAIRY-BE",
      "value": "UMANGDAIRY-BE"
    },
    {
      "instrument_token": "3640833",
      "name": "CAMBRIDGE TECH ENTER",
      "tradingSymbol": "CTE-BE",
      "label": "CTE-BE",
      "value": "CTE-BE"
    },
    {
      "instrument_token": "2267393",
      "name": "IVP",
      "tradingSymbol": "IVP-BE",
      "label": "IVP-BE",
      "value": "IVP-BE"
    },
    {
      "instrument_token": "4556289",
      "name": "SOMI CONVEYOR BELT.",
      "tradingSymbol": "SOMICONVEY-BE",
      "label": "SOMICONVEY-BE",
      "value": "SOMICONVEY-BE"
    },
    {
      "instrument_token": "5740801",
      "name": "TOTAL TRANSPORT SYS",
      "tradingSymbol": "TOTAL-BE",
      "label": "TOTAL-BE",
      "value": "TOTAL-BE"
    },
    {
      "instrument_token": "4328961",
      "name": "TOUCHWOOD ENTERTAIN",
      "tradingSymbol": "TOUCHWOOD-BE",
      "label": "TOUCHWOOD-BE",
      "value": "TOUCHWOOD-BE"
    },
    {
      "instrument_token": "376833",
      "name": "ACCURACY SHIPPING",
      "tradingSymbol": "ACCURACY-BE",
      "label": "ACCURACY-BE",
      "value": "ACCURACY-BE"
    },
    {
      "instrument_token": "3880961",
      "name": "KALYANI FORGE",
      "tradingSymbol": "KALYANIFRG-BE",
      "label": "KALYANIFRG-BE",
      "value": "KALYANIFRG-BE"
    },
    {
      "instrument_token": "2892289",
      "name": "STANDARD INDUSTRIES",
      "tradingSymbol": "SIL-BE",
      "label": "SIL-BE",
      "value": "SIL-BE"
    },
    {
      "instrument_token": "1574913",
      "name": "PANSARI DEVELOPERS",
      "tradingSymbol": "PANSARI-BE",
      "label": "PANSARI-BE",
      "value": "PANSARI-BE"
    },
    {
      "instrument_token": "1995521",
      "name": "AMJ LAND HOLDINGS",
      "tradingSymbol": "AMJLAND-BE",
      "label": "AMJLAND-BE",
      "value": "AMJLAND-BE"
    },
    {
      "instrument_token": "1874689",
      "name": "DCM",
      "tradingSymbol": "DCM-BE",
      "label": "DCM-BE",
      "value": "DCM-BE"
    },
    {
      "instrument_token": "183297",
      "name": "SHRADHA INFRAPROJECTS",
      "tradingSymbol": "SHRADHA-BE",
      "label": "SHRADHA-BE",
      "value": "SHRADHA-BE"
    },
    {
      "instrument_token": "4847361",
      "name": "SUNDARAM MULTI PAP",
      "tradingSymbol": "SUNDARAM-BE",
      "label": "SUNDARAM-BE",
      "value": "SUNDARAM-BE"
    },
    {
      "instrument_token": "2018561",
      "name": "KREBS BIOCHEMICALS & IND",
      "tradingSymbol": "KREBSBIO-BE",
      "label": "KREBSBIO-BE",
      "value": "KREBSBIO-BE"
    },
    {
      "instrument_token": "5245697",
      "name": "THE WESTERN INDIA PLY",
      "tradingSymbol": "WIPL-BE",
      "label": "WIPL-BE",
      "value": "WIPL-BE"
    },
    {
      "instrument_token": "1576705",
      "name": "ART NIRMAN",
      "tradingSymbol": "ARTNIRMAN-BE",
      "label": "ARTNIRMAN-BE",
      "value": "ARTNIRMAN-BE"
    },
    {
      "instrument_token": "5196801",
      "name": "SHAH METACORP",
      "tradingSymbol": "SHAH-BE",
      "label": "SHAH-BE",
      "value": "SHAH-BE"
    },
    {
      "instrument_token": "4265217",
      "name": "ANIK INDUSTRIES",
      "tradingSymbol": "ANIKINDS-BE",
      "label": "ANIKINDS-BE",
      "value": "ANIKINDS-BE"
    },
    {
      "instrument_token": "4352001",
      "name": "TEAMO PRODUCTIONS HQ",
      "tradingSymbol": "TPHQ-BE",
      "label": "TPHQ-BE",
      "value": "TPHQ-BE"
    },
    {
      "instrument_token": "102401",
      "name": "GLOBE TEXTILES (I)",
      "tradingSymbol": "GLOBE-BE",
      "label": "GLOBE-BE",
      "value": "GLOBE-BE"
    },
    {
      "instrument_token": "3728897",
      "name": "ZENITH STEEL PIP IND",
      "tradingSymbol": "ZENITHSTL-BE",
      "label": "ZENITHSTL-BE",
      "value": "ZENITHSTL-BE"
    },
    {
      "instrument_token": "4317953",
      "name": "LANDMARK PR.DEV.CO.LTD",
      "tradingSymbol": "LPDC-BE",
      "label": "LPDC-BE",
      "value": "LPDC-BE"
    },
    {
      "instrument_token": "3853569",
      "name": "ENERGY DEVE. CO.LTD",
      "tradingSymbol": "ENERGYDEV-BE",
      "label": "ENERGYDEV-BE",
      "value": "ENERGYDEV-BE"
    },
    {
      "instrument_token": "3741697",
      "name": "SIMBHAOLI SUGARS",
      "tradingSymbol": "SIMBHALS-BE",
      "label": "SIMBHALS-BE",
      "value": "SIMBHALS-BE"
    },
    {
      "instrument_token": "2641409",
      "name": "THE P K TEA PROD CO",
      "tradingSymbol": "PKTEA-BE",
      "label": "PKTEA-BE",
      "value": "PKTEA-BE"
    },
    {
      "instrument_token": "3201537",
      "name": "VINNY OVERSEAS",
      "tradingSymbol": "VINNY-BE",
      "label": "VINNY-BE",
      "value": "VINNY-BE"
    },
    {
      "instrument_token": "61441",
      "name": "AAKASH EXPLORATION SER L",
      "tradingSymbol": "AAKASH-BE",
      "label": "AAKASH-BE",
      "value": "AAKASH-BE"
    },
    {
      "instrument_token": "6990593",
      "name": "SAMPANN UTPADAN INDIA",
      "tradingSymbol": "SAMPANN-BE",
      "label": "SAMPANN-BE",
      "value": "SAMPANN-BE"
    },
    {
      "instrument_token": "2089473",
      "name": "FIBERWEB INDIA",
      "tradingSymbol": "FIBERWEB-BE",
      "label": "FIBERWEB-BE",
      "value": "FIBERWEB-BE"
    },
    {
      "instrument_token": "6243585",
      "name": "KARMA ENERGY",
      "tradingSymbol": "KARMAENG-BE",
      "label": "KARMAENG-BE",
      "value": "KARMAENG-BE"
    },
    {
      "instrument_token": "1054209",
      "name": "HB STOCKHOLDINGS",
      "tradingSymbol": "HBSL-BE",
      "label": "HBSL-BE",
      "value": "HBSL-BE"
    },
    {
      "instrument_token": "3526401",
      "name": "AARVEE DEN. & EXP.",
      "tradingSymbol": "AARVEEDEN-BE",
      "label": "AARVEEDEN-BE",
      "value": "AARVEEDEN-BE"
    },
    {
      "instrument_token": "2854401",
      "name": "SHRENIK",
      "tradingSymbol": "SHRENIK-BE",
      "label": "SHRENIK-BE",
      "value": "SHRENIK-BE"
    },
    {
      "instrument_token": "165121",
      "name": "DILIGENT MEDIA CORP",
      "tradingSymbol": "DNAMEDIA-BE",
      "label": "DNAMEDIA-BE",
      "value": "DNAMEDIA-BE"
    },
    {
      "instrument_token": "2316289",
      "name": "3P LAND HOLDINGS",
      "tradingSymbol": "3PLAND-BE",
      "label": "3PLAND-BE",
      "value": "3PLAND-BE"
    },
    {
      "instrument_token": "3890689",
      "name": "BURNPUR CEMENT",
      "tradingSymbol": "BURNPUR-BE",
      "label": "BURNPUR-BE",
      "value": "BURNPUR-BE"
    },
    {
      "instrument_token": "5364225",
      "name": "RAVI KUMAR DIST.",
      "tradingSymbol": "RKDL-BE",
      "label": "RKDL-BE",
      "value": "RKDL-BE"
    },
    {
      "instrument_token": "412161",
      "name": "GODHA CABCON INSULAT",
      "tradingSymbol": "GODHA-BE",
      "label": "GODHA-BE",
      "value": "GODHA-BE"
    },
    {
      "instrument_token": "2525185",
      "name": "NEXT MEDIAWORKS",
      "tradingSymbol": "NEXTMEDIA-BE",
      "label": "NEXTMEDIA-BE",
      "value": "NEXTMEDIA-BE"
    },
    {
      "instrument_token": "164609",
      "name": "SUUMAYA INDUSTRIES",
      "tradingSymbol": "SUULD-BE",
      "label": "SUULD-BE",
      "value": "SUULD-BE"
    },
    {
      "instrument_token": "2093057",
      "name": "SAGARDEEP ALLOYS",
      "tradingSymbol": "SAGARDEEP-BE",
      "label": "SAGARDEEP-BE",
      "value": "SAGARDEEP-BE"
    },
    {
      "instrument_token": "4349185",
      "name": "COUNTRY CONDO S",
      "tradingSymbol": "COUNCODOS-BE",
      "label": "COUNCODOS-BE",
      "value": "COUNCODOS-BE"
    },
    {
      "instrument_token": "2452993",
      "name": "KHAITAN (INDIA)LTD.",
      "tradingSymbol": "KHAITANLTD-BE",
      "label": "KHAITANLTD-BE",
      "value": "KHAITANLTD-BE"
    },
    {
      "instrument_token": "7533313",
      "name": "MOHIT INDUSTRIES",
      "tradingSymbol": "MOHITIND-BE",
      "label": "MOHITIND-BE",
      "value": "MOHITIND-BE"
    },
    {
      "instrument_token": "3280385",
      "name": "HYBRID FINANCIAL SERVICE",
      "tradingSymbol": "HYBRIDFIN-BE",
      "label": "HYBRIDFIN-BE",
      "value": "HYBRIDFIN-BE"
    },
    {
      "instrument_token": "4795137",
      "name": "MASK INVESTMENTS",
      "tradingSymbol": "MASKINVEST-BE",
      "label": "MASKINVEST-BE",
      "value": "MASKINVEST-BE"
    },
    {
      "instrument_token": "3875841",
      "name": "KAUSHALYA INFRA DEV",
      "tradingSymbol": "KAUSHALYA-BE",
      "label": "KAUSHALYA-BE",
      "value": "KAUSHALYA-BE"
    },
    {
      "instrument_token": "3825409",
      "name": "NAGREEKA CAP & INFR.LTD",
      "tradingSymbol": "NAGREEKCAP-BE",
      "label": "NAGREEKCAP-BE",
      "value": "NAGREEKCAP-BE"
    },
    {
      "instrument_token": "4889345",
      "name": "SILLY MONKS ENTERTAIN",
      "tradingSymbol": "SILLYMONKS-BE",
      "label": "SILLYMONKS-BE",
      "value": "SILLYMONKS-BE"
    },
    {
      "instrument_token": "4901121",
      "name": "USHA MARTIN EDU & SOL",
      "tradingSymbol": "UMESLTD-BE",
      "label": "UMESLTD-BE",
      "value": "UMESLTD-BE"
    },
    {
      "instrument_token": "2431745",
      "name": "RAJDARSHAN INDUSTRIES",
      "tradingSymbol": "ARENTERP-BE",
      "label": "ARENTERP-BE",
      "value": "ARENTERP-BE"
    },
    {
      "instrument_token": "3505665",
      "name": "INFOMEDIA PRESS",
      "tradingSymbol": "INFOMEDIA-BE",
      "label": "INFOMEDIA-BE",
      "value": "INFOMEDIA-BE"
    }
  ],
  etf: [
    {
      "instrument_token": "4680705",
      "name": "NIP IND ETF HANGSENG BEES",
      "tradingSymbol": "HNGSNGBEES",
      "label": "HNGSNGBEES",
      "value": "HNGSNGBEES"
    },
    {
      "instrument_token": "1358337",
      "name": "ICICIPRAMC - ICICIFMCG",
      "tradingSymbol": "FMCGIETF",
      "label": "FMCGIETF",
      "value": "FMCGIETF"
    },
    {
      "instrument_token": "5935105",
      "name": "MOTILALAMC - MOREALTY",
      "tradingSymbol": "MOREALTY",
      "label": "MOREALTY",
      "value": "MOREALTY"
    },
    {
      "instrument_token": "4779009",
      "name": "NAVIAMC - NAVINIFTY",
      "tradingSymbol": "NAVINIFTY",
      "label": "NAVINIFTY",
      "value": "NAVINIFTY"
    },
    {
      "instrument_token": "5498369",
      "name": "UTIAMC - UTINEXT50",
      "tradingSymbol": "UTINEXT50",
      "label": "UTINEXT50",
      "value": "UTINEXT50"
    },
    {
      "instrument_token": "2770433",
      "name": "MOTILALAMC - MOQUALITY",
      "tradingSymbol": "MOQUALITY",
      "label": "MOQUALITY",
      "value": "MOQUALITY"
    },
    {
      "instrument_token": "2736641",
      "name": "BIRLASLAMC - NIFTYQLITY",
      "tradingSymbol": "NIFTYQLITY",
      "label": "NIFTYQLITY",
      "value": "NIFTYQLITY"
    },
    {
      "instrument_token": "4558849",
      "name": "ICICIPRAMC - ICICIQTY30",
      "tradingSymbol": "QUAL30IETF",
      "label": "QUAL30IETF",
      "value": "QUAL30IETF"
    },
    {
      "instrument_token": "4363265",
      "name": "NIP IND ETF SHARIAH BEES",
      "tradingSymbol": "SHARIABEES",
      "label": "SHARIABEES",
      "value": "SHARIABEES"
    },
    {
      "instrument_token": "595969",
      "name": "CPSE ETF",
      "tradingSymbol": "CPSEETF",
      "label": "CPSEETF",
      "value": "CPSEETF"
    },
    {
      "instrument_token": "1847809",
      "name": "SBIAMC - SBIETFQLTY",
      "tradingSymbol": "SBIETFQLTY",
      "label": "SBIETFQLTY",
      "value": "SBIETFQLTY"
    },
    {
      "instrument_token": "897793",
      "name": "MIRAEAMC - MAFANG",
      "tradingSymbol": "MAFANG",
      "label": "MAFANG",
      "value": "MAFANG"
    },
    {
      "instrument_token": "5816833",
      "name": "LICNAMC - LICNMID100",
      "tradingSymbol": "LICNMID100",
      "label": "LICNMID100",
      "value": "LICNMID100"
    },
    {
      "instrument_token": "3855617",
      "name": "KOTAK PSU BANK",
      "tradingSymbol": "PSUBANK",
      "label": "PSUBANK",
      "value": "PSUBANK"
    },
    {
      "instrument_token": "2177537",
      "name": "NIP IND ETF MIDCAP 150",
      "tradingSymbol": "MID150BEES",
      "label": "MID150BEES",
      "value": "MID150BEES"
    },
    {
      "instrument_token": "1654273",
      "name": "BIRLASLAMC - TECH",
      "tradingSymbol": "TECH",
      "label": "TECH",
      "value": "TECH"
    },
    {
      "instrument_token": "2865409",
      "name": "AXISAMC - AXISILVER",
      "tradingSymbol": "AXISILVER",
      "label": "AXISILVER",
      "value": "AXISILVER"
    },
    {
      "instrument_token": "4696833",
      "name": "UTIAMC - NIFMID150",
      "tradingSymbol": "NIFMID150",
      "label": "NIFMID150",
      "value": "NIFMID150"
    },
    {
      "instrument_token": "3861761",
      "name": "UTIAMC - SILVERETF",
      "tradingSymbol": "SILVERETF",
      "label": "SILVERETF",
      "value": "SILVERETF"
    },
    {
      "instrument_token": "189441",
      "name": "SBIAMC - SBIETFIT",
      "tradingSymbol": "SBIETFIT",
      "label": "SBIETFIT",
      "value": "SBIETFIT"
    },
    {
      "instrument_token": "2153729",
      "name": "MIRAEAMC - MAM150ETF",
      "tradingSymbol": "MIDCAPETF",
      "label": "MIDCAPETF",
      "value": "MIDCAPETF"
    },
    {
      "instrument_token": "1897473",
      "name": "KOTAKMAMC - KOTAKALPHA",
      "tradingSymbol": "ALPHA",
      "label": "ALPHA",
      "value": "ALPHA"
    },
    {
      "instrument_token": "2881281",
      "name": "HDFCAMC - HDFCQUAL",
      "tradingSymbol": "HDFCQUAL",
      "label": "HDFCQUAL",
      "value": "HDFCQUAL"
    },
    {
      "instrument_token": "5725441",
      "name": "ICICIPRAMC - ICICITECH",
      "tradingSymbol": "ITIETF",
      "label": "ITIETF",
      "value": "ITIETF"
    },
    {
      "instrument_token": "2042625",
      "name": "MIRAEAMC - MAMFGETF",
      "tradingSymbol": "MAKEINDIA",
      "label": "MAKEINDIA",
      "value": "MAKEINDIA"
    },
    {
      "instrument_token": "4885505",
      "name": "NIP IND ETF IT",
      "tradingSymbol": "ITBEES",
      "label": "ITBEES",
      "value": "ITBEES"
    },
    {
      "instrument_token": "3733505",
      "name": "ICICIPRAMC - PSUBANKICI",
      "tradingSymbol": "PSUBNKIETF",
      "label": "PSUBNKIETF",
      "value": "PSUBNKIETF"
    },
    {
      "instrument_token": "674817",
      "name": "NIP IND ETF DIV OPP",
      "tradingSymbol": "DIVOPPBEES",
      "label": "DIVOPPBEES",
      "value": "DIVOPPBEES"
    },
    {
      "instrument_token": "2691841",
      "name": "UTIAMC - UTISENSETF",
      "tradingSymbol": "UTISENSETF",
      "label": "UTISENSETF",
      "value": "UTISENSETF"
    },
    {
      "instrument_token": "2006529",
      "name": "TATAAML - NETF",
      "tradingSymbol": "NETF",
      "label": "NETF",
      "value": "NETF"
    },
    {
      "instrument_token": "3824129",
      "name": "MIRAEAMC - MAGS813ETF",
      "tradingSymbol": "GSEC10YEAR",
      "label": "GSEC10YEAR",
      "value": "GSEC10YEAR"
    },
    {
      "instrument_token": "4808449",
      "name": "BANDHANAMC - IDFNIFTYET",
      "tradingSymbol": "IDFNIFTYET",
      "label": "IDFNIFTYET",
      "value": "IDFNIFTYET"
    },
    {
      "instrument_token": "4937985",
      "name": "MOTILAL OSWAL M50 ETF",
      "tradingSymbol": "MOM50",
      "label": "MOM50",
      "value": "MOM50"
    },
    {
      "instrument_token": "923649",
      "name": "AXISAMC - AXISHCETF",
      "tradingSymbol": "AXISHCETF",
      "label": "AXISHCETF",
      "value": "AXISHCETF"
    },
    {
      "instrument_token": "3097857",
      "name": "HDFCAMC - HDFCNIFIT",
      "tradingSymbol": "HDFCNIFIT",
      "label": "HDFCNIFIT",
      "value": "HDFCNIFIT"
    },
    {
      "instrument_token": "5026049",
      "name": "MIRAEAMC - ITETF",
      "tradingSymbol": "ITETF",
      "label": "ITETF",
      "value": "ITETF"
    },
    {
      "instrument_token": "1120769",
      "name": "ICICI PRUD SENSEX ETF",
      "tradingSymbol": "SENSEXIETF",
      "label": "SENSEXIETF",
      "value": "SENSEXIETF"
    },
    {
      "instrument_token": "2928897",
      "name": "LICNAMC - LICNFESGP",
      "tradingSymbol": "LICNETFSEN",
      "label": "LICNETFSEN",
      "value": "LICNETFSEN"
    },
    {
      "instrument_token": "2033153",
      "name": "ICICIPRAMC - ICICISILVE",
      "tradingSymbol": "SILVERIETF",
      "label": "SILVERIETF",
      "value": "SILVERIETF"
    },
    {
      "instrument_token": "5441025",
      "name": "ICICI PR NIF LW VL 30 ETF",
      "tradingSymbol": "LOWVOLIETF",
      "label": "LOWVOLIETF",
      "value": "LOWVOLIETF"
    },
    {
      "instrument_token": "385793",
      "name": "MOTILALAMC - G5",
      "tradingSymbol": "MOGSEC",
      "label": "MOGSEC",
      "value": "MOGSEC"
    },
    {
      "instrument_token": "2956033",
      "name": "HDFCAMC - HDFCLOWVOL",
      "tradingSymbol": "HDFCLOWVOL",
      "label": "HDFCLOWVOL",
      "value": "HDFCLOWVOL"
    },
    {
      "instrument_token": "1323009",
      "name": "SBIAMC - SBIETFCON",
      "tradingSymbol": "SBIETFCON",
      "label": "SBIETFCON",
      "value": "SBIETFCON"
    },
    {
      "instrument_token": "4390913",
      "name": "ICICIPRAMC - ICICIM150",
      "tradingSymbol": "MIDCAPIETF",
      "label": "MIDCAPIETF",
      "value": "MIDCAPIETF"
    },
    {
      "instrument_token": "1551873",
      "name": "LICNAMC - LICNMFET",
      "tradingSymbol": "LICNETFGSC",
      "label": "LICNETFGSC",
      "value": "LICNETFGSC"
    },
    {
      "instrument_token": "133633",
      "name": "ICICIPRAMC - BHARATIWIN",
      "tradingSymbol": "ICICIB22",
      "label": "ICICIB22",
      "value": "ICICIB22"
    },
    {
      "instrument_token": "1626369",
      "name": "MIRAEAMC - MAN50ETF",
      "tradingSymbol": "NIFTYETF",
      "label": "NIFTYETF",
      "value": "NIFTYETF"
    },
    {
      "instrument_token": "1273089",
      "name": "NIPPONAMC - NETFPHARMA",
      "tradingSymbol": "PHARMABEES",
      "label": "PHARMABEES",
      "value": "PHARMABEES"
    },
    {
      "instrument_token": "5484289",
      "name": "MOTILAL OS MIDCAP100 ETF",
      "tradingSymbol": "MOM100",
      "label": "MOM100",
      "value": "MOM100"
    },
    {
      "instrument_token": "3378689",
      "name": "ICICIPRAMC - ICICICOMMO",
      "tradingSymbol": "COMMOIETF",
      "label": "COMMOIETF",
      "value": "COMMOIETF"
    },
    {
      "instrument_token": "2897665",
      "name": "LICNAMC - LICNFENGP",
      "tradingSymbol": "LICNETFN50",
      "label": "LICNETFN50",
      "value": "LICNETFN50"
    },
    {
      "instrument_token": "3848193",
      "name": "NIP IND ETF PSU BANK BEES",
      "tradingSymbol": "PSUBNKBEES",
      "label": "PSUBNKBEES",
      "value": "PSUBNKBEES"
    },
    {
      "instrument_token": "2967297",
      "name": "HDFCAMC - HDFCNIFTY",
      "tradingSymbol": "HDFCNIFTY",
      "label": "HDFCNIFTY",
      "value": "HDFCNIFTY"
    },
    {
      "instrument_token": "5821185",
      "name": "MOTILAL OS NASDAQ100 ETF",
      "tradingSymbol": "MON100",
      "label": "MON100",
      "value": "MON100"
    },
    {
      "instrument_token": "1908737",
      "name": "DSPAMC - DSPQ50ETF",
      "tradingSymbol": "MIDQ50ADD",
      "label": "MIDQ50ADD",
      "value": "MIDQ50ADD"
    },
    {
      "instrument_token": "2068481",
      "name": "NIPPONAMC - NETFSILVER",
      "tradingSymbol": "SILVERBEES",
      "label": "SILVERBEES",
      "value": "SILVERBEES"
    },
    {
      "instrument_token": "5138433",
      "name": "NIP IND ETF INFRA BEES",
      "tradingSymbol": "INFRABEES",
      "label": "INFRABEES",
      "value": "INFRABEES"
    },
    {
      "instrument_token": "928257",
      "name": "ICICIPRAMC - ICICIPHARM",
      "tradingSymbol": "HEALTHIETF",
      "label": "HEALTHIETF",
      "value": "HEALTHIETF"
    },
    {
      "instrument_token": "4473601",
      "name": "ICICI PRUDENTIAL NV20 ETF",
      "tradingSymbol": "NV20IETF",
      "label": "NV20IETF",
      "value": "NV20IETF"
    },
    {
      "instrument_token": "812033",
      "name": "RELCAPAMC - NETFGILT5Y",
      "tradingSymbol": "GILT5YBEES",
      "label": "GILT5YBEES",
      "value": "GILT5YBEES"
    },
    {
      "instrument_token": "6199553",
      "name": "INVESCO INDIA NIFTY ETF",
      "tradingSymbol": "IVZINNIFTY",
      "label": "IVZINNIFTY",
      "value": "IVZINNIFTY"
    },
    {
      "instrument_token": "2967809",
      "name": "HDFCAMC - HDFCSENSEX",
      "tradingSymbol": "HDFCSENSEX",
      "label": "HDFCSENSEX",
      "value": "HDFCSENSEX"
    },
    {
      "instrument_token": "2745089",
      "name": "ICICIPRAMC - ICICIINFRA",
      "tradingSymbol": "INFRAIETF",
      "label": "INFRAIETF",
      "value": "INFRAIETF"
    },
    {
      "instrument_token": "5621505",
      "name": "BFAM - NIFTYBETF",
      "tradingSymbol": "NIFTYBETF",
      "label": "NIFTYBETF",
      "value": "NIFTYBETF"
    },
    {
      "instrument_token": "2209793",
      "name": "KOTAKMAMC - KOTAKLOVOL",
      "tradingSymbol": "LOWVOL1",
      "label": "LOWVOL1",
      "value": "LOWVOL1"
    },
    {
      "instrument_token": "1900033",
      "name": "MOTILALAMC - MONQ50",
      "tradingSymbol": "MONQ50",
      "label": "MONQ50",
      "value": "MONQ50"
    },
    {
      "instrument_token": "2605057",
      "name": "SBI-ETF NIFTY 50",
      "tradingSymbol": "SETFNIF50",
      "label": "SETFNIF50",
      "value": "SETFNIF50"
    },
    {
      "instrument_token": "2067713",
      "name": "KOTAKMAMC - KOTAKMID50",
      "tradingSymbol": "MIDCAP",
      "label": "MIDCAP",
      "value": "MIDCAP"
    },
    {
      "instrument_token": "1612033",
      "name": "BIRLASLAMC - HEALTHY",
      "tradingSymbol": "HEALTHY",
      "label": "HEALTHY",
      "value": "HEALTHY"
    },
    {
      "instrument_token": "1907457",
      "name": "DSPAMC - DSPN50ETF",
      "tradingSymbol": "NIFTY50ADD",
      "label": "NIFTY50ADD",
      "value": "NIFTY50ADD"
    },
    {
      "instrument_token": "7780353",
      "name": "ICICI PRUD NIFTY 100 ETF",
      "tradingSymbol": "NIF100IETF",
      "label": "NIF100IETF",
      "value": "NIF100IETF"
    },
    {
      "instrument_token": "4509697",
      "name": "DSPAMC - DSPPSBKETF",
      "tradingSymbol": "PSUBANKADD",
      "label": "PSUBANKADD",
      "value": "PSUBANKADD"
    },
    {
      "instrument_token": "5485569",
      "name": "TATAAML-TATSILV",
      "tradingSymbol": "TATSILV",
      "label": "TATSILV",
      "value": "TATSILV"
    },
    {
      "instrument_token": "2932993",
      "name": "KOTAKMAMC - KTKNV20ETF",
      "tradingSymbol": "NV20",
      "label": "NV20",
      "value": "NV20"
    },
    {
      "instrument_token": "5784321",
      "name": "HDFCAMC HDFCPSUBK",
      "tradingSymbol": "HDFCPSUBK",
      "label": "HDFCPSUBK",
      "value": "HDFCPSUBK"
    },
    {
      "instrument_token": "2784257",
      "name": "HDFCAMC - HDFCSILVER",
      "tradingSymbol": "HDFCSILVER",
      "label": "HDFCSILVER",
      "value": "HDFCSILVER"
    },
    {
      "instrument_token": "3644417",
      "name": "HDFCAMC - HDFCMID150",
      "tradingSymbol": "HDFCMID150",
      "label": "HDFCMID150",
      "value": "HDFCMID150"
    },
    {
      "instrument_token": "5440513",
      "name": "AXISAMC - AXISNIFTY",
      "tradingSymbol": "AXISNIFTY",
      "label": "AXISNIFTY",
      "value": "AXISNIFTY"
    },
    {
      "instrument_token": "623361",
      "name": "NIP IND ETF CONSUMPTION",
      "tradingSymbol": "CONSUMBEES",
      "label": "CONSUMBEES",
      "value": "CONSUMBEES"
    },
    {
      "instrument_token": "768257",
      "name": "ICICIPRAMC - ICICI500",
      "tradingSymbol": "BSE500IETF",
      "label": "BSE500IETF",
      "value": "BSE500IETF"
    },
    {
      "instrument_token": "1467393",
      "name": "AXISAMC - AXISCETF",
      "tradingSymbol": "AXISCETF",
      "label": "AXISCETF",
      "value": "AXISCETF"
    },
    {
      "instrument_token": "1882369",
      "name": "SBI-ETF NIFTY NEXT 50",
      "tradingSymbol": "SETFNN50",
      "label": "SETFNN50",
      "value": "SETFNN50"
    },
    {
      "instrument_token": "4924673",
      "name": "MOTILALAMC - MONIFTY500",
      "tradingSymbol": "MONIFTY500",
      "label": "MONIFTY500",
      "value": "MONIFTY500"
    },
    {
      "instrument_token": "6343937",
      "name": "ADITYBIRLA SL NIF ETF-GR",
      "tradingSymbol": "BSLNIFTY",
      "label": "BSLNIFTY",
      "value": "BSLNIFTY"
    },
    {
      "instrument_token": "2707457",
      "name": "NIP IND ETF NIFTY BEES",
      "tradingSymbol": "NIFTYBEES",
      "label": "NIFTYBEES",
      "value": "NIFTYBEES"
    },
    {
      "instrument_token": "2215425",
      "name": "MOTILALAMC - MOLOWVOL",
      "tradingSymbol": "MOLOWVOL",
      "label": "MOLOWVOL",
      "value": "MOLOWVOL"
    },
    {
      "instrument_token": "672513",
      "name": "KOTAKMAMC - KOTAKIT",
      "tradingSymbol": "IT",
      "label": "IT",
      "value": "IT"
    },
    {
      "instrument_token": "2690817",
      "name": "UTIAMC - UTINIFTETF",
      "tradingSymbol": "UTINIFTETF",
      "label": "UTINIFTETF",
      "value": "UTINIFTETF"
    },
    {
      "instrument_token": "307201",
      "name": "MIRAEAMC - MAESGETF",
      "tradingSymbol": "ESG",
      "label": "ESG",
      "value": "ESG"
    },
    {
      "instrument_token": "3363585",
      "name": "EDELAMC - EBBETF0433",
      "tradingSymbol": "EBBETF0433",
      "label": "EBBETF0433",
      "value": "EBBETF0433"
    },
    {
      "instrument_token": "5694209",
      "name": "EDELAMC - EBBETF0425",
      "tradingSymbol": "EBBETF0425",
      "label": "EBBETF0425",
      "value": "EBBETF0425"
    },
    {
      "instrument_token": "7571713",
      "name": "NIP IND ETF NIFTY 100",
      "tradingSymbol": "NIF100BEES",
      "label": "NIF100BEES",
      "value": "NIF100BEES"
    },
    {
      "instrument_token": "2709761",
      "name": "ICICIPRAMC - ICICIMOM30",
      "tradingSymbol": "MOM30IETF",
      "label": "MOM30IETF",
      "value": "MOM30IETF"
    },
    {
      "instrument_token": "2520833",
      "name": "NIP IND ETF ETF NV20",
      "tradingSymbol": "NV20BEES",
      "label": "NV20BEES",
      "value": "NV20BEES"
    },
    {
      "instrument_token": "3657217",
      "name": "MIRAEAMC - MAGOLDETF",
      "tradingSymbol": "GOLDETF",
      "label": "GOLDETF",
      "value": "GOLDETF"
    },
    {
      "instrument_token": "5783553",
      "name": "DSPAMC - HEALTHADD",
      "tradingSymbol": "HEALTHADD",
      "label": "HEALTHADD",
      "value": "HEALTHADD"
    },
    {
      "instrument_token": "7565569",
      "name": "ICICI PRUD NIFTY ETF",
      "tradingSymbol": "NIFTYIETF",
      "label": "NIFTYIETF",
      "value": "NIFTYIETF"
    },
    {
      "instrument_token": "2347009",
      "name": "UTIAMC - UTISXN50",
      "tradingSymbol": "UTISXN50",
      "label": "UTISXN50",
      "value": "UTISXN50"
    },
    {
      "instrument_token": "903681",
      "name": "AXISAMC - AXISBPSETF",
      "tradingSymbol": "AXISBPSETF",
      "label": "AXISBPSETF",
      "value": "AXISBPSETF"
    },
    {
      "instrument_token": "4294913",
      "name": "MIRAEAMC - MASILVER",
      "tradingSymbol": "SILVRETF",
      "label": "SILVRETF",
      "value": "SILVRETF"
    },
    {
      "instrument_token": "4305665",
      "name": "QUANTUM NIFTY 50 ETF",
      "tradingSymbol": "QNIFTY",
      "label": "QNIFTY",
      "value": "QNIFTY"
    },
    {
      "instrument_token": "1691137",
      "name": "DSPAMC - DSPNEWETF",
      "tradingSymbol": "EQUAL50ADD",
      "label": "EQUAL50ADD",
      "value": "EQUAL50ADD"
    },
    {
      "instrument_token": "2877697",
      "name": "HDFCAMC - HDFCGROWTH",
      "tradingSymbol": "HDFCGROWTH",
      "label": "HDFCGROWTH",
      "value": "HDFCGROWTH"
    },
    {
      "instrument_token": "770561",
      "name": "AXISAMC - AXISTECETF",
      "tradingSymbol": "AXISTECETF",
      "label": "AXISTECETF",
      "value": "AXISTECETF"
    },
    {
      "instrument_token": "1842177",
      "name": "EDELAMC - BBETF0432",
      "tradingSymbol": "BBETF0432",
      "label": "BBETF0432",
      "value": "BBETF0432"
    },
    {
      "instrument_token": "5102849",
      "name": "EDELAMC - EGOLD",
      "tradingSymbol": "EGOLD",
      "label": "EGOLD",
      "value": "EGOLD"
    },
    {
      "instrument_token": "1650177",
      "name": "ICICIPRAMC - ICICICONSU",
      "tradingSymbol": "CONSUMIETF",
      "label": "CONSUMIETF",
      "value": "CONSUMIETF"
    },
    {
      "instrument_token": "3364609",
      "name": "ICICIPRAMC - ICICI10GS",
      "tradingSymbol": "GSEC10IETF",
      "label": "GSEC10IETF",
      "value": "GSEC10IETF"
    },
    {
      "instrument_token": "1810945",
      "name": "MIRAEAMC - MAHKTECH",
      "tradingSymbol": "MAHKTECH",
      "label": "MAHKTECH",
      "value": "MAHKTECH"
    },
    {
      "instrument_token": "5693185",
      "name": "EDELAMC - EBBETF0431",
      "tradingSymbol": "EBBETF0431",
      "label": "EBBETF0431",
      "value": "EBBETF0431"
    },
    {
      "instrument_token": "3642881",
      "name": "HDFCAMC - HDFCBSE500",
      "tradingSymbol": "HDFCBSE500",
      "label": "HDFCBSE500",
      "value": "HDFCBSE500"
    },
    {
      "instrument_token": "3693569",
      "name": "NIP IND ETF GOLD BEES",
      "tradingSymbol": "GOLDBEES",
      "label": "GOLDBEES",
      "value": "GOLDBEES"
    },
    {
      "instrument_token": "4160769",
      "name": "EDELAMC - EBBETF0430",
      "tradingSymbol": "EBBETF0430",
      "label": "EBBETF0430",
      "value": "EBBETF0430"
    },
    {
      "instrument_token": "5992193",
      "name": "DSPAMC - LIQUIDADD",
      "tradingSymbol": "LIQUIDADD",
      "label": "LIQUIDADD",
      "value": "LIQUIDADD"
    },
    {
      "instrument_token": "5568001",
      "name": "ZERODHAAMC - LIQUIDCASE",
      "tradingSymbol": "LIQUIDCASE",
      "label": "LIQUIDCASE",
      "value": "LIQUIDCASE"
    },
    {
      "instrument_token": "3571201",
      "name": "KOTAKMAMC - KOTAKLIQ",
      "tradingSymbol": "LIQUID1",
      "label": "LIQUID1",
      "value": "LIQUID1"
    },
    {
      "instrument_token": "5044481",
      "name": "SBIAMC - LIQUIDSBI",
      "tradingSymbol": "LIQUIDSBI",
      "label": "LIQUIDSBI",
      "value": "LIQUIDSBI"
    },
    {
      "instrument_token": "3785217",
      "name": "MIRAEAMC - MANV30F",
      "tradingSymbol": "LOWVOL",
      "label": "LOWVOL",
      "value": "LOWVOL"
    },
    {
      "instrument_token": "5862657",
      "name": "ZERODHAAMC - GOLDCASE",
      "tradingSymbol": "GOLDCASE",
      "label": "GOLDCASE",
      "value": "GOLDCASE"
    },
    {
      "instrument_token": "4508929",
      "name": "DSPAMC - DSPSENXETF",
      "tradingSymbol": "SENSEXADD",
      "label": "SENSEXADD",
      "value": "SENSEXADD"
    },
    {
      "instrument_token": "2817537",
      "name": "NIP IND ETF LIQUID BEES",
      "tradingSymbol": "LIQUIDBEES",
      "label": "LIQUIDBEES",
      "value": "LIQUIDBEES"
    },
    {
      "instrument_token": "6093825",
      "name": "ADITYBIRLA SL GOLD ETF-GR",
      "tradingSymbol": "BSLGOLDETF",
      "label": "BSLGOLDETF",
      "value": "BSLGOLDETF"
    },
    {
      "instrument_token": "4679425",
      "name": "HDFCAMC - HDFCLIQUID",
      "tradingSymbol": "HDFCLIQUID",
      "label": "HDFCLIQUID",
      "value": "HDFCLIQUID"
    },
    {
      "instrument_token": "4498433",
      "name": "MIRAEAMC - LIQUID",
      "tradingSymbol": "LIQUID",
      "label": "LIQUID",
      "value": "LIQUID"
    },
    {
      "instrument_token": "3716609",
      "name": "BIRLASLAMC - ABSLLIQUID",
      "tradingSymbol": "ABSLLIQUID",
      "label": "ABSLLIQUID",
      "value": "ABSLLIQUID"
    },
    {
      "instrument_token": "1238529",
      "name": "ICICIPRAMC - ICICILIQ",
      "tradingSymbol": "LIQUIDIETF",
      "label": "LIQUIDIETF",
      "value": "LIQUIDIETF"
    },
    {
      "instrument_token": "493313",
      "name": "DSP LIQUID ETF",
      "tradingSymbol": "LIQUIDETF",
      "label": "LIQUIDETF",
      "value": "LIQUIDETF"
    },
    {
      "instrument_token": "2094593",
      "name": "MOTILALAMC - MOMOMENTUM",
      "tradingSymbol": "MOMOMENTUM",
      "label": "MOMOMENTUM",
      "value": "MOMOMENTUM"
    },
    {
      "instrument_token": "5720065",
      "name": "ICICIPRAMC - ICICIALPLV",
      "tradingSymbol": "ALPL30IETF",
      "label": "ALPL30IETF",
      "value": "ALPL30IETF"
    },
    {
      "instrument_token": "2882561",
      "name": "HDFCAMC - HDFCVALUE",
      "tradingSymbol": "HDFCVALUE",
      "label": "HDFCVALUE",
      "value": "HDFCVALUE"
    },
    {
      "instrument_token": "2800385",
      "name": "NIP IND ETF JUNIOR BEES",
      "tradingSymbol": "JUNIORBEES",
      "label": "JUNIORBEES",
      "value": "JUNIORBEES"
    },
    {
      "instrument_token": "3803649",
      "name": "KOTAK GOLD ETF",
      "tradingSymbol": "GOLD1",
      "label": "GOLD1",
      "value": "GOLD1"
    },
    {
      "instrument_token": "2691073",
      "name": "KOTAKMAMC - KOTAKCONS",
      "tradingSymbol": "CONS",
      "label": "CONS",
      "value": "CONS"
    },
    {
      "instrument_token": "4531201",
      "name": "NIP IND ETF LONGTERM GILT",
      "tradingSymbol": "LTGILTBEES",
      "label": "LTGILTBEES",
      "value": "LTGILTBEES"
    },
    {
      "instrument_token": "5766145",
      "name": "UTIAMC - NIF10GETF",
      "tradingSymbol": "NIF10GETF",
      "label": "NIF10GETF",
      "value": "NIF10GETF"
    },
    {
      "instrument_token": "2754817",
      "name": "DSPAMC - DSPSILVETF",
      "tradingSymbol": "SILVERADD",
      "label": "SILVERADD",
      "value": "SILVERADD"
    },
    {
      "instrument_token": "4634113",
      "name": "KOTAK NIFTY ETF",
      "tradingSymbol": "NIFTY1",
      "label": "NIFTY1",
      "value": "NIFTY1"
    },
    {
      "instrument_token": "5003009",
      "name": "HDFC GOLD ETF",
      "tradingSymbol": "HDFCGOLD",
      "label": "HDFCGOLD",
      "value": "HDFCGOLD"
    },
    {
      "instrument_token": "2048769",
      "name": "BIRLASLAMC - SILVER",
      "tradingSymbol": "SILVER",
      "label": "SILVER",
      "value": "SILVER"
    },
    {
      "instrument_token": "1878785",
      "name": "BIRLASLAMC - ABSLNN50ET",
      "tradingSymbol": "ABSLNN50ET",
      "label": "ABSLNN50ET",
      "value": "ABSLNN50ET"
    },
    {
      "instrument_token": "1159425",
      "name": "ICICIPRAMC - ICICINXT50",
      "tradingSymbol": "NEXT50IETF",
      "label": "NEXT50IETF",
      "value": "NEXT50IETF"
    },
    {
      "instrument_token": "6563841",
      "name": "LIC MF - LIC GOLD ETF",
      "tradingSymbol": "LICMFGOLD",
      "label": "LICMFGOLD",
      "value": "LICMFGOLD"
    },
    {
      "instrument_token": "3720961",
      "name": "UTI GOLD ETF",
      "tradingSymbol": "GOLDSHARE",
      "label": "GOLDSHARE",
      "value": "GOLDSHARE"
    },
    {
      "instrument_token": "2008065",
      "name": "ICICIPRAMC - ICICIAUTO",
      "tradingSymbol": "AUTOIETF",
      "label": "AUTOIETF",
      "value": "AUTOIETF"
    },
    {
      "instrument_token": "5037825",
      "name": "ICICI PRUDENTIAL GOLD ETF",
      "tradingSymbol": "GOLDIETF",
      "label": "GOLDIETF",
      "value": "GOLDIETF"
    },
    {
      "instrument_token": "2273793",
      "name": "TATAAML - TNIDETF",
      "tradingSymbol": "TNIDETF",
      "label": "TNIDETF",
      "value": "TNIDETF"
    },
    {
      "instrument_token": "3643649",
      "name": "HDFCAMC - HDFCSML250",
      "tradingSymbol": "HDFCSML250",
      "label": "HDFCSML250",
      "value": "HDFCSML250"
    },
    {
      "instrument_token": "3898625",
      "name": "LICNAMC - LICNFNHGP",
      "tradingSymbol": "LICNFNHGP",
      "label": "LICNFNHGP",
      "value": "LICNFNHGP"
    },
    {
      "instrument_token": "4404993",
      "name": "DSPAMC - DSPITETF",
      "tradingSymbol": "ITETFADD",
      "label": "ITETFADD",
      "value": "ITETFADD"
    },
    {
      "instrument_token": "292609",
      "name": "NIPPON INDIA- NIMFXX",
      "tradingSymbol": "SDL24BEES",
      "label": "SDL24BEES",
      "value": "SDL24BEES"
    },
    {
      "instrument_token": "5478657",
      "name": "TATAAML-TATAGOLD",
      "tradingSymbol": "TATAGOLD",
      "label": "TATAGOLD",
      "value": "TATAGOLD"
    },
    {
      "instrument_token": "5260033",
      "name": "BARODABNP - BBNPPGOLD",
      "tradingSymbol": "BBNPPGOLD",
      "label": "BBNPPGOLD",
      "value": "BBNPPGOLD"
    },
    {
      "instrument_token": "4921345",
      "name": "MIRAEAMC - SENSEXETF",
      "tradingSymbol": "SENSEXETF",
      "label": "SENSEXETF",
      "value": "SENSEXETF"
    },
    {
      "instrument_token": "3348993",
      "name": "KOTAKMAMC - KOTAKSILVE",
      "tradingSymbol": "SILVER1",
      "label": "SILVER1",
      "value": "SILVER1"
    },
    {
      "instrument_token": "5256193",
      "name": "AXIS MF - AXIS GOLD ETF",
      "tradingSymbol": "AXISGOLD",
      "label": "AXISGOLD",
      "value": "AXISGOLD"
    },
    {
      "instrument_token": "4682753",
      "name": "INVESCO INDIA GOLD ETF",
      "tradingSymbol": "IVZINGOLD",
      "label": "IVZINGOLD",
      "value": "IVZINGOLD"
    },
    {
      "instrument_token": "3924481",
      "name": "QUANTUM GOLD FUND",
      "tradingSymbol": "QGOLDHALF",
      "label": "QGOLDHALF",
      "value": "QGOLDHALF"
    },
    {
      "instrument_token": "2017281",
      "name": "NIPPONAMC - NETFAUTO",
      "tradingSymbol": "AUTOBEES",
      "label": "AUTOBEES",
      "value": "AUTOBEES"
    },
    {
      "instrument_token": "2722049",
      "name": "HDFCAMC - HDFCNIF100",
      "tradingSymbol": "HDFCNIF100",
      "label": "HDFCNIF100",
      "value": "HDFCNIF100"
    },
    {
      "instrument_token": "4421633",
      "name": "SBI-ETF GOLD",
      "tradingSymbol": "SETFGOLD",
      "label": "SETFGOLD",
      "value": "SETFGOLD"
    },
    {
      "instrument_token": "5744641",
      "name": "UTIAMC - UTIBANKETF",
      "tradingSymbol": "UTIBANKETF",
      "label": "UTIBANKETF",
      "value": "UTIBANKETF"
    },
    {
      "instrument_token": "2737409",
      "name": "BIRLASLAMC - MOMENTUM",
      "tradingSymbol": "MOMENTUM",
      "label": "MOMENTUM",
      "value": "MOMENTUM"
    },
    {
      "instrument_token": "1480193",
      "name": "MIRAEAMC - MASPTOP50",
      "tradingSymbol": "MASPTOP50",
      "label": "MASPTOP50",
      "value": "MASPTOP50"
    },
    {
      "instrument_token": "5185793",
      "name": "EDELAMC - ESILVER",
      "tradingSymbol": "ESILVER",
      "label": "ESILVER",
      "value": "ESILVER"
    },
    {
      "instrument_token": "2718465",
      "name": "HDFCAMC - HDFCNEXT50",
      "tradingSymbol": "HDFCNEXT50",
      "label": "HDFCNEXT50",
      "value": "HDFCNEXT50"
    },
    {
      "instrument_token": "5934337",
      "name": "MOTILALAMC - MOSMALL250",
      "tradingSymbol": "MOSMALL250",
      "label": "MOSMALL250",
      "value": "MOSMALL250"
    },
    {
      "instrument_token": "4531713",
      "name": "ICICI PRUD MIDCAP SEL ETF",
      "tradingSymbol": "MIDSELIETF",
      "label": "MIDSELIETF",
      "value": "MIDSELIETF"
    },
    {
      "instrument_token": "773633",
      "name": "RELCAPAMC-NETFSDL26",
      "tradingSymbol": "SDL26BEES",
      "label": "SDL26BEES",
      "value": "SDL26BEES"
    },
    {
      "instrument_token": "5742849",
      "name": "HDFCAMC - HDFCNIFBAN",
      "tradingSymbol": "HDFCNIFBAN",
      "label": "HDFCNIFBAN",
      "value": "HDFCNIFBAN"
    },
    {
      "instrument_token": "5844993",
      "name": "MIRAEAMC - SMALLCAP",
      "tradingSymbol": "SMALLCAP",
      "label": "SMALLCAP",
      "value": "SMALLCAP"
    },
    {
      "instrument_token": "2135553",
      "name": "ICICIPRAMC - ICICI5GSEC",
      "tradingSymbol": "GSEC5IETF",
      "label": "GSEC5IETF",
      "value": "GSEC5IETF"
    },
    {
      "instrument_token": "4453121",
      "name": "SBIAMC - SETF10GILT",
      "tradingSymbol": "SETF10GILT",
      "label": "SETF10GILT",
      "value": "SETF10GILT"
    },
    {
      "instrument_token": "2914817",
      "name": "ICICIPRAMC - ICICIBANKP",
      "tradingSymbol": "PVTBANIETF",
      "label": "PVTBANIETF",
      "value": "PVTBANIETF"
    },
    {
      "instrument_token": "1497857",
      "name": "KOTAKMAMC-KOTAKBKETF",
      "tradingSymbol": "BANKNIFTY1",
      "label": "BANKNIFTY1",
      "value": "BANKNIFTY1"
    },
    {
      "instrument_token": "5756929",
      "name": "UTIAMC - NIFITETF",
      "tradingSymbol": "NIFITETF",
      "label": "NIFITETF",
      "value": "NIFITETF"
    },
    {
      "instrument_token": "2928385",
      "name": "NIP IND ETF BANK BEES",
      "tradingSymbol": "BANKBEES",
      "label": "BANKBEES",
      "value": "BANKBEES"
    },
    {
      "instrument_token": "3580673",
      "name": "BIRLASLAMC - ABSLBANETF",
      "tradingSymbol": "ABSLBANETF",
      "label": "ABSLBANETF",
      "value": "ABSLBANETF"
    },
    {
      "instrument_token": "2953729",
      "name": "HDFCAMC - HDFCMOMENT",
      "tradingSymbol": "HDFCMOMENT",
      "label": "HDFCMOMENT",
      "value": "HDFCMOMENT"
    },
    {
      "instrument_token": "3931137",
      "name": "DSPAMC - DSPGOLDETF",
      "tradingSymbol": "GOLDETFADD",
      "label": "GOLDETFADD",
      "value": "GOLDETFADD"
    },
    {
      "instrument_token": "4398337",
      "name": "MIRAEAMC - MANXT50",
      "tradingSymbol": "NEXT50",
      "label": "NEXT50",
      "value": "NEXT50"
    },
    {
      "instrument_token": "5765377",
      "name": "UTIAMC - NIF5GETF",
      "tradingSymbol": "NIF5GETF",
      "label": "NIF5GETF",
      "value": "NIF5GETF"
    },
    {
      "instrument_token": "1884417",
      "name": "SBI-ETF NIFTY BANK",
      "tradingSymbol": "SETFNIFBK",
      "label": "SETFNIFBK",
      "value": "SETFNIFBK"
    },
    {
      "instrument_token": "2771201",
      "name": "MOTILALAMC - MOVALUE",
      "tradingSymbol": "MOVALUE",
      "label": "MOVALUE",
      "value": "MOVALUE"
    },
    {
      "instrument_token": "2825473",
      "name": "ICICIPRAMC - IPRU5008",
      "tradingSymbol": "BANKIETF",
      "label": "BANKIETF",
      "value": "BANKIETF"
    },
    {
      "instrument_token": "3322369",
      "name": "TATAAML - NPBET",
      "tradingSymbol": "NPBET",
      "label": "NPBET",
      "value": "NPBET"
    },
    {
      "instrument_token": "1336321",
      "name": "MIRAEAMC - MAFSETF",
      "tradingSymbol": "BFSI",
      "label": "BFSI",
      "value": "BFSI"
    },
    {
      "instrument_token": "3099649",
      "name": "HDFCAMC - HDFCPVTBAN",
      "tradingSymbol": "HDFCPVTBAN",
      "label": "HDFCPVTBAN",
      "value": "HDFCPVTBAN"
    },
    {
      "instrument_token": "3773953",
      "name": "AXISAMC - AXSENSEX",
      "tradingSymbol": "AXSENSEX",
      "label": "AXSENSEX",
      "value": "AXSENSEX"
    },
    {
      "instrument_token": "2690049",
      "name": "MOTILALAMC - MOHEALTH",
      "tradingSymbol": "MOHEALTH",
      "label": "MOHEALTH",
      "value": "MOHEALTH"
    },
    {
      "instrument_token": "5027841",
      "name": "MIRAEAMC - ALPHAETF",
      "tradingSymbol": "ALPHAETF",
      "label": "ALPHAETF",
      "value": "ALPHAETF"
    },
    {
      "instrument_token": "4459265",
      "name": "MIRAEAMC - BANKETF",
      "tradingSymbol": "BANKETF",
      "label": "BANKETF",
      "value": "BANKETF"
    },
    {
      "instrument_token": "3219969",
      "name": "ICICIPRAMC-ICICIFIN",
      "tradingSymbol": "FINIETF",
      "label": "FINIETF",
      "value": "FINIETF"
    },
    {
      "instrument_token": "267265",
      "name": "AXISAMC - AXISBNKETF",
      "tradingSymbol": "AXISBNKETF",
      "label": "AXISBNKETF",
      "value": "AXISBNKETF"
    },
    {
      "instrument_token": "4499457",
      "name": "DSPAMC - DSPPVBKETF",
      "tradingSymbol": "PVTBANKADD",
      "label": "PVTBANKADD",
      "value": "PVTBANKADD"
    },
    {
      "instrument_token": "5628417",
      "name": "BFAM - BANKBETF",
      "tradingSymbol": "BANKBETF",
      "label": "BANKBETF",
      "value": "BANKBETF"
    },
    {
      "instrument_token": "3492865",
      "name": "DSPAMC - DSPBANKETF",
      "tradingSymbol": "BANKETFADD",
      "label": "BANKETFADD",
      "value": "BANKETFADD"
    },
    {
      "instrument_token": "184833",
      "name": "SBIAMC - SBIETFPB",
      "tradingSymbol": "SBIETFPB",
      "label": "SBIETFPB",
      "value": "SBIETFPB"
    },
    {
      "instrument_token": "1524993",
      "name": "BIRLASLAMC - BSLSENETFG",
      "tradingSymbol": "BSLSENETFG",
      "label": "BSLSENETFG",
      "value": "BSLSENETFG"
    },
    {
      "instrument_token": "2733057",
      "name": "KOTAKMAMC - KOTAKMNC",
      "tradingSymbol": "MNC",
      "label": "MNC",
      "value": "MNC"
    }
  ],
  futures:[
    {
      "instrument_token": "8961026",
      "name": "NIFTY 50",
      "tradingSymbol": "NIFTY24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "25",
      "motherInstrument": "256265",
      "category": "futures",
      "label": "NIFTY24JUNFUT",
      "value": "NIFTY24JUNFUT"
    },
    {
      "instrument_token": "8961794",
      "name": "NIFTY 50",
      "tradingSymbol": "NIFTY24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "25",
      "motherInstrument": "256265",
      "category": "futures",
      "label": "NIFTY24JULFUT",
      "value": "NIFTY24JULFUT"
    },
    {
      "instrument_token": "9066242",
      "name": "NIFTY 50",
      "tradingSymbol": "NIFTY24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "25",
      "motherInstrument": "256265",
      "category": "futures",
      "label": "NIFTY24AUGFUT",
      "value": "NIFTY24AUGFUT"
    },
    {
      "instrument_token": "8965122",
      "name": "NIFTY BANK",
      "tradingSymbol": "BANKNIFTY24JUNFUT",
      "expiry": "6/26/24",
      "lot_size": "15",
      "motherInstrument": "260105",
      "category": "futures",
      "label": "BANKNIFTY24JUNFUT",
      "value": "BANKNIFTY24JUNFUT"
    },
    {
      "instrument_token": "9002242",
      "name": "NIFTY BANK",
      "tradingSymbol": "BANKNIFTY24JULFUT",
      "expiry": "7/31/24",
      "lot_size": "15",
      "motherInstrument": "260105",
      "category": "futures",
      "label": "BANKNIFTY24JULFUT",
      "value": "BANKNIFTY24JULFUT"
    },
    {
      "instrument_token": "8982786",
      "name": "NIFTY BANK",
      "tradingSymbol": "BANKNIFTY24AUGFUT",
      "expiry": "8/28/24",
      "lot_size": "15",
      "motherInstrument": "260105",
      "category": "futures",
      "label": "BANKNIFTY24AUGFUT",
      "value": "BANKNIFTY24AUGFUT"
    },
    {
      "instrument_token": "8961282",
      "name": "AARTIIND",
      "tradingSymbol": "AARTIIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "1793",
      "category": "futures",
      "label": "AARTIIND24JUNFUT",
      "value": "AARTIIND24JUNFUT"
    },
    {
      "instrument_token": "8963074",
      "name": "AARTIIND",
      "tradingSymbol": "AARTIIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "1793",
      "category": "futures",
      "label": "AARTIIND24JULFUT",
      "value": "AARTIIND24JULFUT"
    },
    {
      "instrument_token": "9069058",
      "name": "AARTIIND",
      "tradingSymbol": "AARTIIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "1793",
      "category": "futures",
      "label": "AARTIIND24AUGFUT",
      "value": "AARTIIND24AUGFUT"
    },
    {
      "instrument_token": "8967170",
      "name": "ABB",
      "tradingSymbol": "ABB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "125",
      "motherInstrument": "3329",
      "category": "futures",
      "label": "ABB24JUNFUT",
      "value": "ABB24JUNFUT"
    },
    {
      "instrument_token": "8963330",
      "name": "ABB",
      "tradingSymbol": "ABB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "125",
      "motherInstrument": "3329",
      "category": "futures",
      "label": "ABB24JULFUT",
      "value": "ABB24JULFUT"
    },
    {
      "instrument_token": "9082626",
      "name": "ABB",
      "tradingSymbol": "ABB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "125",
      "motherInstrument": "3329",
      "category": "futures",
      "label": "ABB24AUGFUT",
      "value": "ABB24AUGFUT"
    },
    {
      "instrument_token": "8967426",
      "name": "ABBOTINDIA",
      "tradingSymbol": "ABBOTINDIA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "20",
      "motherInstrument": "4583169",
      "category": "futures",
      "label": "ABBOTINDIA24JUNFUT",
      "value": "ABBOTINDIA24JUNFUT"
    },
    {
      "instrument_token": "8963586",
      "name": "ABBOTINDIA",
      "tradingSymbol": "ABBOTINDIA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "20",
      "motherInstrument": "4583169",
      "category": "futures",
      "label": "ABBOTINDIA24JULFUT",
      "value": "ABBOTINDIA24JULFUT"
    },
    {
      "instrument_token": "9082882",
      "name": "ABBOTINDIA",
      "tradingSymbol": "ABBOTINDIA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "20",
      "motherInstrument": "4583169",
      "category": "futures",
      "label": "ABBOTINDIA24AUGFUT",
      "value": "ABBOTINDIA24AUGFUT"
    },
    {
      "instrument_token": "8967682",
      "name": "ABCAPITAL",
      "tradingSymbol": "ABCAPITAL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5400",
      "motherInstrument": "5533185",
      "category": "futures",
      "label": "ABCAPITAL24JUNFUT",
      "value": "ABCAPITAL24JUNFUT"
    },
    {
      "instrument_token": "8963842",
      "name": "ABCAPITAL",
      "tradingSymbol": "ABCAPITAL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5400",
      "motherInstrument": "5533185",
      "category": "futures",
      "label": "ABCAPITAL24JULFUT",
      "value": "ABCAPITAL24JULFUT"
    },
    {
      "instrument_token": "9091330",
      "name": "ABCAPITAL",
      "tradingSymbol": "ABCAPITAL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5400",
      "motherInstrument": "5533185",
      "category": "futures",
      "label": "ABCAPITAL24AUGFUT",
      "value": "ABCAPITAL24AUGFUT"
    },
    {
      "instrument_token": "8967938",
      "name": "ABFRL",
      "tradingSymbol": "ABFRL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2600",
      "motherInstrument": "7707649",
      "category": "futures",
      "label": "ABFRL24JUNFUT",
      "value": "ABFRL24JUNFUT"
    },
    {
      "instrument_token": "8964098",
      "name": "ABFRL",
      "tradingSymbol": "ABFRL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2600",
      "motherInstrument": "7707649",
      "category": "futures",
      "label": "ABFRL24JULFUT",
      "value": "ABFRL24JULFUT"
    },
    {
      "instrument_token": "9092098",
      "name": "ABFRL",
      "tradingSymbol": "ABFRL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2600",
      "motherInstrument": "7707649",
      "category": "futures",
      "label": "ABFRL24AUGFUT",
      "value": "ABFRL24AUGFUT"
    },
    {
      "instrument_token": "8972034",
      "name": "ACC",
      "tradingSymbol": "ACC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "5633",
      "category": "futures",
      "label": "ACC24JUNFUT",
      "value": "ACC24JUNFUT"
    },
    {
      "instrument_token": "8964354",
      "name": "ACC",
      "tradingSymbol": "ACC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "5633",
      "category": "futures",
      "label": "ACC24JULFUT",
      "value": "ACC24JULFUT"
    },
    {
      "instrument_token": "9092354",
      "name": "ACC",
      "tradingSymbol": "ACC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "5633",
      "category": "futures",
      "label": "ACC24AUGFUT",
      "value": "ACC24AUGFUT"
    },
    {
      "instrument_token": "8972290",
      "name": "ADANIENT",
      "tradingSymbol": "ADANIENT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "6401",
      "category": "futures",
      "label": "ADANIENT24JUNFUT",
      "value": "ADANIENT24JUNFUT"
    },
    {
      "instrument_token": "8964610",
      "name": "ADANIENT",
      "tradingSymbol": "ADANIENT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "6401",
      "category": "futures",
      "label": "ADANIENT24JULFUT",
      "value": "ADANIENT24JULFUT"
    },
    {
      "instrument_token": "9093634",
      "name": "ADANIENT",
      "tradingSymbol": "ADANIENT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "6401",
      "category": "futures",
      "label": "ADANIENT24AUGFUT",
      "value": "ADANIENT24AUGFUT"
    },
    {
      "instrument_token": "8978946",
      "name": "ADANIPORTS",
      "tradingSymbol": "ADANIPORTS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "400",
      "motherInstrument": "3861249",
      "category": "futures",
      "label": "ADANIPORTS24JUNFUT",
      "value": "ADANIPORTS24JUNFUT"
    },
    {
      "instrument_token": "8964866",
      "name": "ADANIPORTS",
      "tradingSymbol": "ADANIPORTS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "400",
      "motherInstrument": "3861249",
      "category": "futures",
      "label": "ADANIPORTS24JULFUT",
      "value": "ADANIPORTS24JULFUT"
    },
    {
      "instrument_token": "9101058",
      "name": "ADANIPORTS",
      "tradingSymbol": "ADANIPORTS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "400",
      "motherInstrument": "3861249",
      "category": "futures",
      "label": "ADANIPORTS24AUGFUT",
      "value": "ADANIPORTS24AUGFUT"
    },
    {
      "instrument_token": "8979458",
      "name": "ALKEM",
      "tradingSymbol": "ALKEM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "100",
      "motherInstrument": "2995969",
      "category": "futures",
      "label": "ALKEM24JUNFUT",
      "value": "ALKEM24JUNFUT"
    },
    {
      "instrument_token": "8966658",
      "name": "ALKEM",
      "tradingSymbol": "ALKEM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "100",
      "motherInstrument": "2995969",
      "category": "futures",
      "label": "ALKEM24JULFUT",
      "value": "ALKEM24JULFUT"
    },
    {
      "instrument_token": "9101314",
      "name": "ALKEM",
      "tradingSymbol": "ALKEM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "100",
      "motherInstrument": "2995969",
      "category": "futures",
      "label": "ALKEM24AUGFUT",
      "value": "ALKEM24AUGFUT"
    },
    {
      "instrument_token": "9011202",
      "name": "AMBUJACEM",
      "tradingSymbol": "AMBUJACEM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "900",
      "motherInstrument": "325121",
      "category": "futures",
      "label": "AMBUJACEM24JUNFUT",
      "value": "AMBUJACEM24JUNFUT"
    },
    {
      "instrument_token": "8966914",
      "name": "AMBUJACEM",
      "tradingSymbol": "AMBUJACEM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "900",
      "motherInstrument": "325121",
      "category": "futures",
      "label": "AMBUJACEM24JULFUT",
      "value": "AMBUJACEM24JULFUT"
    },
    {
      "instrument_token": "9101570",
      "name": "AMBUJACEM",
      "tradingSymbol": "AMBUJACEM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "900",
      "motherInstrument": "325121",
      "category": "futures",
      "label": "AMBUJACEM24AUGFUT",
      "value": "AMBUJACEM24AUGFUT"
    },
    {
      "instrument_token": "9011458",
      "name": "APOLLOHOSP",
      "tradingSymbol": "APOLLOHOSP24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "125",
      "motherInstrument": "40193",
      "category": "futures",
      "label": "APOLLOHOSP24JUNFUT",
      "value": "APOLLOHOSP24JUNFUT"
    },
    {
      "instrument_token": "8968194",
      "name": "APOLLOHOSP",
      "tradingSymbol": "APOLLOHOSP24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "125",
      "motherInstrument": "40193",
      "category": "futures",
      "label": "APOLLOHOSP24JULFUT",
      "value": "APOLLOHOSP24JULFUT"
    },
    {
      "instrument_token": "9101826",
      "name": "APOLLOHOSP",
      "tradingSymbol": "APOLLOHOSP24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "125",
      "motherInstrument": "40193",
      "category": "futures",
      "label": "APOLLOHOSP24AUGFUT",
      "value": "APOLLOHOSP24AUGFUT"
    },
    {
      "instrument_token": "9011970",
      "name": "APOLLOTYRE",
      "tradingSymbol": "APOLLOTYRE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1700",
      "motherInstrument": "41729",
      "category": "futures",
      "label": "APOLLOTYRE24JUNFUT",
      "value": "APOLLOTYRE24JUNFUT"
    },
    {
      "instrument_token": "8968450",
      "name": "APOLLOTYRE",
      "tradingSymbol": "APOLLOTYRE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1700",
      "motherInstrument": "41729",
      "category": "futures",
      "label": "APOLLOTYRE24JULFUT",
      "value": "APOLLOTYRE24JULFUT"
    },
    {
      "instrument_token": "9102082",
      "name": "APOLLOTYRE",
      "tradingSymbol": "APOLLOTYRE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1700",
      "motherInstrument": "41729",
      "category": "futures",
      "label": "APOLLOTYRE24AUGFUT",
      "value": "APOLLOTYRE24AUGFUT"
    },
    {
      "instrument_token": "9013762",
      "name": "ASHOKLEY",
      "tradingSymbol": "ASHOKLEY24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5000",
      "motherInstrument": "54273",
      "category": "futures",
      "label": "ASHOKLEY24JUNFUT",
      "value": "ASHOKLEY24JUNFUT"
    },
    {
      "instrument_token": "8972802",
      "name": "ASHOKLEY",
      "tradingSymbol": "ASHOKLEY24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5000",
      "motherInstrument": "54273",
      "category": "futures",
      "label": "ASHOKLEY24JULFUT",
      "value": "ASHOKLEY24JULFUT"
    },
    {
      "instrument_token": "9102338",
      "name": "ASHOKLEY",
      "tradingSymbol": "ASHOKLEY24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5000",
      "motherInstrument": "54273",
      "category": "futures",
      "label": "ASHOKLEY24AUGFUT",
      "value": "ASHOKLEY24AUGFUT"
    },
    {
      "instrument_token": "9014530",
      "name": "ASIANPAINT",
      "tradingSymbol": "ASIANPAINT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "60417",
      "category": "futures",
      "label": "ASIANPAINT24JUNFUT",
      "value": "ASIANPAINT24JUNFUT"
    },
    {
      "instrument_token": "8996354",
      "name": "ASIANPAINT",
      "tradingSymbol": "ASIANPAINT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "60417",
      "category": "futures",
      "label": "ASIANPAINT24JULFUT",
      "value": "ASIANPAINT24JULFUT"
    },
    {
      "instrument_token": "9104642",
      "name": "ASIANPAINT",
      "tradingSymbol": "ASIANPAINT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "60417",
      "category": "futures",
      "label": "ASIANPAINT24AUGFUT",
      "value": "ASIANPAINT24AUGFUT"
    },
    {
      "instrument_token": "9015298",
      "name": "ASTRAL",
      "tradingSymbol": "ASTRAL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "367",
      "motherInstrument": "3691009",
      "category": "futures",
      "label": "ASTRAL24JUNFUT",
      "value": "ASTRAL24JUNFUT"
    },
    {
      "instrument_token": "8996610",
      "name": "ASTRAL",
      "tradingSymbol": "ASTRAL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "367",
      "motherInstrument": "3691009",
      "category": "futures",
      "label": "ASTRAL24JULFUT",
      "value": "ASTRAL24JULFUT"
    },
    {
      "instrument_token": "9105922",
      "name": "ASTRAL",
      "tradingSymbol": "ASTRAL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "367",
      "motherInstrument": "3691009",
      "category": "futures",
      "label": "ASTRAL24AUGFUT",
      "value": "ASTRAL24AUGFUT"
    },
    {
      "instrument_token": "9017090",
      "name": "ATUL",
      "tradingSymbol": "ATUL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "75",
      "motherInstrument": "67329",
      "category": "futures",
      "label": "ATUL24JUNFUT",
      "value": "ATUL24JUNFUT"
    },
    {
      "instrument_token": "9007362",
      "name": "ATUL",
      "tradingSymbol": "ATUL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "100",
      "motherInstrument": "67329",
      "category": "futures",
      "label": "ATUL24JULFUT",
      "value": "ATUL24JULFUT"
    },
    {
      "instrument_token": "9106690",
      "name": "ATUL",
      "tradingSymbol": "ATUL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "100",
      "motherInstrument": "67329",
      "category": "futures",
      "label": "ATUL24AUGFUT",
      "value": "ATUL24AUGFUT"
    },
    {
      "instrument_token": "9019394",
      "name": "AUBANK",
      "tradingSymbol": "AUBANK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "5436929",
      "category": "futures",
      "label": "AUBANK24JUNFUT",
      "value": "AUBANK24JUNFUT"
    },
    {
      "instrument_token": "9008642",
      "name": "AUBANK",
      "tradingSymbol": "AUBANK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "5436929",
      "category": "futures",
      "label": "AUBANK24JULFUT",
      "value": "AUBANK24JULFUT"
    },
    {
      "instrument_token": "9106946",
      "name": "AUBANK",
      "tradingSymbol": "AUBANK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "5436929",
      "category": "futures",
      "label": "AUBANK24AUGFUT",
      "value": "AUBANK24AUGFUT"
    },
    {
      "instrument_token": "9372418",
      "name": "AUROPHARMA",
      "tradingSymbol": "AUROPHARMA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "550",
      "motherInstrument": "70401",
      "category": "futures",
      "label": "AUROPHARMA24JUNFUT",
      "value": "AUROPHARMA24JUNFUT"
    },
    {
      "instrument_token": "9008898",
      "name": "AUROPHARMA",
      "tradingSymbol": "AUROPHARMA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "550",
      "motherInstrument": "70401",
      "category": "futures",
      "label": "AUROPHARMA24JULFUT",
      "value": "AUROPHARMA24JULFUT"
    },
    {
      "instrument_token": "9112322",
      "name": "AUROPHARMA",
      "tradingSymbol": "AUROPHARMA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "550",
      "motherInstrument": "70401",
      "category": "futures",
      "label": "AUROPHARMA24AUGFUT",
      "value": "AUROPHARMA24AUGFUT"
    },
    {
      "instrument_token": "9748226",
      "name": "AXISBANK",
      "tradingSymbol": "AXISBANK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "625",
      "motherInstrument": "1510401",
      "category": "futures",
      "label": "AXISBANK24JUNFUT",
      "value": "AXISBANK24JUNFUT"
    },
    {
      "instrument_token": "9009154",
      "name": "AXISBANK",
      "tradingSymbol": "AXISBANK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "625",
      "motherInstrument": "1510401",
      "category": "futures",
      "label": "AXISBANK24JULFUT",
      "value": "AXISBANK24JULFUT"
    },
    {
      "instrument_token": "9114114",
      "name": "AXISBANK",
      "tradingSymbol": "AXISBANK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "625",
      "motherInstrument": "1510401",
      "category": "futures",
      "label": "AXISBANK24AUGFUT",
      "value": "AXISBANK24AUGFUT"
    },
    {
      "instrument_token": "9748482",
      "name": "BAJAJ-AUTO",
      "tradingSymbol": "BAJAJ-AUTO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "125",
      "motherInstrument": "4267265",
      "category": "futures",
      "label": "BAJAJ-AUTO24JUNFUT",
      "value": "BAJAJ-AUTO24JUNFUT"
    },
    {
      "instrument_token": "9009410",
      "name": "BAJAJ-AUTO",
      "tradingSymbol": "BAJAJ-AUTO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "75",
      "motherInstrument": "4267265",
      "category": "futures",
      "label": "BAJAJ-AUTO24JULFUT",
      "value": "BAJAJ-AUTO24JULFUT"
    },
    {
      "instrument_token": "9114370",
      "name": "BAJAJ-AUTO",
      "tradingSymbol": "BAJAJ-AUTO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "75",
      "motherInstrument": "4267265",
      "category": "futures",
      "label": "BAJAJ-AUTO24AUGFUT",
      "value": "BAJAJ-AUTO24AUGFUT"
    },
    {
      "instrument_token": "9833218",
      "name": "BAJAJFINSV",
      "tradingSymbol": "BAJAJFINSV24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "4268801",
      "category": "futures",
      "label": "BAJAJFINSV24JUNFUT",
      "value": "BAJAJFINSV24JUNFUT"
    },
    {
      "instrument_token": "9009922",
      "name": "BAJAJFINSV",
      "tradingSymbol": "BAJAJFINSV24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "4268801",
      "category": "futures",
      "label": "BAJAJFINSV24JULFUT",
      "value": "BAJAJFINSV24JULFUT"
    },
    {
      "instrument_token": "9114626",
      "name": "BAJAJFINSV",
      "tradingSymbol": "BAJAJFINSV24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "4268801",
      "category": "futures",
      "label": "BAJAJFINSV24AUGFUT",
      "value": "BAJAJFINSV24AUGFUT"
    },
    {
      "instrument_token": "9833474",
      "name": "BAJFINANCE",
      "tradingSymbol": "BAJFINANCE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "125",
      "motherInstrument": "81153",
      "category": "futures",
      "label": "BAJFINANCE24JUNFUT",
      "value": "BAJFINANCE24JUNFUT"
    },
    {
      "instrument_token": "9037314",
      "name": "BAJFINANCE",
      "tradingSymbol": "BAJFINANCE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "125",
      "motherInstrument": "81153",
      "category": "futures",
      "label": "BAJFINANCE24JULFUT",
      "value": "BAJFINANCE24JULFUT"
    },
    {
      "instrument_token": "9116930",
      "name": "BAJFINANCE",
      "tradingSymbol": "BAJFINANCE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "125",
      "motherInstrument": "81153",
      "category": "futures",
      "label": "BAJFINANCE24AUGFUT",
      "value": "BAJFINANCE24AUGFUT"
    },
    {
      "instrument_token": "9882882",
      "name": "BALKRISIND",
      "tradingSymbol": "BALKRISIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "85761",
      "category": "futures",
      "label": "BALKRISIND24JUNFUT",
      "value": "BALKRISIND24JUNFUT"
    },
    {
      "instrument_token": "9037570",
      "name": "BALKRISIND",
      "tradingSymbol": "BALKRISIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "85761",
      "category": "futures",
      "label": "BALKRISIND24JULFUT",
      "value": "BALKRISIND24JULFUT"
    },
    {
      "instrument_token": "9117186",
      "name": "BALKRISIND",
      "tradingSymbol": "BALKRISIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "85761",
      "category": "futures",
      "label": "BALKRISIND24AUGFUT",
      "value": "BALKRISIND24AUGFUT"
    },
    {
      "instrument_token": "9886978",
      "name": "BALRAMCHIN",
      "tradingSymbol": "BALRAMCHIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1600",
      "motherInstrument": "87297",
      "category": "futures",
      "label": "BALRAMCHIN24JUNFUT",
      "value": "BALRAMCHIN24JUNFUT"
    },
    {
      "instrument_token": "9046530",
      "name": "BALRAMCHIN",
      "tradingSymbol": "BALRAMCHIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1600",
      "motherInstrument": "87297",
      "category": "futures",
      "label": "BALRAMCHIN24JULFUT",
      "value": "BALRAMCHIN24JULFUT"
    },
    {
      "instrument_token": "9117442",
      "name": "BALRAMCHIN",
      "tradingSymbol": "BALRAMCHIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1600",
      "motherInstrument": "87297",
      "category": "futures",
      "label": "BALRAMCHIN24AUGFUT",
      "value": "BALRAMCHIN24AUGFUT"
    },
    {
      "instrument_token": "10019586",
      "name": "BANDHANBNK",
      "tradingSymbol": "BANDHANBNK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2500",
      "motherInstrument": "579329",
      "category": "futures",
      "label": "BANDHANBNK24JUNFUT",
      "value": "BANDHANBNK24JUNFUT"
    },
    {
      "instrument_token": "9047298",
      "name": "BANDHANBNK",
      "tradingSymbol": "BANDHANBNK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2800",
      "motherInstrument": "579329",
      "category": "futures",
      "label": "BANDHANBNK24JULFUT",
      "value": "BANDHANBNK24JULFUT"
    },
    {
      "instrument_token": "9117698",
      "name": "BANDHANBNK",
      "tradingSymbol": "BANDHANBNK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2800",
      "motherInstrument": "579329",
      "category": "futures",
      "label": "BANDHANBNK24AUGFUT",
      "value": "BANDHANBNK24AUGFUT"
    },
    {
      "instrument_token": "10019842",
      "name": "BANKBARODA",
      "tradingSymbol": "BANKBARODA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2925",
      "motherInstrument": "1195009",
      "category": "futures",
      "label": "BANKBARODA24JUNFUT",
      "value": "BANKBARODA24JUNFUT"
    },
    {
      "instrument_token": "9062146",
      "name": "BANKBARODA",
      "tradingSymbol": "BANKBARODA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2925",
      "motherInstrument": "1195009",
      "category": "futures",
      "label": "BANKBARODA24JULFUT",
      "value": "BANKBARODA24JULFUT"
    },
    {
      "instrument_token": "9117954",
      "name": "BANKBARODA",
      "tradingSymbol": "BANKBARODA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2925",
      "motherInstrument": "1195009",
      "category": "futures",
      "label": "BANKBARODA24AUGFUT",
      "value": "BANKBARODA24AUGFUT"
    },
    {
      "instrument_token": "10188034",
      "name": "BATAINDIA",
      "tradingSymbol": "BATAINDIA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "375",
      "motherInstrument": "94977",
      "category": "futures",
      "label": "BATAINDIA24JUNFUT",
      "value": "BATAINDIA24JUNFUT"
    },
    {
      "instrument_token": "9062914",
      "name": "BATAINDIA",
      "tradingSymbol": "BATAINDIA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "375",
      "motherInstrument": "94977",
      "category": "futures",
      "label": "BATAINDIA24JULFUT",
      "value": "BATAINDIA24JULFUT"
    },
    {
      "instrument_token": "9118210",
      "name": "BATAINDIA",
      "tradingSymbol": "BATAINDIA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "375",
      "motherInstrument": "94977",
      "category": "futures",
      "label": "BATAINDIA24AUGFUT",
      "value": "BATAINDIA24AUGFUT"
    },
    {
      "instrument_token": "10188290",
      "name": "BEL",
      "tradingSymbol": "BEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2850",
      "motherInstrument": "98049",
      "category": "futures",
      "label": "BEL24JUNFUT",
      "value": "BEL24JUNFUT"
    },
    {
      "instrument_token": "9063170",
      "name": "BEL",
      "tradingSymbol": "BEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2850",
      "motherInstrument": "98049",
      "category": "futures",
      "label": "BEL24JULFUT",
      "value": "BEL24JULFUT"
    },
    {
      "instrument_token": "9125122",
      "name": "BEL",
      "tradingSymbol": "BEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2850",
      "motherInstrument": "98049",
      "category": "futures",
      "label": "BEL24AUGFUT",
      "value": "BEL24AUGFUT"
    },
    {
      "instrument_token": "10340866",
      "name": "BERGEPAINT",
      "tradingSymbol": "BERGEPAINT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1320",
      "motherInstrument": "103425",
      "category": "futures",
      "label": "BERGEPAINT24JUNFUT",
      "value": "BERGEPAINT24JUNFUT"
    },
    {
      "instrument_token": "9065986",
      "name": "BERGEPAINT",
      "tradingSymbol": "BERGEPAINT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1320",
      "motherInstrument": "103425",
      "category": "futures",
      "label": "BERGEPAINT24JULFUT",
      "value": "BERGEPAINT24JULFUT"
    },
    {
      "instrument_token": "9125378",
      "name": "BERGEPAINT",
      "tradingSymbol": "BERGEPAINT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1320",
      "motherInstrument": "103425",
      "category": "futures",
      "label": "BERGEPAINT24AUGFUT",
      "value": "BERGEPAINT24AUGFUT"
    },
    {
      "instrument_token": "10341122",
      "name": "BHARATFORG",
      "tradingSymbol": "BHARATFORG24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "108033",
      "category": "futures",
      "label": "BHARATFORG24JUNFUT",
      "value": "BHARATFORG24JUNFUT"
    },
    {
      "instrument_token": "9084674",
      "name": "BHARATFORG",
      "tradingSymbol": "BHARATFORG24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "108033",
      "category": "futures",
      "label": "BHARATFORG24JULFUT",
      "value": "BHARATFORG24JULFUT"
    },
    {
      "instrument_token": "9304322",
      "name": "BHARATFORG",
      "tradingSymbol": "BHARATFORG24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "108033",
      "category": "futures",
      "label": "BHARATFORG24AUGFUT",
      "value": "BHARATFORG24AUGFUT"
    },
    {
      "instrument_token": "10341378",
      "name": "BHARTIARTL",
      "tradingSymbol": "BHARTIARTL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "475",
      "motherInstrument": "2714625",
      "category": "futures",
      "label": "BHARTIARTL24JUNFUT",
      "value": "BHARTIARTL24JUNFUT"
    },
    {
      "instrument_token": "9085954",
      "name": "BHARTIARTL",
      "tradingSymbol": "BHARTIARTL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "475",
      "motherInstrument": "2714625",
      "category": "futures",
      "label": "BHARTIARTL24JULFUT",
      "value": "BHARTIARTL24JULFUT"
    },
    {
      "instrument_token": "9304834",
      "name": "BHARTIARTL",
      "tradingSymbol": "BHARTIARTL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "475",
      "motherInstrument": "2714625",
      "category": "futures",
      "label": "BHARTIARTL24AUGFUT",
      "value": "BHARTIARTL24AUGFUT"
    },
    {
      "instrument_token": "10342914",
      "name": "BHEL",
      "tradingSymbol": "BHEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2625",
      "motherInstrument": "112129",
      "category": "futures",
      "label": "BHEL24JUNFUT",
      "value": "BHEL24JUNFUT"
    },
    {
      "instrument_token": "9086210",
      "name": "BHEL",
      "tradingSymbol": "BHEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2625",
      "motherInstrument": "112129",
      "category": "futures",
      "label": "BHEL24JULFUT",
      "value": "BHEL24JULFUT"
    },
    {
      "instrument_token": "9310210",
      "name": "BHEL",
      "tradingSymbol": "BHEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2625",
      "motherInstrument": "112129",
      "category": "futures",
      "label": "BHEL24AUGFUT",
      "value": "BHEL24AUGFUT"
    },
    {
      "instrument_token": "10343426",
      "name": "BIOCON",
      "tradingSymbol": "BIOCON24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2500",
      "motherInstrument": "2911489",
      "category": "futures",
      "label": "BIOCON24JUNFUT",
      "value": "BIOCON24JUNFUT"
    },
    {
      "instrument_token": "9086466",
      "name": "BIOCON",
      "tradingSymbol": "BIOCON24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2500",
      "motherInstrument": "2911489",
      "category": "futures",
      "label": "BIOCON24JULFUT",
      "value": "BIOCON24JULFUT"
    },
    {
      "instrument_token": "9310466",
      "name": "BIOCON",
      "tradingSymbol": "BIOCON24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2500",
      "motherInstrument": "2911489",
      "category": "futures",
      "label": "BIOCON24AUGFUT",
      "value": "BIOCON24AUGFUT"
    },
    {
      "instrument_token": "10343682",
      "name": "BOSCHLTD",
      "tradingSymbol": "BOSCHLTD24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "25",
      "motherInstrument": "558337",
      "category": "futures",
      "label": "BOSCHLTD24JUNFUT",
      "value": "BOSCHLTD24JUNFUT"
    },
    {
      "instrument_token": "9086722",
      "name": "BOSCHLTD",
      "tradingSymbol": "BOSCHLTD24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "25",
      "motherInstrument": "558337",
      "category": "futures",
      "label": "BOSCHLTD24JULFUT",
      "value": "BOSCHLTD24JULFUT"
    },
    {
      "instrument_token": "9313282",
      "name": "BOSCHLTD",
      "tradingSymbol": "BOSCHLTD24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "25",
      "motherInstrument": "558337",
      "category": "futures",
      "label": "BOSCHLTD24AUGFUT",
      "value": "BOSCHLTD24AUGFUT"
    },
    {
      "instrument_token": "10552834",
      "name": "BPCL",
      "tradingSymbol": "BPCL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1800",
      "motherInstrument": "134657",
      "category": "futures",
      "label": "BPCL24JUNFUT",
      "value": "BPCL24JUNFUT"
    },
    {
      "instrument_token": "9086978",
      "name": "BPCL",
      "tradingSymbol": "BPCL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1800",
      "motherInstrument": "134657",
      "category": "futures",
      "label": "BPCL24JULFUT",
      "value": "BPCL24JULFUT"
    },
    {
      "instrument_token": "9313538",
      "name": "BPCL",
      "tradingSymbol": "BPCL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1800",
      "motherInstrument": "134657",
      "category": "futures",
      "label": "BPCL24AUGFUT",
      "value": "BPCL24AUGFUT"
    },
    {
      "instrument_token": "10553090",
      "name": "BRITANNIA",
      "tradingSymbol": "BRITANNIA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "140033",
      "category": "futures",
      "label": "BRITANNIA24JUNFUT",
      "value": "BRITANNIA24JUNFUT"
    },
    {
      "instrument_token": "9100546",
      "name": "BRITANNIA",
      "tradingSymbol": "BRITANNIA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "140033",
      "category": "futures",
      "label": "BRITANNIA24JULFUT",
      "value": "BRITANNIA24JULFUT"
    },
    {
      "instrument_token": "9332994",
      "name": "BRITANNIA",
      "tradingSymbol": "BRITANNIA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "140033",
      "category": "futures",
      "label": "BRITANNIA24AUGFUT",
      "value": "BRITANNIA24AUGFUT"
    },
    {
      "instrument_token": "10554882",
      "name": "BSOFT",
      "tradingSymbol": "BSOFT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "1790465",
      "category": "futures",
      "label": "BSOFT24JUNFUT",
      "value": "BSOFT24JUNFUT"
    },
    {
      "instrument_token": "9100802",
      "name": "BSOFT",
      "tradingSymbol": "BSOFT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "1790465",
      "category": "futures",
      "label": "BSOFT24JULFUT",
      "value": "BSOFT24JULFUT"
    },
    {
      "instrument_token": "9402114",
      "name": "BSOFT",
      "tradingSymbol": "BSOFT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "1790465",
      "category": "futures",
      "label": "BSOFT24AUGFUT",
      "value": "BSOFT24AUGFUT"
    },
    {
      "instrument_token": "10556162",
      "name": "CANBK",
      "tradingSymbol": "CANBK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "6750",
      "motherInstrument": "2763265",
      "category": "futures",
      "label": "CANBK24JUNFUT",
      "value": "CANBK24JUNFUT"
    },
    {
      "instrument_token": "9126146",
      "name": "CANBK",
      "tradingSymbol": "CANBK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "6750",
      "motherInstrument": "2763265",
      "category": "futures",
      "label": "CANBK24JULFUT",
      "value": "CANBK24JULFUT"
    },
    {
      "instrument_token": "9402370",
      "name": "CANBK",
      "tradingSymbol": "CANBK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "6750",
      "motherInstrument": "2763265",
      "category": "futures",
      "label": "CANBK24AUGFUT",
      "value": "CANBK24AUGFUT"
    },
    {
      "instrument_token": "10773506",
      "name": "CANFINHOME",
      "tradingSymbol": "CANFINHOME24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "975",
      "motherInstrument": "149249",
      "category": "futures",
      "label": "CANFINHOME24JUNFUT",
      "value": "CANFINHOME24JUNFUT"
    },
    {
      "instrument_token": "9126402",
      "name": "CANFINHOME",
      "tradingSymbol": "CANFINHOME24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "975",
      "motherInstrument": "149249",
      "category": "futures",
      "label": "CANFINHOME24JULFUT",
      "value": "CANFINHOME24JULFUT"
    },
    {
      "instrument_token": "9475586",
      "name": "CANFINHOME",
      "tradingSymbol": "CANFINHOME24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "975",
      "motherInstrument": "149249",
      "category": "futures",
      "label": "CANFINHOME24AUGFUT",
      "value": "CANFINHOME24AUGFUT"
    },
    {
      "instrument_token": "10773762",
      "name": "CHAMBLFERT",
      "tradingSymbol": "CHAMBLFERT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1900",
      "motherInstrument": "163073",
      "category": "futures",
      "label": "CHAMBLFERT24JUNFUT",
      "value": "CHAMBLFERT24JUNFUT"
    },
    {
      "instrument_token": "9126658",
      "name": "CHAMBLFERT",
      "tradingSymbol": "CHAMBLFERT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1900",
      "motherInstrument": "163073",
      "category": "futures",
      "label": "CHAMBLFERT24JULFUT",
      "value": "CHAMBLFERT24JULFUT"
    },
    {
      "instrument_token": "10397186",
      "name": "CHAMBLFERT",
      "tradingSymbol": "CHAMBLFERT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1900",
      "motherInstrument": "163073",
      "category": "futures",
      "label": "CHAMBLFERT24AUGFUT",
      "value": "CHAMBLFERT24AUGFUT"
    },
    {
      "instrument_token": "10774530",
      "name": "CHOLAFIN",
      "tradingSymbol": "CHOLAFIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "625",
      "motherInstrument": "175361",
      "category": "futures",
      "label": "CHOLAFIN24JUNFUT",
      "value": "CHOLAFIN24JUNFUT"
    },
    {
      "instrument_token": "9126914",
      "name": "CHOLAFIN",
      "tradingSymbol": "CHOLAFIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "625",
      "motherInstrument": "175361",
      "category": "futures",
      "label": "CHOLAFIN24JULFUT",
      "value": "CHOLAFIN24JULFUT"
    },
    {
      "instrument_token": "10399746",
      "name": "CHOLAFIN",
      "tradingSymbol": "CHOLAFIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "625",
      "motherInstrument": "175361",
      "category": "futures",
      "label": "CHOLAFIN24AUGFUT",
      "value": "CHOLAFIN24AUGFUT"
    },
    {
      "instrument_token": "10774786",
      "name": "CIPLA",
      "tradingSymbol": "CIPLA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "650",
      "motherInstrument": "177665",
      "category": "futures",
      "label": "CIPLA24JUNFUT",
      "value": "CIPLA24JUNFUT"
    },
    {
      "instrument_token": "9128194",
      "name": "CIPLA",
      "tradingSymbol": "CIPLA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "650",
      "motherInstrument": "177665",
      "category": "futures",
      "label": "CIPLA24JULFUT",
      "value": "CIPLA24JULFUT"
    },
    {
      "instrument_token": "10424066",
      "name": "CIPLA",
      "tradingSymbol": "CIPLA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "650",
      "motherInstrument": "177665",
      "category": "futures",
      "label": "CIPLA24AUGFUT",
      "value": "CIPLA24AUGFUT"
    },
    {
      "instrument_token": "10775042",
      "name": "COALINDIA",
      "tradingSymbol": "COALINDIA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2100",
      "motherInstrument": "5215745",
      "category": "futures",
      "label": "COALINDIA24JUNFUT",
      "value": "COALINDIA24JUNFUT"
    },
    {
      "instrument_token": "9128962",
      "name": "COALINDIA",
      "tradingSymbol": "COALINDIA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2100",
      "motherInstrument": "5215745",
      "category": "futures",
      "label": "COALINDIA24JULFUT",
      "value": "COALINDIA24JULFUT"
    },
    {
      "instrument_token": "10424322",
      "name": "COALINDIA",
      "tradingSymbol": "COALINDIA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2100",
      "motherInstrument": "5215745",
      "category": "futures",
      "label": "COALINDIA24AUGFUT",
      "value": "COALINDIA24AUGFUT"
    },
    {
      "instrument_token": "10775554",
      "name": "COFORGE",
      "tradingSymbol": "COFORGE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "2955009",
      "category": "futures",
      "label": "COFORGE24JUNFUT",
      "value": "COFORGE24JUNFUT"
    },
    {
      "instrument_token": "10283778",
      "name": "COFORGE",
      "tradingSymbol": "COFORGE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "2955009",
      "category": "futures",
      "label": "COFORGE24JULFUT",
      "value": "COFORGE24JULFUT"
    },
    {
      "instrument_token": "10815746",
      "name": "COFORGE",
      "tradingSymbol": "COFORGE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "2955009",
      "category": "futures",
      "label": "COFORGE24AUGFUT",
      "value": "COFORGE24AUGFUT"
    },
    {
      "instrument_token": "10777346",
      "name": "COLPAL",
      "tradingSymbol": "COLPAL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "350",
      "motherInstrument": "3876097",
      "category": "futures",
      "label": "COLPAL24JUNFUT",
      "value": "COLPAL24JUNFUT"
    },
    {
      "instrument_token": "10284034",
      "name": "COLPAL",
      "tradingSymbol": "COLPAL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "350",
      "motherInstrument": "3876097",
      "category": "futures",
      "label": "COLPAL24JULFUT",
      "value": "COLPAL24JULFUT"
    },
    {
      "instrument_token": "10816002",
      "name": "COLPAL",
      "tradingSymbol": "COLPAL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "350",
      "motherInstrument": "3876097",
      "category": "futures",
      "label": "COLPAL24AUGFUT",
      "value": "COLPAL24AUGFUT"
    },
    {
      "instrument_token": "10777602",
      "name": "CONCOR",
      "tradingSymbol": "CONCOR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "1215745",
      "category": "futures",
      "label": "CONCOR24JUNFUT",
      "value": "CONCOR24JUNFUT"
    },
    {
      "instrument_token": "10284290",
      "name": "CONCOR",
      "tradingSymbol": "CONCOR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "1215745",
      "category": "futures",
      "label": "CONCOR24JULFUT",
      "value": "CONCOR24JULFUT"
    },
    {
      "instrument_token": "10816258",
      "name": "CONCOR",
      "tradingSymbol": "CONCOR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "1215745",
      "category": "futures",
      "label": "CONCOR24AUGFUT",
      "value": "CONCOR24AUGFUT"
    },
    {
      "instrument_token": "10777858",
      "name": "COROMANDEL",
      "tradingSymbol": "COROMANDEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "700",
      "motherInstrument": "189185",
      "category": "futures",
      "label": "COROMANDEL24JUNFUT",
      "value": "COROMANDEL24JUNFUT"
    },
    {
      "instrument_token": "10284546",
      "name": "COROMANDEL",
      "tradingSymbol": "COROMANDEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "700",
      "motherInstrument": "189185",
      "category": "futures",
      "label": "COROMANDEL24JULFUT",
      "value": "COROMANDEL24JULFUT"
    },
    {
      "instrument_token": "10816514",
      "name": "COROMANDEL",
      "tradingSymbol": "COROMANDEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "700",
      "motherInstrument": "189185",
      "category": "futures",
      "label": "COROMANDEL24AUGFUT",
      "value": "COROMANDEL24AUGFUT"
    },
    {
      "instrument_token": "10778114",
      "name": "CROMPTON",
      "tradingSymbol": "CROMPTON24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1800",
      "motherInstrument": "4376065",
      "category": "futures",
      "label": "CROMPTON24JUNFUT",
      "value": "CROMPTON24JUNFUT"
    },
    {
      "instrument_token": "10284802",
      "name": "CROMPTON",
      "tradingSymbol": "CROMPTON24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1800",
      "motherInstrument": "4376065",
      "category": "futures",
      "label": "CROMPTON24JULFUT",
      "value": "CROMPTON24JULFUT"
    },
    {
      "instrument_token": "10816770",
      "name": "CROMPTON",
      "tradingSymbol": "CROMPTON24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1800",
      "motherInstrument": "4376065",
      "category": "futures",
      "label": "CROMPTON24AUGFUT",
      "value": "CROMPTON24AUGFUT"
    },
    {
      "instrument_token": "10779138",
      "name": "CUB",
      "tradingSymbol": "CUB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5000",
      "motherInstrument": "1459457",
      "category": "futures",
      "label": "CUB24JUNFUT",
      "value": "CUB24JUNFUT"
    },
    {
      "instrument_token": "10285058",
      "name": "CUB",
      "tradingSymbol": "CUB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5000",
      "motherInstrument": "1459457",
      "category": "futures",
      "label": "CUB24JULFUT",
      "value": "CUB24JULFUT"
    },
    {
      "instrument_token": "10817282",
      "name": "CUB",
      "tradingSymbol": "CUB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5000",
      "motherInstrument": "1459457",
      "category": "futures",
      "label": "CUB24AUGFUT",
      "value": "CUB24AUGFUT"
    },
    {
      "instrument_token": "10779394",
      "name": "CUMMINSIND",
      "tradingSymbol": "CUMMINSIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "486657",
      "category": "futures",
      "label": "CUMMINSIND24JUNFUT",
      "value": "CUMMINSIND24JUNFUT"
    },
    {
      "instrument_token": "10285314",
      "name": "CUMMINSIND",
      "tradingSymbol": "CUMMINSIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "486657",
      "category": "futures",
      "label": "CUMMINSIND24JULFUT",
      "value": "CUMMINSIND24JULFUT"
    },
    {
      "instrument_token": "10817538",
      "name": "CUMMINSIND",
      "tradingSymbol": "CUMMINSIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "486657",
      "category": "futures",
      "label": "CUMMINSIND24AUGFUT",
      "value": "CUMMINSIND24AUGFUT"
    },
    {
      "instrument_token": "10780418",
      "name": "DABUR",
      "tradingSymbol": "DABUR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1250",
      "motherInstrument": "197633",
      "category": "futures",
      "label": "DABUR24JUNFUT",
      "value": "DABUR24JUNFUT"
    },
    {
      "instrument_token": "10285570",
      "name": "DABUR",
      "tradingSymbol": "DABUR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1250",
      "motherInstrument": "197633",
      "category": "futures",
      "label": "DABUR24JULFUT",
      "value": "DABUR24JULFUT"
    },
    {
      "instrument_token": "10818306",
      "name": "DABUR",
      "tradingSymbol": "DABUR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1250",
      "motherInstrument": "197633",
      "category": "futures",
      "label": "DABUR24AUGFUT",
      "value": "DABUR24AUGFUT"
    },
    {
      "instrument_token": "10780674",
      "name": "DALBHARAT",
      "tradingSymbol": "DALBHARAT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "250",
      "motherInstrument": "2067201",
      "category": "futures",
      "label": "DALBHARAT24JUNFUT",
      "value": "DALBHARAT24JUNFUT"
    },
    {
      "instrument_token": "10285826",
      "name": "DALBHARAT",
      "tradingSymbol": "DALBHARAT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "275",
      "motherInstrument": "2067201",
      "category": "futures",
      "label": "DALBHARAT24JULFUT",
      "value": "DALBHARAT24JULFUT"
    },
    {
      "instrument_token": "11990274",
      "name": "DALBHARAT",
      "tradingSymbol": "DALBHARAT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "275",
      "motherInstrument": "2067201",
      "category": "futures",
      "label": "DALBHARAT24AUGFUT",
      "value": "DALBHARAT24AUGFUT"
    },
    {
      "instrument_token": "10780930",
      "name": "DEEPAKNTR",
      "tradingSymbol": "DEEPAKNTR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "5105409",
      "category": "futures",
      "label": "DEEPAKNTR24JUNFUT",
      "value": "DEEPAKNTR24JUNFUT"
    },
    {
      "instrument_token": "10286082",
      "name": "DEEPAKNTR",
      "tradingSymbol": "DEEPAKNTR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "5105409",
      "category": "futures",
      "label": "DEEPAKNTR24JULFUT",
      "value": "DEEPAKNTR24JULFUT"
    },
    {
      "instrument_token": "11990530",
      "name": "DEEPAKNTR",
      "tradingSymbol": "DEEPAKNTR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "5105409",
      "category": "futures",
      "label": "DEEPAKNTR24AUGFUT",
      "value": "DEEPAKNTR24AUGFUT"
    },
    {
      "instrument_token": "10781186",
      "name": "DIVISLAB",
      "tradingSymbol": "DIVISLAB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "2800641",
      "category": "futures",
      "label": "DIVISLAB24JUNFUT",
      "value": "DIVISLAB24JUNFUT"
    },
    {
      "instrument_token": "10396162",
      "name": "DIVISLAB",
      "tradingSymbol": "DIVISLAB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "2800641",
      "category": "futures",
      "label": "DIVISLAB24JULFUT",
      "value": "DIVISLAB24JULFUT"
    },
    {
      "instrument_token": "11990786",
      "name": "DIVISLAB",
      "tradingSymbol": "DIVISLAB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "2800641",
      "category": "futures",
      "label": "DIVISLAB24AUGFUT",
      "value": "DIVISLAB24AUGFUT"
    },
    {
      "instrument_token": "10781698",
      "name": "DIXON",
      "tradingSymbol": "DIXON24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "100",
      "motherInstrument": "5552641",
      "category": "futures",
      "label": "DIXON24JUNFUT",
      "value": "DIXON24JUNFUT"
    },
    {
      "instrument_token": "10396930",
      "name": "DIXON",
      "tradingSymbol": "DIXON24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "100",
      "motherInstrument": "5552641",
      "category": "futures",
      "label": "DIXON24JULFUT",
      "value": "DIXON24JULFUT"
    },
    {
      "instrument_token": "11991042",
      "name": "DIXON",
      "tradingSymbol": "DIXON24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "100",
      "motherInstrument": "5552641",
      "category": "futures",
      "label": "DIXON24AUGFUT",
      "value": "DIXON24AUGFUT"
    },
    {
      "instrument_token": "10781954",
      "name": "DLF",
      "tradingSymbol": "DLF24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "825",
      "motherInstrument": "3771393",
      "category": "futures",
      "label": "DLF24JUNFUT",
      "value": "DLF24JUNFUT"
    },
    {
      "instrument_token": "10418690",
      "name": "DLF",
      "tradingSymbol": "DLF24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "825",
      "motherInstrument": "3771393",
      "category": "futures",
      "label": "DLF24JULFUT",
      "value": "DLF24JULFUT"
    },
    {
      "instrument_token": "11991810",
      "name": "DLF",
      "tradingSymbol": "DLF24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "825",
      "motherInstrument": "3771393",
      "category": "futures",
      "label": "DLF24AUGFUT",
      "value": "DLF24AUGFUT"
    },
    {
      "instrument_token": "10782210",
      "name": "DRREDDY",
      "tradingSymbol": "DRREDDY24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "125",
      "motherInstrument": "225537",
      "category": "futures",
      "label": "DRREDDY24JUNFUT",
      "value": "DRREDDY24JUNFUT"
    },
    {
      "instrument_token": "10419714",
      "name": "DRREDDY",
      "tradingSymbol": "DRREDDY24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "125",
      "motherInstrument": "225537",
      "category": "futures",
      "label": "DRREDDY24JULFUT",
      "value": "DRREDDY24JULFUT"
    },
    {
      "instrument_token": "11992066",
      "name": "DRREDDY",
      "tradingSymbol": "DRREDDY24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "125",
      "motherInstrument": "225537",
      "category": "futures",
      "label": "DRREDDY24AUGFUT",
      "value": "DRREDDY24AUGFUT"
    },
    {
      "instrument_token": "10782466",
      "name": "EICHERMOT",
      "tradingSymbol": "EICHERMOT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "175",
      "motherInstrument": "232961",
      "category": "futures",
      "label": "EICHERMOT24JUNFUT",
      "value": "EICHERMOT24JUNFUT"
    },
    {
      "instrument_token": "11985922",
      "name": "EICHERMOT",
      "tradingSymbol": "EICHERMOT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "175",
      "motherInstrument": "232961",
      "category": "futures",
      "label": "EICHERMOT24JULFUT",
      "value": "EICHERMOT24JULFUT"
    },
    {
      "instrument_token": "11992322",
      "name": "EICHERMOT",
      "tradingSymbol": "EICHERMOT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "175",
      "motherInstrument": "232961",
      "category": "futures",
      "label": "EICHERMOT24AUGFUT",
      "value": "EICHERMOT24AUGFUT"
    },
    {
      "instrument_token": "10782722",
      "name": "ESCORTS",
      "tradingSymbol": "ESCORTS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "275",
      "motherInstrument": "245249",
      "category": "futures",
      "label": "ESCORTS24JUNFUT",
      "value": "ESCORTS24JUNFUT"
    },
    {
      "instrument_token": "11986178",
      "name": "ESCORTS",
      "tradingSymbol": "ESCORTS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "275",
      "motherInstrument": "245249",
      "category": "futures",
      "label": "ESCORTS24JULFUT",
      "value": "ESCORTS24JULFUT"
    },
    {
      "instrument_token": "11992578",
      "name": "ESCORTS",
      "tradingSymbol": "ESCORTS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "275",
      "motherInstrument": "245249",
      "category": "futures",
      "label": "ESCORTS24AUGFUT",
      "value": "ESCORTS24AUGFUT"
    },
    {
      "instrument_token": "10783234",
      "name": "EXIDEIND",
      "tradingSymbol": "EXIDEIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1800",
      "motherInstrument": "173057",
      "category": "futures",
      "label": "EXIDEIND24JUNFUT",
      "value": "EXIDEIND24JUNFUT"
    },
    {
      "instrument_token": "11986690",
      "name": "EXIDEIND",
      "tradingSymbol": "EXIDEIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1800",
      "motherInstrument": "173057",
      "category": "futures",
      "label": "EXIDEIND24JULFUT",
      "value": "EXIDEIND24JULFUT"
    },
    {
      "instrument_token": "11995394",
      "name": "EXIDEIND",
      "tradingSymbol": "EXIDEIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1800",
      "motherInstrument": "173057",
      "category": "futures",
      "label": "EXIDEIND24AUGFUT",
      "value": "EXIDEIND24AUGFUT"
    },
    {
      "instrument_token": "10783746",
      "name": "FEDERALBNK",
      "tradingSymbol": "FEDERALBNK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5000",
      "motherInstrument": "261889",
      "category": "futures",
      "label": "FEDERALBNK24JUNFUT",
      "value": "FEDERALBNK24JUNFUT"
    },
    {
      "instrument_token": "11988994",
      "name": "FEDERALBNK",
      "tradingSymbol": "FEDERALBNK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5000",
      "motherInstrument": "261889",
      "category": "futures",
      "label": "FEDERALBNK24JULFUT",
      "value": "FEDERALBNK24JULFUT"
    },
    {
      "instrument_token": "11995650",
      "name": "FEDERALBNK",
      "tradingSymbol": "FEDERALBNK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5000",
      "motherInstrument": "261889",
      "category": "futures",
      "label": "FEDERALBNK24AUGFUT",
      "value": "FEDERALBNK24AUGFUT"
    },
    {
      "instrument_token": "8998914",
      "name": "NIFTY FIN SERVICE",
      "tradingSymbol": "FINNIFTY24JUNFUT",
      "expiry": "6/25/24",
      "lot_size": "40",
      "motherInstrument": "257801",
      "category": "futures",
      "label": "FINNIFTY24JUNFUT",
      "value": "FINNIFTY24JUNFUT"
    },
    {
      "instrument_token": "8961538",
      "name": "NIFTY FIN SERVICE",
      "tradingSymbol": "FINNIFTY24JULFUT",
      "expiry": "7/30/24",
      "lot_size": "25",
      "motherInstrument": "257801",
      "category": "futures",
      "label": "FINNIFTY24JULFUT",
      "value": "FINNIFTY24JULFUT"
    },
    {
      "instrument_token": "8978690",
      "name": "NIFTY FIN SERVICE",
      "tradingSymbol": "FINNIFTY24AUGFUT",
      "expiry": "8/27/24",
      "lot_size": "25",
      "motherInstrument": "257801",
      "category": "futures",
      "label": "FINNIFTY24AUGFUT",
      "value": "FINNIFTY24AUGFUT"
    },
    {
      "instrument_token": "10784258",
      "name": "GAIL",
      "tradingSymbol": "GAIL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "4575",
      "motherInstrument": "1207553",
      "category": "futures",
      "label": "GAIL24JUNFUT",
      "value": "GAIL24JUNFUT"
    },
    {
      "instrument_token": "11989250",
      "name": "GAIL",
      "tradingSymbol": "GAIL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "4575",
      "motherInstrument": "1207553",
      "category": "futures",
      "label": "GAIL24JULFUT",
      "value": "GAIL24JULFUT"
    },
    {
      "instrument_token": "11995906",
      "name": "GAIL",
      "tradingSymbol": "GAIL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "4575",
      "motherInstrument": "1207553",
      "category": "futures",
      "label": "GAIL24AUGFUT",
      "value": "GAIL24AUGFUT"
    },
    {
      "instrument_token": "10784514",
      "name": "GLENMARK",
      "tradingSymbol": "GLENMARK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "725",
      "motherInstrument": "1895937",
      "category": "futures",
      "label": "GLENMARK24JUNFUT",
      "value": "GLENMARK24JUNFUT"
    },
    {
      "instrument_token": "11989506",
      "name": "GLENMARK",
      "tradingSymbol": "GLENMARK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "725",
      "motherInstrument": "1895937",
      "category": "futures",
      "label": "GLENMARK24JULFUT",
      "value": "GLENMARK24JULFUT"
    },
    {
      "instrument_token": "11996162",
      "name": "GLENMARK",
      "tradingSymbol": "GLENMARK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "725",
      "motherInstrument": "1895937",
      "category": "futures",
      "label": "GLENMARK24AUGFUT",
      "value": "GLENMARK24AUGFUT"
    },
    {
      "instrument_token": "10785282",
      "name": "GMRINFRA",
      "tradingSymbol": "GMRINFRA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "11250",
      "motherInstrument": "3463169",
      "category": "futures",
      "label": "GMRINFRA24JUNFUT",
      "value": "GMRINFRA24JUNFUT"
    },
    {
      "instrument_token": "11989762",
      "name": "GMRINFRA",
      "tradingSymbol": "GMRINFRA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "11250",
      "motherInstrument": "3463169",
      "category": "futures",
      "label": "GMRINFRA24JULFUT",
      "value": "GMRINFRA24JULFUT"
    },
    {
      "instrument_token": "12012290",
      "name": "GMRINFRA",
      "tradingSymbol": "GMRINFRA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "11250",
      "motherInstrument": "3463169",
      "category": "futures",
      "label": "GMRINFRA24AUGFUT",
      "value": "GMRINFRA24AUGFUT"
    },
    {
      "instrument_token": "10785538",
      "name": "GNFC",
      "tradingSymbol": "GNFC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1300",
      "motherInstrument": "300545",
      "category": "futures",
      "label": "GNFC24JUNFUT",
      "value": "GNFC24JUNFUT"
    },
    {
      "instrument_token": "11990018",
      "name": "GNFC",
      "tradingSymbol": "GNFC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1300",
      "motherInstrument": "300545",
      "category": "futures",
      "label": "GNFC24JULFUT",
      "value": "GNFC24JULFUT"
    },
    {
      "instrument_token": "12440066",
      "name": "GNFC",
      "tradingSymbol": "GNFC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1300",
      "motherInstrument": "300545",
      "category": "futures",
      "label": "GNFC24AUGFUT",
      "value": "GNFC24AUGFUT"
    },
    {
      "instrument_token": "10786562",
      "name": "GODREJCP",
      "tradingSymbol": "GODREJCP24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "2585345",
      "category": "futures",
      "label": "GODREJCP24JUNFUT",
      "value": "GODREJCP24JUNFUT"
    },
    {
      "instrument_token": "12438530",
      "name": "GODREJCP",
      "tradingSymbol": "GODREJCP24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "2585345",
      "category": "futures",
      "label": "GODREJCP24JULFUT",
      "value": "GODREJCP24JULFUT"
    },
    {
      "instrument_token": "12440322",
      "name": "GODREJCP",
      "tradingSymbol": "GODREJCP24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "2585345",
      "category": "futures",
      "label": "GODREJCP24AUGFUT",
      "value": "GODREJCP24AUGFUT"
    },
    {
      "instrument_token": "10789122",
      "name": "GODREJPROP",
      "tradingSymbol": "GODREJPROP24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "475",
      "motherInstrument": "4576001",
      "category": "futures",
      "label": "GODREJPROP24JUNFUT",
      "value": "GODREJPROP24JUNFUT"
    },
    {
      "instrument_token": "12438786",
      "name": "GODREJPROP",
      "tradingSymbol": "GODREJPROP24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "225",
      "motherInstrument": "4576001",
      "category": "futures",
      "label": "GODREJPROP24JULFUT",
      "value": "GODREJPROP24JULFUT"
    },
    {
      "instrument_token": "13154562",
      "name": "GODREJPROP",
      "tradingSymbol": "GODREJPROP24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "225",
      "motherInstrument": "4576001",
      "category": "futures",
      "label": "GODREJPROP24AUGFUT",
      "value": "GODREJPROP24AUGFUT"
    },
    {
      "instrument_token": "10789378",
      "name": "GRANULES",
      "tradingSymbol": "GRANULES24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2000",
      "motherInstrument": "3039233",
      "category": "futures",
      "label": "GRANULES24JUNFUT",
      "value": "GRANULES24JUNFUT"
    },
    {
      "instrument_token": "12771586",
      "name": "GRANULES",
      "tradingSymbol": "GRANULES24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2000",
      "motherInstrument": "3039233",
      "category": "futures",
      "label": "GRANULES24JULFUT",
      "value": "GRANULES24JULFUT"
    },
    {
      "instrument_token": "13154818",
      "name": "GRANULES",
      "tradingSymbol": "GRANULES24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2000",
      "motherInstrument": "3039233",
      "category": "futures",
      "label": "GRANULES24AUGFUT",
      "value": "GRANULES24AUGFUT"
    },
    {
      "instrument_token": "10789890",
      "name": "GRASIM",
      "tradingSymbol": "GRASIM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "477",
      "motherInstrument": "315393",
      "category": "futures",
      "label": "GRASIM24JUNFUT",
      "value": "GRASIM24JUNFUT"
    },
    {
      "instrument_token": "12771842",
      "name": "GRASIM",
      "tradingSymbol": "GRASIM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "250",
      "motherInstrument": "315393",
      "category": "futures",
      "label": "GRASIM24JULFUT",
      "value": "GRASIM24JULFUT"
    },
    {
      "instrument_token": "14443266",
      "name": "GRASIM",
      "tradingSymbol": "GRASIM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "250",
      "motherInstrument": "315393",
      "category": "futures",
      "label": "GRASIM24AUGFUT",
      "value": "GRASIM24AUGFUT"
    },
    {
      "instrument_token": "10790146",
      "name": "GUJGASLTD",
      "tradingSymbol": "GUJGASLTD24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1250",
      "motherInstrument": "2713345",
      "category": "futures",
      "label": "GUJGASLTD24JUNFUT",
      "value": "GUJGASLTD24JUNFUT"
    },
    {
      "instrument_token": "12774402",
      "name": "GUJGASLTD",
      "tradingSymbol": "GUJGASLTD24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1250",
      "motherInstrument": "2713345",
      "category": "futures",
      "label": "GUJGASLTD24JULFUT",
      "value": "GUJGASLTD24JULFUT"
    },
    {
      "instrument_token": "14443522",
      "name": "GUJGASLTD",
      "tradingSymbol": "GUJGASLTD24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1250",
      "motherInstrument": "2713345",
      "category": "futures",
      "label": "GUJGASLTD24AUGFUT",
      "value": "GUJGASLTD24AUGFUT"
    },
    {
      "instrument_token": "10872322",
      "name": "HAL",
      "tradingSymbol": "HAL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "589569",
      "category": "futures",
      "label": "HAL24JUNFUT",
      "value": "HAL24JUNFUT"
    },
    {
      "instrument_token": "12774658",
      "name": "HAL",
      "tradingSymbol": "HAL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "589569",
      "category": "futures",
      "label": "HAL24JULFUT",
      "value": "HAL24JULFUT"
    },
    {
      "instrument_token": "14443778",
      "name": "HAL",
      "tradingSymbol": "HAL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "589569",
      "category": "futures",
      "label": "HAL24AUGFUT",
      "value": "HAL24AUGFUT"
    },
    {
      "instrument_token": "10872578",
      "name": "HAVELLS",
      "tradingSymbol": "HAVELLS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "2513665",
      "category": "futures",
      "label": "HAVELLS24JUNFUT",
      "value": "HAVELLS24JUNFUT"
    },
    {
      "instrument_token": "12776450",
      "name": "HAVELLS",
      "tradingSymbol": "HAVELLS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "2513665",
      "category": "futures",
      "label": "HAVELLS24JULFUT",
      "value": "HAVELLS24JULFUT"
    },
    {
      "instrument_token": "14444290",
      "name": "HAVELLS",
      "tradingSymbol": "HAVELLS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "2513665",
      "category": "futures",
      "label": "HAVELLS24AUGFUT",
      "value": "HAVELLS24AUGFUT"
    },
    {
      "instrument_token": "10963458",
      "name": "HCLTECH",
      "tradingSymbol": "HCLTECH24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "350",
      "motherInstrument": "1850625",
      "category": "futures",
      "label": "HCLTECH24JUNFUT",
      "value": "HCLTECH24JUNFUT"
    },
    {
      "instrument_token": "12776706",
      "name": "HCLTECH",
      "tradingSymbol": "HCLTECH24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "350",
      "motherInstrument": "1850625",
      "category": "futures",
      "label": "HCLTECH24JULFUT",
      "value": "HCLTECH24JULFUT"
    },
    {
      "instrument_token": "14444546",
      "name": "HCLTECH",
      "tradingSymbol": "HCLTECH24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "350",
      "motherInstrument": "1850625",
      "category": "futures",
      "label": "HCLTECH24AUGFUT",
      "value": "HCLTECH24AUGFUT"
    },
    {
      "instrument_token": "10965506",
      "name": "HDFCAMC",
      "tradingSymbol": "HDFCAMC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "1086465",
      "category": "futures",
      "label": "HDFCAMC24JUNFUT",
      "value": "HDFCAMC24JUNFUT"
    },
    {
      "instrument_token": "13248770",
      "name": "HDFCAMC",
      "tradingSymbol": "HDFCAMC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "1086465",
      "category": "futures",
      "label": "HDFCAMC24JULFUT",
      "value": "HDFCAMC24JULFUT"
    },
    {
      "instrument_token": "14444802",
      "name": "HDFCAMC",
      "tradingSymbol": "HDFCAMC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "1086465",
      "category": "futures",
      "label": "HDFCAMC24AUGFUT",
      "value": "HDFCAMC24AUGFUT"
    },
    {
      "instrument_token": "11312898",
      "name": "HDFCBANK",
      "tradingSymbol": "HDFCBANK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "550",
      "motherInstrument": "341249",
      "category": "futures",
      "label": "HDFCBANK24JUNFUT",
      "value": "HDFCBANK24JUNFUT"
    },
    {
      "instrument_token": "13249026",
      "name": "HDFCBANK",
      "tradingSymbol": "HDFCBANK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "550",
      "motherInstrument": "341249",
      "category": "futures",
      "label": "HDFCBANK24JULFUT",
      "value": "HDFCBANK24JULFUT"
    },
    {
      "instrument_token": "14445058",
      "name": "HDFCBANK",
      "tradingSymbol": "HDFCBANK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "550",
      "motherInstrument": "341249",
      "category": "futures",
      "label": "HDFCBANK24AUGFUT",
      "value": "HDFCBANK24AUGFUT"
    },
    {
      "instrument_token": "11313154",
      "name": "HDFCLIFE",
      "tradingSymbol": "HDFCLIFE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1100",
      "motherInstrument": "119553",
      "category": "futures",
      "label": "HDFCLIFE24JUNFUT",
      "value": "HDFCLIFE24JUNFUT"
    },
    {
      "instrument_token": "13253122",
      "name": "HDFCLIFE",
      "tradingSymbol": "HDFCLIFE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1100",
      "motherInstrument": "119553",
      "category": "futures",
      "label": "HDFCLIFE24JULFUT",
      "value": "HDFCLIFE24JULFUT"
    },
    {
      "instrument_token": "14445314",
      "name": "HDFCLIFE",
      "tradingSymbol": "HDFCLIFE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1100",
      "motherInstrument": "119553",
      "category": "futures",
      "label": "HDFCLIFE24AUGFUT",
      "value": "HDFCLIFE24AUGFUT"
    },
    {
      "instrument_token": "11313410",
      "name": "HEROMOTOCO",
      "tradingSymbol": "HEROMOTOCO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "345089",
      "category": "futures",
      "label": "HEROMOTOCO24JUNFUT",
      "value": "HEROMOTOCO24JUNFUT"
    },
    {
      "instrument_token": "13253634",
      "name": "HEROMOTOCO",
      "tradingSymbol": "HEROMOTOCO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "345089",
      "category": "futures",
      "label": "HEROMOTOCO24JULFUT",
      "value": "HEROMOTOCO24JULFUT"
    },
    {
      "instrument_token": "14445570",
      "name": "HEROMOTOCO",
      "tradingSymbol": "HEROMOTOCO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "345089",
      "category": "futures",
      "label": "HEROMOTOCO24AUGFUT",
      "value": "HEROMOTOCO24AUGFUT"
    },
    {
      "instrument_token": "11313666",
      "name": "HINDALCO",
      "tradingSymbol": "HINDALCO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1400",
      "motherInstrument": "348929",
      "category": "futures",
      "label": "HINDALCO24JUNFUT",
      "value": "HINDALCO24JUNFUT"
    },
    {
      "instrument_token": "13254914",
      "name": "HINDALCO",
      "tradingSymbol": "HINDALCO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1400",
      "motherInstrument": "348929",
      "category": "futures",
      "label": "HINDALCO24JULFUT",
      "value": "HINDALCO24JULFUT"
    },
    {
      "instrument_token": "14445826",
      "name": "HINDALCO",
      "tradingSymbol": "HINDALCO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1400",
      "motherInstrument": "348929",
      "category": "futures",
      "label": "HINDALCO24AUGFUT",
      "value": "HINDALCO24AUGFUT"
    },
    {
      "instrument_token": "11567362",
      "name": "HINDCOPPER",
      "tradingSymbol": "HINDCOPPER24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2650",
      "motherInstrument": "4592385",
      "category": "futures",
      "label": "HINDCOPPER24JUNFUT",
      "value": "HINDCOPPER24JUNFUT"
    },
    {
      "instrument_token": "13255170",
      "name": "HINDCOPPER",
      "tradingSymbol": "HINDCOPPER24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2650",
      "motherInstrument": "4592385",
      "category": "futures",
      "label": "HINDCOPPER24JULFUT",
      "value": "HINDCOPPER24JULFUT"
    },
    {
      "instrument_token": "14446082",
      "name": "HINDCOPPER",
      "tradingSymbol": "HINDCOPPER24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2650",
      "motherInstrument": "4592385",
      "category": "futures",
      "label": "HINDCOPPER24AUGFUT",
      "value": "HINDCOPPER24AUGFUT"
    },
    {
      "instrument_token": "11567618",
      "name": "HINDPETRO",
      "tradingSymbol": "HINDPETRO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2025",
      "motherInstrument": "359937",
      "category": "futures",
      "label": "HINDPETRO24JUNFUT",
      "value": "HINDPETRO24JUNFUT"
    },
    {
      "instrument_token": "13368322",
      "name": "HINDPETRO",
      "tradingSymbol": "HINDPETRO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2025",
      "motherInstrument": "359937",
      "category": "futures",
      "label": "HINDPETRO24JULFUT",
      "value": "HINDPETRO24JULFUT"
    },
    {
      "instrument_token": "14446338",
      "name": "HINDPETRO",
      "tradingSymbol": "HINDPETRO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2025",
      "motherInstrument": "359937",
      "category": "futures",
      "label": "HINDPETRO24AUGFUT",
      "value": "HINDPETRO24AUGFUT"
    },
    {
      "instrument_token": "12246786",
      "name": "HINDUNILVR",
      "tradingSymbol": "HINDUNILVR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "356865",
      "category": "futures",
      "label": "HINDUNILVR24JUNFUT",
      "value": "HINDUNILVR24JUNFUT"
    },
    {
      "instrument_token": "16304386",
      "name": "HINDUNILVR",
      "tradingSymbol": "HINDUNILVR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "356865",
      "category": "futures",
      "label": "HINDUNILVR24JULFUT",
      "value": "HINDUNILVR24JULFUT"
    },
    {
      "instrument_token": "14446594",
      "name": "HINDUNILVR",
      "tradingSymbol": "HINDUNILVR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "356865",
      "category": "futures",
      "label": "HINDUNILVR24AUGFUT",
      "value": "HINDUNILVR24AUGFUT"
    },
    {
      "instrument_token": "12247042",
      "name": "ICICIBANK",
      "tradingSymbol": "ICICIBANK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "700",
      "motherInstrument": "1270529",
      "category": "futures",
      "label": "ICICIBANK24JUNFUT",
      "value": "ICICIBANK24JUNFUT"
    },
    {
      "instrument_token": "16304642",
      "name": "ICICIBANK",
      "tradingSymbol": "ICICIBANK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "700",
      "motherInstrument": "1270529",
      "category": "futures",
      "label": "ICICIBANK24JULFUT",
      "value": "ICICIBANK24JULFUT"
    },
    {
      "instrument_token": "14446850",
      "name": "ICICIBANK",
      "tradingSymbol": "ICICIBANK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "700",
      "motherInstrument": "1270529",
      "category": "futures",
      "label": "ICICIBANK24AUGFUT",
      "value": "ICICIBANK24AUGFUT"
    },
    {
      "instrument_token": "12582146",
      "name": "ICICIGI",
      "tradingSymbol": "ICICIGI24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "5573121",
      "category": "futures",
      "label": "ICICIGI24JUNFUT",
      "value": "ICICIGI24JUNFUT"
    },
    {
      "instrument_token": "16305154",
      "name": "ICICIGI",
      "tradingSymbol": "ICICIGI24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "5573121",
      "category": "futures",
      "label": "ICICIGI24JULFUT",
      "value": "ICICIGI24JULFUT"
    },
    {
      "instrument_token": "14447106",
      "name": "ICICIGI",
      "tradingSymbol": "ICICIGI24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "5573121",
      "category": "futures",
      "label": "ICICIGI24AUGFUT",
      "value": "ICICIGI24AUGFUT"
    },
    {
      "instrument_token": "12582402",
      "name": "ICICIPRULI",
      "tradingSymbol": "ICICIPRULI24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1500",
      "motherInstrument": "4774913",
      "category": "futures",
      "label": "ICICIPRULI24JUNFUT",
      "value": "ICICIPRULI24JUNFUT"
    },
    {
      "instrument_token": "16305410",
      "name": "ICICIPRULI",
      "tradingSymbol": "ICICIPRULI24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1500",
      "motherInstrument": "4774913",
      "category": "futures",
      "label": "ICICIPRULI24JULFUT",
      "value": "ICICIPRULI24JULFUT"
    },
    {
      "instrument_token": "14447362",
      "name": "ICICIPRULI",
      "tradingSymbol": "ICICIPRULI24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1500",
      "motherInstrument": "4774913",
      "category": "futures",
      "label": "ICICIPRULI24AUGFUT",
      "value": "ICICIPRULI24AUGFUT"
    },
    {
      "instrument_token": "12582658",
      "name": "IDEA",
      "tradingSymbol": "IDEA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "40000",
      "motherInstrument": "3677697",
      "category": "futures",
      "label": "IDEA24JUNFUT",
      "value": "IDEA24JUNFUT"
    },
    {
      "instrument_token": "16305666",
      "name": "IDEA",
      "tradingSymbol": "IDEA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "40000",
      "motherInstrument": "3677697",
      "category": "futures",
      "label": "IDEA24JULFUT",
      "value": "IDEA24JULFUT"
    },
    {
      "instrument_token": "14447874",
      "name": "IDEA",
      "tradingSymbol": "IDEA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "40000",
      "motherInstrument": "3677697",
      "category": "futures",
      "label": "IDEA24AUGFUT",
      "value": "IDEA24AUGFUT"
    },
    {
      "instrument_token": "12582914",
      "name": "IDFC",
      "tradingSymbol": "IDFC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5000",
      "motherInstrument": "3060993",
      "category": "futures",
      "label": "IDFC24JUNFUT",
      "value": "IDFC24JUNFUT"
    },
    {
      "instrument_token": "16305922",
      "name": "IDFC",
      "tradingSymbol": "IDFC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5000",
      "motherInstrument": "3060993",
      "category": "futures",
      "label": "IDFC24JULFUT",
      "value": "IDFC24JULFUT"
    },
    {
      "instrument_token": "14448130",
      "name": "IDFC",
      "tradingSymbol": "IDFC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5000",
      "motherInstrument": "3060993",
      "category": "futures",
      "label": "IDFC24AUGFUT",
      "value": "IDFC24AUGFUT"
    },
    {
      "instrument_token": "13275906",
      "name": "IDFCFIRSTB",
      "tradingSymbol": "IDFCFIRSTB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "7500",
      "motherInstrument": "2863105",
      "category": "futures",
      "label": "IDFCFIRSTB24JUNFUT",
      "value": "IDFCFIRSTB24JUNFUT"
    },
    {
      "instrument_token": "16307714",
      "name": "IDFCFIRSTB",
      "tradingSymbol": "IDFCFIRSTB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "7500",
      "motherInstrument": "2863105",
      "category": "futures",
      "label": "IDFCFIRSTB24JULFUT",
      "value": "IDFCFIRSTB24JULFUT"
    },
    {
      "instrument_token": "14448898",
      "name": "IDFCFIRSTB",
      "tradingSymbol": "IDFCFIRSTB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "7500",
      "motherInstrument": "2863105",
      "category": "futures",
      "label": "IDFCFIRSTB24AUGFUT",
      "value": "IDFCFIRSTB24AUGFUT"
    },
    {
      "instrument_token": "13276162",
      "name": "IEX",
      "tradingSymbol": "IEX24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3750",
      "motherInstrument": "56321",
      "category": "futures",
      "label": "IEX24JUNFUT",
      "value": "IEX24JUNFUT"
    },
    {
      "instrument_token": "16307970",
      "name": "IEX",
      "tradingSymbol": "IEX24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "3750",
      "motherInstrument": "56321",
      "category": "futures",
      "label": "IEX24JULFUT",
      "value": "IEX24JULFUT"
    },
    {
      "instrument_token": "14449154",
      "name": "IEX",
      "tradingSymbol": "IEX24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "3750",
      "motherInstrument": "56321",
      "category": "futures",
      "label": "IEX24AUGFUT",
      "value": "IEX24AUGFUT"
    },
    {
      "instrument_token": "13277698",
      "name": "IGL",
      "tradingSymbol": "IGL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1375",
      "motherInstrument": "2883073",
      "category": "futures",
      "label": "IGL24JUNFUT",
      "value": "IGL24JUNFUT"
    },
    {
      "instrument_token": "16308226",
      "name": "IGL",
      "tradingSymbol": "IGL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1375",
      "motherInstrument": "2883073",
      "category": "futures",
      "label": "IGL24JULFUT",
      "value": "IGL24JULFUT"
    },
    {
      "instrument_token": "14449410",
      "name": "IGL",
      "tradingSymbol": "IGL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1375",
      "motherInstrument": "2883073",
      "category": "futures",
      "label": "IGL24AUGFUT",
      "value": "IGL24AUGFUT"
    },
    {
      "instrument_token": "13278210",
      "name": "INDHOTEL",
      "tradingSymbol": "INDHOTEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "387073",
      "category": "futures",
      "label": "INDHOTEL24JUNFUT",
      "value": "INDHOTEL24JUNFUT"
    },
    {
      "instrument_token": "16308482",
      "name": "INDHOTEL",
      "tradingSymbol": "INDHOTEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "387073",
      "category": "futures",
      "label": "INDHOTEL24JULFUT",
      "value": "INDHOTEL24JULFUT"
    },
    {
      "instrument_token": "14450690",
      "name": "INDHOTEL",
      "tradingSymbol": "INDHOTEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "387073",
      "category": "futures",
      "label": "INDHOTEL24AUGFUT",
      "value": "INDHOTEL24AUGFUT"
    },
    {
      "instrument_token": "13278466",
      "name": "INDIACEM",
      "tradingSymbol": "INDIACEM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2900",
      "motherInstrument": "387841",
      "category": "futures",
      "label": "INDIACEM24JUNFUT",
      "value": "INDIACEM24JUNFUT"
    },
    {
      "instrument_token": "16308738",
      "name": "INDIACEM",
      "tradingSymbol": "INDIACEM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2900",
      "motherInstrument": "387841",
      "category": "futures",
      "label": "INDIACEM24JULFUT",
      "value": "INDIACEM24JULFUT"
    },
    {
      "instrument_token": "14450946",
      "name": "INDIACEM",
      "tradingSymbol": "INDIACEM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2900",
      "motherInstrument": "387841",
      "category": "futures",
      "label": "INDIACEM24AUGFUT",
      "value": "INDIACEM24AUGFUT"
    },
    {
      "instrument_token": "13278978",
      "name": "INDIAMART",
      "tradingSymbol": "INDIAMART24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "2745857",
      "category": "futures",
      "label": "INDIAMART24JUNFUT",
      "value": "INDIAMART24JUNFUT"
    },
    {
      "instrument_token": "16308994",
      "name": "INDIAMART",
      "tradingSymbol": "INDIAMART24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "2745857",
      "category": "futures",
      "label": "INDIAMART24JULFUT",
      "value": "INDIAMART24JULFUT"
    },
    {
      "instrument_token": "14451714",
      "name": "INDIAMART",
      "tradingSymbol": "INDIAMART24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "2745857",
      "category": "futures",
      "label": "INDIAMART24AUGFUT",
      "value": "INDIAMART24AUGFUT"
    },
    {
      "instrument_token": "13279746",
      "name": "INDIGO",
      "tradingSymbol": "INDIGO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "2865921",
      "category": "futures",
      "label": "INDIGO24JUNFUT",
      "value": "INDIGO24JUNFUT"
    },
    {
      "instrument_token": "16309250",
      "name": "INDIGO",
      "tradingSymbol": "INDIGO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "2865921",
      "category": "futures",
      "label": "INDIGO24JULFUT",
      "value": "INDIGO24JULFUT"
    },
    {
      "instrument_token": "14451970",
      "name": "INDIGO",
      "tradingSymbol": "INDIGO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "2865921",
      "category": "futures",
      "label": "INDIGO24AUGFUT",
      "value": "INDIGO24AUGFUT"
    },
    {
      "instrument_token": "13280770",
      "name": "INDUSINDBK",
      "tradingSymbol": "INDUSINDBK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "1346049",
      "category": "futures",
      "label": "INDUSINDBK24JUNFUT",
      "value": "INDUSINDBK24JUNFUT"
    },
    {
      "instrument_token": "16309506",
      "name": "INDUSINDBK",
      "tradingSymbol": "INDUSINDBK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "1346049",
      "category": "futures",
      "label": "INDUSINDBK24JULFUT",
      "value": "INDUSINDBK24JULFUT"
    },
    {
      "instrument_token": "14452738",
      "name": "INDUSINDBK",
      "tradingSymbol": "INDUSINDBK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "1346049",
      "category": "futures",
      "label": "INDUSINDBK24AUGFUT",
      "value": "INDUSINDBK24AUGFUT"
    },
    {
      "instrument_token": "13354498",
      "name": "INDUSTOWER",
      "tradingSymbol": "INDUSTOWER24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3400",
      "motherInstrument": "7458561",
      "category": "futures",
      "label": "INDUSTOWER24JUNFUT",
      "value": "INDUSTOWER24JUNFUT"
    },
    {
      "instrument_token": "16309762",
      "name": "INDUSTOWER",
      "tradingSymbol": "INDUSTOWER24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "3400",
      "motherInstrument": "7458561",
      "category": "futures",
      "label": "INDUSTOWER24JULFUT",
      "value": "INDUSTOWER24JULFUT"
    },
    {
      "instrument_token": "14452994",
      "name": "INDUSTOWER",
      "tradingSymbol": "INDUSTOWER24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "3400",
      "motherInstrument": "7458561",
      "category": "futures",
      "label": "INDUSTOWER24AUGFUT",
      "value": "INDUSTOWER24AUGFUT"
    },
    {
      "instrument_token": "13355010",
      "name": "INFY",
      "tradingSymbol": "INFY24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "400",
      "motherInstrument": "408065",
      "category": "futures",
      "label": "INFY24JUNFUT",
      "value": "INFY24JUNFUT"
    },
    {
      "instrument_token": "16310018",
      "name": "INFY",
      "tradingSymbol": "INFY24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "400",
      "motherInstrument": "408065",
      "category": "futures",
      "label": "INFY24JULFUT",
      "value": "INFY24JULFUT"
    },
    {
      "instrument_token": "14453250",
      "name": "INFY",
      "tradingSymbol": "INFY24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "400",
      "motherInstrument": "408065",
      "category": "futures",
      "label": "INFY24AUGFUT",
      "value": "INFY24AUGFUT"
    },
    {
      "instrument_token": "13355266",
      "name": "IOC",
      "tradingSymbol": "IOC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "4875",
      "motherInstrument": "415745",
      "category": "futures",
      "label": "IOC24JUNFUT",
      "value": "IOC24JUNFUT"
    },
    {
      "instrument_token": "16310274",
      "name": "IOC",
      "tradingSymbol": "IOC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "4875",
      "motherInstrument": "415745",
      "category": "futures",
      "label": "IOC24JULFUT",
      "value": "IOC24JULFUT"
    },
    {
      "instrument_token": "14453506",
      "name": "IOC",
      "tradingSymbol": "IOC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "4875",
      "motherInstrument": "415745",
      "category": "futures",
      "label": "IOC24AUGFUT",
      "value": "IOC24AUGFUT"
    },
    {
      "instrument_token": "13355522",
      "name": "IPCALAB",
      "tradingSymbol": "IPCALAB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "650",
      "motherInstrument": "418049",
      "category": "futures",
      "label": "IPCALAB24JUNFUT",
      "value": "IPCALAB24JUNFUT"
    },
    {
      "instrument_token": "16310530",
      "name": "IPCALAB",
      "tradingSymbol": "IPCALAB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "650",
      "motherInstrument": "418049",
      "category": "futures",
      "label": "IPCALAB24JULFUT",
      "value": "IPCALAB24JULFUT"
    },
    {
      "instrument_token": "14453762",
      "name": "IPCALAB",
      "tradingSymbol": "IPCALAB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "650",
      "motherInstrument": "418049",
      "category": "futures",
      "label": "IPCALAB24AUGFUT",
      "value": "IPCALAB24AUGFUT"
    },
    {
      "instrument_token": "13357314",
      "name": "IRCTC",
      "tradingSymbol": "IRCTC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "875",
      "motherInstrument": "3484417",
      "category": "futures",
      "label": "IRCTC24JUNFUT",
      "value": "IRCTC24JUNFUT"
    },
    {
      "instrument_token": "16310786",
      "name": "IRCTC",
      "tradingSymbol": "IRCTC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "875",
      "motherInstrument": "3484417",
      "category": "futures",
      "label": "IRCTC24JULFUT",
      "value": "IRCTC24JULFUT"
    },
    {
      "instrument_token": "14454018",
      "name": "IRCTC",
      "tradingSymbol": "IRCTC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "875",
      "motherInstrument": "3484417",
      "category": "futures",
      "label": "IRCTC24AUGFUT",
      "value": "IRCTC24AUGFUT"
    },
    {
      "instrument_token": "13357570",
      "name": "ITC",
      "tradingSymbol": "ITC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1600",
      "motherInstrument": "424961",
      "category": "futures",
      "label": "ITC24JUNFUT",
      "value": "ITC24JUNFUT"
    },
    {
      "instrument_token": "16311042",
      "name": "ITC",
      "tradingSymbol": "ITC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1600",
      "motherInstrument": "424961",
      "category": "futures",
      "label": "ITC24JULFUT",
      "value": "ITC24JULFUT"
    },
    {
      "instrument_token": "14454274",
      "name": "ITC",
      "tradingSymbol": "ITC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1600",
      "motherInstrument": "424961",
      "category": "futures",
      "label": "ITC24AUGFUT",
      "value": "ITC24AUGFUT"
    },
    {
      "instrument_token": "13358338",
      "name": "JINDALSTEL",
      "tradingSymbol": "JINDALSTEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "625",
      "motherInstrument": "1723649",
      "category": "futures",
      "label": "JINDALSTEL24JUNFUT",
      "value": "JINDALSTEL24JUNFUT"
    },
    {
      "instrument_token": "16311298",
      "name": "JINDALSTEL",
      "tradingSymbol": "JINDALSTEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "625",
      "motherInstrument": "1723649",
      "category": "futures",
      "label": "JINDALSTEL24JULFUT",
      "value": "JINDALSTEL24JULFUT"
    },
    {
      "instrument_token": "14454530",
      "name": "JINDALSTEL",
      "tradingSymbol": "JINDALSTEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "625",
      "motherInstrument": "1723649",
      "category": "futures",
      "label": "JINDALSTEL24AUGFUT",
      "value": "JINDALSTEL24AUGFUT"
    },
    {
      "instrument_token": "13358594",
      "name": "JKCEMENT",
      "tradingSymbol": "JKCEMENT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "125",
      "motherInstrument": "3397121",
      "category": "futures",
      "label": "JKCEMENT24JUNFUT",
      "value": "JKCEMENT24JUNFUT"
    },
    {
      "instrument_token": "16311554",
      "name": "JKCEMENT",
      "tradingSymbol": "JKCEMENT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "125",
      "motherInstrument": "3397121",
      "category": "futures",
      "label": "JKCEMENT24JULFUT",
      "value": "JKCEMENT24JULFUT"
    },
    {
      "instrument_token": "14454786",
      "name": "JKCEMENT",
      "tradingSymbol": "JKCEMENT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "125",
      "motherInstrument": "3397121",
      "category": "futures",
      "label": "JKCEMENT24AUGFUT",
      "value": "JKCEMENT24AUGFUT"
    },
    {
      "instrument_token": "13360130",
      "name": "JSWSTEEL",
      "tradingSymbol": "JSWSTEEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "675",
      "motherInstrument": "3001089",
      "category": "futures",
      "label": "JSWSTEEL24JUNFUT",
      "value": "JSWSTEEL24JUNFUT"
    },
    {
      "instrument_token": "16311810",
      "name": "JSWSTEEL",
      "tradingSymbol": "JSWSTEEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "675",
      "motherInstrument": "3001089",
      "category": "futures",
      "label": "JSWSTEEL24JULFUT",
      "value": "JSWSTEEL24JULFUT"
    },
    {
      "instrument_token": "14455042",
      "name": "JSWSTEEL",
      "tradingSymbol": "JSWSTEEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "675",
      "motherInstrument": "3001089",
      "category": "futures",
      "label": "JSWSTEEL24AUGFUT",
      "value": "JSWSTEEL24AUGFUT"
    },
    {
      "instrument_token": "13360386",
      "name": "JUBLFOOD",
      "tradingSymbol": "JUBLFOOD24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1250",
      "motherInstrument": "4632577",
      "category": "futures",
      "label": "JUBLFOOD24JUNFUT",
      "value": "JUBLFOOD24JUNFUT"
    },
    {
      "instrument_token": "16312066",
      "name": "JUBLFOOD",
      "tradingSymbol": "JUBLFOOD24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1250",
      "motherInstrument": "4632577",
      "category": "futures",
      "label": "JUBLFOOD24JULFUT",
      "value": "JUBLFOOD24JULFUT"
    },
    {
      "instrument_token": "14455298",
      "name": "JUBLFOOD",
      "tradingSymbol": "JUBLFOOD24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1250",
      "motherInstrument": "4632577",
      "category": "futures",
      "label": "JUBLFOOD24AUGFUT",
      "value": "JUBLFOOD24AUGFUT"
    },
    {
      "instrument_token": "13361666",
      "name": "KOTAKBANK",
      "tradingSymbol": "KOTAKBANK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "400",
      "motherInstrument": "492033",
      "category": "futures",
      "label": "KOTAKBANK24JUNFUT",
      "value": "KOTAKBANK24JUNFUT"
    },
    {
      "instrument_token": "16312834",
      "name": "KOTAKBANK",
      "tradingSymbol": "KOTAKBANK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "400",
      "motherInstrument": "492033",
      "category": "futures",
      "label": "KOTAKBANK24JULFUT",
      "value": "KOTAKBANK24JULFUT"
    },
    {
      "instrument_token": "14455554",
      "name": "KOTAKBANK",
      "tradingSymbol": "KOTAKBANK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "400",
      "motherInstrument": "492033",
      "category": "futures",
      "label": "KOTAKBANK24AUGFUT",
      "value": "KOTAKBANK24AUGFUT"
    },
    {
      "instrument_token": "13365250",
      "name": "LALPATHLAB",
      "tradingSymbol": "LALPATHLAB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "2983425",
      "category": "futures",
      "label": "LALPATHLAB24JUNFUT",
      "value": "LALPATHLAB24JUNFUT"
    },
    {
      "instrument_token": "16313090",
      "name": "LALPATHLAB",
      "tradingSymbol": "LALPATHLAB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "2983425",
      "category": "futures",
      "label": "LALPATHLAB24JULFUT",
      "value": "LALPATHLAB24JULFUT"
    },
    {
      "instrument_token": "14455810",
      "name": "LALPATHLAB",
      "tradingSymbol": "LALPATHLAB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "2983425",
      "category": "futures",
      "label": "LALPATHLAB24AUGFUT",
      "value": "LALPATHLAB24AUGFUT"
    },
    {
      "instrument_token": "13365506",
      "name": "LAURUSLABS",
      "tradingSymbol": "LAURUSLABS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1700",
      "motherInstrument": "4923905",
      "category": "futures",
      "label": "LAURUSLABS24JUNFUT",
      "value": "LAURUSLABS24JUNFUT"
    },
    {
      "instrument_token": "16313346",
      "name": "LAURUSLABS",
      "tradingSymbol": "LAURUSLABS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1700",
      "motherInstrument": "4923905",
      "category": "futures",
      "label": "LAURUSLABS24JULFUT",
      "value": "LAURUSLABS24JULFUT"
    },
    {
      "instrument_token": "14456066",
      "name": "LAURUSLABS",
      "tradingSymbol": "LAURUSLABS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1700",
      "motherInstrument": "4923905",
      "category": "futures",
      "label": "LAURUSLABS24AUGFUT",
      "value": "LAURUSLABS24AUGFUT"
    },
    {
      "instrument_token": "13366274",
      "name": "LICHSGFIN",
      "tradingSymbol": "LICHSGFIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "511233",
      "category": "futures",
      "label": "LICHSGFIN24JUNFUT",
      "value": "LICHSGFIN24JUNFUT"
    },
    {
      "instrument_token": "16313602",
      "name": "LICHSGFIN",
      "tradingSymbol": "LICHSGFIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "511233",
      "category": "futures",
      "label": "LICHSGFIN24JULFUT",
      "value": "LICHSGFIN24JULFUT"
    },
    {
      "instrument_token": "14456322",
      "name": "LICHSGFIN",
      "tradingSymbol": "LICHSGFIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "511233",
      "category": "futures",
      "label": "LICHSGFIN24AUGFUT",
      "value": "LICHSGFIN24AUGFUT"
    },
    {
      "instrument_token": "13366530",
      "name": "LT",
      "tradingSymbol": "LT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "2939649",
      "category": "futures",
      "label": "LT24JUNFUT",
      "value": "LT24JUNFUT"
    },
    {
      "instrument_token": "16314882",
      "name": "LT",
      "tradingSymbol": "LT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "2939649",
      "category": "futures",
      "label": "LT24JULFUT",
      "value": "LT24JULFUT"
    },
    {
      "instrument_token": "14456578",
      "name": "LT",
      "tradingSymbol": "LT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "2939649",
      "category": "futures",
      "label": "LT24AUGFUT",
      "value": "LT24AUGFUT"
    },
    {
      "instrument_token": "13361922",
      "name": "LTF",
      "tradingSymbol": "LTF24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "4462",
      "motherInstrument": "6386689",
      "category": "futures",
      "label": "LTF24JUNFUT",
      "value": "LTF24JUNFUT"
    },
    {
      "instrument_token": "16315138",
      "name": "LTF",
      "tradingSymbol": "LTF24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "4462",
      "motherInstrument": "6386689",
      "category": "futures",
      "label": "LTF24JULFUT",
      "value": "LTF24JULFUT"
    },
    {
      "instrument_token": "14456834",
      "name": "LTF",
      "tradingSymbol": "LTF24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "4462",
      "motherInstrument": "6386689",
      "category": "futures",
      "label": "LTF24AUGFUT",
      "value": "LTF24AUGFUT"
    },
    {
      "instrument_token": "13367042",
      "name": "LTIM",
      "tradingSymbol": "LTIM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "4561409",
      "category": "futures",
      "label": "LTIM24JUNFUT",
      "value": "LTIM24JUNFUT"
    },
    {
      "instrument_token": "16316418",
      "name": "LTIM",
      "tradingSymbol": "LTIM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "4561409",
      "category": "futures",
      "label": "LTIM24JULFUT",
      "value": "LTIM24JULFUT"
    },
    {
      "instrument_token": "14457090",
      "name": "LTIM",
      "tradingSymbol": "LTIM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "4561409",
      "category": "futures",
      "label": "LTIM24AUGFUT",
      "value": "LTIM24AUGFUT"
    },
    {
      "instrument_token": "13367298",
      "name": "LTTS",
      "tradingSymbol": "LTTS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "100",
      "motherInstrument": "4752385",
      "category": "futures",
      "label": "LTTS24JUNFUT",
      "value": "LTTS24JUNFUT"
    },
    {
      "instrument_token": "16316674",
      "name": "LTTS",
      "tradingSymbol": "LTTS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "100",
      "motherInstrument": "4752385",
      "category": "futures",
      "label": "LTTS24JULFUT",
      "value": "LTTS24JULFUT"
    },
    {
      "instrument_token": "14457346",
      "name": "LTTS",
      "tradingSymbol": "LTTS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "100",
      "motherInstrument": "4752385",
      "category": "futures",
      "label": "LTTS24AUGFUT",
      "value": "LTTS24AUGFUT"
    },
    {
      "instrument_token": "13367554",
      "name": "LUPIN",
      "tradingSymbol": "LUPIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "425",
      "motherInstrument": "2672641",
      "category": "futures",
      "label": "LUPIN24JUNFUT",
      "value": "LUPIN24JUNFUT"
    },
    {
      "instrument_token": "16316930",
      "name": "LUPIN",
      "tradingSymbol": "LUPIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "425",
      "motherInstrument": "2672641",
      "category": "futures",
      "label": "LUPIN24JULFUT",
      "value": "LUPIN24JULFUT"
    },
    {
      "instrument_token": "14457602",
      "name": "LUPIN",
      "tradingSymbol": "LUPIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "425",
      "motherInstrument": "2672641",
      "category": "futures",
      "label": "LUPIN24AUGFUT",
      "value": "LUPIN24AUGFUT"
    },
    {
      "instrument_token": "13367810",
      "name": "M&M",
      "tradingSymbol": "M&M24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "350",
      "motherInstrument": "519937",
      "category": "futures",
      "label": "M&M24JUNFUT",
      "value": "M&M24JUNFUT"
    },
    {
      "instrument_token": "16317186",
      "name": "M&M",
      "tradingSymbol": "M&M24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "350",
      "motherInstrument": "519937",
      "category": "futures",
      "label": "M&M24JULFUT",
      "value": "M&M24JULFUT"
    },
    {
      "instrument_token": "14457858",
      "name": "M&M",
      "tradingSymbol": "M&M24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "350",
      "motherInstrument": "519937",
      "category": "futures",
      "label": "M&M24AUGFUT",
      "value": "M&M24AUGFUT"
    },
    {
      "instrument_token": "13368066",
      "name": "M&MFIN",
      "tradingSymbol": "M&MFIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2000",
      "motherInstrument": "3400961",
      "category": "futures",
      "label": "M&MFIN24JUNFUT",
      "value": "M&MFIN24JUNFUT"
    },
    {
      "instrument_token": "16319490",
      "name": "M&MFIN",
      "tradingSymbol": "M&MFIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2000",
      "motherInstrument": "3400961",
      "category": "futures",
      "label": "M&MFIN24JULFUT",
      "value": "M&MFIN24JULFUT"
    },
    {
      "instrument_token": "14458114",
      "name": "M&MFIN",
      "tradingSymbol": "M&MFIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2000",
      "motherInstrument": "3400961",
      "category": "futures",
      "label": "M&MFIN24AUGFUT",
      "value": "M&MFIN24AUGFUT"
    },
    {
      "instrument_token": "13368578",
      "name": "MANAPPURAM",
      "tradingSymbol": "MANAPPURAM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3000",
      "motherInstrument": "4879617",
      "category": "futures",
      "label": "MANAPPURAM24JUNFUT",
      "value": "MANAPPURAM24JUNFUT"
    },
    {
      "instrument_token": "16319746",
      "name": "MANAPPURAM",
      "tradingSymbol": "MANAPPURAM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "3000",
      "motherInstrument": "4879617",
      "category": "futures",
      "label": "MANAPPURAM24JULFUT",
      "value": "MANAPPURAM24JULFUT"
    },
    {
      "instrument_token": "14458370",
      "name": "MANAPPURAM",
      "tradingSymbol": "MANAPPURAM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "3000",
      "motherInstrument": "4879617",
      "category": "futures",
      "label": "MANAPPURAM24AUGFUT",
      "value": "MANAPPURAM24AUGFUT"
    },
    {
      "instrument_token": "13371138",
      "name": "MARICO",
      "tradingSymbol": "MARICO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1200",
      "motherInstrument": "1041153",
      "category": "futures",
      "label": "MARICO24JUNFUT",
      "value": "MARICO24JUNFUT"
    },
    {
      "instrument_token": "16320002",
      "name": "MARICO",
      "tradingSymbol": "MARICO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1200",
      "motherInstrument": "1041153",
      "category": "futures",
      "label": "MARICO24JULFUT",
      "value": "MARICO24JULFUT"
    },
    {
      "instrument_token": "14458626",
      "name": "MARICO",
      "tradingSymbol": "MARICO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1200",
      "motherInstrument": "1041153",
      "category": "futures",
      "label": "MARICO24AUGFUT",
      "value": "MARICO24AUGFUT"
    },
    {
      "instrument_token": "13371394",
      "name": "MARUTI",
      "tradingSymbol": "MARUTI24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "50",
      "motherInstrument": "2815745",
      "category": "futures",
      "label": "MARUTI24JUNFUT",
      "value": "MARUTI24JUNFUT"
    },
    {
      "instrument_token": "16320258",
      "name": "MARUTI",
      "tradingSymbol": "MARUTI24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "50",
      "motherInstrument": "2815745",
      "category": "futures",
      "label": "MARUTI24JULFUT",
      "value": "MARUTI24JULFUT"
    },
    {
      "instrument_token": "14458882",
      "name": "MARUTI",
      "tradingSymbol": "MARUTI24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "50",
      "motherInstrument": "2815745",
      "category": "futures",
      "label": "MARUTI24AUGFUT",
      "value": "MARUTI24AUGFUT"
    },
    {
      "instrument_token": "13372674",
      "name": "MCX",
      "tradingSymbol": "MCX24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "7982337",
      "category": "futures",
      "label": "MCX24JUNFUT",
      "value": "MCX24JUNFUT"
    },
    {
      "instrument_token": "16320770",
      "name": "MCX",
      "tradingSymbol": "MCX24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "7982337",
      "category": "futures",
      "label": "MCX24JULFUT",
      "value": "MCX24JULFUT"
    },
    {
      "instrument_token": "14459394",
      "name": "MCX",
      "tradingSymbol": "MCX24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "7982337",
      "category": "futures",
      "label": "MCX24AUGFUT",
      "value": "MCX24AUGFUT"
    },
    {
      "instrument_token": "13372930",
      "name": "METROPOLIS",
      "tradingSymbol": "METROPOLIS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "400",
      "motherInstrument": "2452737",
      "category": "futures",
      "label": "METROPOLIS24JUNFUT",
      "value": "METROPOLIS24JUNFUT"
    },
    {
      "instrument_token": "16321026",
      "name": "METROPOLIS",
      "tradingSymbol": "METROPOLIS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "400",
      "motherInstrument": "2452737",
      "category": "futures",
      "label": "METROPOLIS24JULFUT",
      "value": "METROPOLIS24JULFUT"
    },
    {
      "instrument_token": "14459650",
      "name": "METROPOLIS",
      "tradingSymbol": "METROPOLIS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "400",
      "motherInstrument": "2452737",
      "category": "futures",
      "label": "METROPOLIS24AUGFUT",
      "value": "METROPOLIS24AUGFUT"
    },
    {
      "instrument_token": "13373186",
      "name": "MFSL",
      "tradingSymbol": "MFSL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "800",
      "motherInstrument": "548353",
      "category": "futures",
      "label": "MFSL24JUNFUT",
      "value": "MFSL24JUNFUT"
    },
    {
      "instrument_token": "16321282",
      "name": "MFSL",
      "tradingSymbol": "MFSL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "800",
      "motherInstrument": "548353",
      "category": "futures",
      "label": "MFSL24JULFUT",
      "value": "MFSL24JULFUT"
    },
    {
      "instrument_token": "14459906",
      "name": "MFSL",
      "tradingSymbol": "MFSL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "800",
      "motherInstrument": "548353",
      "category": "futures",
      "label": "MFSL24AUGFUT",
      "value": "MFSL24AUGFUT"
    },
    {
      "instrument_token": "13373954",
      "name": "MGL",
      "tradingSymbol": "MGL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "400",
      "motherInstrument": "4488705",
      "category": "futures",
      "label": "MGL24JUNFUT",
      "value": "MGL24JUNFUT"
    },
    {
      "instrument_token": "16324098",
      "name": "MGL",
      "tradingSymbol": "MGL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "400",
      "motherInstrument": "4488705",
      "category": "futures",
      "label": "MGL24JULFUT",
      "value": "MGL24JULFUT"
    },
    {
      "instrument_token": "14460162",
      "name": "MGL",
      "tradingSymbol": "MGL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "400",
      "motherInstrument": "4488705",
      "category": "futures",
      "label": "MGL24AUGFUT",
      "value": "MGL24AUGFUT"
    },
    {
      "instrument_token": "8966402",
      "name": "NIFTY MID SELECT",
      "tradingSymbol": "MIDCPNIFTY24JUNFUT",
      "expiry": "6/24/24",
      "lot_size": "75",
      "motherInstrument": "288009",
      "category": "futures",
      "label": "MIDCPNIFTY24JUNFUT",
      "value": "MIDCPNIFTY24JUNFUT"
    },
    {
      "instrument_token": "8980482",
      "name": "NIFTY MID SELECT",
      "tradingSymbol": "MIDCPNIFTY24JULFUT",
      "expiry": "7/29/24",
      "lot_size": "50",
      "motherInstrument": "288009",
      "category": "futures",
      "label": "MIDCPNIFTY24JULFUT",
      "value": "MIDCPNIFTY24JULFUT"
    },
    {
      "instrument_token": "13537794",
      "name": "NIFTY MID SELECT",
      "tradingSymbol": "MIDCPNIFTY24AUGFUT",
      "expiry": "8/26/24",
      "lot_size": "50",
      "motherInstrument": "288009",
      "category": "futures",
      "label": "MIDCPNIFTY24AUGFUT",
      "value": "MIDCPNIFTY24AUGFUT"
    },
    {
      "instrument_token": "13374210",
      "name": "MOTHERSON",
      "tradingSymbol": "MOTHERSON24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "7100",
      "motherInstrument": "1076225",
      "category": "futures",
      "label": "MOTHERSON24JUNFUT",
      "value": "MOTHERSON24JUNFUT"
    },
    {
      "instrument_token": "16324354",
      "name": "MOTHERSON",
      "tradingSymbol": "MOTHERSON24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "7100",
      "motherInstrument": "1076225",
      "category": "futures",
      "label": "MOTHERSON24JULFUT",
      "value": "MOTHERSON24JULFUT"
    },
    {
      "instrument_token": "14460418",
      "name": "MOTHERSON",
      "tradingSymbol": "MOTHERSON24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "7100",
      "motherInstrument": "1076225",
      "category": "futures",
      "label": "MOTHERSON24AUGFUT",
      "value": "MOTHERSON24AUGFUT"
    },
    {
      "instrument_token": "13374466",
      "name": "MPHASIS",
      "tradingSymbol": "MPHASIS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "275",
      "motherInstrument": "1152769",
      "category": "futures",
      "label": "MPHASIS24JUNFUT",
      "value": "MPHASIS24JUNFUT"
    },
    {
      "instrument_token": "16325122",
      "name": "MPHASIS",
      "tradingSymbol": "MPHASIS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "275",
      "motherInstrument": "1152769",
      "category": "futures",
      "label": "MPHASIS24JULFUT",
      "value": "MPHASIS24JULFUT"
    },
    {
      "instrument_token": "14460674",
      "name": "MPHASIS",
      "tradingSymbol": "MPHASIS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "275",
      "motherInstrument": "1152769",
      "category": "futures",
      "label": "MPHASIS24AUGFUT",
      "value": "MPHASIS24AUGFUT"
    },
    {
      "instrument_token": "13374722",
      "name": "MRF",
      "tradingSymbol": "MRF24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5",
      "motherInstrument": "582913",
      "category": "futures",
      "label": "MRF24JUNFUT",
      "value": "MRF24JUNFUT"
    },
    {
      "instrument_token": "16325378",
      "name": "MRF",
      "tradingSymbol": "MRF24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5",
      "motherInstrument": "582913",
      "category": "futures",
      "label": "MRF24JULFUT",
      "value": "MRF24JULFUT"
    },
    {
      "instrument_token": "14460930",
      "name": "MRF",
      "tradingSymbol": "MRF24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5",
      "motherInstrument": "582913",
      "category": "futures",
      "label": "MRF24AUGFUT",
      "value": "MRF24AUGFUT"
    },
    {
      "instrument_token": "13377026",
      "name": "MUTHOOTFIN",
      "tradingSymbol": "MUTHOOTFIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "550",
      "motherInstrument": "6054401",
      "category": "futures",
      "label": "MUTHOOTFIN24JUNFUT",
      "value": "MUTHOOTFIN24JUNFUT"
    },
    {
      "instrument_token": "16325634",
      "name": "MUTHOOTFIN",
      "tradingSymbol": "MUTHOOTFIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "550",
      "motherInstrument": "6054401",
      "category": "futures",
      "label": "MUTHOOTFIN24JULFUT",
      "value": "MUTHOOTFIN24JULFUT"
    },
    {
      "instrument_token": "14461186",
      "name": "MUTHOOTFIN",
      "tradingSymbol": "MUTHOOTFIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "550",
      "motherInstrument": "6054401",
      "category": "futures",
      "label": "MUTHOOTFIN24AUGFUT",
      "value": "MUTHOOTFIN24AUGFUT"
    },
    {
      "instrument_token": "13377282",
      "name": "NATIONALUM",
      "tradingSymbol": "NATIONALUM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3750",
      "motherInstrument": "1629185",
      "category": "futures",
      "label": "NATIONALUM24JUNFUT",
      "value": "NATIONALUM24JUNFUT"
    },
    {
      "instrument_token": "16325890",
      "name": "NATIONALUM",
      "tradingSymbol": "NATIONALUM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "3750",
      "motherInstrument": "1629185",
      "category": "futures",
      "label": "NATIONALUM24JULFUT",
      "value": "NATIONALUM24JULFUT"
    },
    {
      "instrument_token": "14461442",
      "name": "NATIONALUM",
      "tradingSymbol": "NATIONALUM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "3750",
      "motherInstrument": "1629185",
      "category": "futures",
      "label": "NATIONALUM24AUGFUT",
      "value": "NATIONALUM24AUGFUT"
    },
    {
      "instrument_token": "13379586",
      "name": "NAUKRI",
      "tradingSymbol": "NAUKRI24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "3520257",
      "category": "futures",
      "label": "NAUKRI24JUNFUT",
      "value": "NAUKRI24JUNFUT"
    },
    {
      "instrument_token": "16326146",
      "name": "NAUKRI",
      "tradingSymbol": "NAUKRI24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "3520257",
      "category": "futures",
      "label": "NAUKRI24JULFUT",
      "value": "NAUKRI24JULFUT"
    },
    {
      "instrument_token": "14461698",
      "name": "NAUKRI",
      "tradingSymbol": "NAUKRI24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "3520257",
      "category": "futures",
      "label": "NAUKRI24AUGFUT",
      "value": "NAUKRI24AUGFUT"
    },
    {
      "instrument_token": "13379842",
      "name": "NAVINFLUOR",
      "tradingSymbol": "NAVINFLUOR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "3756033",
      "category": "futures",
      "label": "NAVINFLUOR24JUNFUT",
      "value": "NAVINFLUOR24JUNFUT"
    },
    {
      "instrument_token": "16326402",
      "name": "NAVINFLUOR",
      "tradingSymbol": "NAVINFLUOR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "175",
      "motherInstrument": "3756033",
      "category": "futures",
      "label": "NAVINFLUOR24JULFUT",
      "value": "NAVINFLUOR24JULFUT"
    },
    {
      "instrument_token": "14461954",
      "name": "NAVINFLUOR",
      "tradingSymbol": "NAVINFLUOR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "175",
      "motherInstrument": "3756033",
      "category": "futures",
      "label": "NAVINFLUOR24AUGFUT",
      "value": "NAVINFLUOR24AUGFUT"
    },
    {
      "instrument_token": "13401346",
      "name": "NESTLEIND",
      "tradingSymbol": "NESTLEIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "4598529",
      "category": "futures",
      "label": "NESTLEIND24JUNFUT",
      "value": "NESTLEIND24JUNFUT"
    },
    {
      "instrument_token": "16326658",
      "name": "NESTLEIND",
      "tradingSymbol": "NESTLEIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "4598529",
      "category": "futures",
      "label": "NESTLEIND24JULFUT",
      "value": "NESTLEIND24JULFUT"
    },
    {
      "instrument_token": "14462210",
      "name": "NESTLEIND",
      "tradingSymbol": "NESTLEIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "4598529",
      "category": "futures",
      "label": "NESTLEIND24AUGFUT",
      "value": "NESTLEIND24AUGFUT"
    },
    {
      "instrument_token": "9020930",
      "name": "NIFTY NEXT 50",
      "tradingSymbol": "NIFTYNXT5024JUNFUT",
      "expiry": "6/28/24",
      "lot_size": "10",
      "motherInstrument": "270857",
      "category": "futures",
      "label": "NIFTYNXT5024JUNFUT",
      "value": "NIFTYNXT5024JUNFUT"
    },
    {
      "instrument_token": "9021186",
      "name": "NIFTY NEXT 50",
      "tradingSymbol": "NIFTYNXT5024JULFUT",
      "expiry": "7/26/24",
      "lot_size": "10",
      "motherInstrument": "270857",
      "category": "futures",
      "label": "NIFTYNXT5024JULFUT",
      "value": "NIFTYNXT5024JULFUT"
    },
    {
      "instrument_token": "8979970",
      "name": "NIFTY NEXT 50",
      "tradingSymbol": "NIFTYNXT5024AUGFUT",
      "expiry": "8/30/24",
      "lot_size": "10",
      "motherInstrument": "270857",
      "category": "futures",
      "label": "NIFTYNXT5024AUGFUT",
      "value": "NIFTYNXT5024AUGFUT"
    },
    {
      "instrument_token": "13401602",
      "name": "NMDC",
      "tradingSymbol": "NMDC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "4500",
      "motherInstrument": "3924993",
      "category": "futures",
      "label": "NMDC24JUNFUT",
      "value": "NMDC24JUNFUT"
    },
    {
      "instrument_token": "16326914",
      "name": "NMDC",
      "tradingSymbol": "NMDC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "4500",
      "motherInstrument": "3924993",
      "category": "futures",
      "label": "NMDC24JULFUT",
      "value": "NMDC24JULFUT"
    },
    {
      "instrument_token": "14462466",
      "name": "NMDC",
      "tradingSymbol": "NMDC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "4500",
      "motherInstrument": "3924993",
      "category": "futures",
      "label": "NMDC24AUGFUT",
      "value": "NMDC24AUGFUT"
    },
    {
      "instrument_token": "14301442",
      "name": "NTPC",
      "tradingSymbol": "NTPC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1500",
      "motherInstrument": "2977281",
      "category": "futures",
      "label": "NTPC24JUNFUT",
      "value": "NTPC24JUNFUT"
    },
    {
      "instrument_token": "16327170",
      "name": "NTPC",
      "tradingSymbol": "NTPC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1500",
      "motherInstrument": "2977281",
      "category": "futures",
      "label": "NTPC24JULFUT",
      "value": "NTPC24JULFUT"
    },
    {
      "instrument_token": "14462722",
      "name": "NTPC",
      "tradingSymbol": "NTPC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1500",
      "motherInstrument": "2977281",
      "category": "futures",
      "label": "NTPC24AUGFUT",
      "value": "NTPC24AUGFUT"
    },
    {
      "instrument_token": "14301698",
      "name": "OBEROIRLTY",
      "tradingSymbol": "OBEROIRLTY24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "700",
      "motherInstrument": "5181953",
      "category": "futures",
      "label": "OBEROIRLTY24JUNFUT",
      "value": "OBEROIRLTY24JUNFUT"
    },
    {
      "instrument_token": "16327426",
      "name": "OBEROIRLTY",
      "tradingSymbol": "OBEROIRLTY24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "700",
      "motherInstrument": "5181953",
      "category": "futures",
      "label": "OBEROIRLTY24JULFUT",
      "value": "OBEROIRLTY24JULFUT"
    },
    {
      "instrument_token": "14462978",
      "name": "OBEROIRLTY",
      "tradingSymbol": "OBEROIRLTY24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "700",
      "motherInstrument": "5181953",
      "category": "futures",
      "label": "OBEROIRLTY24AUGFUT",
      "value": "OBEROIRLTY24AUGFUT"
    },
    {
      "instrument_token": "15066370",
      "name": "OFSS",
      "tradingSymbol": "OFSS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "100",
      "motherInstrument": "2748929",
      "category": "futures",
      "label": "OFSS24JUNFUT",
      "value": "OFSS24JUNFUT"
    },
    {
      "instrument_token": "16327682",
      "name": "OFSS",
      "tradingSymbol": "OFSS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "100",
      "motherInstrument": "2748929",
      "category": "futures",
      "label": "OFSS24JULFUT",
      "value": "OFSS24JULFUT"
    },
    {
      "instrument_token": "14463234",
      "name": "OFSS",
      "tradingSymbol": "OFSS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "100",
      "motherInstrument": "2748929",
      "category": "futures",
      "label": "OFSS24AUGFUT",
      "value": "OFSS24AUGFUT"
    },
    {
      "instrument_token": "15066626",
      "name": "ONGC",
      "tradingSymbol": "ONGC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1925",
      "motherInstrument": "633601",
      "category": "futures",
      "label": "ONGC24JUNFUT",
      "value": "ONGC24JUNFUT"
    },
    {
      "instrument_token": "16327938",
      "name": "ONGC",
      "tradingSymbol": "ONGC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1925",
      "motherInstrument": "633601",
      "category": "futures",
      "label": "ONGC24JULFUT",
      "value": "ONGC24JULFUT"
    },
    {
      "instrument_token": "14463490",
      "name": "ONGC",
      "tradingSymbol": "ONGC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1925",
      "motherInstrument": "633601",
      "category": "futures",
      "label": "ONGC24AUGFUT",
      "value": "ONGC24AUGFUT"
    },
    {
      "instrument_token": "15069442",
      "name": "PAGEIND",
      "tradingSymbol": "PAGEIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "15",
      "motherInstrument": "3689729",
      "category": "futures",
      "label": "PAGEIND24JUNFUT",
      "value": "PAGEIND24JUNFUT"
    },
    {
      "instrument_token": "16328194",
      "name": "PAGEIND",
      "tradingSymbol": "PAGEIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "15",
      "motherInstrument": "3689729",
      "category": "futures",
      "label": "PAGEIND24JULFUT",
      "value": "PAGEIND24JULFUT"
    },
    {
      "instrument_token": "14463746",
      "name": "PAGEIND",
      "tradingSymbol": "PAGEIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "15",
      "motherInstrument": "3689729",
      "category": "futures",
      "label": "PAGEIND24AUGFUT",
      "value": "PAGEIND24AUGFUT"
    },
    {
      "instrument_token": "15069698",
      "name": "PEL",
      "tradingSymbol": "PEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "750",
      "motherInstrument": "617473",
      "category": "futures",
      "label": "PEL24JUNFUT",
      "value": "PEL24JUNFUT"
    },
    {
      "instrument_token": "16328450",
      "name": "PEL",
      "tradingSymbol": "PEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "750",
      "motherInstrument": "617473",
      "category": "futures",
      "label": "PEL24JULFUT",
      "value": "PEL24JULFUT"
    },
    {
      "instrument_token": "14464002",
      "name": "PEL",
      "tradingSymbol": "PEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "750",
      "motherInstrument": "617473",
      "category": "futures",
      "label": "PEL24AUGFUT",
      "value": "PEL24AUGFUT"
    },
    {
      "instrument_token": "15071746",
      "name": "PERSISTENT",
      "tradingSymbol": "PERSISTENT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "4701441",
      "category": "futures",
      "label": "PERSISTENT24JUNFUT",
      "value": "PERSISTENT24JUNFUT"
    },
    {
      "instrument_token": "16328706",
      "name": "PERSISTENT",
      "tradingSymbol": "PERSISTENT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "4701441",
      "category": "futures",
      "label": "PERSISTENT24JULFUT",
      "value": "PERSISTENT24JULFUT"
    },
    {
      "instrument_token": "14464258",
      "name": "PERSISTENT",
      "tradingSymbol": "PERSISTENT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "4701441",
      "category": "futures",
      "label": "PERSISTENT24AUGFUT",
      "value": "PERSISTENT24AUGFUT"
    },
    {
      "instrument_token": "15072258",
      "name": "PETRONET",
      "tradingSymbol": "PETRONET24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3000",
      "motherInstrument": "2905857",
      "category": "futures",
      "label": "PETRONET24JUNFUT",
      "value": "PETRONET24JUNFUT"
    },
    {
      "instrument_token": "16328962",
      "name": "PETRONET",
      "tradingSymbol": "PETRONET24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "3000",
      "motherInstrument": "2905857",
      "category": "futures",
      "label": "PETRONET24JULFUT",
      "value": "PETRONET24JULFUT"
    },
    {
      "instrument_token": "14464514",
      "name": "PETRONET",
      "tradingSymbol": "PETRONET24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "3000",
      "motherInstrument": "2905857",
      "category": "futures",
      "label": "PETRONET24AUGFUT",
      "value": "PETRONET24AUGFUT"
    },
    {
      "instrument_token": "15072514",
      "name": "PFC",
      "tradingSymbol": "PFC24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3875",
      "motherInstrument": "3660545",
      "category": "futures",
      "label": "PFC24JUNFUT",
      "value": "PFC24JUNFUT"
    },
    {
      "instrument_token": "16331266",
      "name": "PFC",
      "tradingSymbol": "PFC24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1300",
      "motherInstrument": "3660545",
      "category": "futures",
      "label": "PFC24JULFUT",
      "value": "PFC24JULFUT"
    },
    {
      "instrument_token": "14464770",
      "name": "PFC",
      "tradingSymbol": "PFC24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1300",
      "motherInstrument": "3660545",
      "category": "futures",
      "label": "PFC24AUGFUT",
      "value": "PFC24AUGFUT"
    },
    {
      "instrument_token": "15072770",
      "name": "PIDILITIND",
      "tradingSymbol": "PIDILITIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "250",
      "motherInstrument": "681985",
      "category": "futures",
      "label": "PIDILITIND24JUNFUT",
      "value": "PIDILITIND24JUNFUT"
    },
    {
      "instrument_token": "16331522",
      "name": "PIDILITIND",
      "tradingSymbol": "PIDILITIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "250",
      "motherInstrument": "681985",
      "category": "futures",
      "label": "PIDILITIND24JULFUT",
      "value": "PIDILITIND24JULFUT"
    },
    {
      "instrument_token": "14465026",
      "name": "PIDILITIND",
      "tradingSymbol": "PIDILITIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "250",
      "motherInstrument": "681985",
      "category": "futures",
      "label": "PIDILITIND24AUGFUT",
      "value": "PIDILITIND24AUGFUT"
    },
    {
      "instrument_token": "15075074",
      "name": "PIIND",
      "tradingSymbol": "PIIND24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "250",
      "motherInstrument": "6191105",
      "category": "futures",
      "label": "PIIND24JUNFUT",
      "value": "PIIND24JUNFUT"
    },
    {
      "instrument_token": "16331778",
      "name": "PIIND",
      "tradingSymbol": "PIIND24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "250",
      "motherInstrument": "6191105",
      "category": "futures",
      "label": "PIIND24JULFUT",
      "value": "PIIND24JULFUT"
    },
    {
      "instrument_token": "14465282",
      "name": "PIIND",
      "tradingSymbol": "PIIND24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "250",
      "motherInstrument": "6191105",
      "category": "futures",
      "label": "PIIND24AUGFUT",
      "value": "PIIND24AUGFUT"
    },
    {
      "instrument_token": "15075330",
      "name": "PNB",
      "tradingSymbol": "PNB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "8000",
      "motherInstrument": "2730497",
      "category": "futures",
      "label": "PNB24JUNFUT",
      "value": "PNB24JUNFUT"
    },
    {
      "instrument_token": "16332034",
      "name": "PNB",
      "tradingSymbol": "PNB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "8000",
      "motherInstrument": "2730497",
      "category": "futures",
      "label": "PNB24JULFUT",
      "value": "PNB24JULFUT"
    },
    {
      "instrument_token": "14465538",
      "name": "PNB",
      "tradingSymbol": "PNB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "8000",
      "motherInstrument": "2730497",
      "category": "futures",
      "label": "PNB24AUGFUT",
      "value": "PNB24AUGFUT"
    },
    {
      "instrument_token": "15076610",
      "name": "POLYCAB",
      "tradingSymbol": "POLYCAB24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "100",
      "motherInstrument": "2455041",
      "category": "futures",
      "label": "POLYCAB24JUNFUT",
      "value": "POLYCAB24JUNFUT"
    },
    {
      "instrument_token": "16332290",
      "name": "POLYCAB",
      "tradingSymbol": "POLYCAB24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "125",
      "motherInstrument": "2455041",
      "category": "futures",
      "label": "POLYCAB24JULFUT",
      "value": "POLYCAB24JULFUT"
    },
    {
      "instrument_token": "14465794",
      "name": "POLYCAB",
      "tradingSymbol": "POLYCAB24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "125",
      "motherInstrument": "2455041",
      "category": "futures",
      "label": "POLYCAB24AUGFUT",
      "value": "POLYCAB24AUGFUT"
    },
    {
      "instrument_token": "15076866",
      "name": "POWERGRID",
      "tradingSymbol": "POWERGRID24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3600",
      "motherInstrument": "3834113",
      "category": "futures",
      "label": "POWERGRID24JUNFUT",
      "value": "POWERGRID24JUNFUT"
    },
    {
      "instrument_token": "16332546",
      "name": "POWERGRID",
      "tradingSymbol": "POWERGRID24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "3600",
      "motherInstrument": "3834113",
      "category": "futures",
      "label": "POWERGRID24JULFUT",
      "value": "POWERGRID24JULFUT"
    },
    {
      "instrument_token": "14466050",
      "name": "POWERGRID",
      "tradingSymbol": "POWERGRID24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "3600",
      "motherInstrument": "3834113",
      "category": "futures",
      "label": "POWERGRID24AUGFUT",
      "value": "POWERGRID24AUGFUT"
    },
    {
      "instrument_token": "15078146",
      "name": "PVRINOX",
      "tradingSymbol": "PVRINOX24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "407",
      "motherInstrument": "3365633",
      "category": "futures",
      "label": "PVRINOX24JUNFUT",
      "value": "PVRINOX24JUNFUT"
    },
    {
      "instrument_token": "16332802",
      "name": "PVRINOX",
      "tradingSymbol": "PVRINOX24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "407",
      "motherInstrument": "3365633",
      "category": "futures",
      "label": "PVRINOX24JULFUT",
      "value": "PVRINOX24JULFUT"
    },
    {
      "instrument_token": "14466306",
      "name": "PVRINOX",
      "tradingSymbol": "PVRINOX24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "407",
      "motherInstrument": "3365633",
      "category": "futures",
      "label": "PVRINOX24AUGFUT",
      "value": "PVRINOX24AUGFUT"
    },
    {
      "instrument_token": "15078402",
      "name": "RAMCOCEM",
      "tradingSymbol": "RAMCOCEM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "850",
      "motherInstrument": "523009",
      "category": "futures",
      "label": "RAMCOCEM24JUNFUT",
      "value": "RAMCOCEM24JUNFUT"
    },
    {
      "instrument_token": "16333058",
      "name": "RAMCOCEM",
      "tradingSymbol": "RAMCOCEM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "850",
      "motherInstrument": "523009",
      "category": "futures",
      "label": "RAMCOCEM24JULFUT",
      "value": "RAMCOCEM24JULFUT"
    },
    {
      "instrument_token": "14466562",
      "name": "RAMCOCEM",
      "tradingSymbol": "RAMCOCEM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "850",
      "motherInstrument": "523009",
      "category": "futures",
      "label": "RAMCOCEM24AUGFUT",
      "value": "RAMCOCEM24AUGFUT"
    },
    {
      "instrument_token": "15078658",
      "name": "RBLBANK",
      "tradingSymbol": "RBLBANK24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2500",
      "motherInstrument": "4708097",
      "category": "futures",
      "label": "RBLBANK24JUNFUT",
      "value": "RBLBANK24JUNFUT"
    },
    {
      "instrument_token": "16333826",
      "name": "RBLBANK",
      "tradingSymbol": "RBLBANK24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2500",
      "motherInstrument": "4708097",
      "category": "futures",
      "label": "RBLBANK24JULFUT",
      "value": "RBLBANK24JULFUT"
    },
    {
      "instrument_token": "14466818",
      "name": "RBLBANK",
      "tradingSymbol": "RBLBANK24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2500",
      "motherInstrument": "4708097",
      "category": "futures",
      "label": "RBLBANK24AUGFUT",
      "value": "RBLBANK24AUGFUT"
    },
    {
      "instrument_token": "15078914",
      "name": "RECLTD",
      "tradingSymbol": "RECLTD24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2000",
      "motherInstrument": "3930881",
      "category": "futures",
      "label": "RECLTD24JUNFUT",
      "value": "RECLTD24JUNFUT"
    },
    {
      "instrument_token": "16334082",
      "name": "RECLTD",
      "tradingSymbol": "RECLTD24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2000",
      "motherInstrument": "3930881",
      "category": "futures",
      "label": "RECLTD24JULFUT",
      "value": "RECLTD24JULFUT"
    },
    {
      "instrument_token": "14467074",
      "name": "RECLTD",
      "tradingSymbol": "RECLTD24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2000",
      "motherInstrument": "3930881",
      "category": "futures",
      "label": "RECLTD24AUGFUT",
      "value": "RECLTD24AUGFUT"
    },
    {
      "instrument_token": "15079170",
      "name": "RELIANCE",
      "tradingSymbol": "RELIANCE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "250",
      "motherInstrument": "738561",
      "category": "futures",
      "label": "RELIANCE24JUNFUT",
      "value": "RELIANCE24JUNFUT"
    },
    {
      "instrument_token": "16334338",
      "name": "RELIANCE",
      "tradingSymbol": "RELIANCE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "250",
      "motherInstrument": "738561",
      "category": "futures",
      "label": "RELIANCE24JULFUT",
      "value": "RELIANCE24JULFUT"
    },
    {
      "instrument_token": "14467330",
      "name": "RELIANCE",
      "tradingSymbol": "RELIANCE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "250",
      "motherInstrument": "738561",
      "category": "futures",
      "label": "RELIANCE24AUGFUT",
      "value": "RELIANCE24AUGFUT"
    },
    {
      "instrument_token": "15079426",
      "name": "SAIL",
      "tradingSymbol": "SAIL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "4000",
      "motherInstrument": "758529",
      "category": "futures",
      "label": "SAIL24JUNFUT",
      "value": "SAIL24JUNFUT"
    },
    {
      "instrument_token": "16334594",
      "name": "SAIL",
      "tradingSymbol": "SAIL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "4000",
      "motherInstrument": "758529",
      "category": "futures",
      "label": "SAIL24JULFUT",
      "value": "SAIL24JULFUT"
    },
    {
      "instrument_token": "14467586",
      "name": "SAIL",
      "tradingSymbol": "SAIL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "4000",
      "motherInstrument": "758529",
      "category": "futures",
      "label": "SAIL24AUGFUT",
      "value": "SAIL24AUGFUT"
    },
    {
      "instrument_token": "15079682",
      "name": "SBICARD",
      "tradingSymbol": "SBICARD24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "800",
      "motherInstrument": "4600577",
      "category": "futures",
      "label": "SBICARD24JUNFUT",
      "value": "SBICARD24JUNFUT"
    },
    {
      "instrument_token": "16334850",
      "name": "SBICARD",
      "tradingSymbol": "SBICARD24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "800",
      "motherInstrument": "4600577",
      "category": "futures",
      "label": "SBICARD24JULFUT",
      "value": "SBICARD24JULFUT"
    },
    {
      "instrument_token": "14467842",
      "name": "SBICARD",
      "tradingSymbol": "SBICARD24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "800",
      "motherInstrument": "4600577",
      "category": "futures",
      "label": "SBICARD24AUGFUT",
      "value": "SBICARD24AUGFUT"
    },
    {
      "instrument_token": "15079938",
      "name": "SBILIFE",
      "tradingSymbol": "SBILIFE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "375",
      "motherInstrument": "5582849",
      "category": "futures",
      "label": "SBILIFE24JUNFUT",
      "value": "SBILIFE24JUNFUT"
    },
    {
      "instrument_token": "16335106",
      "name": "SBILIFE",
      "tradingSymbol": "SBILIFE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "375",
      "motherInstrument": "5582849",
      "category": "futures",
      "label": "SBILIFE24JULFUT",
      "value": "SBILIFE24JULFUT"
    },
    {
      "instrument_token": "14468098",
      "name": "SBILIFE",
      "tradingSymbol": "SBILIFE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "375",
      "motherInstrument": "5582849",
      "category": "futures",
      "label": "SBILIFE24AUGFUT",
      "value": "SBILIFE24AUGFUT"
    },
    {
      "instrument_token": "15708930",
      "name": "SBIN",
      "tradingSymbol": "SBIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "750",
      "motherInstrument": "779521",
      "category": "futures",
      "label": "SBIN24JUNFUT",
      "value": "SBIN24JUNFUT"
    },
    {
      "instrument_token": "16337410",
      "name": "SBIN",
      "tradingSymbol": "SBIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "750",
      "motherInstrument": "779521",
      "category": "futures",
      "label": "SBIN24JULFUT",
      "value": "SBIN24JULFUT"
    },
    {
      "instrument_token": "14468354",
      "name": "SBIN",
      "tradingSymbol": "SBIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "750",
      "motherInstrument": "779521",
      "category": "futures",
      "label": "SBIN24AUGFUT",
      "value": "SBIN24AUGFUT"
    },
    {
      "instrument_token": "15709954",
      "name": "SHREECEM",
      "tradingSymbol": "SHREECEM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "25",
      "motherInstrument": "794369",
      "category": "futures",
      "label": "SHREECEM24JUNFUT",
      "value": "SHREECEM24JUNFUT"
    },
    {
      "instrument_token": "16337666",
      "name": "SHREECEM",
      "tradingSymbol": "SHREECEM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "25",
      "motherInstrument": "794369",
      "category": "futures",
      "label": "SHREECEM24JULFUT",
      "value": "SHREECEM24JULFUT"
    },
    {
      "instrument_token": "14468610",
      "name": "SHREECEM",
      "tradingSymbol": "SHREECEM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "25",
      "motherInstrument": "794369",
      "category": "futures",
      "label": "SHREECEM24AUGFUT",
      "value": "SHREECEM24AUGFUT"
    },
    {
      "instrument_token": "16348674",
      "name": "SHRIRAMFIN",
      "tradingSymbol": "SHRIRAMFIN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "300",
      "motherInstrument": "1102337",
      "category": "futures",
      "label": "SHRIRAMFIN24JUNFUT",
      "value": "SHRIRAMFIN24JUNFUT"
    },
    {
      "instrument_token": "16341506",
      "name": "SHRIRAMFIN",
      "tradingSymbol": "SHRIRAMFIN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "300",
      "motherInstrument": "1102337",
      "category": "futures",
      "label": "SHRIRAMFIN24JULFUT",
      "value": "SHRIRAMFIN24JULFUT"
    },
    {
      "instrument_token": "14468866",
      "name": "SHRIRAMFIN",
      "tradingSymbol": "SHRIRAMFIN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "300",
      "motherInstrument": "1102337",
      "category": "futures",
      "label": "SHRIRAMFIN24AUGFUT",
      "value": "SHRIRAMFIN24AUGFUT"
    },
    {
      "instrument_token": "16348930",
      "name": "SIEMENS",
      "tradingSymbol": "SIEMENS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "150",
      "motherInstrument": "806401",
      "category": "futures",
      "label": "SIEMENS24JUNFUT",
      "value": "SIEMENS24JUNFUT"
    },
    {
      "instrument_token": "16341762",
      "name": "SIEMENS",
      "tradingSymbol": "SIEMENS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "150",
      "motherInstrument": "806401",
      "category": "futures",
      "label": "SIEMENS24JULFUT",
      "value": "SIEMENS24JULFUT"
    },
    {
      "instrument_token": "14469122",
      "name": "SIEMENS",
      "tradingSymbol": "SIEMENS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "150",
      "motherInstrument": "806401",
      "category": "futures",
      "label": "SIEMENS24AUGFUT",
      "value": "SIEMENS24AUGFUT"
    },
    {
      "instrument_token": "16349186",
      "name": "SRF",
      "tradingSymbol": "SRF24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "375",
      "motherInstrument": "837889",
      "category": "futures",
      "label": "SRF24JUNFUT",
      "value": "SRF24JUNFUT"
    },
    {
      "instrument_token": "16342018",
      "name": "SRF",
      "tradingSymbol": "SRF24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "375",
      "motherInstrument": "837889",
      "category": "futures",
      "label": "SRF24JULFUT",
      "value": "SRF24JULFUT"
    },
    {
      "instrument_token": "14469378",
      "name": "SRF",
      "tradingSymbol": "SRF24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "375",
      "motherInstrument": "837889",
      "category": "futures",
      "label": "SRF24AUGFUT",
      "value": "SRF24AUGFUT"
    },
    {
      "instrument_token": "16349442",
      "name": "SUNPHARMA",
      "tradingSymbol": "SUNPHARMA24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "350",
      "motherInstrument": "857857",
      "category": "futures",
      "label": "SUNPHARMA24JUNFUT",
      "value": "SUNPHARMA24JUNFUT"
    },
    {
      "instrument_token": "16342274",
      "name": "SUNPHARMA",
      "tradingSymbol": "SUNPHARMA24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "350",
      "motherInstrument": "857857",
      "category": "futures",
      "label": "SUNPHARMA24JULFUT",
      "value": "SUNPHARMA24JULFUT"
    },
    {
      "instrument_token": "14470146",
      "name": "SUNPHARMA",
      "tradingSymbol": "SUNPHARMA24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "350",
      "motherInstrument": "857857",
      "category": "futures",
      "label": "SUNPHARMA24AUGFUT",
      "value": "SUNPHARMA24AUGFUT"
    },
    {
      "instrument_token": "16349698",
      "name": "SUNTV",
      "tradingSymbol": "SUNTV24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1500",
      "motherInstrument": "3431425",
      "category": "futures",
      "label": "SUNTV24JUNFUT",
      "value": "SUNTV24JUNFUT"
    },
    {
      "instrument_token": "16344578",
      "name": "SUNTV",
      "tradingSymbol": "SUNTV24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1500",
      "motherInstrument": "3431425",
      "category": "futures",
      "label": "SUNTV24JULFUT",
      "value": "SUNTV24JULFUT"
    },
    {
      "instrument_token": "14470402",
      "name": "SUNTV",
      "tradingSymbol": "SUNTV24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1500",
      "motherInstrument": "3431425",
      "category": "futures",
      "label": "SUNTV24AUGFUT",
      "value": "SUNTV24AUGFUT"
    },
    {
      "instrument_token": "16349954",
      "name": "SYNGENE",
      "tradingSymbol": "SYNGENE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1000",
      "motherInstrument": "2622209",
      "category": "futures",
      "label": "SYNGENE24JUNFUT",
      "value": "SYNGENE24JUNFUT"
    },
    {
      "instrument_token": "16344834",
      "name": "SYNGENE",
      "tradingSymbol": "SYNGENE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1000",
      "motherInstrument": "2622209",
      "category": "futures",
      "label": "SYNGENE24JULFUT",
      "value": "SYNGENE24JULFUT"
    },
    {
      "instrument_token": "14470658",
      "name": "SYNGENE",
      "tradingSymbol": "SYNGENE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1000",
      "motherInstrument": "2622209",
      "category": "futures",
      "label": "SYNGENE24AUGFUT",
      "value": "SYNGENE24AUGFUT"
    },
    {
      "instrument_token": "16350210",
      "name": "TATACHEM",
      "tradingSymbol": "TATACHEM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "550",
      "motherInstrument": "871681",
      "category": "futures",
      "label": "TATACHEM24JUNFUT",
      "value": "TATACHEM24JUNFUT"
    },
    {
      "instrument_token": "16345090",
      "name": "TATACHEM",
      "tradingSymbol": "TATACHEM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "550",
      "motherInstrument": "871681",
      "category": "futures",
      "label": "TATACHEM24JULFUT",
      "value": "TATACHEM24JULFUT"
    },
    {
      "instrument_token": "14470914",
      "name": "TATACHEM",
      "tradingSymbol": "TATACHEM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "550",
      "motherInstrument": "871681",
      "category": "futures",
      "label": "TATACHEM24AUGFUT",
      "value": "TATACHEM24AUGFUT"
    },
    {
      "instrument_token": "16350466",
      "name": "TATACOMM",
      "tradingSymbol": "TATACOMM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "500",
      "motherInstrument": "952577",
      "category": "futures",
      "label": "TATACOMM24JUNFUT",
      "value": "TATACOMM24JUNFUT"
    },
    {
      "instrument_token": "16345346",
      "name": "TATACOMM",
      "tradingSymbol": "TATACOMM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "500",
      "motherInstrument": "952577",
      "category": "futures",
      "label": "TATACOMM24JULFUT",
      "value": "TATACOMM24JULFUT"
    },
    {
      "instrument_token": "14471426",
      "name": "TATACOMM",
      "tradingSymbol": "TATACOMM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "500",
      "motherInstrument": "952577",
      "category": "futures",
      "label": "TATACOMM24AUGFUT",
      "value": "TATACOMM24AUGFUT"
    },
    {
      "instrument_token": "16350722",
      "name": "TATACONSUM",
      "tradingSymbol": "TATACONSUM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "450",
      "motherInstrument": "878593",
      "category": "futures",
      "label": "TATACONSUM24JUNFUT",
      "value": "TATACONSUM24JUNFUT"
    },
    {
      "instrument_token": "16346114",
      "name": "TATACONSUM",
      "tradingSymbol": "TATACONSUM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "450",
      "motherInstrument": "878593",
      "category": "futures",
      "label": "TATACONSUM24JULFUT",
      "value": "TATACONSUM24JULFUT"
    },
    {
      "instrument_token": "14471682",
      "name": "TATACONSUM",
      "tradingSymbol": "TATACONSUM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "450",
      "motherInstrument": "878593",
      "category": "futures",
      "label": "TATACONSUM24AUGFUT",
      "value": "TATACONSUM24AUGFUT"
    },
    {
      "instrument_token": "16350978",
      "name": "TATAMOTORS",
      "tradingSymbol": "TATAMOTORS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1425",
      "motherInstrument": "884737",
      "category": "futures",
      "label": "TATAMOTORS24JUNFUT",
      "value": "TATAMOTORS24JUNFUT"
    },
    {
      "instrument_token": "16346370",
      "name": "TATAMOTORS",
      "tradingSymbol": "TATAMOTORS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "550",
      "motherInstrument": "884737",
      "category": "futures",
      "label": "TATAMOTORS24JULFUT",
      "value": "TATAMOTORS24JULFUT"
    },
    {
      "instrument_token": "14472194",
      "name": "TATAMOTORS",
      "tradingSymbol": "TATAMOTORS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "550",
      "motherInstrument": "884737",
      "category": "futures",
      "label": "TATAMOTORS24AUGFUT",
      "value": "TATAMOTORS24AUGFUT"
    },
    {
      "instrument_token": "16351234",
      "name": "TATAPOWER",
      "tradingSymbol": "TATAPOWER24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3375",
      "motherInstrument": "877057",
      "category": "futures",
      "label": "TATAPOWER24JUNFUT",
      "value": "TATAPOWER24JUNFUT"
    },
    {
      "instrument_token": "16346626",
      "name": "TATAPOWER",
      "tradingSymbol": "TATAPOWER24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1350",
      "motherInstrument": "877057",
      "category": "futures",
      "label": "TATAPOWER24JULFUT",
      "value": "TATAPOWER24JULFUT"
    },
    {
      "instrument_token": "14472450",
      "name": "TATAPOWER",
      "tradingSymbol": "TATAPOWER24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1350",
      "motherInstrument": "877057",
      "category": "futures",
      "label": "TATAPOWER24AUGFUT",
      "value": "TATAPOWER24AUGFUT"
    },
    {
      "instrument_token": "16351490",
      "name": "TATASTEEL",
      "tradingSymbol": "TATASTEEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "5500",
      "motherInstrument": "895745",
      "category": "futures",
      "label": "TATASTEEL24JUNFUT",
      "value": "TATASTEEL24JUNFUT"
    },
    {
      "instrument_token": "16346882",
      "name": "TATASTEEL",
      "tradingSymbol": "TATASTEEL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "5500",
      "motherInstrument": "895745",
      "category": "futures",
      "label": "TATASTEEL24JULFUT",
      "value": "TATASTEEL24JULFUT"
    },
    {
      "instrument_token": "14472706",
      "name": "TATASTEEL",
      "tradingSymbol": "TATASTEEL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "5500",
      "motherInstrument": "895745",
      "category": "futures",
      "label": "TATASTEEL24AUGFUT",
      "value": "TATASTEEL24AUGFUT"
    },
    {
      "instrument_token": "16351746",
      "name": "TCS",
      "tradingSymbol": "TCS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "175",
      "motherInstrument": "2953217",
      "category": "futures",
      "label": "TCS24JUNFUT",
      "value": "TCS24JUNFUT"
    },
    {
      "instrument_token": "16347138",
      "name": "TCS",
      "tradingSymbol": "TCS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "175",
      "motherInstrument": "2953217",
      "category": "futures",
      "label": "TCS24JULFUT",
      "value": "TCS24JULFUT"
    },
    {
      "instrument_token": "14472962",
      "name": "TCS",
      "tradingSymbol": "TCS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "175",
      "motherInstrument": "2953217",
      "category": "futures",
      "label": "TCS24AUGFUT",
      "value": "TCS24AUGFUT"
    },
    {
      "instrument_token": "16352002",
      "name": "TECHM",
      "tradingSymbol": "TECHM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "600",
      "motherInstrument": "3465729",
      "category": "futures",
      "label": "TECHM24JUNFUT",
      "value": "TECHM24JUNFUT"
    },
    {
      "instrument_token": "16347394",
      "name": "TECHM",
      "tradingSymbol": "TECHM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "600",
      "motherInstrument": "3465729",
      "category": "futures",
      "label": "TECHM24JULFUT",
      "value": "TECHM24JULFUT"
    },
    {
      "instrument_token": "14473218",
      "name": "TECHM",
      "tradingSymbol": "TECHM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "600",
      "motherInstrument": "3465729",
      "category": "futures",
      "label": "TECHM24AUGFUT",
      "value": "TECHM24AUGFUT"
    },
    {
      "instrument_token": "16352258",
      "name": "TITAN",
      "tradingSymbol": "TITAN24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "175",
      "motherInstrument": "897537",
      "category": "futures",
      "label": "TITAN24JUNFUT",
      "value": "TITAN24JUNFUT"
    },
    {
      "instrument_token": "16348162",
      "name": "TITAN",
      "tradingSymbol": "TITAN24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "175",
      "motherInstrument": "897537",
      "category": "futures",
      "label": "TITAN24JULFUT",
      "value": "TITAN24JULFUT"
    },
    {
      "instrument_token": "14473474",
      "name": "TITAN",
      "tradingSymbol": "TITAN24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "175",
      "motherInstrument": "897537",
      "category": "futures",
      "label": "TITAN24AUGFUT",
      "value": "TITAN24AUGFUT"
    },
    {
      "instrument_token": "16352514",
      "name": "TORNTPHARM",
      "tradingSymbol": "TORNTPHARM24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "250",
      "motherInstrument": "900609",
      "category": "futures",
      "label": "TORNTPHARM24JUNFUT",
      "value": "TORNTPHARM24JUNFUT"
    },
    {
      "instrument_token": "16348418",
      "name": "TORNTPHARM",
      "tradingSymbol": "TORNTPHARM24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "250",
      "motherInstrument": "900609",
      "category": "futures",
      "label": "TORNTPHARM24JULFUT",
      "value": "TORNTPHARM24JULFUT"
    },
    {
      "instrument_token": "14473730",
      "name": "TORNTPHARM",
      "tradingSymbol": "TORNTPHARM24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "250",
      "motherInstrument": "900609",
      "category": "futures",
      "label": "TORNTPHARM24AUGFUT",
      "value": "TORNTPHARM24AUGFUT"
    },
    {
      "instrument_token": "16352770",
      "name": "TRENT",
      "tradingSymbol": "TRENT24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "200",
      "motherInstrument": "502785",
      "category": "futures",
      "label": "TRENT24JUNFUT",
      "value": "TRENT24JUNFUT"
    },
    {
      "instrument_token": "16355330",
      "name": "TRENT",
      "tradingSymbol": "TRENT24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "200",
      "motherInstrument": "502785",
      "category": "futures",
      "label": "TRENT24JULFUT",
      "value": "TRENT24JULFUT"
    },
    {
      "instrument_token": "14473986",
      "name": "TRENT",
      "tradingSymbol": "TRENT24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "200",
      "motherInstrument": "502785",
      "category": "futures",
      "label": "TRENT24AUGFUT",
      "value": "TRENT24AUGFUT"
    },
    {
      "instrument_token": "16353026",
      "name": "TVSMOTOR",
      "tradingSymbol": "TVSMOTOR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "350",
      "motherInstrument": "2170625",
      "category": "futures",
      "label": "TVSMOTOR24JUNFUT",
      "value": "TVSMOTOR24JUNFUT"
    },
    {
      "instrument_token": "16355586",
      "name": "TVSMOTOR",
      "tradingSymbol": "TVSMOTOR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "350",
      "motherInstrument": "2170625",
      "category": "futures",
      "label": "TVSMOTOR24JULFUT",
      "value": "TVSMOTOR24JULFUT"
    },
    {
      "instrument_token": "14474242",
      "name": "TVSMOTOR",
      "tradingSymbol": "TVSMOTOR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "350",
      "motherInstrument": "2170625",
      "category": "futures",
      "label": "TVSMOTOR24AUGFUT",
      "value": "TVSMOTOR24AUGFUT"
    },
    {
      "instrument_token": "16353282",
      "name": "UBL",
      "tradingSymbol": "UBL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "400",
      "motherInstrument": "4278529",
      "category": "futures",
      "label": "UBL24JUNFUT",
      "value": "UBL24JUNFUT"
    },
    {
      "instrument_token": "16357378",
      "name": "UBL",
      "tradingSymbol": "UBL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "400",
      "motherInstrument": "4278529",
      "category": "futures",
      "label": "UBL24JULFUT",
      "value": "UBL24JULFUT"
    },
    {
      "instrument_token": "14474498",
      "name": "UBL",
      "tradingSymbol": "UBL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "400",
      "motherInstrument": "4278529",
      "category": "futures",
      "label": "UBL24AUGFUT",
      "value": "UBL24AUGFUT"
    },
    {
      "instrument_token": "16353538",
      "name": "ULTRACEMCO",
      "tradingSymbol": "ULTRACEMCO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "100",
      "motherInstrument": "2952193",
      "category": "futures",
      "label": "ULTRACEMCO24JUNFUT",
      "value": "ULTRACEMCO24JUNFUT"
    },
    {
      "instrument_token": "16357634",
      "name": "ULTRACEMCO",
      "tradingSymbol": "ULTRACEMCO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "100",
      "motherInstrument": "2952193",
      "category": "futures",
      "label": "ULTRACEMCO24JULFUT",
      "value": "ULTRACEMCO24JULFUT"
    },
    {
      "instrument_token": "14474754",
      "name": "ULTRACEMCO",
      "tradingSymbol": "ULTRACEMCO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "100",
      "motherInstrument": "2952193",
      "category": "futures",
      "label": "ULTRACEMCO24AUGFUT",
      "value": "ULTRACEMCO24AUGFUT"
    },
    {
      "instrument_token": "13372418",
      "name": "UNITDSPR",
      "tradingSymbol": "UNITDSPR24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "700",
      "motherInstrument": "2674433",
      "category": "futures",
      "label": "UNITDSPR24JUNFUT",
      "value": "UNITDSPR24JUNFUT"
    },
    {
      "instrument_token": "16320514",
      "name": "UNITDSPR",
      "tradingSymbol": "UNITDSPR24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "700",
      "motherInstrument": "2674433",
      "category": "futures",
      "label": "UNITDSPR24JULFUT",
      "value": "UNITDSPR24JULFUT"
    },
    {
      "instrument_token": "14459138",
      "name": "UNITDSPR",
      "tradingSymbol": "UNITDSPR24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "700",
      "motherInstrument": "2674433",
      "category": "futures",
      "label": "UNITDSPR24AUGFUT",
      "value": "UNITDSPR24AUGFUT"
    },
    {
      "instrument_token": "16353794",
      "name": "UPL",
      "tradingSymbol": "UPL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1300",
      "motherInstrument": "2889473",
      "category": "futures",
      "label": "UPL24JUNFUT",
      "value": "UPL24JUNFUT"
    },
    {
      "instrument_token": "16357890",
      "name": "UPL",
      "tradingSymbol": "UPL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1300",
      "motherInstrument": "2889473",
      "category": "futures",
      "label": "UPL24JULFUT",
      "value": "UPL24JULFUT"
    },
    {
      "instrument_token": "14475010",
      "name": "UPL",
      "tradingSymbol": "UPL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1300",
      "motherInstrument": "2889473",
      "category": "futures",
      "label": "UPL24AUGFUT",
      "value": "UPL24AUGFUT"
    },
    {
      "instrument_token": "16354050",
      "name": "VEDL",
      "tradingSymbol": "VEDL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "2300",
      "motherInstrument": "784129",
      "category": "futures",
      "label": "VEDL24JUNFUT",
      "value": "VEDL24JUNFUT"
    },
    {
      "instrument_token": "16360194",
      "name": "VEDL",
      "tradingSymbol": "VEDL24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "2300",
      "motherInstrument": "784129",
      "category": "futures",
      "label": "VEDL24JULFUT",
      "value": "VEDL24JULFUT"
    },
    {
      "instrument_token": "14475266",
      "name": "VEDL",
      "tradingSymbol": "VEDL24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "2300",
      "motherInstrument": "784129",
      "category": "futures",
      "label": "VEDL24AUGFUT",
      "value": "VEDL24AUGFUT"
    },
    {
      "instrument_token": "16354306",
      "name": "VOLTAS",
      "tradingSymbol": "VOLTAS24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "600",
      "motherInstrument": "951809",
      "category": "futures",
      "label": "VOLTAS24JUNFUT",
      "value": "VOLTAS24JUNFUT"
    },
    {
      "instrument_token": "16360450",
      "name": "VOLTAS",
      "tradingSymbol": "VOLTAS24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "600",
      "motherInstrument": "951809",
      "category": "futures",
      "label": "VOLTAS24JULFUT",
      "value": "VOLTAS24JULFUT"
    },
    {
      "instrument_token": "14475522",
      "name": "VOLTAS",
      "tradingSymbol": "VOLTAS24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "600",
      "motherInstrument": "951809",
      "category": "futures",
      "label": "VOLTAS24AUGFUT",
      "value": "VOLTAS24AUGFUT"
    },
    {
      "instrument_token": "16354562",
      "name": "WIPRO",
      "tradingSymbol": "WIPRO24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "1500",
      "motherInstrument": "969473",
      "category": "futures",
      "label": "WIPRO24JUNFUT",
      "value": "WIPRO24JUNFUT"
    },
    {
      "instrument_token": "16360706",
      "name": "WIPRO",
      "tradingSymbol": "WIPRO24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "1500",
      "motherInstrument": "969473",
      "category": "futures",
      "label": "WIPRO24JULFUT",
      "value": "WIPRO24JULFUT"
    },
    {
      "instrument_token": "14475778",
      "name": "WIPRO",
      "tradingSymbol": "WIPRO24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "1500",
      "motherInstrument": "969473",
      "category": "futures",
      "label": "WIPRO24AUGFUT",
      "value": "WIPRO24AUGFUT"
    },
    {
      "instrument_token": "16354818",
      "name": "ZEEL",
      "tradingSymbol": "ZEEL24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "3000",
      "motherInstrument": "975873",
      "category": "futures",
      "label": "ZEEL24JUNFUT",
      "value": "ZEEL24JUNFUT"
    },
    {
      "instrument_token": "16355074",
      "name": "ZYDUSLIFE",
      "tradingSymbol": "ZYDUSLIFE24JUNFUT",
      "expiry": "6/27/24",
      "lot_size": "900",
      "motherInstrument": "2029825",
      "category": "futures",
      "label": "ZYDUSLIFE24JUNFUT",
      "value": "ZYDUSLIFE24JUNFUT"
    },
    {
      "instrument_token": "16360962",
      "name": "ZYDUSLIFE",
      "tradingSymbol": "ZYDUSLIFE24JULFUT",
      "expiry": "7/25/24",
      "lot_size": "900",
      "motherInstrument": "2029825",
      "category": "futures",
      "label": "ZYDUSLIFE24JULFUT",
      "value": "ZYDUSLIFE24JULFUT"
    },
    {
      "instrument_token": "14476034",
      "name": "ZYDUSLIFE",
      "tradingSymbol": "ZYDUSLIFE24AUGFUT",
      "expiry": "8/29/24",
      "lot_size": "900",
      "motherInstrument": "2029825",
      "category": "futures",
      "label": "ZYDUSLIFE24AUGFUT",
      "value": "ZYDUSLIFE24AUGFUT"
    }
  ]
}


export default insTokens